import React, { useState } from 'react';
import CoreButton from '../../../../../../../components/Forms/Button';
import { BaseBookingPacingRule, ExperienceType } from '../../../../../../../api/api-definitions';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { DISPLAY_DATE_FORMAT, TIMEFORMAT, formatDate } from '../../../../../../../utils/date-helpers';
import { ApiService } from '../../../../../../../api/api-connectors';
import { useBusiness } from '../../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../../services/NotificationService';
import BookingRuleForm from './pacingRuleForm';
import DashboardAddButton from '../../../../../../../components/Dashboard/AddButton';
import { useApi } from '../../../../../../../hooks/useApi';
import Loader from '../../../../../../../components/Layout/Loader';
import { InfoMessage } from '../../../../../../../components/Forms/Messaging';
import { DateBandValidityItem } from '../../../../../../../components/Forms/Calendar';
import { ExperienceService } from '../../Experiences/experienceService';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import ExperienceInfoBadge from '../../Experiences/experienceInfoBadge';
import DayLabels from '../../modules/DayLabels';

const defaultDayValue = true;
const defaultDataForRule: BaseBookingPacingRule = {
    id: undefined,
    monday: defaultDayValue,
    tuesday: defaultDayValue,
    wednesday: defaultDayValue,
    thursday: defaultDayValue,
    friday: defaultDayValue,
    saturday: defaultDayValue,
    sunday: defaultDayValue,
    businessId: undefined,
    pacingInMinutes: undefined
}

export const generateBookingPacingRulesTable = (
    rules: BaseBookingPacingRule[],
    experienceId: number,
    editAction: (item: BaseBookingPacingRule) => void = null,
    deleteAction: (item: BaseBookingPacingRule) => void = null
): DataTableItem[] => {
    const items: DataTableItem[] = [];
    rules?.forEach((item) => {
        const dataItem: DataTableItem = {
            data: {
                'Pacing': {
                    value: `Up to ${item.covers} covers every ${item.pacingInMinutes} mins`
                },
                'Dates': {
                    value: item.dateFrom ? `${formatDate(item.dateFrom, DISPLAY_DATE_FORMAT)} ${item.dateTo ? `to ${formatDate(item.dateTo, DISPLAY_DATE_FORMAT)}` : 'onwards'}` : '-',
                },
                'Time': {
                    value: item.timeFrom ? `${formatDate(item.timeFrom, TIMEFORMAT)} to ${formatDate(item.timeTo, TIMEFORMAT)}` : '-',
                },
                'Days': {
                    value: <DayLabels {...item} />
                },
                'Experience': {
                    value: <>
                        {!experienceId && !!item.experienceId && <ExperienceInfoBadge experienceId={item.experienceId} />}
                    </>,
                    hideName: true
                },
                ...(editAction && deleteAction ? {
                    'Edit': {
                        value: <CoreButton disabled={!experienceId && !!item.experienceId} type='secondary' onClick={() => editAction(item)}>Edit</CoreButton>,
                        hideName: true
                    },
                    'Delete': {
                        value: <CoreButton disabled={!experienceId && !!item.experienceId} type='danger' onClick={() => deleteAction(item)}>Delete</CoreButton>,
                        hideName: true
                    },
                } : {})
            }
        }
        items.push(dataItem);
    });
    return items;
}

interface Props {
    experienceId?: number;
    validDateBands?: DateBandValidityItem[];
}

const PacingRules = ({ experienceId, validDateBands }: Props) => {
    const [businessLoaded, businessData] = useBusiness();
    const activeOnly = !experienceId; // non-expired records only unless we are loading experience setup

    const [rulesLoaded, rules, setRules, updateRule, loadRules] = useApi<BaseBookingPacingRule[]>(
        ApiService.bookingPacingRules.Get__GET,
        { id: businessData.id, experienceId: experienceId || 0, activeOnly: activeOnly },
        'Failed to load pacing rules',
        (response) => ExperienceService._pacingRules = response
    );
    const [editRule, setEditRule] = useState<BaseBookingPacingRule>();
    const [saving, setSaving] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<BaseBookingPacingRule>();

    const deleteRule = () => {
        setSaving(true)
        if (!!itemToDelete) {
            ApiService.bookingPacingRules.Delete__DELETE(itemToDelete).then((response) => {
                if (response.success) {
                    NotificationService.Confirm('Booking pacing rule deleted successfully')
                    setItemToDelete(undefined)
                } else {
                    NotificationService.Error('Something went wrong, could not remove booking pacing rule.')
                }
            }).catch(() => {
                NotificationService.Error('Sorry, there was an error saving your changes.')
            }).finally(() => {
                setSaving(false)
                loadRules()
            })
        }
    }

    const loadRuleTable = () => {
        return generateBookingPacingRulesTable(rules, experienceId, setEditRule, setItemToDelete);
    }

    return (
        <div>
            {itemToDelete &&
                <CoreModal
                    small
                    onClose={() => setItemToDelete(undefined)}
                    title='Are you sure you want to delete this rule?'
                    slimPanel
                    actionBar={<CoreButton type='danger' requesting={saving} disabled={saving} onClick={() => deleteRule()}>Confirm</CoreButton>}
                >

                </CoreModal>
            }
            {editRule &&
                <BookingRuleForm
                    rule={editRule}
                    experienceId={experienceId}
                    allRules={rules}
                    close={() => { setEditRule(undefined); loadRules() }}
                    validDateBands={validDateBands}
                />
            }
            <InfoMessage>
                Create rules to limit the number of covers that can visit in a given period of time and/or on a given date.
            </InfoMessage>
            <br />
            {saving && <Loader />}
            {!saving &&
                <>
                    {rules?.length === 0 &&
                        <InfoMessage>
                            There are no booking pacing rules currently set up.
                        </InfoMessage>
                    }
                    {rules?.length > 0 &&
                        <DataTable data={loadRuleTable()} />
                    }
                    <br />
                    <DashboardAddButton onClick={() => setEditRule({ ...defaultDataForRule, businessId: businessData.id, experienceId })}>Add a booking pacing rule</DashboardAddButton>
                </>
            }
        </div>
    );
};

export default PacingRules;