import React from 'react';

interface ComponentProps {
    title: any;
    children: any;
}

const TabItem = ({ title, children }: ComponentProps) => {
  return children;
};

export default TabItem;