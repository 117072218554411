import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ApiService } from '../../../../../api/api-connectors';
import { BaseMenu, BaseMenuSection, GenericResponse, MenuType } from '../../../../../api/api-definitions';
import CoreButton from '../../../../../components/Forms/Button';
import FormWrapper from '../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../components/Layout/Grid';
import Loader from '../../../../../components/Layout/Loader';
import CoreModal from '../../../../../components/Layout/CoreModal';
import Icon from '../../../../../components/Media/Icon';
import { H2, H3 } from '../../../../../components/Typography/Headings';
import { NotificationService } from '../../../../../services/NotificationService';
import { StyledCalendar, StyledDropdown, StyledTextarea, StyledTextInput } from '../../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import { Constants, MenuDescriptionSize, MenuNameSize } from '../../../../../constants';
import { DATABASE_TIME_FORMAT, formatDate } from '../../../../../utils/date-helpers';
import moment from 'moment';

interface ComponentProps {
    callback: (menu: BaseMenu) => void;
    onClose: () => void;
    open: boolean;
    parentBusinessId: number;
}

const Section = styled.div<{ hover?: boolean; right?: boolean; }>`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
    display: inline-block;
    ${props => props.hover && !props.right ? `
        border-left: 3px solid ${props.theme.primary};
    ` : `border-left: 3px solid transparent;`}

    ${props => props.hover && props.right ? `
        border-right: 3px solid ${props.theme.primary};
    ` : `border-right: 3px solid transparent;`}
`

const SectionButton = styled.div<{ active?: boolean; noPointers?: boolean; }>`
    border: 1px solid ${props => props.theme.primary};
    padding: 0.5rem;
    display: inline-block;
    transition: all 0.5s ease;
    cursor: pointer;
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast};
    ${props => props.noPointers && 'pointer-events: none; touch-action: none;'}

    &:hover {
        opacity: 0.8;
    }

`

interface ExtendedBaseMenu extends BaseMenu {
    sectionList?: string;
    menuSections?: BaseMenuSection[];
}

const AddNewMenu = ({ parentBusinessId, open, onClose, callback }: ComponentProps) => {
    const [newSectionName, setNewSectionName] = useState<string>('');
    const [valid, setValid] = useState<boolean>(false);
    const [dragOverItem, setDragOverItem] = useState<number>();
    const [dragItem, setDragItem] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [menuData, setMenuData] = useState<ExtendedBaseMenu>();
    const history = useHistory();

    const addMenu = () => {
        menuData.businessId = null;
        menuData.parentBusinessId = parentBusinessId;
        menuData.menuSections = [];
        if (!isNullOrWhitespace(menuData.sectionList)) {
            const list = menuData.sectionList.split(',');
            list.forEach(item => {
                menuData.menuSections.push({
                    name: item.trim(),
                    menuId: 0,
                    dishes: [],
                    businessId: null,
                    sortOrder: menuData.menuSections.length,
                    parentBusinessId: parentBusinessId
                })
            });
        }
        setLoading(true)
        if (!isNullOrWhitespace(menuData.validFrom)) menuData.validFrom = formatDate(menuData.validFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(menuData.validTo)) menuData.validTo = formatDate(menuData.validTo, DATABASE_TIME_FORMAT);
        ApiService.menu.Add__PUT(menuData).then((response: GenericResponse) => {
            menuData.id = parseInt(response.info);
            callback(menuData)
            onClose()
            setMenuData(undefined)
            setLoading(false)
            NotificationService.ConfirmOrError('Added new menu', 'Failed to add menu', response.success);
            history.push(`${window.location.pathname}/${menuData.id}`)
        }).catch(() => {
            NotificationService.Error('Failed to add menu');
        })
    }

    const addMenuSection = () => {
        const newData = cloneDeep(menuData)
        if (!newData.menuSections) newData.menuSections = [];
        newData.menuSections.push({
            name: newSectionName,
            menuId: 0,
            sortOrder: newData.menuSections.length,
            parentBusinessId: parentBusinessId
        })
        setMenuData(newData)
        setNewSectionName(undefined)
    }

    const removeMenuSection = (index: number) => {
        const newData = cloneDeep(menuData)
        newData.menuSections.splice(index, 1)
        setMenuData(newData)
    }

    const moveSection = (index: number) => {
        if (dragOverItem !== undefined) {
            const newData = cloneDeep(menuData)
            const item = cloneDeep(newData.menuSections[index]);
            newData.menuSections.splice(index, 1);
            newData.menuSections.splice(dragOverItem, 0, item);
            setMenuData(newData)
        }
    }

    return (
        <>
            {open &&
                <CoreModal title='New menu' onClose={onClose} actionBar={<CoreButton onClick={addMenu} disabled={!valid || loading}>Create menu</CoreButton>}>
                    {!loading && <FormWrapper onUpdate={(data, isValid) => { setMenuData({ ...menuData, ...data }); setValid(isValid) }}>
                        {() => (
                            <>
                                {loading && <Loader />}
                                <Row>
                                    <Column size={6}>
                                        <StyledTextInput required label='Menu name' max={MenuNameSize} model='name' value={menuData?.name} />
                                    </Column>
                                    <Column size={6}>
                                        <StyledDropdown required label='Menu type' model='type' addDefault items={Object.keys(MenuType).map(x => ({ value: x, text: MenuType[x].split(/(?=[A-Z])/).join(' ') }))} value={menuData?.type} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={6}>
                                        <StyledCalendar minDate={menuData?.validFrom || moment()} label='Valid from' model='validFrom' value={menuData?.validFrom} />
                                    </Column>
                                    <Column size={6}>
                                        <StyledCalendar label='Valid to' model='validTo' value={menuData?.validTo} minDate={menuData?.validFrom || moment()} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={12}>
                                        <StyledTextarea label='Menu description' maxLength={MenuDescriptionSize} value={menuData?.description} model='description' />
                                    </Column>
                                </Row>
                                <H3>Menu sections</H3>
                                <Row>
                                    <Column size={6}>
                                        <StyledTextInput label='*Comma separated list of menu section names. These can be amended after creation.' placeholder='Eg, Starters, Mains' model='sectionList' value={menuData?.sectionList} />
                                    </Column>
                                    {/* <Column size={6}>
                                        <CoreButton onClick={addMenuSection}>Add</CoreButton>
                                    </Column> */}
                                </Row>
                                {/* {menuData?.menuSections && menuData.menuSections.map((section, index) => (
                                    <Section
                                        hover={dragOverItem === index}
                                        right={dragItem < index}
                                        onDragOver={() => {if (index !== dragOverItem) {setDragOverItem(index); }}}
                                        onDragEnd={() => {moveSection(index); setDragItem(undefined)}}
                                        onDragStart={() => setDragItem(index)}
                                        draggable
                                        onDragLeave={() => {setTimeout(() => setDragOverItem(undefined), 100);}}
                                    >
                                        <SectionButton
                                            noPointers={dragItem !== undefined}
                                        >{section.name} <Icon name='times' onClick={() => removeMenuSection(index)} /></SectionButton>
                                    </Section>
                                ))} */}

                            </>
                        )}
                    </FormWrapper>}
                </CoreModal>
            }
        </>
    );
};

export default AddNewMenu;