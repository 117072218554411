import React, { useEffect, useRef, useState } from 'react';
import CoreButton from '../../../../../../../components/Forms/Button';
import { BaseDepositRule, DepositCancellationDurationType, DepositPaymentType, DepositRuleType, ExperienceType } from '../../../../../../../api/api-definitions';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { DISPLAY_DATE_FORMAT, formatDate } from '../../../../../../../utils/date-helpers';
import { ApiService } from '../../../../../../../api/api-connectors';
import { useBusiness } from '../../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../../services/NotificationService';
import BookingRuleForm from './depositRuleForm';
import DashboardAddButton from '../../../../../../../components/Dashboard/AddButton';
import Loader from '../../../../../../../components/Layout/Loader';
import DashboardHeader from '../../../../../../../components/Dashboard/Header';
import ActionBox, { InfoMessage } from '../../../../../../../components/Forms/Messaging';
import { Moment } from 'moment';
import { DateBandValidityItem } from '../../../../../../../components/Forms/Calendar';
import { ExperienceService } from '../../Experiences/experienceService';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import ExperienceInfoBadge from '../../Experiences/experienceInfoBadge';
import { GetCurrencySymbol } from '../../../../../../../utils/currency-helper';
import DayLabels from '../../modules/DayLabels';

const defaultDayValue = true;
const defaultDataForRule: BaseDepositRule = {
    id: undefined,
    monday: defaultDayValue,
    tuesday: defaultDayValue,
    wednesday: defaultDayValue,
    thursday: defaultDayValue,
    friday: defaultDayValue,
    saturday: defaultDayValue,
    sunday: defaultDayValue,
    businessId: undefined,
    enabled: false,
    type: DepositRuleType.PerPerson,
    paymentType: DepositPaymentType.TakeOnNoShow,
    cancellationDurationType: DepositCancellationDurationType.Days,
    cancellationDuration: 1,
    amount: undefined
}

interface DescriptionProps {
    item: BaseDepositRule;
}

const AmountDescription = ({ item }: DescriptionProps) => {
    const [loaded, business] = useBusiness();
    let response = GetCurrencySymbol(business?.currencyCode) + item.amount.toString();
    if (item.type == DepositRuleType.PerBooking) {
        response += ' per booking'
    } else {
        response += ' per person'
    }
    if (item.paymentType == DepositPaymentType.TakeNow) {
        response += ' at time of booking.'
        if (item.cancellationDurationType == DepositCancellationDurationType.Days) {
            response += ` Refunded if cancelled ${item.cancellationDuration} day${item.cancellationDuration > 1 ? 's' : ''} before booking date.`
        }
        if (item.cancellationDurationType == DepositCancellationDurationType.Hours) {
            response += ` Refunded if cancelled ${item.cancellationDuration} hour${item.cancellationDuration > 1 ? 's' : ''} before booking date.`
        }
    }
    if (item.paymentType == DepositPaymentType.TakeOnNoShow) {
        response += ' when customer no shows'
    }
    if (item.paymentType == DepositPaymentType.TakeOnCancelAndNoShow) {
        response += ' when customer no shows or cancels '
        if (item.cancellationDurationType == DepositCancellationDurationType.Days) {
            response += `${item.cancellationDuration} day${item.cancellationDuration > 1 ? 's' : ''} before booking date.`
        }
        if (item.cancellationDurationType == DepositCancellationDurationType.Hours) {
            response += `${item.cancellationDuration} hour${item.cancellationDuration > 1 ? 's' : ''} before booking date.`
        }
    }
    return response;

}

export const generateBookingRulesTable = (
    rules: BaseDepositRule[],
    editAction: (item: BaseDepositRule) => void = null,
    deleteAction: (item: BaseDepositRule) => void = null,
    experienceId?: number
): DataTableItem[] => {
    const items: DataTableItem[] = [];
    rules?.forEach((item) => {
        if (!experienceId || item.experienceId == experienceId) {
            const dataItem: DataTableItem = {
                data: {
                    'Amount': {
                        value: <AmountDescription item={item} />,
                        style: { maxWidth: '15rem' }
                    },
                    'Dates': {
                        value: item.dateFrom && item.dateTo ?
                            <>{formatDate(item.dateFrom, DISPLAY_DATE_FORMAT)} to<br />{formatDate(item.dateTo, DISPLAY_DATE_FORMAT)}</> :
                            item.dateFrom ? <>{formatDate(item.dateFrom, DISPLAY_DATE_FORMAT)} onwards</> : '-',
                    },
                    'Times': {
                        value: item.timeFrom ? `${formatDate(item.timeFrom, 'HH:mm')} to ${formatDate(item.timeTo, 'HH:mm')}` : '-',
                    },
                    'Party size': {
                        value: item.partySizeFrom && item.partySizeTo ?
                            `${item.partySizeFrom} to ${item.partySizeTo}` :
                            (item.partySizeFrom ? `${item.partySizeFrom}+` : '-'),
                    },
                    'Days': {
                        value: <DayLabels {...item} />
                    },
                    'Experience': {
                        value: <>
                            {!experienceId && !!item.experienceId && <ExperienceInfoBadge experienceId={item.experienceId} />}
                        </>,
                        hideName: true
                    },
                    ...(editAction && deleteAction ? {
                        'Edit': {
                            value: <CoreButton disabled={!experienceId && !!item.experienceId} type='secondary' onClick={() => editAction(item)}>Edit</CoreButton>,
                            hideName: true
                        },
                        'Delete': {
                            value: <CoreButton disabled={!experienceId && !!item.experienceId} type='danger' onClick={() => deleteAction(item)}>Delete</CoreButton>,
                            hideName: true
                        },
                    } : {})
                }
            }
            items.push(dataItem);
        }
    });
    return items;
}

interface Props {
    experienceId?: number;
    maxPrice?: number;
    validDateBands?: DateBandValidityItem[];
    experienceType?: ExperienceType;
}

const PaymentRules = ({ experienceId, maxPrice, validDateBands, experienceType }: Props) => {
    const [businessLoaded, businessData] = useBusiness();
    const [rules, setRules] = useState<BaseDepositRule[]>();
    const [editRule, setEditRule] = useState<BaseDepositRule>();
    const [saving, setSaving] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<BaseDepositRule>();
    const mounted = useRef(false);

    useEffect(() => {
        if (businessData) {
            if (businessData.stripeEnabled && !mounted.current) loadRules()
        }
    }, [businessLoaded])

    const loadRules = () => {
        mounted.current = true;
        setSaving(true)
        const activeOnly = !experienceId; // non-expired records only unless we are loading experience setup
        ApiService.depositRule.List__GET(businessData.id, experienceId || 0, activeOnly).then(response => {
            setRules(response)
            ExperienceService._depositRules = response;
            setSaving(false)
        }).catch(() => NotificationService.Error('Sorry, there was an error loading payment rules'))
    }

    const deleteRule = () => {
        setSaving(true)
        if (!!itemToDelete) {
            ApiService.depositRule.Delete__DELETE(itemToDelete).then((response) => {
                if (response.success) {
                    NotificationService.Confirm('Payment rule deleted successfully')
                    setItemToDelete(undefined)
                } else {
                    NotificationService.Error('Something went wrong, could not remove payment rule.')
                }
            }).catch(() => {
                NotificationService.Error('Sorry, there was an error saving your changes.')
            }).finally(() => {
                setSaving(false)
                loadRules()
            })
        }
    }

    const loadRuleTable = () => {
        return generateBookingRulesTable(rules, setEditRule, setItemToDelete, experienceId);
    }

    return (
        <div>
            {itemToDelete &&
                <CoreModal
                    small
                    onClose={() => setItemToDelete(undefined)}
                    title='Are you sure you want to delete this rule?'
                    slimPanel
                    actionBar={<CoreButton type='danger' requesting={saving} disabled={saving} onClick={() => deleteRule()}>Confirm</CoreButton>}
                >

                </CoreModal>
            }
            {editRule &&
                <BookingRuleForm
                    rule={editRule}
                    maxPrice={maxPrice}
                    experienceId={experienceId}
                    experienceType={experienceType}
                    allRules={rules}
                    close={() => { setEditRule(undefined); loadRules() }}
                    validDateBands={validDateBands}
                />
            }
            {businessData && <>
                {!experienceId &&
                    <DashboardHeader icon='credit-card' title="Payment rules">
                        Payment rules allow you to take pre-payment or no show fees from your website.
                    </DashboardHeader>
                }
                {!businessData.stripeEnabled &&
                    <ActionBox
                        icon={'cog'}
                        url={`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/integrations`}
                        title='Additional configuration needed'
                    >
                        Additional configuration is needed before you can set up payments.
                        <br />
                        <br />
                        You need a valid active payment provider set up.
                    </ActionBox>
                }
                {businessData.stripeEnabled && saving && <Loader />}
                {businessData.stripeEnabled && !saving &&
                    <>
                        {rules?.length === 0 &&
                            <InfoMessage>There are no payment rules currently set up.</InfoMessage>
                        }
                        {rules?.length > 0 &&
                            <DataTable data={loadRuleTable()} />
                        }
                        <br />
                        <DashboardAddButton onClick={() => setEditRule({ ...defaultDataForRule, businessId: businessData.id, experienceId })}>Add a payment rule</DashboardAddButton>
                    </>
                }
            </>}
        </div>
    );
};

export default PaymentRules;