import React, { useRef, useState } from 'react';
import { BaseBookingDurationRule, BaseDepositRule, DepositCancellationDurationType, DepositPaymentType, DepositRuleType, ExperienceType } from '../../../../../../../api/api-definitions';
import FormWrapper from '../../../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../../../components/Layout/Grid';
import { ApiService } from '../../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../../services/NotificationService';
import { StyledCalendar, StyledDropdown, StyledNumberInput, StyledTimeInput } from '../../../../../../../theme/input.styles';
import Checkbox from '../../../../../../../components/Forms/Checkbox';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import { DayBox } from '../../BookingSetup';
import CoreButton from '../../../../../../../components/Forms/Button';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import Icon from '../../../../../../../components/Media/Icon';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../../components/Forms/Messaging';
import DataTable from '../../../../../../../components/Layout/Datatable';
import { generateBookingRulesTable } from '.';
import { formatDate, DATABASE_TIME_FORMAT, getMinDateForRuleSetup } from '../../../../../../../utils/date-helpers';
import Time from '../../../../../../../components/Forms/Time';
import { DateBandValidityItem } from '../../../../../../../components/Forms/Calendar';
import moment from 'moment';

interface ComponentProps {
    rule: BaseDepositRule;
    allRules: BaseDepositRule[];
    close: () => void;
    experienceId?: number;
    maxPrice?: number;
    validDateBands?: DateBandValidityItem[];
    experienceType?: ExperienceType;
}

interface ExtendedBaseBookingDurationRule extends BaseDepositRule {
    partySizeEnabled?: boolean;
    dateRangeEnabled?: boolean;
    timeRangeEnabled?: boolean;
}

const BookingRuleForm = ({ rule, allRules, close, experienceId, maxPrice, validDateBands, experienceType }: ComponentProps) => {
    const [data, setData] = useState<ExtendedBaseBookingDurationRule>({
        ...rule,
        dateRangeEnabled: !isNullOrWhitespace(rule.dateFrom) || !isNullOrWhitespace(rule.dateTo),
        timeRangeEnabled: !isNullOrWhitespace(rule.dateFrom) || !isNullOrWhitespace(rule.dateTo),
        partySizeEnabled: !isNullOrWhitespace(rule.partySizeFrom) || !isNullOrWhitespace(rule.partySizeTo),
    });
    const [overlappingIds, setOverlappingIds] = useState<number[]>();
    const modalContentRef = useRef<HTMLDivElement>();
    const [saving, setSaving] = useState<boolean>(false);

    const save = () => {
        setSaving(true)
        if (isNullOrWhitespace(data.partySizeTo)) data.partySizeTo = undefined;
        if (experienceId) data.experienceId = experienceId;
        if (!isNullOrWhitespace(data.dateFrom)) data.dateFrom = formatDate(data.dateFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(data.dateTo)) data.dateTo = formatDate(data.dateTo, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(data.timeFrom)) data.timeFrom = formatDate(data.timeFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(data.timeTo)) data.timeTo = formatDate(data.timeTo, DATABASE_TIME_FORMAT);
        const apiEndpoint = data.id ? ApiService.depositRule.Save__POST : ApiService.depositRule.Add__PUT;
        apiEndpoint(data).then((response) => {
            if (response.errorMessage) {
                if (response.idsOfOverlappingRecords?.length > 0) {
                    setOverlappingIds(response.idsOfOverlappingRecords)
                    scrollToBottom();
                } else {
                    NotificationService.Error(response.errorMessage)
                }
            } else {
                NotificationService.Confirm(data.id ? 'Rule saved' : 'Rule added');
                close()
            }
        }).catch(() => {
            NotificationService.Error('Sorry, there has been an error processing the request.')
        }).finally(() => setSaving(false))
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if (modalContentRef) modalContentRef.current.scrollTo({
                top: 1000,
                left: 0,
                behavior: "smooth",
            })
        }, 200);
    }

    const allSelected = data.monday && data.tuesday && data.wednesday && data.thursday && data.friday && data.saturday && data.sunday;

    const toggleSelectAll = () => {
        setData({
            ...data,
            monday: !allSelected,
            tuesday: !allSelected,
            wednesday: !allSelected,
            thursday: !allSelected,
            friday: !allSelected,
            saturday: !allSelected,
            sunday: !allSelected,
        })
    }

    const maxWarningMessage = !!experienceId && experienceType ? `The amount should not exceed the ${experienceType.toLowerCase()} price of ` : '';
    return (
        <FormWrapper onUpdate={(formDetails) => setData({ ...data, ...formDetails })}>
            {({ id, valid }) => (
                <CoreModal
                    title={data.id ? 'Edit payment rule' : 'Add new payment rule'}    
                    isOpen
                    onClose={close}
                    contentRef={modalContentRef}
                    actionBar={<>
                    <CoreButton requesting={saving} disabled={!valid || saving} onClick={save}><Icon name='save' /> Save</CoreButton>
                </>}>
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <Checkbox label='Party size' asToggle model='partySizeEnabled' checked={data.partySizeEnabled} />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledNumberInput required={data.partySizeEnabled} disabled={!data.partySizeEnabled} hideValue={!data.partySizeEnabled} model='partySizeFrom' value={data.partySizeFrom} max={data.partySizeTo} label='From' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledNumberInput placeholder='Leave blank for no limit' disabled={!data.partySizeEnabled} hideValue={!data.partySizeEnabled} model='partySizeTo' value={data.partySizeTo} min={data.partySizeFrom} label={data.partySizeEnabled ? 'To (inclusive)' : 'To'} />
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <Checkbox label='Date range' asToggle model='dateRangeEnabled' checked={data.dateRangeEnabled} />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledCalendar validDates={validDateBands} minDate={getMinDateForRuleSetup(data.dateFrom)} required={data.dateRangeEnabled} disabled={!data.dateRangeEnabled} hideValue={!data.dateRangeEnabled} model='dateFrom' value={data.dateFrom} label='From' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledCalendar validDates={validDateBands} placeholder='Leave blank for no limit' disabled={!data.dateRangeEnabled} hideValue={!data.dateRangeEnabled} model='dateTo' value={data.dateTo} minDate={data.dateRangeEnabled ? getMinDateForRuleSetup(data.dateFrom, true) : undefined} label={data.dateRangeEnabled ? 'To (inclusive)' : 'To'} />
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <Checkbox label='Time range' asToggle model='timeRangeEnabled' checked={data.timeRangeEnabled} />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledTimeInput required={data.timeRangeEnabled} disabled={!data.timeRangeEnabled} hideValue={!data.timeRangeEnabled} model='timeFrom' value={data.timeFrom} label='From' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledTimeInput required={data.timeRangeEnabled} disabled={!data.timeRangeEnabled} hideValue={!data.timeRangeEnabled} model='timeTo' value={data.timeTo} min={data.timeFrom} label={data.timeRangeEnabled ? 'To (inclusive)' : 'To'} />
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={12}>
                            <br/>
                            <CoreButton type='secondary' outline onClick={toggleSelectAll}>{allSelected ? 'Deselect' : 'Select'} all</CoreButton>
                        </Column>
                        <Column size={8} mobile={12}>
                            <label> Days of week</label>
                            <br />
                            <DayBox onClick={() => setData({ ...data, monday: !data.monday })} checked={data.monday}><Icon name={data.monday ? 'check' : 'times'} /> Mon</DayBox>
                            <DayBox onClick={() => setData({ ...data, tuesday: !data.tuesday })} checked={data.tuesday}><Icon name={data.tuesday ? 'check' : 'times'} /> Tue</DayBox>
                            <DayBox onClick={() => setData({ ...data, wednesday: !data.wednesday })} checked={data.wednesday}><Icon name={data.wednesday ? 'check' : 'times'} /> Wed</DayBox>
                            <DayBox onClick={() => setData({ ...data, thursday: !data.thursday })} checked={data.thursday}><Icon name={data.thursday ? 'check' : 'times'} /> Thu</DayBox>
                            <DayBox onClick={() => setData({ ...data, friday: !data.friday })} checked={data.friday}><Icon name={data.friday ? 'check' : 'times'} /> Fri</DayBox>
                            <DayBox onClick={() => setData({ ...data, saturday: !data.saturday })} checked={data.saturday}><Icon name={data.saturday ? 'check' : 'times'} /> Sat</DayBox>
                            <DayBox onClick={() => setData({ ...data, sunday: !data.sunday })} checked={data.sunday}><Icon name={data.sunday ? 'check' : 'times'} /> Sun</DayBox>
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={6}>
                            <StyledNumberInput value={data.amount} 
                            model='amount'
                            min={1} 
                            max={maxPrice} 
                            maxWarningMessage={maxWarningMessage} 
                            required label='Amount' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledDropdown value={data.type} model='type' required label='Type' items={[{value: DepositRuleType.PerPerson, text: 'Per person'}, {value: DepositRuleType.PerBooking, text: 'Per booking'}]} />
                        </Column>
                        <Column size={4} mobile={12}>
                            <StyledDropdown value={data.paymentType} model='paymentType' required label='When to charge customer' items={[{value: DepositPaymentType.TakeOnCancelAndNoShow, text: 'Take payment on cancellation and no show'}, {value: DepositPaymentType.TakeOnNoShow, text: 'Take payment on no show'}, {value: DepositPaymentType.TakeNow, text: 'Take payment on booking'}]} onChange={() => scrollToBottom()} />
                        </Column>
                    </Row>
                    <Row hidden={data.paymentType === DepositPaymentType.TakeOnNoShow}>
                        <Column size={3} mobile={12}>
                            <StyledNumberInput value={data.cancellationDuration} model='cancellationDuration' min={1} required={data.paymentType === DepositPaymentType.TakeOnCancelAndNoShow} label='Cancellation period' />
                        </Column>
                        <Column size={3} mobile={12}>
                            <StyledDropdown value={data.cancellationDurationType} model='cancellationDurationType' required={data.paymentType === DepositPaymentType.TakeOnCancelAndNoShow} label='Period type' items={[{value: DepositCancellationDurationType.Days, text: 'Day(s) before booking'}, {value: DepositCancellationDurationType.Hours, text: 'Hour(s) before booking'}]} />
                        </Column>
                        <Column size={6} mobile={12}>
                            <br />
                            {data.paymentType == DepositPaymentType.TakeOnCancelAndNoShow && <InfoMessage>Customers will only be charged for cancelling within the specificed period. Customer will need to call you to amend the booking if inside the specificed period.</InfoMessage>}
                            {data.paymentType == DepositPaymentType.TakeNow && <InfoMessage>Customers will be refunded for cancelling outside the specificed period.</InfoMessage>}
                        </Column>
                    </Row>
                    {overlappingIds &&
                        <>
                            <ErrorMessage>
                                Cannot save the rule as it conflicts with the below rule{overlappingIds.length > 1 ? 's' : ''}. 
                                Please review and amend the rule setup before saving.
                            </ErrorMessage>
                            <br />
                            <DataTable data={generateBookingRulesTable(allRules.filter(x => overlappingIds.includes(x.id)), null, null, experienceId)} />
                        </>
                    }
                </CoreModal>
            )}
        </FormWrapper>
    );
};

export default BookingRuleForm;