import React, { useState } from 'react';
import styled from 'styled-components';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseDish } from '../../../../../../api/api-definitions';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import Icon from '../../../../../../components/Media/Icon';
import NoImage from '../../../../../../components/Media/NoImage';
import { Constants } from '../../../../../../constants';
import { NotificationService } from '../../../../../../services/NotificationService';
import { BoxShadowStyleWithHover, ConvertHexToRGBA } from '../../../../../../theme';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import MenuItemForm from './MenuItemForm';
import { ConvertCurrency } from '../../../../../../utils/currency-helper';

interface ComponentProps {
  dish: BaseDish;
  currencyCode?: string;
  onEdit?: (item: BaseDish) => void;
  onDelete?: () => void;
}


const FoodItemContainer = styled(BoxShadowStyleWithHover)`
  position: relative;
`;

const FlexItem = styled.div<{ bold?: boolean; }>`
  margin-top: 0.4rem;
  padding-right: 0.4rem;
  ${props => props.bold && 'font-weight: bold;'}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ChangeImage = styled.div`
  display: none;
  background-color: rgba(${props => ConvertHexToRGBA(props.theme.background, 0.6)});
  border: 3px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  text-align: center;
  font-size: 2rem;
  width: 100%;
  height: 100%;
  padding-top: calc(50% - 1.5rem);  
  position: absolute;
  top: 0;
  left: 0;
`;

const NoImageHolder = styled.div`
  width: 100%;
  height: 100%;
`;

const FoodItemImage = styled.div`
  flex: 0 0 auto;
  width: 5rem;
  max-width: 5rem;
  height: 5rem;
  background-position: center;
  background-size: cover;
  margin: 1rem;
  padding: 0px;
  position: relative;
  cursor: pointer;

  &:hover {
    ${ChangeImage} {
      display: block;
    }

    ${NoImageHolder} {
      display: none;
    }
  }
`;

const InfoBox = styled.div`
    display: flex;
    font-size: 0.9rem;
    font-weight: bold;
    font-style: italic;
`

const FlexTextRight = styled.div`
    flex: 1 1 auto;
    text-align: right;
`

const FlexTextLeft = styled.div`
    flex: 1 1 auto;
    text-align: left;
`

const DashboardFoodItem = ({ dish, onEdit, onDelete, currencyCode = 'GBP' }: ComponentProps) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editImage, setEditImage] = useState(false);

  const save = (item: BaseDish) => {
    setSaving(true)
    ApiService.dish.Save__POST(item).then(() => {
      onEdit(item)
      setEditing(false);
      setEditImage(false);
      setSaving(false);
      NotificationService.Confirm(`${item.name} has been updated`)
    })
  }

  return (
    <>
      {(editing || editImage) &&
        <MenuItemForm
          loading={saving}
          isOpen
          imageOnly={editImage}
          onClose={() => editImage ? setEditImage(false) : setEditing(false)}
          dishData={dish}
          onSave={save} />
      }
      <FoodItemContainer onClick={() => setEditing(true)}>
        {/* <DashboardContextMenu>
          <CoreButton full onClick={() => setEditing(true)}>Edit item</CoreButton>
          <CoreButton full type='danger' onClick={onDelete}>Delete item</CoreButton>
        </DashboardContextMenu> */}
        <Row>
          <Column size={3} noMarginBottom>
            <FoodItemImage style={{ backgroundImage: isNullOrWhitespace(dish.imageUrl) ? null : `url('${Constants.baseImageUrl}${dish.imageUrl}')` }}>
              {isNullOrWhitespace(dish.imageUrl) && <NoImageHolder><NoImage /></NoImageHolder>}
              <ChangeImage onClick={() => setEditImage(true)}><Icon name='edit' /></ChangeImage>
            </FoodItemImage>
          </Column>
          <Column size={9} noMarginBottom>
            <FlexItem bold>{dish.name}</FlexItem>
            {!isNullOrWhitespace(dish.description) &&
              <FlexItem>
                {dish.description}
              </FlexItem>
            }
            {!isNullOrWhitespace(currencyCode) && !isNullOrWhitespace(dish.price) && dish.price > 0 &&
              <InfoBox>
                <FlexTextLeft>
                  {(!isNullOrWhitespace(currencyCode) && !isNullOrWhitespace(dish.price) && dish.price > 0) ? ConvertCurrency({
                    amount: dish.price,
                    code: currencyCode
                  }) : 'No price'}
                </FlexTextLeft>
                {!isNullOrWhitespace(dish.calorieCount) &&
                  <FlexTextRight>
                    {dish.calorieCount} kcal
                  </FlexTextRight>
                }
              </InfoBox>
            }
          </Column>
        </Row>
      </FoodItemContainer>
    </>
  );
};

export default DashboardFoodItem;