import React from 'react';
import { StyledDropdown, StyledTextInput } from '../../../theme/input.styles';
import { TELEPHONE_REGEX } from '../../../constants';
import { DropdownItem } from '../Dropdown';
import styled from 'styled-components';
import CountryFlag from '../CountryFlag/flags/CountryFlag';
import { BaseInputStyle, BaseLabelStyle } from '../../../theme/input.core.styles';
import Icon from '../../Media/Icon';

interface ComponentProps {
    value: string;
    countryCode: string;
    telephoneModel?: string;
    countryCodeModel?: string;
    required?: boolean;
}

const TelephoneInput = ({ value, countryCode, telephoneModel, countryCodeModel, required }: ComponentProps) => {
    return (
        <>
            <BaseLabelStyle required={required}>Telephone</BaseLabelStyle>
            <Wrapper>
                <CountryCode
                    value={countryCode}
                    items={PhoneNumberOptions}
                    model={countryCodeModel}
                    addDefault={false}
                    minContentWidth='14rem'
                    inputDom={<>
                        <InputElement>
                            <CountryFlag code={countryCode} />
                            <DropdownIcon name='chevron-down' />
                        </InputElement>
                    </>}
                />
                <StyledTextInput
                    required={required}
                    model={telephoneModel}
                    max={30}
                    value={value}
                    regexMatch={TELEPHONE_REGEX}
                    placeholder='Telephone number'
                    regexError='Value must be a telephone number'
                />
            </Wrapper>
        </>
    );
};

const DropdownIcon = styled(Icon)`
    position: absolute;
    right: 0rem;
    top: calc(50% - 0.4rem);
    user-select: none;
    pointer-events: none;
    font-size: 0.7rem;
    z-index: 1;
`

const Wrapper = styled.div`
    display: flex;
    position: relative;

    input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    ${StyledTextInput} {
        flex: 1;
    }
`

const InputElement = styled.div`
    display: block;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    font-weight: normal;
    line-height: normal;
    font-family: serif;
    -webkit-letter-spacing: inherit;
    -moz-letter-spacing: inherit;
    -ms-letter-spacing: inherit;
    letter-spacing: inherit;
    color: inherit;
    outline: none;
    box-shadow: none;
    padding: 0.6rem 0.6rem;
    font-size: 1rem;
    border: 1px solid #c7c7c7;
    background: #f6f6f6;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    height: 45px;

    svg {
        height: 1.2rem !important;
        width: auto !important;
    }
`

const CountryCode = styled(StyledDropdown)`
    width: 4.5rem;
    
    select {
        background-color: transparent !important;
        border: none !important;
    }

    svg {
        width: 0.75em;
    }
`

const PhoneNumberOptions: DropdownItem[] = [
    { value: "AF", selectedDisplayText: <CountryFlag code='AF' />, text: 'Afghanistan +93' },
    { value: "AL", selectedDisplayText: <CountryFlag code='AL' />, text: 'Albania +355' },
    { value: "DZ", selectedDisplayText: <CountryFlag code='DZ' />, text: 'Algeria +213' },
    { value: "AS", selectedDisplayText: <CountryFlag code='AS' />, text: 'American Samoa +1' },
    { value: "AD", selectedDisplayText: <CountryFlag code='AD' />, text: 'Andorra +376' },
    { value: "AO", selectedDisplayText: <CountryFlag code='AO' />, text: 'Angola +244' },
    { value: "AI", selectedDisplayText: <CountryFlag code='AI' />, text: 'Anguilla +1' },
    { value: "AG", selectedDisplayText: <CountryFlag code='AG' />, text: 'Antigua and Barbuda +1' },
    { value: "AR", selectedDisplayText: <CountryFlag code='AR' />, text: 'Argentina +54' },
    { value: "AM", selectedDisplayText: <CountryFlag code='AM' />, text: 'Armenia +374' },
    { value: "AW", selectedDisplayText: <CountryFlag code='AW' />, text: 'Aruba +297' },
    { value: "AU", selectedDisplayText: <CountryFlag code='AU' />, text: 'Australia +61' },
    { value: "AT", selectedDisplayText: <CountryFlag code='AT' />, text: 'Austria +43' },
    { value: "AZ", selectedDisplayText: <CountryFlag code='AZ' />, text: 'Azerbaijan +994' },
    { value: "BS", selectedDisplayText: <CountryFlag code='BS' />, text: 'Bahamas +1' },
    { value: "BH", selectedDisplayText: <CountryFlag code='BH' />, text: 'Bahrain +973' },
    { value: "BD", selectedDisplayText: <CountryFlag code='BD' />, text: 'Bangladesh +880' },
    { value: "BB", selectedDisplayText: <CountryFlag code='BB' />, text: 'Barbados +1' },
    { value: "BY", selectedDisplayText: <CountryFlag code='BY' />, text: 'Belarus +375' },
    { value: "BE", selectedDisplayText: <CountryFlag code='BE' />, text: 'Belgium +32' },
    { value: "BZ", selectedDisplayText: <CountryFlag code='BZ' />, text: 'Belize +501' },
    { value: "BJ", selectedDisplayText: <CountryFlag code='BJ' />, text: 'Benin +229' },
    { value: "BM", selectedDisplayText: <CountryFlag code='BM' />, text: 'Bermuda +1' },
    { value: "BT", selectedDisplayText: <CountryFlag code='BT' />, text: 'Bhutan +975' },
    { value: "BO", selectedDisplayText: <CountryFlag code='BO' />, text: 'Bolivia +591' },
    { value: "BA", selectedDisplayText: <CountryFlag code='BA' />, text: 'Bosnia and Herzegovina +387' },
    { value: "BW", selectedDisplayText: <CountryFlag code='BW' />, text: 'Botswana +267' },
    { value: "BR", selectedDisplayText: <CountryFlag code='BR' />, text: 'Brazil +55' },
    { value: "IO", selectedDisplayText: <CountryFlag code='IO' />, text: 'British Indian Ocean Terr +246' },
    { value: "VG", selectedDisplayText: <CountryFlag code='VG' />, text: 'British Virgin Islands +1' },
    { value: "BN", selectedDisplayText: <CountryFlag code='BN' />, text: 'Brunei Dar ussalam +673' },
    { value: "BG", selectedDisplayText: <CountryFlag code='BG' />, text: 'Bulgaria +359' },
    { value: "BF", selectedDisplayText: <CountryFlag code='BF' />, text: 'Burkina Faso +226' },
    { value: "BI", selectedDisplayText: <CountryFlag code='BI' />, text: 'Burundi +257' },
    { value: "KH", selectedDisplayText: <CountryFlag code='KH' />, text: 'Cambodia +855' },
    { value: "CM", selectedDisplayText: <CountryFlag code='CM' />, text: 'Cameroon +237' },
    { value: "CA", selectedDisplayText: <CountryFlag code='CA' />, text: 'Canada +1' },
    { value: "CV", selectedDisplayText: <CountryFlag code='CV' />, text: 'Cape Verde +238' },
    { value: "BQ", selectedDisplayText: <CountryFlag code='NL' />, text: 'Caribbean Netherlands +599' },
    { value: "KY", selectedDisplayText: <CountryFlag code='KY' />, text: 'Cayman Islands +1' },
    { value: "CF", selectedDisplayText: <CountryFlag code='CF' />, text: 'Central African Republic +236' },
    { value: "TD", selectedDisplayText: <CountryFlag code='TD' />, text: 'Chad +235' },
    { value: "CL", selectedDisplayText: <CountryFlag code='CL' />, text: 'Chile +56' },
    { value: "CN", selectedDisplayText: <CountryFlag code='CN' />, text: 'China +86' },
    { value: "CC", selectedDisplayText: <CountryFlag code='CC' />, text: 'Cocos (Keeling) Islands +61' },
    { value: "CO", selectedDisplayText: <CountryFlag code='CO' />, text: 'Colombia +57' },
    { value: "KM", selectedDisplayText: <CountryFlag code='KM' />, text: 'Comoros +269' },
    { value: "CG", selectedDisplayText: <CountryFlag code='CG' />, text: 'Congo +242' },
    { value: "CD", selectedDisplayText: <CountryFlag code='CD' />, text: 'Congo - Kinshasa +243' },
    { value: "CK", selectedDisplayText: <CountryFlag code='CK' />, text: 'Cook Islands +682' },
    { value: "CR", selectedDisplayText: <CountryFlag code='CR' />, text: 'Costa Rica +506' },
    { value: "CI", selectedDisplayText: <CountryFlag code='CI' />, text: 'Cote d Ivoire +225' },
    { value: "HR", selectedDisplayText: <CountryFlag code='HR' />, text: 'Croatia +385' },
    { value: "CU", selectedDisplayText: <CountryFlag code='CU' />, text: 'Cuba +53' },
    { value: "CW", selectedDisplayText: <CountryFlag code='CW' />, text: 'Curaçao +599' },
    { value: "CY", selectedDisplayText: <CountryFlag code='CY' />, text: 'Cyprus +357' },
    { value: "CZ", selectedDisplayText: <CountryFlag code='CZ' />, text: 'Czech Republic +420' },
    { value: "DK", selectedDisplayText: <CountryFlag code='DK' />, text: 'Denmark +45' },
    { value: "DJ", selectedDisplayText: <CountryFlag code='DJ' />, text: 'Djibouti +253' },
    { value: "DM", selectedDisplayText: <CountryFlag code='DM' />, text: 'Dominica +1' },
    { value: "DO", selectedDisplayText: <CountryFlag code='DO' />, text: 'Dominican Republic +1' },
    { value: "EC", selectedDisplayText: <CountryFlag code='EC' />, text: 'Ecuador +593' },
    { value: "EG", selectedDisplayText: <CountryFlag code='EG' />, text: 'Egypt +20' },
    { value: "SV", selectedDisplayText: <CountryFlag code='SV' />, text: 'El Salvador +503' },
    { value: "GQ", selectedDisplayText: <CountryFlag code='GQ' />, text: 'Equatorial Guinea +240' },
    { value: "ER", selectedDisplayText: <CountryFlag code='ER' />, text: 'Eritrea +291' },
    { value: "EE", selectedDisplayText: <CountryFlag code='EE' />, text: 'Estonia +372' },
    { value: "ET", selectedDisplayText: <CountryFlag code='ET' />, text: 'Ethiopia +251' },
    { value: "FK", selectedDisplayText: <CountryFlag code='FK' />, text: 'Falkland Islands +500' },
    { value: "FO", selectedDisplayText: <CountryFlag code='FO' />, text: 'Faroe Islands +298' },
    { value: "FJ", selectedDisplayText: <CountryFlag code='FJ' />, text: 'Fiji +679' },
    { value: "FI", selectedDisplayText: <CountryFlag code='FI' />, text: 'Finland +358' },
    { value: "FR", selectedDisplayText: <CountryFlag code='FR' />, text: 'France +33' },
    { value: "GF", selectedDisplayText: <CountryFlag code='GF' />, text: 'French Guiana +594' },
    { value: "PF", selectedDisplayText: <CountryFlag code='PF' />, text: 'French Polynesia +689' },
    { value: "TF", selectedDisplayText: <CountryFlag code='TF' />, text: 'French Southern Territories +262' },
    { value: "GA", selectedDisplayText: <CountryFlag code='GA' />, text: 'Gabon +241' },
    { value: "GM", selectedDisplayText: <CountryFlag code='GM' />, text: 'Gambia +220' },
    { value: "GE", selectedDisplayText: <CountryFlag code='GE' />, text: 'Georgia +995' },
    { value: "DE", selectedDisplayText: <CountryFlag code='DE' />, text: 'Germany +49' },
    { value: "GH", selectedDisplayText: <CountryFlag code='GH' />, text: 'Ghana +233' },
    { value: "GI", selectedDisplayText: <CountryFlag code='GI' />, text: 'Gibraltar +350' },
    { value: "GR", selectedDisplayText: <CountryFlag code='GR' />, text: 'Greece +30' },
    { value: "GL", selectedDisplayText: <CountryFlag code='GL' />, text: 'Greenland +299' },
    { value: "GD", selectedDisplayText: <CountryFlag code='GD' />, text: 'Grenada +1' },
    { value: "GP", selectedDisplayText: <CountryFlag code='GP' />, text: 'Guadeloupe +590' },
    { value: "GU", selectedDisplayText: <CountryFlag code='GU' />, text: 'Guam +1' },
    { value: "GT", selectedDisplayText: <CountryFlag code='GT' />, text: 'Guatemala +502' },
    { value: "GG", selectedDisplayText: <CountryFlag code='GG' />, text: 'Guernsey +44' },
    { value: "GN", selectedDisplayText: <CountryFlag code='GN' />, text: 'Guinea +224' },
    { value: "GW", selectedDisplayText: <CountryFlag code='GW' />, text: 'Guinea-Bissau +245' },
    { value: "GY", selectedDisplayText: <CountryFlag code='GY' />, text: 'Guyana +592' },
    { value: "HT", selectedDisplayText: <CountryFlag code='HT' />, text: 'Haiti +509' },
    { value: "VA", selectedDisplayText: <CountryFlag code='VA' />, text: 'Holy See Vatican City +379' },
    { value: "HN", selectedDisplayText: <CountryFlag code='HN' />, text: 'Honduras +504' },
    { value: "HK", selectedDisplayText: <CountryFlag code='HK' />, text: 'Hong Kong +852' },
    { value: "HU", selectedDisplayText: <CountryFlag code='HU' />, text: 'Hungary +36' },
    { value: "IS", selectedDisplayText: <CountryFlag code='IS' />, text: 'Iceland +354' },
    { value: "IN", selectedDisplayText: <CountryFlag code='IN' />, text: 'India +91' },
    { value: "ID", selectedDisplayText: <CountryFlag code='ID' />, text: 'Indonesia +62' },
    { value: "IR", selectedDisplayText: <CountryFlag code='IR' />, text: 'Iran +98' },
    { value: "IQ", selectedDisplayText: <CountryFlag code='IQ' />, text: 'Iraq +964' },
    { value: "IE", selectedDisplayText: <CountryFlag code='IE' />, text: 'Ireland, Republic of +353' },
    { value: "IM", selectedDisplayText: <CountryFlag code='IM' />, text: 'Isle of Man +44' },
    { value: "IL", selectedDisplayText: <CountryFlag code='IL' />, text: 'Israel +972' },
    { value: "IT", selectedDisplayText: <CountryFlag code='IT' />, text: 'Italy +39' },
    { value: "JM", selectedDisplayText: <CountryFlag code='JM' />, text: 'Jamaica +1' },
    { value: "JP", selectedDisplayText: <CountryFlag code='JP' />, text: 'Japan +81' },
    { value: "JE", selectedDisplayText: <CountryFlag code='JE' />, text: 'Jersey +44' },
    { value: "JO", selectedDisplayText: <CountryFlag code='JO' />, text: 'Jordan +962' },
    { value: "KZ", selectedDisplayText: <CountryFlag code='KZ' />, text: 'Kazakhstan +7' },
    { value: "KE", selectedDisplayText: <CountryFlag code='KE' />, text: 'Kenya +254' },
    { value: "KI", selectedDisplayText: <CountryFlag code='KI' />, text: 'Kiribati +686' },
    { value: "KP", selectedDisplayText: <CountryFlag code='KP' />, text: 'Korea, North +850' },
    { value: "KR", selectedDisplayText: <CountryFlag code='KR' />, text: 'Korea, South +82' },
    { value: "XK", selectedDisplayText: <CountryFlag code='XK' />, text: 'Kosovo +383' },
    { value: "KW", selectedDisplayText: <CountryFlag code='KW' />, text: 'Kuwait +965' },
    { value: "KG", selectedDisplayText: <CountryFlag code='KG' />, text: 'Kyrgyzstan +996' },
    { value: "LA", selectedDisplayText: <CountryFlag code='LA' />, text: 'Laos +856' },
    { value: "LV", selectedDisplayText: <CountryFlag code='LV' />, text: 'Latvia +371' },
    { value: "LB", selectedDisplayText: <CountryFlag code='LB' />, text: 'Lebanon +961' },
    { value: "LS", selectedDisplayText: <CountryFlag code='LS' />, text: 'Lesotho +266' },
    { value: "LR", selectedDisplayText: <CountryFlag code='LR' />, text: 'Liberia +231' },
    { value: "LY", selectedDisplayText: <CountryFlag code='LY' />, text: 'Libya +218' },
    { value: "LI", selectedDisplayText: <CountryFlag code='LI' />, text: 'Liechtenstein +423' },
    { value: "LT", selectedDisplayText: <CountryFlag code='LT' />, text: 'Lithuania +370' },
    { value: "LU", selectedDisplayText: <CountryFlag code='LU' />, text: 'Luxembourg +352' },
    { value: "MO", selectedDisplayText: <CountryFlag code='MO' />, text: 'Macao +853' },
    { value: "MK", selectedDisplayText: <CountryFlag code='MK' />, text: 'Macedonia +389' },
    { value: "MG", selectedDisplayText: <CountryFlag code='MG' />, text: 'Madagascar +261' },
    { value: "MW", selectedDisplayText: <CountryFlag code='MW' />, text: 'Malawi +265' },
    { value: "MY", selectedDisplayText: <CountryFlag code='MY' />, text: 'Malaysia +60' },
    { value: "MV", selectedDisplayText: <CountryFlag code='MV' />, text: 'Maldives +960' },
    { value: "ML", selectedDisplayText: <CountryFlag code='ML' />, text: 'Mali +223' },
    { value: "MT", selectedDisplayText: <CountryFlag code='MT' />, text: 'Malta +356' },
    { value: "MH", selectedDisplayText: <CountryFlag code='MH' />, text: 'Marshall Islands +692' },
    { value: "MQ", selectedDisplayText: <CountryFlag code='MQ' />, text: 'Martinique +596' },
    { value: "MR", selectedDisplayText: <CountryFlag code='MR' />, text: 'Mauritania +222' },
    { value: "MU", selectedDisplayText: <CountryFlag code='MU' />, text: 'Mauritius +230' },
    { value: "YT", selectedDisplayText: <CountryFlag code='YT' />, text: 'Mayotte +262' },
    { value: "MX", selectedDisplayText: <CountryFlag code='MX' />, text: 'Mexico +52' },
    { value: "FM", selectedDisplayText: <CountryFlag code='FM' />, text: 'Micronesia +691' },
    { value: "MD", selectedDisplayText: <CountryFlag code='MD' />, text: 'Moldova +373' },
    { value: "MC", selectedDisplayText: <CountryFlag code='MC' />, text: 'Monaco +377' },
    { value: "MN", selectedDisplayText: <CountryFlag code='MN' />, text: 'Mongolia +976' },
    { value: "ME", selectedDisplayText: <CountryFlag code='ME' />, text: 'Montenegro +382' },
    { value: "MS", selectedDisplayText: <CountryFlag code='MS' />, text: 'Montserrat +1' },
    { value: "MA", selectedDisplayText: <CountryFlag code='MA' />, text: 'Morocco +212' },
    { value: "MZ", selectedDisplayText: <CountryFlag code='MZ' />, text: 'Mozambique +258' },
    { value: "MM", selectedDisplayText: <CountryFlag code='MM' />, text: 'Myanmar +95' },
    { value: "NA", selectedDisplayText: <CountryFlag code='NA' />, text: 'Namibia +264' },
    { value: "NR", selectedDisplayText: <CountryFlag code='NR' />, text: 'Nauru +674' },
    { value: "NP", selectedDisplayText: <CountryFlag code='NP' />, text: 'Nepal +977' },
    { value: "NL", selectedDisplayText: <CountryFlag code='NL' />, text: 'Netherlands +31' },
    { value: "NC", selectedDisplayText: <CountryFlag code='NC' />, text: 'New Caledonia +687' },
    { value: "NZ", selectedDisplayText: <CountryFlag code='NZ' />, text: 'New Zealand +64' },
    { value: "NI", selectedDisplayText: <CountryFlag code='NI' />, text: 'Nicaragua +505' },
    { value: "NE", selectedDisplayText: <CountryFlag code='NE' />, text: 'Niger +227' },
    { value: "NG", selectedDisplayText: <CountryFlag code='NG' />, text: 'Nigeria +234' },
    { value: "NU", selectedDisplayText: <CountryFlag code='NU' />, text: 'Niue +683' },
    { value: "NF", selectedDisplayText: <CountryFlag code='NF' />, text: 'Norfolk Island +672' },
    { value: "MP", selectedDisplayText: <CountryFlag code='MP' />, text: 'Northern Mariana Islands +1' },
    { value: "NO", selectedDisplayText: <CountryFlag code='NO' />, text: 'Norway +47' },
    { value: "OM", selectedDisplayText: <CountryFlag code='OM' />, text: 'Oman +968' },
    { value: "PK", selectedDisplayText: <CountryFlag code='PK' />, text: 'Pakistan +92' },
    { value: "PW", selectedDisplayText: <CountryFlag code='PW' />, text: 'Palau +680' },
    { value: "PS", selectedDisplayText: <CountryFlag code='PS' />, text: 'Palestine +970' },
    { value: "PA", selectedDisplayText: <CountryFlag code='PA' />, text: 'Panama +507' },
    { value: "PG", selectedDisplayText: <CountryFlag code='PG' />, text: 'Papua New Guinea +675' },
    { value: "PY", selectedDisplayText: <CountryFlag code='PY' />, text: 'Paraguay +595' },
    { value: "PE", selectedDisplayText: <CountryFlag code='PE' />, text: 'Peru +51' },
    { value: "PH", selectedDisplayText: <CountryFlag code='PH' />, text: 'Philippines +63' },
    { value: "PN", selectedDisplayText: <CountryFlag code='PN' />, text: 'Pitcairn Islands +64' },
    { value: "PL", selectedDisplayText: <CountryFlag code='PL' />, text: 'Poland +48' },
    { value: "PT", selectedDisplayText: <CountryFlag code='PT' />, text: 'Portugal +351' },
    { value: "PR", selectedDisplayText: <CountryFlag code='PR' />, text: 'Puerto Rico +1' },
    { value: "QA", selectedDisplayText: <CountryFlag code='QA' />, text: 'Qatar +974' },
    { value: "RE", selectedDisplayText: <CountryFlag code='RE' />, text: 'Réunion +262' },
    { value: "RO", selectedDisplayText: <CountryFlag code='RO' />, text: 'Romania +40' },
    { value: "RU", selectedDisplayText: <CountryFlag code='RU' />, text: 'Russia +7' },
    { value: "RW", selectedDisplayText: <CountryFlag code='RW' />, text: 'Rwanda +250' },
    { value: "BL", selectedDisplayText: <CountryFlag code='BL' />, text: 'Saint Barthélemy +590' },
    { value: "SH", selectedDisplayText: <CountryFlag code='SH' />, text: 'Saint Helena +290' },
    { value: "KN", selectedDisplayText: <CountryFlag code='KN' />, text: 'Saint Kitts and Nevis +1' },
    { value: "LC", selectedDisplayText: <CountryFlag code='LC' />, text: 'Saint Lucia +1' },
    { value: "MF", selectedDisplayText: <CountryFlag code='FR' />, text: 'Saint Martin +590' },
    { value: "PM", selectedDisplayText: <CountryFlag code='PM' />, text: 'Saint Pierre and Miquelon +508' },
    { value: "VC", selectedDisplayText: <CountryFlag code='VC' />, text: 'Saint Vincent and the Grenadines +1' },
    { value: "WS", selectedDisplayText: <CountryFlag code='WS' />, text: 'Samoa +685' },
    { value: "SM", selectedDisplayText: <CountryFlag code='SM' />, text: 'San Marino +378' },
    { value: "ST", selectedDisplayText: <CountryFlag code='ST' />, text: 'São Tomé and Príncipe +239' },
    { value: "SA", selectedDisplayText: <CountryFlag code='SA' />, text: 'Saudi Arabia +966' },
    { value: "SN", selectedDisplayText: <CountryFlag code='SN' />, text: 'Senegal +221' },
    { value: "RS", selectedDisplayText: <CountryFlag code='RS' />, text: 'Serbia +381' },
    { value: "SC", selectedDisplayText: <CountryFlag code='SC' />, text: 'Seychelles +248' },
    { value: "SL", selectedDisplayText: <CountryFlag code='SL' />, text: 'Sierra Leone +232' },
    { value: "SG", selectedDisplayText: <CountryFlag code='SG' />, text: 'Singapore +65' },
    { value: "SX", selectedDisplayText: <CountryFlag code='SX' />, text: 'Sint Maarten +1' },
    { value: "SK", selectedDisplayText: <CountryFlag code='SK' />, text: 'Slovakia +421' },
    { value: "SI", selectedDisplayText: <CountryFlag code='SI' />, text: 'Slovenia +386' },
    { value: "SB", selectedDisplayText: <CountryFlag code='SB' />, text: 'Solomon Islands +677' },
    { value: "SO", selectedDisplayText: <CountryFlag code='SO' />, text: 'Somalia +252' },
    { value: "ZA", selectedDisplayText: <CountryFlag code='ZA' />, text: 'South Africa +27' },
    { value: "GS", selectedDisplayText: <CountryFlag code='GS' />, text: 'South Georgia +500' },
    { value: "SS", selectedDisplayText: <CountryFlag code='SS' />, text: 'South Sudan +211' },
    { value: "ES", selectedDisplayText: <CountryFlag code='ES' />, text: 'Spain +34' },
    { value: "LK", selectedDisplayText: <CountryFlag code='LK' />, text: 'Sri Lanka +94' },
    { value: "SD", selectedDisplayText: <CountryFlag code='SD' />, text: 'Sudan +249' },
    { value: "SR", selectedDisplayText: <CountryFlag code='SR' />, text: 'Suriname +597' },
    { value: "SJ", selectedDisplayText: <CountryFlag code='SJ' />, text: 'Svalbard and Jan Mayen +47' },
    { value: "SZ", selectedDisplayText: <CountryFlag code='SZ' />, text: 'Swaziland +268' },
    { value: "SE", selectedDisplayText: <CountryFlag code='SE' />, text: 'Sweden +46' },
    { value: "CH", selectedDisplayText: <CountryFlag code='CH' />, text: 'Switzerland +41' },
    { value: "SY", selectedDisplayText: <CountryFlag code='SY' />, text: 'Syria +963' },
    { value: "TW", selectedDisplayText: <CountryFlag code='TW' />, text: 'Taiwan +886' },
    { value: "TJ", selectedDisplayText: <CountryFlag code='TJ' />, text: 'Tajikistan +992' },
    { value: "TZ", selectedDisplayText: <CountryFlag code='TZ' />, text: 'Tanzania +255' },
    { value: "TH", selectedDisplayText: <CountryFlag code='TH' />, text: 'Thailand +66' },
    { value: "TL", selectedDisplayText: <CountryFlag code='TL' />, text: 'Timor-Leste +670' },
    { value: "TG", selectedDisplayText: <CountryFlag code='TG' />, text: 'Togo +228' },
    { value: "TK", selectedDisplayText: <CountryFlag code='TK' />, text: 'Tokelau +690' },
    { value: "TO", selectedDisplayText: <CountryFlag code='TO' />, text: 'Tonga +676' },
    { value: "TT", selectedDisplayText: <CountryFlag code='TT' />, text: 'Trinidad and Tobago +1' },
    { value: "TN", selectedDisplayText: <CountryFlag code='TN' />, text: 'Tunisia +216' },
    { value: "TR", selectedDisplayText: <CountryFlag code='TR' />, text: 'Turkey +90' },
    { value: "TM", selectedDisplayText: <CountryFlag code='TM' />, text: 'Turkmenistan +993' },
    { value: "TC", selectedDisplayText: <CountryFlag code='TC' />, text: 'Turks and Caicos Islands +1' },
    { value: "TV", selectedDisplayText: <CountryFlag code='TV' />, text: 'Tuvalu +688' },
    { value: "UG", selectedDisplayText: <CountryFlag code='UG' />, text: 'Uganda +256' },
    { value: "UA", selectedDisplayText: <CountryFlag code='UA' />, text: 'Ukraine +380' },
    { value: "AE", selectedDisplayText: <CountryFlag code='AE' />, text: 'United Arab Emirates +971' },
    { value: "GB", selectedDisplayText: <CountryFlag code='GB' />, text: 'United Kingdom +44' },
    { value: "US", selectedDisplayText: <CountryFlag code='US' />, text: 'United States +1' },
    { value: "UM", selectedDisplayText: <CountryFlag code='UM' />, text: 'United States Minor Outlying Islands +1' },
    { value: "UY", selectedDisplayText: <CountryFlag code='UY' />, text: 'Uruguay +598' },
    { value: "UZ", selectedDisplayText: <CountryFlag code='UZ' />, text: 'Uzbekistan +998' },
    { value: "VU", selectedDisplayText: <CountryFlag code='VU' />, text: 'Vanuatu +678' },
    { value: "VE", selectedDisplayText: <CountryFlag code='VE' />, text: 'Venezuela +58' },
    { value: "VN", selectedDisplayText: <CountryFlag code='VN' />, text: 'Vietnam +84' },
    { value: "VI", selectedDisplayText: <CountryFlag code='VI' />, text: 'Virgin Islands, U.S. +1' },
    { value: "WF", selectedDisplayText: <CountryFlag code='WF' />, text: 'Wallis and Futuna +681' },
    { value: "YE", selectedDisplayText: <CountryFlag code='YE' />, text: 'Yemen +967' },
    { value: "ZM", selectedDisplayText: <CountryFlag code='ZM' />, text: 'Zambia +260' },
    { value: "ZW", selectedDisplayText: <CountryFlag code='ZW' />, text: 'Zimbabwe +263' }
];

export default TelephoneInput;