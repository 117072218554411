import { useState } from 'react';
import { ApiService } from '../../../api/api-connectors';
import { RegisterBusinessModel } from '../../../api/api-definitions';
import DashboardAddButton from '../../../components/Dashboard/AddButton';
import DashboardHeader from '../../../components/Dashboard/Header';
import LocationLink from '../../../components/Dashboard/LocationLink';
import CoreButton from '../../../components/Forms/Button';
import { Row, Column } from '../../../components/Layout/Grid';
import Loader from '../../../components/Layout/Loader';
import CoreModal from '../../../components/Layout/CoreModal';
import useBusinessList from '../../../hooks/useBusinessList';
import { NotificationService } from '../../../services/NotificationService';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import NewBusinessForm from '../../NewBusiness/modules/NewBusinessForm';
import { StyledTextInput } from '../../../theme/input.styles';
// import { SpotlightTarget } from '@atlaskit/onboarding';
import { usePageName } from '../../../hooks/usePageName';

interface ComponentProps {
}


const DashboardLanding = ({ }: ComponentProps) => {
    const [loaded, data, reload] = useBusinessList();
    const [newLocation, setNewLocation] = useState<RegisterBusinessModel>()
    const [newLocationOpen, setNewLocationOpen] = useState<boolean>(false)
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [chatResponse, setChatResponse] = useState("");
    const [chatRequest, setChatRequest] = useState("");
    const [filter, setFilter] = useState<string>();

    if (!loaded) return <Loader />;

    const closeModal = () => {
        setNewLocationOpen(false);
        setNewLocation(undefined);
    }

    const openModal = () => {
        setNewLocation({ currency: 'GBP' });
        setNewLocationOpen(true);
    }

    const addLocation = () => {
        setModalLoading(true)
        ApiService.parentBusiness.Add__PUT(newLocation).then((response) => {
            if (response.success) {
                setModalLoading(false)
                closeModal()
                NotificationService.Confirm('Location added');
                setFilter(newLocation.name);
                reload();
            } else {
                NotificationService.Error(response.info)
                setModalLoading(false)
            }
        })
    }
    const getSuggestion = () => {
        ApiService.aiprompt.Aiprompt__POST(`Suggest a dish description suitable to go on a restaraunt menu for: ${chatRequest}`).then(res => {
            setChatResponse(res.info)
        })
    }

    const filteredData = isNullOrWhitespace(filter) ? data : data.filter(x => x.name.toLowerCase().indexOf(filter.toLowerCase()) > -1)

    return (
        <div>
            {/* <Row>
                <Column size={3}>
                    <StyledTextInput onChange={e => setChatRequest(e.target.value)} label='Test' />
                </Column>
                <Column size={2}>
                    <CoreButton onClick={getSuggestion}>
                        Go
                    </CoreButton>
                </Column>
                <Column size={7}>
                    {chatResponse}
                </Column>
            </Row> */}
            <DashboardHeader icon='home' title='Your dashboard'>View and manage your businesses and locations.</DashboardHeader>
            {!!data && data.length > 1 &&
                <Row>
                    <Column size={4} tablet={6} mobile={12}>
                        <StyledTextInput model='filter' value={filter} onChange={(e) => setFilter(e.target.value)} label='Filter by name' />
                    </Column>
                    <Column size={4} tablet={6} mobile={12}>
                        <br />
                        {!isNullOrWhitespace(filter) && <CoreButton outline onClick={() => setFilter(undefined)}>Clear filter</CoreButton> }
                    </Column>
                </Row>
            }
            <DashboardAddButton onClick={openModal}>
                Add business
            </DashboardAddButton>
            <br />
            {filteredData.map((item, index) => index > 0 ? (
                <LocationLink key={`parentBusiness-${index}`} name={item.name} url={`/dashboard/${item.id}/locations`} description={`${item.locations.length} location${item.locations.length > 1 ? 's' : ''}`} />
            ) : (
                <LocationLink key={`parentBusiness-${index}`} name={item.name} url={`/dashboard/${item.id}/locations`} description={`${item.locations.length} location${item.locations.length > 1 ? 's' : ''}`} />
            ))}
            {newLocationOpen &&
                <CoreModal title='Add new location' onClose={closeModal} actionBar={<>
                    <CoreButton onClick={addLocation} disabled={modalLoading || isNullOrWhitespace(newLocation.name) || (newLocation.isChain && isNullOrWhitespace(newLocation.branchName))}>Add</CoreButton>
                </>}>
                    {!modalLoading &&
                        <Row>
                            <Column size={8} tablet={12} mobile={12}>
                                <NewBusinessForm data={newLocation} setData={setNewLocation} errors={[]} />
                            </Column>
                        </Row>
                    }
                    {modalLoading && <Loader />}
                </CoreModal>
            }
        </div>
    );
};

export default DashboardLanding;