import React, { useEffect, useRef, useState } from 'react';
import { DashboardPage } from '../../../../DashboardLayout';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledCurrencyInput, StyledDropdown, StyledNumberInput, StyledTextInput } from '../../../../../../theme/input.styles';
import RichTextEdior from '../../../../../../components/Forms/RichTextEditor';
import PaymentRules from '../BookingRules/DepositRules';
import DurationRules from '../BookingRules/DurationRules';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import PacingRules from '../BookingRules/PacingRules';
import { ApiService } from '../../../../../../api/api-connectors';
import { useHistory, useParams } from 'react-router';
import { ApiPDF, BaseBusinessOpeningTimeException, BaseExperience, BookingStatus, ExperienceType, ExtendedBaseExperience, LightExperienceBooking, WidgetImageLayout } from '../../../../../../api/api-definitions';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import Loader from '../../../../../../components/Layout/Loader';
import SpecialOpeningTimes from '../../BusinessDetails/SpecialOpeningTimes';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { BookingTypeDescriptionSize, BookingTypeNameSize, Constants, EmailAddressSize } from '../../../../../../constants';
import CoreButton from '../../../../../../components/Forms/Button';
import Icon from '../../../../../../components/Media/Icon';
import { NotificationService } from '../../../../../../services/NotificationService';
import { cloneDeep } from 'lodash';
import BookingTableLayout from '../TableLayout';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import styled from 'styled-components';
import InfoButton from '../../../../../../components/Cta/InfoButton';
import ImageSelector from '../../../../../../components/Forms/ImageSelector';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import BookingModule from '../../../../../../bookingModule/pages/Bookings';
import WebisteIntegration from '../BookingSetup/websiteIntegration';
import BlockOutTimesSetup from '../BookingRules/BlockOutTimeRules';
import EasyCalendar, { DateBandValidityItem } from '../../../../../../components/Forms/Calendar';
import { createMomentFromValue, DATEONLYFORMAT, DISPLAY_DATE_TIME_FORMAT, formatDate } from '../../../../../../utils/date-helpers'; import { ExperienceService, ExperienceWarningType } from './experienceService';
import { H4style } from '../../../../../../components/Typography/Headings';
import { ModalService } from '../../../../../../services/ModalService';
import { Badge } from '@chakra-ui/react';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import ExperienceLocation from './experienceLocation';
import PDFUpload from '../../../../../../components/Forms/PDFUpload';
import DataTable, { DataTableItem } from '../../../../../../components/Layout/Datatable';
import SectionGroup from '../../../../../../components/Forms/SectionGroup';
import { ListWrap } from '../modules/BookingList';
import { GetCurrencySymbol } from '../../../../../../utils/currency-helper';

interface BookingFilter {
    reference?: string;
    name?: string;
    telephone?: string;
    email?: string;
    bookingDateTime?: string;
}

const EditExperience = () => {
    const [businessLoaded, businessData] = useBusiness();
    const [data, setData] = useState<ExtendedBaseExperience>();
    const [dates, setDates] = useState<DateBandValidityItem[]>();
    const [saving, setSaving] = useState<boolean>(false);
    const initialData = useRef<ExtendedBaseExperience>();
    const [preview, setPreview] = useState(false);
    const params = useParams<{ experienceId: string }>();
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const history = useHistory();
    const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
    const [viewBookings, setViewBookings] = useState(false);
    const [bookings, setBookings] = useState<LightExperienceBooking[]>();
    const [cancelBookingItem, setCancelBookingItem] = useState<LightExperienceBooking>();
    const [cancelling, setCancelling] = useState(false);
    const [bookingFilter, setBookingFilter] = useState<BookingFilter>({});

    useEffect(() => {
        ApiService.experience.Get__GET(+params.experienceId).then((resp) => {
            initialData.current = cloneDeep(resp);
            setData(resp)
        })
    }, [])

    const saveChanges = () => {
        setSaving(true)
        adjustNumericFieldsBeforeSave(data);
        ApiService.experience.Save__POST(data).then(() => {
            initialData.current = cloneDeep(data);
            NotificationService.Confirm('Changes saved');
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        }).finally(() => {
            setSaving(false)
        })
    }

    const adjustNumericFieldsBeforeSave = (data: ExtendedBaseExperience) => {
        if (isNullOrWhitespace(data.minGuests)) data.minGuests = null;
        if (isNullOrWhitespace(data.maxGuests)) data.maxGuests = null;
        if (isNullOrWhitespace(data.pricePerGuest)) data.pricePerGuest = 0;
    }

    const confirmPublishAction = (published: boolean) => {
        const newData: BaseExperience = {
            ...initialData.current,
            published
        }
        setSaving(true)
        adjustNumericFieldsBeforeSave(newData);
        ApiService.experience.Save__POST(newData).then(() => {
            initialData.current = cloneDeep(newData);
            setData({
                ...data,
                published
            })
            setPublishModalOpen(false)
            NotificationService.Confirm(published ? 'Published!' : 'Unpublished');
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        }).finally(() => {
            setSaving(false)
        })
    }

    const updateDates = (response: BaseBusinessOpeningTimeException[]) => {
        ExperienceService._dates = response;
        setDates(response.map(x => ({
            dateFrom: x.dateFrom ? createMomentFromValue(x.dateFrom) : undefined,
            dateTo: x.dateTo ? createMomentFromValue(x.dateTo) : undefined,
        } as DateBandValidityItem)))
    }

    const hasChanges = JSON.stringify(data) != JSON.stringify(initialData.current);
    const typeName = initialData.current?.type == ExperienceType.Event ? 'event' : 'experience';
    const warningList = publishModalOpen ? ExperienceService.warningList : undefined;
    const eventOrExperience = data && data.type == ExperienceType.Event ? 'Event' : 'Experience';
    const eventOrExperienceLowerCase = eventOrExperience.toLowerCase();

    const confirmDelete = () => {
        ModalService.Close()
        setSaving(true)
        ApiService.experience.Delete__DELETE(data.id).then(() => {
            NotificationService.Confirm(`${eventOrExperience} deleted`)
            history.push(`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/experiences`)
        }).catch(() => NotificationService.Error('An error has occured.'))
            .finally(() => setSaving(false))
    }

    const confirmMenuUpload = (value: string, details: ApiPDF) => {
        const newData: BaseExperience = {
            ...initialData.current,
            pdfMenu: value
        }
        setSaving(true)
        adjustNumericFieldsBeforeSave(newData);
        ApiService.experience.Save__POST(newData).then(() => {
            initialData.current = cloneDeep(newData);
            setData({
                ...data,
                pdfMenu: value
            })
            setPublishModalOpen(false)
            NotificationService.Confirm('Menu uploaded');
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        }).finally(() => {
            setSaving(false)
        })
    }

    const confirmMenuRemove = () => {
        const oldPdfName = initialData.current?.pdfMenu;
        const newData: BaseExperience = {
            ...initialData.current,
            pdfMenu: ''
        }
        setSaving(true)
        ModalService.Close();
        adjustNumericFieldsBeforeSave(newData);
        ApiService.experience.Save__POST(newData).then(() => {
            initialData.current = cloneDeep(newData);
            setData({
                ...data,
                pdfMenu: ''
            })
            setPublishModalOpen(false)
            NotificationService.Confirm('Menu removed');
            ApiService.pdf.Delete__POST({
                rowKey: oldPdfName.split('.')[0],
                partitionKey: businessData?.id.toString()
            })
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        }).finally(() => {
            setSaving(false)
        })
    }

    const openBookingsModal = () => {
        loadBookings();
        clearBookingFilters()
        setViewBookings(true);
    }

    const clearBookingFilters = () => {
        bookingFilter.bookingDateTime = null;
        bookingFilter.email = null;
        bookingFilter.name = null;
        bookingFilter.reference = null;
        bookingFilter.telephone = null;
    }

    const loadBookings = () => {
        if (!bookings) ApiService.experience.GetBookings__GET(data.id, businessData.id).then(resp => {
            setBookings(resp);
        })
    }

    const tableFilter = (booking: LightExperienceBooking): boolean => {
        return isValidFilterItem(booking.bookingReference, bookingFilter.reference) &&
            isValidFilterItem(booking.clientTelephone, bookingFilter.telephone) &&
            isValidFilterItem(getName(booking), bookingFilter.name) &&
            isValidFilterItem(booking.clientEmail, bookingFilter.email) &&
            isValidFilterDateItem(booking.bookingDateTime, bookingFilter.bookingDateTime)
    }

    function isValidFilterItem(item: string, filterValue: string): boolean {
        return isNullOrWhitespace(filterValue) || item?.toLowerCase().indexOf(filterValue?.toLowerCase()) > -1
    }

    function isValidFilterDateItem(item: string, filterValue: string): boolean {
        return !filterValue || (item && formatDate(filterValue, DATEONLYFORMAT) == formatDate(item, DATEONLYFORMAT))
    }

    function getName(booking: LightExperienceBooking): string {
        return `${booking.clientName} ${booking.clientSurname ? booking.clientSurname : ''}`
    }

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];

        bookings.filter(tableFilter).forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Reference': {
                        value: item.bookingReference,
                        sortable: true,
                    },
                    'Date and time': {
                        value: formatDate(item.bookingDateTime, DISPLAY_DATE_TIME_FORMAT),
                        sortable: true,
                    },
                    'Client': {
                        value: `${item.clientName} ${item.clientSurname ? item.clientSurname : ''}`,
                        sortable: true,
                    },
                    'Email': {
                        value: item.clientEmail,
                        sortable: true,
                    },
                    'Telephone': {
                        value: item.clientTelephone,
                        sortable: true,
                    },
                    'Guests': {
                        value: item.guests,
                        sortable: true,
                    },
                    'Cancel': {
                        value: <CoreButton type='danger' onClick={() => setCancelBookingItem(item)}>Cancel booking</CoreButton>,
                    }
                },
            }
            items.push(dataItem);
        });
        return items;
    }

    const cancelBooking = () => {
        setCancelling(true)
        ApiService.bookings.UpdateStatus__POST({
            bookingId: cancelBookingItem.id,
            status: BookingStatus.Cancelled
        }).then(() => {
            NotificationService.Confirm('Booking cancelled');
            setBookings([...bookings.filter(x => x.id !== cancelBookingItem.id)]);
            setData({ ...data, futureBookingsCount: data.futureBookingsCount - 1 })
            initialData.current = { ...data, futureBookingsCount: data.futureBookingsCount - 1 };
            setCancelBookingItem(undefined);
        }).catch(() => {
            NotificationService.Error('Unable to cancel booking');
        }).finally(() => setCancelling(false))
    }

    const generateShortName = () => {
        ApiService.aiprompt.Aiprompt__POST(`Rephrase this in 20 characters or less. Try and retain as many original words as possible. Before re-phrasing, remove any emoji. : ${data?.displayName}`).then((response) => {
            console.log(response.info.length)
            setData({ ...data, name: response.info })
        })
    }

    return (
        <>
            <DashboardHeader title={!initialData.current ? '' : <>Edit {eventOrExperienceLowerCase} {initialData.current?.published ? <Badge colorPalette='green'>Published</Badge> : <Badge colorPalette='orange'>Unpublished</Badge>}</>} />
            {viewBookings &&
                <CoreModal onClose={() => setViewBookings(false)} title='Booking list' cancelText='Close'>
                    {cancelBookingItem &&
                        <CoreModal
                            hasCancel={!cancelling}
                            cancelText='Keep booking'
                            small
                            mediumPanel
                            title='Cancel booking'
                            onClose={() => setCancelBookingItem(undefined)}
                            actionBar={
                                <CoreButton requesting={cancelling}
                                    disabled={cancelling}
                                    type='danger'
                                    onClick={cancelBooking}
                                >Cancel booking</CoreButton>
                            }>
                            <WarningMessage>Are you sure you want to cancel {cancelBookingItem.clientName + (isNullOrWhitespace(cancelBookingItem.clientSurname) ? '' : ' ' + cancelBookingItem.clientSurname)}'s booking?</WarningMessage>
                        </CoreModal>
                    }
                    <ListWrap>
                        <SectionGroup name='Filter'>
                            <FormWrapper onUpdate={setBookingFilter}>
                                {({ id, valid }) => (
                                    <>
                                        <Row>
                                            <Column size={2} tablet={6} mobile={12} noMarginBottom>
                                                <StyledTextInput model='reference' label='Reference' value={bookingFilter.reference} />
                                            </Column>
                                            <Column size={2} tablet={6} mobile={12} noMarginBottom>
                                                <EasyCalendar validDates={dates} model='bookingDateTime' label='Date' value={bookingFilter.bookingDateTime} />
                                            </Column>
                                            <Column size={3} tablet={6} mobile={12} noMarginBottom>
                                                <StyledTextInput model='name' label='Client name' value={bookingFilter.name} />
                                            </Column>
                                            <Column size={3} tablet={6} mobile={12} noMarginBottom>
                                                <StyledTextInput model='email' label='Email' max={EmailAddressSize} value={bookingFilter.email} />
                                            </Column>
                                            <Column size={2} tablet={6} mobile={12} noMarginBottom>
                                                <StyledTextInput model='telephone' label='Telephone' value={bookingFilter.telephone} />
                                            </Column>
                                        </Row>
                                        <br />
                                    </>
                                )}
                            </FormWrapper>
                        </SectionGroup>
                    </ListWrap>
                    {bookings && <DataTable defaultSort='Date and time' data={gentable()} />}
                    {!bookings && <Loader />}
                </CoreModal>
            }
            {publishModalOpen &&
                <CoreModal
                    title={`Are you sure you want to ${initialData.current.published ? 'unpublish' : 'publish'} this ${typeName}?`}
                    onClose={() => setPublishModalOpen(false)}
                    small={initialData.current.published}
                    slimPanel={initialData.current.published}
                    actionBar={initialData.current.published ?
                        <CoreButton requesting={saving} disabled={saving} type='warning' onClick={() => confirmPublishAction(false)}><Icon name='download' /> Unpublish</CoreButton> :
                        <CoreButton requesting={saving} disabled={saving || warningList.hasErrors} type='success' onClick={() => confirmPublishAction(true)}><Icon name='upload' /> Publish</CoreButton>
                    }
                >
                    {!initialData.current.published &&
                        <>
                            {warningList.hasErrors && <H4style>Required actions</H4style>}
                            {warningList.dates == ExperienceWarningType.Error &&
                                <MessageContainer><ErrorMessage>There are no&nbsp;<strong>dates and time slots</strong>&nbsp;set up for your {typeName}.</ErrorMessage></MessageContainer>
                            }
                            {warningList.publishedDates == ExperienceWarningType.Error &&
                                <MessageContainer><ErrorMessage>There are no&nbsp;<strong>active dates</strong>&nbsp;set up for your {typeName}.</ErrorMessage></MessageContainer>
                            }
                            {warningList.selectedTables == ExperienceWarningType.Error &&
                                <MessageContainer><ErrorMessage>There are no&nbsp;<strong>areas or tables</strong>&nbsp;set up for your {typeName}.</ErrorMessage></MessageContainer>
                            }
                            {warningList.hasWarnings && <H4style>Optional settings to review</H4style>}
                            {warningList.timeBlockRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>block out rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                            {warningList.pacingRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>pacing rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                            {warningList.durationRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>duration rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                            {warningList.depositRules == ExperienceWarningType.Info &&
                                <MessageContainer><InfoMessage>There are no&nbsp;<strong>payment rules</strong>&nbsp;set up for your {typeName}.</InfoMessage></MessageContainer>
                            }
                        </>
                    }
                </CoreModal>
            }
            {preview &&
                <CoreModal isOpen onClose={() => setPreview(false)} title='Widget preview' cancelText='Close'>
                    <BookingModule
                        previewLocation={businessData.locationId}
                        previewBusinessData={{
                            name: businessData.name,
                            theme: {
                                primaryColour: businessData.bookingConfigurationSettings.primaryColour,
                                primaryContrastColour: businessData.bookingConfigurationSettings.primaryContrastColour,
                                backgroundColour: businessData.bookingConfigurationSettings.backgroundColour,
                                textColour: businessData.bookingConfigurationSettings.textColour,
                                buttonColour: businessData.bookingConfigurationSettings.buttonColour,
                                buttonTextColour: businessData.bookingConfigurationSettings.buttonTextColour,
                            },
                            [data.type == ExperienceType.Event ? 'specialEvents' : 'experiences']: {
                                [data.id]: {
                                    name: data.displayName,
                                    description: data.description,
                                    imageUrl: data.imageUrl,
                                    menuUrl: data.pdfMenu,
                                    type: data.type,
                                    location: data.displayLocation,
                                    minimumPeople: data.minGuests,
                                    maximumPeople: data.maxGuests,
                                    price: data.pricePerGuest,
                                    layout: data.widgetLayout
                                }
                            }
                        }}
                    />
                </CoreModal>
            }
            {(!businessData || !data) && <Loader />}
            {businessData && data &&
                <PageWrap>
                    <TopActionBar>
                        {!initialData.current.published &&
                            <TopActionBarButton>
                                <CoreButton onClick={() => setPublishModalOpen(true)} type='success'><Icon name='upload' /> Publish</CoreButton>
                            </TopActionBarButton>
                        }
                        {initialData.current.published &&
                            <TopActionBarButton>
                                <CoreButton onClick={() => setPublishModalOpen(true)} type='warning'><Icon name='download' /> Unpublish</CoreButton>
                            </TopActionBarButton>
                        }
                        <TopActionBarButton>
                            <CoreButton type='secondary' outline onClick={() => setPreview(true)}><Icon name='magnifying-glass' /> Preview on booking widget</CoreButton>
                        </TopActionBarButton>
                        {/* <TopActionBarButton>
                            <CoreButton><Icon name='list' /> Summary</CoreButton>
                            </TopActionBarButton> */}
                        {data.futureBookingsCount > 0 &&
                            <TopActionBarButton>
                                <CoreButton onClick={openBookingsModal}><Icon name='calendar' /> View bookings ({data.futureBookingsCount})</CoreButton>
                            </TopActionBarButton>
                        }
                        <Spacer />
                        <TopActionBarButton>
                            <CoreButton noOutline type='secondary' to={`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/experiences`}><Icon name='arrow-left' /> Back to all</CoreButton>
                        </TopActionBarButton>
                    </TopActionBar>
                    <FormWrapper<BaseExperience> disabled={saving} onUpdate={(formDetails) => setData({ ...data, ...formDetails })}>
                        {({ id, valid }) => (
                            <DashboardPanel title='Details'>
                                <Row>
                                    <Column size={4}>
                                        <StyledTextInput max={BookingTypeNameSize} model='displayName' label={<>Full name <InfoButton>This will be the event/experience name visible to your clients online.</InfoButton></>} required value={data?.displayName} />
                                    </Column>
                                    <Column size={4}>
                                        <StyledTextInput max={20} model='name' label={<>Short name <InfoButton>This short name is visible on the widget and dashboard in scenarios where there is not enough space to display the full name. For example, an experience called "Bottomless afternoon tea for two" could be set to "Afternoon tea" in the short name field. This name will be visible to your staff when making bookings internally and on the widget where the long name can't be displayed.</InfoButton></>} required value={data?.name} />
                                    </Column>
                                    {/* <Column size={4}>
                                        <CoreButton onClick={generateShortName}>Generate short name</CoreButton>
                                    </Column> */}
                                    <Column size={12}>
                                        <RichTextEdior maxLength={BookingTypeDescriptionSize} model='description' label='Description' value={data?.description} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={3}>
                                        <StyledCurrencyInput model='pricePerGuest' value={data?.pricePerGuest} label='Price per guest' currencySymbol={GetCurrencySymbol(businessData?.currencyCode)} />
                                    </Column>
                                    <Column size={3}>
                                        <StyledNumberInput model='minGuests' value={data?.minGuests} min={1} max={data?.maxGuests} label='Min guests per booking' placeholder='No min' />
                                    </Column>
                                    <Column size={3}>
                                        <StyledNumberInput model='maxGuests' value={data?.maxGuests} min={1} label='Max guests per booking' placeholder='No max' />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={3}>
                                        <ImageSelector value={data.imageUrl} label={`${eventOrExperience} image`} onChange={(imageUrl) => setData({ ...data, imageUrl })} />
                                    </Column>
                                    <Column size={3}>
                                        <StyledDropdown addDefault={false} model='widgetLayout' label='Image position on widget' value={data.widgetLayout == WidgetImageLayout.NotSet ? WidgetImageLayout.Top : data.widgetLayout} items={[{ value: WidgetImageLayout.Top, text: 'Top' }, { value: WidgetImageLayout.Left, text: 'Left' }]} />
                                    </Column>
                                </Row>
                                {hasChanges &&
                                    <FloatingActionBar flex>
                                        <WarningMessage>{Constants.messaging.makeSureToSave}</WarningMessage>
                                        <CoreButton small disabled={!valid || saving} onClick={() => saveChanges()} floatRight>Save changes <Icon name='save' /></CoreButton>
                                    </FloatingActionBar>
                                }
                            </DashboardPanel>
                        )}
                    </FormWrapper>
                    {data.type == ExperienceType.Event && <DashboardPanel title={<>Location<InfoButton>{`This will be the ${eventOrExperienceLowerCase} location visible to your clients online. Please provide location details if the location of the ${eventOrExperienceLowerCase} is different to your restaurant address. `}</InfoButton></>}>
                        <ExperienceLocation typeName={typeName} data={data} setData={setData} />
                    </DashboardPanel>}
                    <DashboardPanel title={`${eventOrExperience} menu`}>
                        {isNullOrWhitespace(data.pdfMenu) &&
                            <>
                                <InfoMessage>For best user experience please upload optimised menus that are less than 1MB.</InfoMessage>
                                <PDFUpload onUpload={confirmMenuUpload} site={businessData?.id.toString()} />
                            </>
                        }
                        {!isNullOrWhitespace(data.pdfMenu) &&
                            <>
                                {pdfViewerOpen &&
                                    <CoreModal onClose={() => setPdfViewerOpen(false)} title='View menu'>
                                        <iframe src={Constants.baseImageUrl + data.pdfMenu} style={{ height: '100%', width: '100%' }} />
                                    </CoreModal>
                                }
                                <CoreButton onClick={() => setPdfViewerOpen(true)}><Icon name='eye' /> View menu</CoreButton><br /><br />
                                <CoreButton
                                    icon='trash'
                                    type='danger'
                                    noOutline
                                    onClick={() => ModalService.Open({
                                        title: 'Remove uploaded menu?',
                                        medium: true,
                                        actionBar: <CoreButton onClick={confirmMenuRemove}>Confirm</CoreButton>,
                                    })}
                                >Remove</CoreButton>
                            </>
                        }
                    </DashboardPanel>
                    <DashboardPanel title='Dates and bookable slots'>
                        {data.type === ExperienceType.Event && <>
                            <InfoMessage>Any dates/times entered here will make the event bookable during those times, even if the business is closed on that day.</InfoMessage><br />
                        </>}
                        <SpecialOpeningTimes experienceId={data.id} experienceType={data.type} onDatesUpdate={updateDates} />
                    </DashboardPanel>
                    <DashboardPanel title='Duration rules'>
                        {(!ExperienceService._dates || ExperienceService._dates.length == 0) ?
                            <WarningMessage>To enable this feature, please set up at least 1 date band for the {eventOrExperienceLowerCase}.</WarningMessage>
                            :
                            <DurationRules experienceId={data.id} validDateBands={dates} />
                        }
                    </DashboardPanel>
                    <DashboardPanel title='Pacing rules'>
                        {(!ExperienceService._dates || ExperienceService._dates.length == 0) ?
                            <WarningMessage>To enable this feature, please set up at least 1 date band for the {eventOrExperienceLowerCase}.</WarningMessage>
                            :
                            <PacingRules experienceId={data.id} validDateBands={dates} />
                        }
                    </DashboardPanel>
                    <DashboardPanel title='Floor plans'>
                        <BookingTableLayout experienceId={data.id} experienceType={data.type} />
                    </DashboardPanel>
                    <DashboardPanel title='Block out times'>
                        {(!ExperienceService._dates || ExperienceService._dates.length == 0) ?
                            <WarningMessage>To enable this feature, please set up at least 1 date band for the {eventOrExperienceLowerCase}.</WarningMessage>
                            :
                            <BlockOutTimesSetup experienceId={data.id} experienceType={data.type} validDateBands={dates} />
                        }
                    </DashboardPanel>
                    <DashboardPanel title='Payments'>
                        {(!ExperienceService._dates || ExperienceService._dates.length == 0) ?
                            <WarningMessage>To enable this feature, please set up at least 1 date band for the {eventOrExperienceLowerCase}.</WarningMessage>
                            :
                            <PaymentRules experienceId={data.id} experienceType={data.type} maxPrice={isNullOrWhitespace(data?.pricePerGuest) ? undefined : data?.pricePerGuest} validDateBands={dates} />
                        }
                    </DashboardPanel>
                    {data.type == ExperienceType.Event && <WebisteIntegration experienceGuid={data.experienceGuid} />}
                    {!data.hasBookings &&
                        <CoreButton type='danger' onClick={() => ModalService.Open({
                            title: `Are you sure you want to ${data.hasBookings ? 'archive' : 'delete'} "${data?.name}"?`,
                            slim: true,
                            medium: true,
                            children: data.hasBookings ?
                                <InfoMessage>Archiving this {data.type == ExperienceType.Event ? 'event' : 'experience'} will make it unavailable from widget and in-house views.</InfoMessage> :
                                <WarningMessage children={<>This will <strong>permanently</strong> remove the {eventOrExperience.toLowerCase()}. This action is irreversible.</>}></WarningMessage>,
                            actionBar: <CoreButton type='danger' onClick={confirmDelete}>Confirm</CoreButton>
                        })}>{data.hasBookings ? 'Archive' : 'Delete'} {data.type == ExperienceType.Event ? 'event' : 'experience'}</CoreButton>
                    }
                </PageWrap>
            }
        </>
    );
};

const MessageContainer = styled.div`
    margin-bottom: 0.5rem;
`

const PageWrap = styled.div`
    position: relative;
    overflow: clip;
`

const TopActionBar = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0.5rem;
    background-color: ${props => props.theme.background};
    border: 1px solid ${props => props.theme.borderColor};
    padding: 0.5rem;
    position: sticky;
    align-self: flex-start;
    top: 0;
    z-index: 2;
`

const TopActionBarButton = styled.div`
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.primary};
    cursor: pointer;
    text-align: center;
    transition: all 0.4s ease;
    margin-left: 1rem;
    display: flex;
    line-height: 1.5rem;
`

const Spacer = styled.div`
    flex: 1;
`

export default EditExperience;