import { CurrencyCodes } from "../config/currency";
import { formatNumberToText } from "./number-helper";

interface Currency {
  code: string;
  amount: number;
}

const commaCurrencies = ['al', 'ad', 'ar', 'at', 'az', 'be', 'bo', 'by', 'da', 'fr', 'fx', 'de'];

export function ConvertCurrency(value: Currency) {
    return `${value.amount < 0 ? '-' : ''}${!!CurrencyCodes[value.code] ? CurrencyCodes[value.code] : value.code}${value.amount ? formatNumberToText((value.amount < 0 ? Math.abs(value.amount) : value.amount), 2) : '--.--'}`;
}

export function GetCurrencySymbol(value: string) {
  return !!CurrencyCodes[value] ? CurrencyCodes[value] : value;
}

export function UseCommaInCurrency() {
  return commaCurrencies.find(x => x === document.documentElement.lang.toLowerCase());
}
