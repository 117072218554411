import styled from 'styled-components';

export const FlexStyles = {
    List: styled.div`
        display: flex;
        flex-wrap: wrap;
        list-style: none;
    `,
    Item: styled.div`
        display: flex;
        padding: .5em;
        max-width: 100%;
    `,
    Content: styled.div`
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: .5em;
        width: 100%;

        img {
            width: 100%;
        }
    `,
    Text: styled.div`
        flex: 1 0 auto;
    `
}