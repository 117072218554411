import React, { useEffect, useRef, useState } from 'react';
import { Column, Row } from '../../../../../../../components/Layout/Grid';
import DashboardAddButton from '../../../../../../../components/Dashboard/AddButton';
import CoreButton from '../../../../../../../components/Forms/Button';
import { DATABASE_TIME_FORMAT, DISPLAY_DATE_FORMAT, TIMEFORMAT, formatDate, getMinDateForRuleSetup } from '../../../../../../../utils/date-helpers';
import { DayBox } from '../../BookingSetup';
import Icon from '../../../../../../../components/Media/Icon';
import styled from 'styled-components';
import { ApiService } from '../../../../../../../api/api-connectors';
import { useBusiness } from '../../../../../../../hooks/useBusiness';
import { BaseBookingTimeBlock, BookingSourceToBlock, ExperienceType } from '../../../../../../../api/api-definitions';
import Loader from '../../../../../../../components/Layout/Loader';
import { NotificationService } from '../../../../../../../services/NotificationService';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { Badge, Button, Stack } from '@chakra-ui/react';
import ActionBox, { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../../components/Forms/Messaging';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import FormWrapper from '../../../../../../../components/Forms/FormWrapper';
import { StyledCalendar, StyledRadioInput, StyledTimeInput } from '../../../../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import Checkbox from '../../../../../../../components/Forms/Checkbox';
import { ExtendedArea, ExtendedShape, ExtendedTable } from '../../TableLayout';
import { TABLEWIDTH } from '../../TableLayout/tableLayoutUtils';
import BlockingTableAndAreaSelection from '../../TableLayout/BlockingTableAndAreaSelection';
import { DateBandValidityItem } from '../../../../../../../components/Forms/Calendar';
import { ExperienceService } from '../../Experiences/experienceService';
import { createUUID } from '../../../../../../../utils/data-helpers';
import ExperienceInfoBadge from '../../Experiences/experienceInfoBadge';
import { RadioGroup } from '../../../../../../../components/ui/radio';
import CoreRadioGroup from '../../../../../../../components/Forms/RadioGroup';
import moment from 'moment';
import DayLabels from '../../modules/DayLabels';

interface ComponentProps {
    experienceId?: number;
    experienceType?: ExperienceType;
    validDateBands?: DateBandValidityItem[];
}


const DEFAULTTIMEBLOCK: BaseBookingTimeBlock = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    bookingSourceToBlock: BookingSourceToBlock.OnlineOnly
}

const BlockOutTimesSetup = ({ experienceId, experienceType, validDateBands }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [tables, setTables] = useState<ExtendedTable[]>();
    const [shapes, setShapes] = useState<ExtendedShape[]>();
    const [areas, setAreas] = useState<ExtendedArea[]>();
    const [data, setData] = useState<BaseBookingTimeBlock[]>();
    const [itemToEdit, setItemToEdit] = useState<BaseBookingTimeBlock>();
    const [itemToEditOpen, setItemToEditOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<BaseBookingTimeBlock>();
    const [saving, setSaving] = useState(false);
    const [overlappingIds, setOverlappingIds] = useState<number[]>();
    const [showDateTimeWarning, setShowDateTimeWarning] = useState(false);
    const [showAreaTableWarning, setShowAreaTableWarning] = useState(false);
    const init = useRef(false);
    const modalContentRef = useRef<HTMLDivElement>();
    const allSelected = itemToEdit?.monday && itemToEdit?.tuesday && itemToEdit?.wednesday && itemToEdit?.thursday && itemToEdit?.friday && itemToEdit?.saturday && itemToEdit?.sunday;
    const oneSelected = itemToEdit?.monday || itemToEdit?.tuesday || itemToEdit?.wednesday || itemToEdit?.thursday || itemToEdit?.friday || itemToEdit?.saturday || itemToEdit?.sunday;
    const tableViewLoaded = tables && shapes && areas;

    useEffect(() => {
        if (!init.current && business) {
            init.current = true;
            load()
        }
    }, [business])

    const loadTableView = () => {
        if (!tableViewLoaded) {
            ApiService.bookingtablesetup.List__GET(business.id).then(data => {
                const newData: ExtendedTable[] = data.map((item: ExtendedTable) => {
                    item.width = TABLEWIDTH;
                    item.valid = true;
                    return item;
                })
                setTables(newData)
            })
            ApiService.shapes.List__GET(business.id).then((data) => {
                setShapes(data)
            })
            ApiService.tablesetupareas.List__GET(business.id).then(data => {
                setAreas(data)
            })
        }
    }

    const load = () => {
        const activeOnly = !experienceId; // non-expired records only unless we are loading experience setup
        ApiService.bookingtimeblock.List__GET(business.id, experienceId || 0, activeOnly).then(response => {
            setData(response)
            ExperienceService._timeBlockRules = response;
        }).catch(() => {
            NotificationService.Error('Unable to fetch blocked times')
        })
    }

    const gentable = (dataToShow: BaseBookingTimeBlock[]): DataTableItem[] => {
        const items: DataTableItem[] = [];
        dataToShow.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Dates': {
                        value: item.dateFrom || item.dateTo ? <>{item.dateFrom ? formatDate(item.dateFrom, DISPLAY_DATE_FORMAT) : '-- --'} to {item.dateTo ? formatDate(item.dateTo, DISPLAY_DATE_FORMAT) : '-- --'}</> : '--',
                    },
                    'Times': {
                        value: item.timeFrom || item.timeTo ? <>{item.timeFrom ? formatDate(item.timeFrom, TIMEFORMAT) : '-- --'} to {item.timeTo ? formatDate(item.timeTo, TIMEFORMAT) : '-- --'}</> : '--',
                    },
                    'Days': {
                        value: <DayLabels {...item} />
                    },
                    'Applies to areas/tables': {
                        value: item.appliedToAreas ? <Icon name='check' /> : <Icon name='times' />
                    },
                    'Blocked source': {
                        value: <>
                            {item.bookingSourceToBlock == BookingSourceToBlock.OnlineOnly && <Badge colorPalette='purple'>Online</Badge>}
                            {item.bookingSourceToBlock == BookingSourceToBlock.OnlineAndInHouse && <Badge colorPalette='orange'>Online and in-house</Badge>}
                        </>,
                        hideName: true
                    },
                    'Experience': {
                        value: <>
                            {!experienceId && !!item.experienceId && <ExperienceInfoBadge experienceId={item.experienceId} />}
                        </>,
                        hideName: true
                    },
                    'Edit': {
                        value: <CoreButton disabled={!experienceId && !!item.experienceId} onClick={() => { loadTableView(); setItemToEditOpen(true); setItemToEdit(item) }}>Edit</CoreButton>,
                        hideName: true
                    },
                    'Delete': {
                        value: <CoreButton disabled={!experienceId && !!item.experienceId} onClick={() => setItemToDelete(item)} type='danger'>Delete</CoreButton>,
                        hideName: true
                    }
                },
            }
            items.push(dataItem);
        });
        return items;
    }

    const saveRule = () => {
        if (
            isNullOrWhitespace(itemToEdit.dateFrom) &&
            isNullOrWhitespace(itemToEdit.dateTo) &&
            isNullOrWhitespace(itemToEdit.timeFrom) &&
            isNullOrWhitespace(itemToEdit.timeTo)
        ) {
            setShowDateTimeWarning(true)
            return;
        }
        if (itemToEdit.appliedToAreas && itemToEdit.areaLinks?.filter(x => x.wholeAreaSelected || x.tableSelections?.length > 0).length == 0) {
            setShowAreaTableWarning(true)
            return;
        }
        setSaving(true);
        if (!isNullOrWhitespace(itemToEdit.dateFrom)) itemToEdit.dateFrom = formatDate(itemToEdit.dateFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(itemToEdit.dateTo)) itemToEdit.dateTo = formatDate(itemToEdit.dateTo, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(itemToEdit.timeFrom)) itemToEdit.timeFrom = formatDate(itemToEdit.timeFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(itemToEdit.timeTo)) itemToEdit.timeTo = formatDate(itemToEdit.timeTo, DATABASE_TIME_FORMAT);
        itemToEdit.businessId = business.id;
        if (experienceId) itemToEdit.experienceId = experienceId;
        if (!itemToEdit.areaLinks) itemToEdit.areaLinks = [];
        (itemToEdit.id ? ApiService.bookingtimeblock.Update__POST : ApiService.bookingtimeblock.Insert__PUT)(itemToEdit).then(response => {
            if (response.idsOfOverlappingRecords?.length > 0) {
                setOverlappingIds(response.idsOfOverlappingRecords)
                scrollToBottom();
            } else if (response.success) {
                NotificationService.Confirm('Rule saved')
                load()
                setItemToEdit(undefined)
                setItemToEditOpen(false)
                setOverlappingIds(undefined)
            } else {
                NotificationService.Error('Unable to save rule')
            }
        }).catch(() => {
            NotificationService.Error('Unable to save rule')
        }).finally(() => setSaving(false))
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if (modalContentRef) modalContentRef.current.scrollTo({
                top: 1000,
                left: 0,
                behavior: "smooth",
            })
        }, 200);
    }

    const confirmDelete = () => {
        setSaving(true);
        ApiService.bookingtimeblock.Delete__DELETE(itemToDelete).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Rule deleted')
                load()
                setItemToDelete(undefined)
            } else {
                NotificationService.Error('Unable to delete rule')
            }
        }).catch(() => {
            NotificationService.Error('Unable to save rule')
        }).finally(() => setSaving(false))
    }

    const toggleSelectAll = () => {
        setItemToEdit({
            ...itemToEdit,
            monday: !allSelected,
            tuesday: !allSelected,
            wednesday: !allSelected,
            thursday: !allSelected,
            friday: !allSelected,
            saturday: !allSelected,
            sunday: !allSelected,
        })
    }
    const eventOrExperienceLowerCase = experienceType === ExperienceType.Event ? 'event' : 'experience';
    const customMessage = experienceType === ExperienceType.Event ? 'other' : 'standard';
    const experienceBlockMessage = <>Please specify time ranges to prevent {customMessage} bookings from being made when this {eventOrExperienceLowerCase} takes place.<br />
        This block will not apply on days when this experience isn't running.</>;
    const hideBlockType = experienceId && experienceType == ExperienceType.Experience;
    const experienceDatesAreLimited = experienceType == ExperienceType.Experience &&
        !!validDateBands &&
        validDateBands.length > 0
        && validDateBands?.filter(x => !!x.dateTo).length === validDateBands?.length;

    return (
        <div>
            {itemToDelete &&
                <CoreModal
                    small
                    onClose={() => setItemToDelete(undefined)}
                    title='Are you sure you want to delete this rule?'
                    slimPanel
                    actionBar={<CoreButton type='danger' requesting={saving} disabled={saving} onClick={() => confirmDelete()}>Confirm</CoreButton>}
                />
            }
            {showDateTimeWarning &&
                <CoreModal
                    small
                    onClose={() => setShowDateTimeWarning(false)}
                    title={<><WarningIcon name='exclamation-triangle' /> Could not save rule</>}
                    slimPanel
                    hasCancel={false}
                    actionBar={<CoreButton requesting={saving} disabled={saving} onClick={() => setShowDateTimeWarning(false)}>Confirm</CoreButton>}
                >
                    Please select either a date range and/or a time range for your rule.
                </CoreModal>
            }
            {showAreaTableWarning &&
                <CoreModal
                    small
                    onClose={() => setShowAreaTableWarning(false)}
                    title={<><WarningIcon name='exclamation-triangle' /> Could not save rule</>}
                    slimPanel
                    hasCancel={false}
                    actionBar={<CoreButton requesting={saving} disabled={saving} onClick={() => setShowAreaTableWarning(false)}>Confirm</CoreButton>}
                >
                    The rule has been set to apply to tables/areas but none have been selected.
                </CoreModal>
            }
            {itemToEditOpen && itemToEdit &&
                <FormWrapper<BaseBookingTimeBlock> onUpdate={(formDetails) => setItemToEdit({ ...itemToEdit, ...formDetails })}>
                    {({ id, valid }) => (
                        <CoreModal
                            title={itemToEdit.id ? 'Edit block out rule' : 'Add new block out rule'}
                            contentRef={modalContentRef}
                            actionBar={<CoreButton onClick={saveRule} requesting={saving} disabled={!valid || saving || !oneSelected}><Icon name='save' /> {itemToEdit.id ? 'Save block out rule' : 'Add block out rule'}</CoreButton>}
                            onClose={() => { setItemToEditOpen(false); setItemToEdit(undefined); setOverlappingIds(undefined) }}>
                            <Row>
                                <Column size={4} mobile={6}>
                                    <StyledCalendar required={experienceType === ExperienceType.Event || experienceDatesAreLimited} minDate={getMinDateForRuleSetup(itemToEdit.dateFrom)} model='dateFrom' value={itemToEdit.dateFrom} label='From' validDates={validDateBands} />
                                </Column>
                                <Column size={4} mobile={6}>
                                    <StyledCalendar required={experienceType === ExperienceType.Event || experienceDatesAreLimited} model='dateTo' value={itemToEdit.dateTo} minDate={getMinDateForRuleSetup(itemToEdit.dateFrom, true)} label='To (inclusive)' validDates={validDateBands} />
                                </Column>
                            </Row>
                            <hr />
                            <br />
                            <Row>
                                {!!experienceId &&
                                    <Column size={12}>
                                        {experienceType === ExperienceType.Event &&
                                            <InfoMessage>
                                                {experienceBlockMessage}
                                                <br />
                                                Make sure to consider the time needed to prepare the areas/tables before the event commences and after it completes.
                                            </InfoMessage>
                                        }
                                        {experienceType !== ExperienceType.Event &&
                                            <InfoMessage>
                                                {experienceBlockMessage}
                                            </InfoMessage>
                                        }
                                    </Column>
                                }
                            </Row>
                            <Row>
                                <Column size={4} mobile={6}>
                                    <StyledTimeInput model='timeFrom' required={!experienceId || !isNullOrWhitespace(itemToEdit.timeFrom)} value={itemToEdit.timeFrom} label='From' />
                                </Column>
                                <Column size={4} mobile={6}>
                                    <StyledTimeInput model='timeTo' required={!experienceId || !isNullOrWhitespace(itemToEdit.timeFrom)} value={itemToEdit.timeTo} min={itemToEdit.timeFrom} label='To (inclusive)' />
                                </Column>
                            </Row>
                            <hr />
                            <br />
                            {!hideBlockType &&
                                <>
                                    <CoreRadioGroup value={itemToEdit.bookingSourceToBlock} model='bookingSourceToBlock'>
                                        <Stack>
                                            <StyledRadioInput model='bookingSourceToBlock' value={BookingSourceToBlock.OnlineOnly} label='Block online bookings only' />
                                            <StyledRadioInput model='bookingSourceToBlock' value={BookingSourceToBlock.OnlineAndInHouse} label='Block both online and in-house bookings' />
                                        </Stack>
                                    </CoreRadioGroup>
                                </>
                            }
                            {hideBlockType &&
                                <InfoMessage>For experiences, you can only block online bookings.</InfoMessage>
                            }
                            <br />
                            <hr />
                            <br />
                            <Row>
                                <Column size={4} mobile={12}>
                                    <br />
                                    <CoreButton type='secondary' outline onClick={toggleSelectAll}>{allSelected ? 'Deselect' : 'Select'} all</CoreButton>
                                </Column>
                                <Column size={8} mobile={12}>
                                    <label> Days of week</label>
                                    <br />
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, monday: !itemToEdit.monday })} checked={itemToEdit.monday}><Icon name={itemToEdit.monday ? 'check' : 'times'} /> Mon</DayBox>
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, tuesday: !itemToEdit.tuesday })} checked={itemToEdit.tuesday}><Icon name={itemToEdit.tuesday ? 'check' : 'times'} /> Tue</DayBox>
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, wednesday: !itemToEdit.wednesday })} checked={itemToEdit.wednesday}><Icon name={itemToEdit.wednesday ? 'check' : 'times'} /> Wed</DayBox>
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, thursday: !itemToEdit.thursday })} checked={itemToEdit.thursday}><Icon name={itemToEdit.thursday ? 'check' : 'times'} /> Thu</DayBox>
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, friday: !itemToEdit.friday })} checked={itemToEdit.friday}><Icon name={itemToEdit.friday ? 'check' : 'times'} /> Fri</DayBox>
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, saturday: !itemToEdit.saturday })} checked={itemToEdit.saturday}><Icon name={itemToEdit.saturday ? 'check' : 'times'} /> Sat</DayBox>
                                    <DayBox onClick={() => setItemToEdit({ ...itemToEdit, sunday: !itemToEdit.sunday })} checked={itemToEdit.sunday}><Icon name={itemToEdit.sunday ? 'check' : 'times'} /> Sun</DayBox>
                                </Column>
                            </Row>
                            <hr />
                            <br />
                            <Row>
                                <Column size={12}>
                                    <Checkbox label='Apply to specific areas or tables' asToggle model='appliedToAreas' checked={itemToEdit.appliedToAreas} />
                                </Column>
                            </Row>
                            {tableViewLoaded && itemToEdit.appliedToAreas && areas.length > 0 &&
                                <BlockingTableAndAreaSelection
                                    areas={areas}
                                    tables={tables}
                                    shapes={shapes}
                                    onUpdate={(selected) => setItemToEdit({ ...itemToEdit, areaLinks: selected })}
                                    selectedTables={itemToEdit.areaLinks || []}
                                    timeBlockId={itemToEdit.id}
                                    overlay={createUUID()}
                                />
                            }
                            {tableViewLoaded && itemToEdit.appliedToAreas && areas.length == 0 &&
                                <ActionBox
                                    icon={'dining-table'}
                                    url={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/table-setup`}
                                    title='Additional configuration needed'
                                >
                                    Additional configuration is needed before you can make bookings.
                                    <br />
                                    Please visit the booking setup page and configure at least 1 table.
                                </ActionBox>
                            }
                            {overlappingIds &&
                                <>
                                    <ErrorMessage>
                                        Cannot save the rule as it conflicts with the below rule{overlappingIds.length > 1 ? 's' : ''}.
                                        Please review and amend the rule setup before saving.
                                    </ErrorMessage>
                                    <br />
                                    <DataTable data={gentable(data.filter(x => overlappingIds.includes(x.id)))} />
                                </>
                            }
                        </CoreModal>
                    )}
                </FormWrapper>
            }
            {!experienceId && <InfoMessage>This is the time range during which bookings cannot commence.
                This time range will appear greyed out (striped) on the booking timeline screen, but you will still be able to make bookings during blocked out times.
                These times however will not be bookable online.</InfoMessage>}
            {experienceId && <InfoMessage>{`Set up restrictions for preventing ${customMessage} bookings being made when this ${eventOrExperienceLowerCase} takes place. These blocks will only apply to ${customMessage} bookings, meaning that this ${eventOrExperienceLowerCase} will still remain bookable during the ${eventOrExperienceLowerCase} validity date/times configured above. `}</InfoMessage>}
            <br />
            {!data && <Loader />}
            {data && data.length == 0 &&
                <InfoMessage>There are no blocked times currently set up.</InfoMessage>
            }
            {data && data.length > 0 &&
                <>
                    <DataTable data={gentable(data)} />
                </>
            }
            {data &&
                <>
                    <br />
                    <DashboardAddButton onClick={() => { loadTableView(); setItemToEditOpen(true); setItemToEdit({ ...DEFAULTTIMEBLOCK, appliedToAreas: !!experienceId, bookingSourceToBlock: experienceId && experienceType == ExperienceType.Event ? BookingSourceToBlock.OnlineAndInHouse : BookingSourceToBlock.OnlineOnly }) }}>Add a blocked time</DashboardAddButton>
                </>
            }
        </div>
    );
};

const WarningIcon = styled(Icon)`
    color: ${props => props.theme.negative};
`

export default BlockOutTimesSetup;