import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getCenter } from './tableLayoutUtils';
import { tableCenter } from './Table';
import { clone } from 'lodash';
import { TIMEFORMAT, businessNowTime, createMomentFromValue, formatDate } from '../../../../../../utils/date-helpers';
import { ExtendedTable } from '.';
import moment from 'moment';
import { BookingStatus } from '../../../../../../api/api-definitions';
import { BookingStatusColours, GetStatusColour, GetStatusTextColour } from '../bookingUtils';
import { defaultColors } from '../../../../../../theme';

interface ComponentProps {
    table: ExtendedTable;
    scale: number;
    browserScale: number;
}

const Label = styled.div<{ colour: string }>`
    color: ${props => props.colour};
    padding: 0.2rem;
    position: absolute;
    font-size: 0.7rem;
    z-index: 2;
    user-select: none;
    pointer-events: none;
    touch-action: none;
    user-modify: read-only;
    user-focus: none;
    touch-action: none;
    text-align: center;
`

const ProgressBar = styled.div`
    display: block;
    background-color: white;
    height: 5px;
    width: 2.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    margin: 0 auto;
`

const ProgressBarInner = styled.span<{ percentage: number }>`
    display: inline-block;
    background-color: ${props => props.theme.primary};
    height: 5px;
    position: absolute;
    border-top-right-radius: ${props => props.percentage < 100 ? '5px' : ''};
    border-bottom-right-radius: ${props => props.percentage < 100 ? '5px' : ''};
    left: 0;
    width: ${props => props.percentage}%;
`

const TableLabel = ({ table, scale, browserScale = 1 }: ComponentProps) => {
    const tableRef = useRef<HTMLDivElement>();
    const [labelOffsetWidth, setLabelOffsetWidth] = useState<number>();
    const [labelOffsetHeight, setLabelOffsetHeight] = useState<number>();
    const center = getCenter(table)
    const nameToUse = <>{table.tableName}{table.held ? <><br />(HELD)</> : ''}</>;

    useEffect(() => {
        if (tableRef.current) {
            const rect = tableRef.current.getBoundingClientRect();
            setLabelOffsetWidth((rect.width / browserScale) / 2);
            setLabelOffsetHeight((rect.height / browserScale) / 2);
        }
    }, [tableRef.current, table.tableName, table.booking?.status, table.booking?.isHeld, browserScale])

    scale = !scale ? 1 : scale;

    center.x = center.x * scale;
    center.y = center.y * scale;

    let progress: number = undefined;

    if (table.booking && (table.booking.status === BookingStatus.Seated || table.booking.status === BookingStatus.PartiallySeated)) {
        const startTime = createMomentFromValue(table.booking.startDateTime);
        const now = businessNowTime();
        const minutesAfterStart = now.diff(startTime, 'minutes');
        progress = (100 * minutesAfterStart) / table.booking.bookingDurationInMinutes;
    }

    return (
        <Label
            ref={tableRef}
            colour={table.textColour}
            style={
                {
                    top: `${center.y - (labelOffsetHeight || 0)}px`,
                    left: `${center.x - (labelOffsetWidth || 0)}px`,
                    opacity: labelOffsetWidth === undefined ? '0' : '1',
                }
            }>
            {nameToUse}
            {table.booking && <>
                {!table.free && table.booking.status === BookingStatus.Pending &&
                    <div style={{
                        minWidth: table.width + 'px',
                        backgroundColor: GetStatusColour(table.booking),
                        color: GetStatusTextColour(table.booking),
                        padding: '0 10px',
                        lineHeight: '1rem'
                    }}>S: {formatDate(table.booking.startDateTime, TIMEFORMAT)}</div>
                }
                {!table.free && (table.booking.status === BookingStatus.Seated || table.booking.status === BookingStatus.PartiallySeated) &&
                    <div style={{
                        minWidth: table.width + 'px',
                        backgroundColor: GetStatusColour(table.booking),
                        color: GetStatusTextColour(table.booking),
                        padding: '0 10px',
                        lineHeight: '1rem'
                    }}>E: {formatDate(table.booking.endDateTime, TIMEFORMAT)}</div>
                }
                {table.free &&
                    <div style={{
                        minWidth: table.width + 'px',
                        backgroundColor: BookingStatusColours.pending.background,
                        color: BookingStatusColours.pending.text,
                        padding: '0 10px',
                        lineHeight: '1rem',
                    }}>S: {formatDate(table.booking.startDateTime, TIMEFORMAT)}</div>
                }
            </>}
            {table.info &&
                    <div style={{
                        minWidth: table.width + 'px',
                        backgroundColor: defaultColors.secondary,
                        color: defaultColors.secondaryContrast,
                        padding: '0 10px',
                        lineHeight: '1rem',
                        position: 'absolute',
                        width: 'max-content',
                        left: '50%',
                        transform: 'translate(-50%, 0)'
                    }}>{table.info}</div>
                }
            {progress !== undefined &&
                <>  
                    <ProgressBar>
                        <ProgressBarInner percentage={progress > 100 ? 100 : progress} />
                    </ProgressBar>
                </>
            }
        </Label>
    );
};

export default TableLabel;