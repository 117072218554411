import moment, { Moment } from 'moment-timezone';
import { DropdownItem } from '../components/Forms/Dropdown';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_WITH_DAY_OF_WEEK_FORMAT = 'ddd, D MMM YYYY';

export const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';

export const DISPLAY_DATE_TIME_FORMAT = 'DD-MMM-YYYY h:mmA';

export const ARRIVAL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const DATABASE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const TIMEFORMAT = 'h:mmA';

export const DATEONLYFORMAT = 'YYYY-MM-DD'

// DO NOT ADD FORMATS WITH MMM OR MMMM as this causes regex error
export const SUPPORTED_DATE_FORMATS = [
  'DD/MM/YYYY',
  'D/M/YYYY',
  'DD.MM.YYYY',
  'DD.MM.YY',
  'D.M.YYYY',
  'D.M.YY',
  'DD. MM. YYYY',
  'DD. MM. YY',
  'D. M. YYYY',
  'D. M. YY',
  'DD-MM-YY',
  'DD-MM-YYYY',
  'DD/MM/YY',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSS',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSSS',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSS',
  'YYYY-MM-DDTHH:mm:ss.SSSSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSS',
  'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSS',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ssZ',
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DD',
];

export function isValidDateObject(dateObject) {
  if (
    Object.prototype.toString.call(dateObject) === '[object Date]'
    && !Number.isNaN(dateObject.getTime())
  ) {
    return true;
  }

  return false;
}

export function toDate(value: string | Date | moment.Moment): Date | undefined {
  if (!value) return null;
  const regex = RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$');
  if (typeof value === 'string' && regex.test(value)) {
    const newDate = new Date();
    newDate.setHours(parseInt(value.split(':')[0]));
    newDate.setMinutes(parseInt(value.split(':')[1]));
    value = newDate;
  }

  const momentObject = createMomentFromValue(value);

  if (momentObject.isValid()) {
    return momentObject.toDate();
  }

  return undefined;
}

export function isValidDate(value: Moment | Date | string) {
  if (!value) return null;
  return createMomentFromValue(value).isValid();
}

export function resetTime(date): moment.Moment {
  if (!date) return null;
  return createMomentFromValue(date).startOf('day');
}

export function getTimeRemainingBetweenDateAndNow(expiryDate: string): number {
  if (!expiryDate || Number.isNaN(Date.parse(expiryDate))) {
    return -1;
  }

  const parsedExpiryDate = new Date(expiryDate);

  // @ts-ignore
  let now = getUkTime();
  let milliSecondsDifference = parsedExpiryDate.getTime() - now.getTime();

  let secondsDifference = milliSecondsDifference / 1000;

  return secondsDifference;
}

export function formatDate(date: string | Date | moment.Moment, format?: string, isUtc: boolean = false) {
  if (!date) return null;
  const momentObject = isUtc ? createMomentFromValueUtc(date) : createMomentFromValue(date);
  return momentObject.format(format ? format : DEFAULT_DATE_FORMAT);
}

export function createMomentFromValueUtc(value: Moment | Date | string) {
  if (!value) return null;
  moment.locale('en-gb');
  return moment.utc(value, SUPPORTED_DATE_FORMATS, 'L', true).tz(moment.tz.guess());
}

export function getUtcNow() {
  return moment.utc(moment(), SUPPORTED_DATE_FORMATS, 'L', true)
}

export const resetDateOnTime = (date: string | Date | Moment, nextDay?: boolean): Moment => {
  if (!date) return null;
  return createMomentFromValue(`1970-01-${nextDay ? '02' : '01'}T${createMomentFromValue(date).format('HH:mm')}:00`)
}


export const wipeDateOnTime = (date: string | Date | Moment, nextDay?: boolean): Moment => {
  if (!date) return null;
  return createMomentFromValue(`0001-01-${nextDay ? '02' : '01'}T${createMomentFromValue(date).format('HH:mm')}:00`)
}

export function businessNowTime() {
  return moment().tz('Europe/London', true);
}

export function createMomentFromValue(value: Moment | Date | string, isUtc: boolean = false) {
  if (!value) return null;
  moment.locale('en-gb');
  if (isUtc) return moment.utc(value, SUPPORTED_DATE_FORMATS, 'L', true);
  return moment(value, SUPPORTED_DATE_FORMATS, 'L', true);
}

export function getMomentLocalTime(date: Moment) {
  if (!date) return null;
  return moment(date).tz(moment.tz.guess(), true);
}

export function getUkTime(date: Date | string = new Date()) {
  if (!date) return null;
  return new Date(moment(date).format("YYYY-MM-DD HH:mm:ss"));
}

export function getUKTimeString(date: Date | string = new Date()) {
  if (!date) return null;

  let offset = new Date(date).getTimezoneOffset();
  offset = offset < 0 ? Math.abs(offset) : offset * -1;
  return moment(date).add(offset, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
}

export function convertTime(time: string | number) {
  if (!time) return null;

  time = time.toString();
  if (time.length === 3) return `0${time.substr(0, 1)}:${time.substr(1, 2)}`;
  if (time.length === 4) return `${time.substr(0, 2)}:${time.substr(2, 2)}`;
}

export function RoundTo15(datetime: Moment | Date | string, intervalOverride: number = 15) {
  if (!datetime) return null;

  const convertedDate = createMomentFromValue(datetime);
  if ((convertedDate.minutes() / intervalOverride) % 1 == 0) return convertedDate;
  const minutes = intervalOverride;
  const ms = 1000 * 60 * minutes;
  const roundedDate = new Date(Math.round(convertedDate.toDate().getTime() / ms) * ms);
  return createMomentFromValue(roundedDate)
}

export function RoundDownTo15(datetime: Moment | Date | string, intervalOverride: number = 15) {
  if (!datetime) return null;

  const convertedDate = createMomentFromValue(datetime);
  if ((convertedDate.minutes() / intervalOverride) % 1 == 0) return convertedDate;
  const minutes = intervalOverride;
  const ms = 1000 * 60 * minutes;
  const roundedDate = new Date(Math.round(convertedDate.toDate().getTime() / ms) * ms);
  const date = createMomentFromValue(roundedDate);
  if (date.isAfter(convertedDate)) return date.subtract(intervalOverride, 'm');
  return date;
}

export function dateTimeToDecimalTime(datetime: Moment | Date | string): number | null {
  if (!datetime) return null;

  const date = createMomentFromValue(datetime);
  if (!date.isValid()) return null;
  const hours = date.hours();
  const minutes = date.minutes();
  const time = hours + (minutes / 100);
  return time;
}

export function secondsToTime(secs) {
  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    m: minutes,
    s: seconds
  };
  return obj;
}

export function minutesToTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let obj = {
    m: minutes,
    h: hours
  };
  return obj;
}

export function getHoursAndMinutesIntervalsFromMinutes(minutes: number, step: number = 15) {
  const items: DropdownItem[] = [];
  for (let i = step; i <= minutes; i = i + step) {
      const minutesAndHours = minutesToTime(i);
      const dropdownTextValue = getDurationText(minutesAndHours);
      const item: DropdownItem = {
          value: `${i}`,
          text: `${dropdownTextValue}`
      }

      items.push(item);
  }
  return items;
}

export function getDurationText(minutesAndHours: { m: number; h: number; }) {
  if (minutesAndHours.h > 0 && minutesAndHours.m > 0) {
    return `${minutesAndHours.h > 0 ? `${minutesAndHours.h}h ` : ''}${minutesAndHours.m > 0 ? `${minutesAndHours.m}m` : ''}`;
  } else if (minutesAndHours.h > 0) {
    return `${minutesAndHours.h}h`;
  } else {
    return `${minutesAndHours.m}m`;
  }
}

export function dateIsToday(date: string | Date | Moment) {
  if (!date) return false;
  return businessNowTime().format(DATEONLYFORMAT) === createMomentFromValue(date).format(DATEONLYFORMAT)
}

export function getMinDateForRuleSetup(date: string | Date | Moment, isToDate?: boolean) {
  if (isToDate && date) return date;
  return date && createMomentFromValue(date).isBefore(moment().startOf('day')) ? date : moment()
}
