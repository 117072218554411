import { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, businessNowTime, createMomentFromValue, dateIsToday, resetDateOnTime } from '../../../../../../utils/date-helpers';
import Icon from '../../../../../../components/Media/Icon';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import { BaseActiveBooking, EventAreaSelection } from '../../../../../../api/api-definitions';
import { shouldShowArea, shouldShowTable } from '../bookingUtils';
import { Tooltip } from '../../../../../../components/ui/tooltip';

export interface TimelineItem {
    id: number;
    name: React.ReactNode;
    tooltip: React.ReactNode;
    duration: number;
    onClick: () => void;
    onDragEnd?: (newDateTime: Date) => void;
    backgroundColour: string;
    textColour: string;
    canDrag: boolean;
    booking: BaseActiveBooking;
    rows: number;
}

export interface TimelineRow {
    id: number;
    sectionId?: number;
    name: string;
    secondaryName?: string;
    startOfSection: boolean;
    blocked?: boolean;
    items?: { [key: string]: TimelineItem };
}

export interface BlockedId {
    hardBlocked?: boolean;
}

export interface TimelineTimeRange {
    formatted: string;
    formattedMinutes: string;
    formattedHoursMinutes: string;
    raw: Date;
    next: Moment;
    blocked: boolean;
    blockedRows: {[id: number]: BlockedId};
    closed: boolean;
}

interface ComponentProps {
    rowKeys: TimelineRow[];
    primaryIcon?: React.ReactNode;
    secondaryIcon?: React.ReactNode;
    subHeaderInfo?: { [key: string]: number | string };
    showOnlySectionsAndTables?: { [key: string | number]: EventAreaSelection };
    hideSectionsAndTables?: { [key: string | number]: EventAreaSelection };
    times: TimelineTimeRange[];
    date: Moment;
    interval?: number;
    selectedExperience?: number;
    updating?: boolean;
    onTimeClick: (time: Date, rowId: number, isNow: boolean) => void;
    onTimeDrop: (time: Date, rowId: number, previousRowId: number, itemId: number) => void;
}

const TIMELINE_CELL_WIDTH = '3.5rem';
const TIMELINE_CELL_WIDTH_SMALL = '2rem';
const TIMELINE_CELL_WIDTH_COMBINED = '4rem';

function getNowTime(): string {
    const timeNow = businessNowTime().format('HH:mm').split(':');
    const minutes = +timeNow[1];
    if (minutes < 15) return `${timeNow[0]}:00`;
    if (minutes < 30) return `${timeNow[0]}:15`;
    if (minutes < 45) return `${timeNow[0]}:30`;
    return `${timeNow[0]}:45`;
}

function getNowDate(): string {
    return businessNowTime().format(DATEONLYFORMAT);
}

const Timeline = ({
    rowKeys,
    primaryIcon,
    secondaryIcon,
    times,
    subHeaderInfo,
    date,
    updating,
    showOnlySectionsAndTables,
    hideSectionsAndTables,
    selectedExperience,
    onTimeClick,
    onTimeDrop,
    interval = 15
}: ComponentProps) => {
    const containerRef = useRef<HTMLDivElement>();
    const labelContainer = useRef<HTMLDivElement>();
    const [dragging, setDragging] = useState<number>();
    const previousRowId = useRef<number>();
    const [collapsedSections, setCollapsedSections] = useState<{ [key: number]: boolean }>({});
    const hasSecondary = !!secondaryIcon;
    const nowTime = getNowTime();
    const selectedDateIsToday = dateIsToday(date);
    const [hoverCell, setHoverCell] = useState<string>();
    const currentDate = useRef<string>();

    useEffect(() => {
        addScrollListener();

        () => removeScrollListener()
    }, [containerRef.current])

    useEffect(() => {
        if (currentDate.current !== date.format('YYYY-MM-DD')) {
            currentDate.current = date.format('YYYY-MM-DD');
            scrollIntoView();
        }
    }, [date])

    const addScrollListener = () => {
        setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.addEventListener('scroll', scrollTableInfo)
            }
        }, 100);
    }

    const removeScrollListener = () => {
        if (containerRef.current) {
            containerRef.current.removeEventListener('scroll', scrollTableInfo)
        }
    }

    const scrollTableInfo = () => {
        labelContainer.current.style.top = `-${containerRef.current.scrollTop}px`
    }

    const scrollIntoView = () => {
        const timeNow = resetDateOnTime(businessNowTime());
        const selectedIsToday = dateIsToday(date);

        let matchingTimeIndex = times.findIndex(x =>
            !x.closed &&
            !x.blocked &&
            timeNow.isSameOrAfter(resetDateOnTime(x.raw)) &&
            timeNow.isBefore(resetDateOnTime(x.next))
        );

        if (matchingTimeIndex === -1 || !selectedIsToday) {
            matchingTimeIndex = times.findIndex(x => !x.closed && !x.blocked);
        }

        if (matchingTimeIndex - 1) {
            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.scrollTo({
                        top: 0,
                        left: (matchingTimeIndex * 63),
                        behavior: "smooth",
                    })
                }
            }, 300);
        }
    }

    const dragEnd = () => {
        if (hoverCell && dragging) {
            const hoverSplit = hoverCell.split('-');
            const time = hoverSplit[0];
            const rowId = +hoverSplit[1];
            const newTime = date.format(DATABASE_TIME_FORMAT).split('T')[0] + `T${time}:00`;
            onTimeDrop(createMomentFromValue(newTime).toDate(), rowId, previousRowId.current, dragging)
        }
        setDragging(undefined);
        setHoverCell(undefined);
    }

    const startDrag = (id: number, rowId: number) => {
        setDragging(id);
        previousRowId.current = rowId;
    }

    const shouldShowSection = (id: number, tableId: number) => {
        if (tableId == null) {
            if (hideSectionsAndTables && hideSectionsAndTables[id]) return false;
            if (showOnlySectionsAndTables) {
                return !!showOnlySectionsAndTables[id];
            } else return true;
        }
        const shouldShow = shouldShowTable(id, tableId, showOnlySectionsAndTables, hideSectionsAndTables);
        return shouldShow;
    }

    return (
        <>
            <TimelineContainer updating={updating} dragging={!!dragging}>
                <TimelineRowStickyHeader indicator>{primaryIcon}</TimelineRowStickyHeader>
                <TimelineRowStickyHeader secondary small>{secondaryIcon}</TimelineRowStickyHeader>
                <TimelineTableContainer hasSecondary={hasSecondary} ref={labelContainer}>
                    <TimelineCellFlex>
                        <TimelineCell indicator />
                        {secondaryIcon && <TimelineCell small />}
                    </TimelineCellFlex>
                    {rowKeys.map(row => shouldShowSection(row.sectionId, row.startOfSection ? null : row.id) ? (
                        <React.Fragment key={`heading-${row.name}`}>
                            {row.startOfSection &&
                                <>
                                    <TimelineCellFlex style={{ cursor: 'pointer', width: '20rem' }} hasSecondary={hasSecondary} areaLabel onClick={() => setCollapsedSections({ ...collapsedSections, [row.id]: !!!collapsedSections[row.id] })}>
                                        <TimelineCell areaLabel noBorder>{row.name} {collapsedSections[row.id] ? <Icon name="chevron-right" /> : <Icon name="chevron-down" />}</TimelineCell>
                                    </TimelineCellFlex>
                                    <TimelineCellFlex hasSecondary={hasSecondary}></TimelineCellFlex>
                                </>
                            }
                            {!row.startOfSection && !collapsedSections[row.sectionId] &&
                                <TimelineCellFlex hasSecondary={hasSecondary} style={{ fontSize: '0.9rem' }}>
                                    <TimelineCell indicator>
                                        {row.name}
                                    </TimelineCell>
                                    {hasSecondary &&
                                        <TimelineCell small style={{ fontSize: '0.8rem' }}>
                                            {row.secondaryName}
                                        </TimelineCell>
                                    }
                                </TimelineCellFlex>
                            }
                        </React.Fragment>
                    ) : <React.Fragment key={`heading-${row.name}`}></React.Fragment>)}
                </TimelineTableContainer>
                <Wrap ref={containerRef}>
                    <Scenes>
                        {times.map(time => (
                            <Track key={`track-${time.raw}`}>
                                <Heading>
                                    {time.formatted.indexOf(':00') > -1 ?
                                        <HeadingTime>{time.formatted}</HeadingTime>
                                        :
                                        <HeadingTimeSmall>{time.formattedMinutes}</HeadingTimeSmall>
                                    }
                                    {subHeaderInfo && subHeaderInfo[time.formattedHoursMinutes] &&
                                        <HeadingIndicator>{subHeaderInfo[time.formattedHoursMinutes]}</HeadingIndicator>
                                    }
                                </Heading>
                                {rowKeys.map(row => {
                                    if (shouldShowSection(row.sectionId, row.startOfSection ? null : row.id) && (row.startOfSection || !collapsedSections[row.sectionId])) {
                                        const blockedRowId = time.blockedRows[row.id];
                                        const blocked = time.blocked || row.blocked || !!blockedRowId;
                                        const hardBlocked = blockedRowId?.hardBlocked;
                                        return (
                                            <React.Fragment key={`body-${row.name}-track-${time.raw}`}>
                                                <Entry
                                                    sidebar={row.startOfSection}
                                                    onClick={() => !time.closed && !hardBlocked && !row.items?.hasOwnProperty(time.formattedHoursMinutes) && !row.startOfSection ? onTimeClick(time.raw, row.id, nowTime === time.formattedHoursMinutes) : {}}
                                                    closed={time.closed}
                                                    blocked={!hardBlocked && blocked}
                                                    hardBlocked={hardBlocked}
                                                    now={selectedDateIsToday && nowTime === time.formattedHoursMinutes}
                                                    title={(blocked && 'Blocked') || (time.closed && 'Closed') || ''}
                                                    hover={row.id > -1 && hoverCell === `${time.formattedHoursMinutes}-${row.id}`}
                                                >
                                                    {!row.startOfSection &&
                                                        <>
                                                            {selectedDateIsToday && nowTime === time.formattedHoursMinutes && <NowBar leftSpace={(new Date().getMinutes() - time.raw.getMinutes()) * 4.2} />}
                                                            {dragging !== row.items[time.formattedHoursMinutes]?.id &&
                                                                <div
                                                                    onDragEnter={() => {
                                                                        if (row.id > -1 && !row.items?.hasOwnProperty(time.formattedHoursMinutes) && !time.closed && !hardBlocked) {
                                                                            setHoverCell(`${time.formattedHoursMinutes}-${row.id}`)
                                                                        } else {
                                                                            setHoverCell(undefined)
                                                                        }
                                                                    }}
                                                                    className='hoverCell'
                                                                    style={{
                                                                        zIndex: 50,
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        pointerEvents: 'none',
                                                                    }}
                                                                />
                                                            }
                                                            <span>+</span>
                                                            {row.items?.hasOwnProperty(time.formattedHoursMinutes) &&
                                                                <TimelineBookingWrapper
                                                                    slots={row.items[time.formattedHoursMinutes].duration / interval}
                                                                    onDragEnter={() => setHoverCell(undefined)}
                                                                    bookingHeight={row.items[time.formattedHoursMinutes].rows}
                                                                >
                                                                    <Tooltip
                                                                        content={row.items[time.formattedHoursMinutes].tooltip}
                                                                        portalRef={containerRef}
                                                                    >
                                                                        <TimelineBooking
                                                                            disabled={(selectedExperience && row.items[time.formattedHoursMinutes].booking.experienceId !== selectedExperience)}
                                                                            bookingHeight={row.items[time.formattedHoursMinutes].rows}
                                                                            slots={row.items[time.formattedHoursMinutes].duration / interval}
                                                                            style={{
                                                                                backgroundColor: row.items[time.formattedHoursMinutes].backgroundColour,
                                                                                color: row.items[time.formattedHoursMinutes].textColour
                                                                            }}
                                                                            draggable={(!selectedExperience || row.items[time.formattedHoursMinutes].booking.experienceId === selectedExperience) && row.items[time.formattedHoursMinutes].canDrag}
                                                                            onDragEnd={() => dragEnd()}
                                                                            onDragStart={() => (!selectedExperience || row.items[time.formattedHoursMinutes].booking.experienceId === selectedExperience) && row.items[time.formattedHoursMinutes].canDrag ? startDrag(row.items[time.formattedHoursMinutes].id, row.id) : null}
                                                                            onClick={() => (!selectedExperience || row.items[time.formattedHoursMinutes].booking.experienceId === selectedExperience) && row.items[time.formattedHoursMinutes].canDrag ? row.items[time.formattedHoursMinutes].onClick() : {}}
                                                                        >
                                                                            {row.items[time.formattedHoursMinutes].canDrag && <DragIcon name='grip-dots-vertical' />}
                                                                            {row.items[time.formattedHoursMinutes].name}
                                                                        </TimelineBooking>
                                                                    </Tooltip>
                                                                </TimelineBookingWrapper>
                                                            }
                                                        </>
                                                    }
                                                </Entry>
                                            </React.Fragment>
                                        );
                                    }
                                    return <React.Fragment key={`body-${row.name}-track-${time.raw}`}></React.Fragment>;
                                })}
                            </Track>
                        ))}
                    </Scenes>
                </Wrap>
            </TimelineContainer>
        </>
    );
};

const DragIcon = styled(Icon)`
    display: inline-block !important;
    position: absolute;
    left: 0px;
    top: 0.25rem;
    opacity: 0.8;
    cursor: grab !important;
    color: inherit !important;
`

const TimelineContainer = styled.div<{ updating?: boolean; dragging?: boolean; }>`
    display: flex;
    position: relative;
    overflow: hidden;
    ${props => props.updating && `
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
    `}

    ${props => props.dragging && `
        .hoverCell {
            pointer-events: all !important;
        }
    `}
`

const TimelineCellFlex = styled.div<{ hasSecondary?: boolean; areaLabel?: boolean; }>`
    display: flex;
    min-width: ${props => props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};
    max-width: ${props => props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};
    width: ${props => props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};

    ${props => props.areaLabel ? `
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
    ` : `
        min-width: ${props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};
        max-width: ${props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};
        width: ${props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};
    `}
    height: 2rem;
    line-height: 2rem;
    background-color: ${props => props.theme.timeline.defaultBackground};
`

const TimelineCell = styled.div<{ small?: boolean; areaLabel?: boolean; noBorder?: boolean; indicator?: boolean; }>`
    ${props => props.areaLabel ? `
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        padding-left: 1rem;
    ` : `
        min-width: ${props.indicator ? TIMELINE_CELL_WIDTH_SMALL : props.small ? TIMELINE_CELL_WIDTH_SMALL : TIMELINE_CELL_WIDTH};
        max-width: ${props.indicator ? TIMELINE_CELL_WIDTH_SMALL : props.small ? TIMELINE_CELL_WIDTH_SMALL : TIMELINE_CELL_WIDTH};
        width: ${props.indicator ? TIMELINE_CELL_WIDTH_SMALL : props.small ? TIMELINE_CELL_WIDTH_SMALL : TIMELINE_CELL_WIDTH};
        text-align: center;
    `}
    background: ${props => props.theme.timeline.defaultBackground};
    height: 2rem;
    line-height: 2rem;
    display: block;
    ${props => props.noBorder ? '' : `
        border-right: 1px solid ${props.theme.borderColor};
    `}
    ${props => props.small && `
        color: #686868;
    `}
    border-bottom: 1px solid ${props => props.theme.borderColor};
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const NowBar = styled.div<{ leftSpace: number; }>`
    border-left: 1px solid blue;
    pointer-events: none;
    touch-action: none;
    opacity: 0.9;
    position: absolute;
    left: ${props => props.leftSpace}px;
    height: 100%;
    width: 2px;
    z-index: 2;
`;

const TimelineRowStickyHeader = styled(TimelineCell) <{ secondary?: boolean; }>`
    position: absolute;
    top: 0;
    left: ${props => props.secondary ? '2rem' : '0'};
    z-index: 2;
`

const TimelineScrollMock = styled.div`
    height: 17px;
    width: 100%;
    background-color: #FFF;
`

const TimelineScrollMockOverlay = styled(TimelineScrollMock)`
    position: absolute;
    bottom: 0;
    z-index: 2;
`

const TimelineTableContainer = styled.div<{ hasSecondary?: boolean; }>`
    min-width: ${props => props.hasSecondary ? TIMELINE_CELL_WIDTH_COMBINED : TIMELINE_CELL_WIDTH};
    line-height: 2rem;
    flex: 0;
    height: calc(80vh - 17px);
    position: relative;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        height: calc(100vh - 290px);
    }
`

const HeadingTime = styled.div`
    position: absolute;
    left: -1.5rem;
    background-color: ${props => props.theme.timeline.defaultBackground};
    font-size: 0.9rem;
    top: -7px;
    height: 1.6rem;
`

const HeadingTimeSmall = styled(HeadingTime)`
    left: -0.4rem;
    font-size: 0.6rem;
`

const HeadingIndicator = styled(HeadingTimeSmall)`
    left: -0.55rem;
    top: 1.2rem;
    font-size: 0.6rem;
    background-color: ${props => props.theme.secondary};
    color: ${props => props.theme.secondaryContrast};
    width: 1rem;
    text-align: center;
    line-height: 0.7rem;
    height: 0.7rem;
`

const Heading = styled.div`
    height: 2rem;
    line-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-right: 1px solid ${props => props.theme.borderColor};
    border-bottom: 1px solid ${props => props.theme.borderColor};
    z-index: 3;
    background: ${props => props.theme.timeline.defaultBackground};
    font-weight: 700;
`


const Wrap = styled.div`
    height: 80vh;
    position: relative;
    overflow: auto;
    width: 100%;
    flex: 1;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        height: calc(100vh - 310px);
    }
`

const Scenes = styled.div`
    display: flex;
    flex-wrap: nowrap;
`

const Track = styled.div`
    flex: 1 0 ${TIMELINE_CELL_WIDTH};
    scroll-snap-align: start;
`

const Entry = styled.div<{
    hover?: boolean;
    now?: boolean;
    header?: boolean;
    blocked?: boolean;
    hardBlocked?: boolean;
    closed?: boolean;
    sidebar?: boolean;
}>`
    ${props => props.sidebar ? `
        background-color: ${props.theme.timeline.defaultBackground};
        border-bottom: 1px solid ${props.theme.borderColor};
    ` : `
        border-right: 1px solid ${props.theme.borderColor};
        border-bottom: 1px solid ${props.theme.borderColor};
        background-color: ${props.theme.background};
    `}
    border-top: 0;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    position: relative;

    span {
        color: ${props => props.theme.positiveContrast};
        font-weight: bold;
        display: none;
    }

    ${props => !props.sidebar && props.blocked && !props.closed && `
    background-color: rgba(0, 0, 0, 0.2);
    background: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05) 5px,
        rgba(0, 0, 0, 0) 5px,
        rgba(0, 0, 0, 0) 10px
      );
    `}
    ${props => !props.sidebar && props.hardBlocked && !props.closed && `
        background-color: #bdbdbd;
        `}
    ${props => !props.sidebar && !props.closed && props.hover && `background-color: ${props.theme.positive}; cursor: pointer;`}
    ${props => !props.sidebar && !props.closed && !props.hardBlocked && !props.hover && props.now && `background-color: #ddeaf7;`}

    ${props => !props.sidebar && props.closed && `
        background-color: ${props.theme.timeline.closedBackground};
    `}
    ${props => !props.sidebar && !props.closed && !props.hardBlocked && !props.hover && !props.header && `
        cursor: pointer;
        &:hover {
            background-color: ${props.theme.positive};

            span {
                display: inline;
            }
        }
    `}
`

const TimelineBookingWrapper = styled.div<{ slots?: number, bookingHeight?: number }>`
    height: ${props => 2 * (props.bookingHeight || 1)}rem;
    width: ${props => 100 * (props.slots || 1) + props.slots}%;
    background-color: rgba(0,0,0,0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
`

const TimelineBooking = styled.div<{ slots?: number; bookingHeight?: number, unclickable?: boolean, disabled?: boolean }>`
    height: ${props => `${(2 * props.bookingHeight) - 0.6}rem`};
    line-height: ${props => `${(2 * props.bookingHeight) - 0.6}rem`};
    padding: 0 1rem;
    width: 100%;
    top: 0.25rem;
    border-radius: 0.2rem;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    transition: all 0.5s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${props => props.disabled && `opacity: 0.5;`}

    ${props => (props.unclickable || props.disabled) && `
        user-select: none;
        pointer-events: none;
    `}
    
    ${props => !props.disabled && `
        &:hover {
            opacity: 0.9;
        }
    `}
`

export default Timeline;