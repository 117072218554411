export default class ThemeService {
    private static _switcher: () => void;

    public static setSwitcher(switcher: () => void): void {
        this._switcher = switcher;
    }

    public static switchTheme(): void {
        if (this._switcher) this._switcher();
    }
}