import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BaseDish, ObjectStates, SpiceLevel } from '../../../../../../api/api-definitions';
import CoreButton from '../../../../../../components/Forms/Button';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import ImageUpload from '../../../../../../components/Forms/ImageUpload';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import Loader from '../../../../../../components/Layout/Loader';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import Icon from '../../../../../../components/Media/Icon';
import NoImage from '../../../../../../components/Media/NoImage';
import { H3 } from '../../../../../../components/Typography/Headings';
import { Constants, DishDescriptionSize, DishNameSize } from '../../../../../../constants';
import { BoxShadowStyleWithHover } from '../../../../../../theme';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import DishTagList from '../DishTagList';
import { StyledCurrencyInput, StyledNumberInput, StyledTextarea, StyledTextInput } from '../../../../../../theme/input.styles';
import ImageSelector from '../../../../../../components/Forms/ImageSelector';
import DishOption from './DishOption';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { GetCurrencySymbol } from '../../../../../../utils/currency-helper';

interface ComponentProps {
    onClose: () => void;
    dishData: BaseDish;
    onSave: (dish: BaseDish, keepOpen: boolean) => void;
    isOpen: boolean;
    loading: boolean;
    imageOnly?: boolean;
}

export const HeatLevelIcon = styled<any>(Icon)`
    color: ${(props) => props.active ? props.theme.negative : 'grey'};
    cursor: pointer;
    font-size: 1.6rem;
`;

const HeatLevelLabel = styled.div`
    margin-bottom: 1rem;
`;

const MenuItemForm = ({ onClose, dishData, onSave, isOpen, loading, imageOnly }: ComponentProps) => {
    const [item, setItem] = useState<BaseDish>(dishData ? cloneDeep(dishData) : {} as BaseDish);
    const [loaded, business] = useBusiness();

    const updateFormData = (newData: BaseDish) => {
        setItem({ ...dishData, ...item, ...newData });
    }

    const updateImage = (imageUrl) => {
        const dish = cloneDeep(item);
        dish.imageUrl = imageUrl;
        setItem({ ...dishData, ...dish });
    }

    const toggleAllergen = (value: boolean) => {
        const newItem = cloneDeep(item);
        newItem.allergenDetailsProvided = value;
        if (!newItem.allergenDetailsProvided && newItem.dishTags) {
            newItem.dishTags.forEach(tag => {
                if (tag.isAllergen) {
                    tag.state = ObjectStates.Deleted;
                }
            });
        }
        setItem({ ...dishData, ...newItem })
    }

    if (!isOpen) return null;

    const newItem = dishData?.id === null || dishData?.id === undefined;

    const getSpiceLevel = (): 0 | 1 | 2 | 3 | 4 | 5 => {
        if (item.spiceLevel == SpiceLevel.NotSpicy) return 0;
        if (item.spiceLevel == SpiceLevel.Mild) return 1;
        if (item.spiceLevel == SpiceLevel.Medium) return 2;
        if (item.spiceLevel == SpiceLevel.Hot) return 3;
        if (item.spiceLevel == SpiceLevel.ExtraHot) return 4;
        if (item.spiceLevel == SpiceLevel.ExtremelyHot) return 5;
        return 0;
    }

    const spiceLevelInt = getSpiceLevel();

    return (
        <FormWrapper onUpdate={(dishData: BaseDish) => loading ? null : updateFormData(dishData)}>
            {({ id, valid }) => (
                <CoreModal
                    title={newItem ? 'New item' : 'Edit item'}
                    onClose={onClose}
                    isOpen={isOpen}
                    actionBar={<>
                        <CoreButton disabled={!valid} onClick={() => onSave(item, false)}>
                            {newItem ? 'Save and close' : 'Save changes'} <Icon name='save' />
                        </CoreButton>
                        {newItem &&
                            <>
                                &nbsp;
                                <CoreButton disabled={!valid} onClick={() => onSave(item, true)}>
                                    Save and add next <Icon name='save' />
                                </CoreButton>
                            </>
                        }
                    </>}
                >
                    {loading && <Loader />}
                    {!loading &&
                        <>
                            {!imageOnly &&
                                <>
                                    <Row>
                                        <Column size={6}>
                                            <StyledTextInput model='name' max={DishNameSize} label='Dish name:' value={item.name} required />
                                        </Column>
                                        <Column size={6}>
                                            <StyledCurrencyInput model='price' label='Price:' value={item.price} currencySymbol={GetCurrencySymbol(business.currencyCode)} />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={12}>
                                            <StyledTextarea model='description' rows={3} maxLength={DishDescriptionSize} label='Description:' value={item.description} />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={4}>
                                            <StyledNumberInput model='calorieCount' label='Calories:' min={0} value={item.calorieCount} />
                                        </Column>
                                        <Column size={4}>
                                            <HeatLevelLabel>Spice level:</HeatLevelLabel>
                                            <HeatLevelIcon name='pepper-hot' active={spiceLevelInt >= 1} onClick={() => setItem({ ...item, spiceLevel: (item.spiceLevel == SpiceLevel.Mild ? SpiceLevel.NotSpicy : SpiceLevel.Mild) })} />
                                            <HeatLevelIcon name='pepper-hot' active={spiceLevelInt >= 2} onClick={() => setItem({ ...item, spiceLevel: (item.spiceLevel == SpiceLevel.Medium ? SpiceLevel.NotSpicy : SpiceLevel.Medium) })} />
                                            <HeatLevelIcon name='pepper-hot' active={spiceLevelInt >= 3} onClick={() => setItem({ ...item, spiceLevel: (item.spiceLevel == SpiceLevel.Hot ? SpiceLevel.NotSpicy : SpiceLevel.Hot) })} />
                                            <HeatLevelIcon name='pepper-hot' active={spiceLevelInt >= 4} onClick={() => setItem({ ...item, spiceLevel: (item.spiceLevel == SpiceLevel.ExtraHot ? SpiceLevel.NotSpicy : SpiceLevel.ExtraHot) })} />
                                            <HeatLevelIcon name='pepper-hot' active={spiceLevelInt >= 5} onClick={() => setItem({ ...item, spiceLevel: (item.spiceLevel == SpiceLevel.ExtremelyHot ? SpiceLevel.NotSpicy : SpiceLevel.ExtremelyHot) })} />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={4}>
                                            <Checkbox asToggle model='canBeDairyFree' label='Can be dairy free' checked={item.canBeDairyFree} />
                                        </Column>
                                        <Column size={4}>
                                            <Checkbox asToggle model='canBeGlutenFree' label='Can be gluten free' checked={item.canBeGlutenFree} />
                                        </Column>
                                        <Column size={4}>
                                            <Checkbox asToggle model='isDrink' label='Is a drink' checked={item.isDrink} />
                                        </Column>
                                    </Row>
                                </>
                            }
                            <H3 marginBottom={0} textCenter={false}>Dish image</H3>
                            <Row>
                                <Column size={4}>
                                    <ImageSelector value={item.imageUrl} onChange={(imageUrl) => updateImage(imageUrl)} />
                                </Column>
                                {!imageOnly &&
                                    <Column size={12}>
                                        <DishTagList
                                            data={item.dishTags}
                                            dishAllergenDetailsProvided={item.allergenDetailsProvided}
                                            updateAllergenToggle={toggleAllergen}
                                            dishId={item.id}
                                            onUpdate={(newTags) => { item.dishTags = newTags; setItem({ ...item }) }}
                                        />
                                    </Column>
                                }
                            </Row>
                            {!imageOnly &&
                                <>
                                    <H3 marginBottom={0} textCenter={false}>Dish variants</H3>
                                    <Row>
                                        <Column size={1} />
                                        <Column size={7} noMarginBottom>
                                            Name
                                        </Column>
                                        <Column size={3} noMarginBottom>
                                            Additional price
                                        </Column>
                                        <Column size={1} noMarginBottom>
                                        </Column>
                                    </Row>
                                    <DishOption onRemove={() => { }} />
                                    <DishOption onRemove={() => { }} />
                                    <DashboardAddButton onClick={() => { }}>Add option</DashboardAddButton>
                                    <br />
                                    <H3 marginBottom={0} textCenter={false}>Dish extras</H3>
                                    <Row>
                                        <Column size={1} />
                                        <Column size={7} noMarginBottom>
                                            Name
                                        </Column>
                                        <Column size={3} noMarginBottom>
                                            Price
                                        </Column>
                                        <Column size={1} noMarginBottom>
                                        </Column>
                                    </Row>
                                    <DishOption onRemove={() => { }} />
                                    <DishOption onRemove={() => { }} />
                                    <DashboardAddButton onClick={() => { }}>Add extra</DashboardAddButton>
                                </>
                            }
                        </>
                    }
                </CoreModal>
            )}
        </FormWrapper>
    );
};

export default MenuItemForm;