import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../../../../components/Media/Icon';
import { BaseActiveBooking, BaseBookingTableSetup, BaseShape, BaseTableSetupArea, ExperienceType } from '../../../../../../api/api-definitions';
import { ApiService } from '../../../../../../api/api-connectors';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import MainStage from './MainStage';
import Loader from '../../../../../../components/Layout/Loader';
import ShapeForm from './ShapeForm';
import TableForm from './TableForm';
import { TABLEWIDTH } from './tableLayoutUtils';
import AreaForm from './AreaForm';
import { StyledDropdown } from '../../../../../../theme/input.styles';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import Combinability from './Combinability';
import DocsButton from '../../../../../../components/Dashboard/DocsButton';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import CoreButton from '../../../../../../components/Forms/Button';
import { hasDuplicateKeyValues } from '../../../../../../utils/data-helpers';
import ActionBox, { InfoMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { NotificationService } from '../../../../../../services/NotificationService';
import ExperienceSelection from './ExperienceSelection';
import { BoxShadowStyle, BoxShadowStyleWithHover } from '../../../../../../theme';

export const SidePanel = styled(BoxShadowStyle)`
    flex: 1;
    background-color: ${props => props.theme.background};
    right: 0;
    top: 0;
    height: 650px;
    overflow-y: scroll;
`;

export const Canvas = styled.div`
    height: calc(100vh - 4rem);
`

const Wrapper = styled.div`
    display: flex;
    margin-top: 1rem;
`;

export const TableSetupWrapper = styled.div`
    padding: 1rem;
`

export const NumberToggle = styled(Icon)`
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    line-height: 2rem;

    &:hover {
        color: ${props => props.theme.primary};
    }
`

export const TableOptionLabel = styled.div`
    flex: 0 0 auto;
    width: 5rem;
    line-height: 2rem;
`

export const TableOptionValue = styled.div`
    display: flex;

    input {
        padding: 5px !important;
        width: 4rem !important;
    }

    div {
        display: inline !important;
    }
`

export const TableSetupAddButtonContainer = styled.div`
    text-align: center;
    padding: 2rem;
`

export const TableSetupBackButton = styled.div`
    border-bottom: 1px solid ${props => props.theme.borderColor};
    padding: 0.2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
`

export const AreaTabs = styled.div`
    display: flex;
    border-bottom: 2px solid ${props => props.theme.primary};
`

export const AreaTabItem = styled.div<{ active: boolean }>`
    padding: 0.5rem;
    border-right: 2px solid ${props => props.theme.primary};
    cursor: pointer;

    ${props => props.active && `
        background-color: ${props.theme.primary};
        color: ${props.theme.primaryContrast};
    `}

    &:hover {
        text-decoration: underline;
    }
`

export const TableDragItem = styled(BoxShadowStyleWithHover)`
    padding: 0.5rem;
    text-align: center;

    img {
        height: 3rem;
        margin: 0 auto;
    }
`

export interface ExtendedTable extends BaseBookingTableSetup {
    width: number;
    height: number;
    isDragging?: boolean;
    booking?: BaseActiveBooking;
    fill?: string;
    seatFill?: string;
    textColour?: string;
    disabled?: boolean;
    held?: boolean;
    free?: boolean;
    valid?: boolean;
    info?: string;
    chairColours?: { [key: number]: string };
}

export interface ExtendedShape extends BaseShape {

}

export interface ExtendedArea extends BaseTableSetupArea {

}

const BlankDiv = styled.div``;

const DragDropItems = styled(Row)`
    padding: 0.5rem;
`;

export const tableIsValid = (item: BaseBookingTableSetup, layout: BaseBookingTableSetup[]) => {
    const matchingTable = layout.find(x => x.id !== item.id && x.tableName === item.tableName);
    return {
        occupancyValid: +item.minimumOccupancy > 0 && +item.minimumOccupancy <= +item.seats,
        tableNameValid: !isNullOrWhitespace(item.tableName) && !matchingTable
    }
}

interface ComponentProps {
    experienceId?: number;
    experienceType?: ExperienceType;
}

const BookingTableLayout = ({ experienceId, experienceType }: ComponentProps) => {
    const [layout, setLayout] = useState<ExtendedTable[]>();
    const [shapes, setShapes] = useState<ExtendedShape[]>([]);
    const [areas, setAreas] = useState<ExtendedArea[]>();
    const dragItem = useRef<ExtendedTable>();
    const dragItemShape = useRef<ExtendedShape>();
    const [businessLoaded, businessData] = useBusiness();
    const [selectedAreaId, selectArea] = useState<number>(null);
    const [selectedTableId, selectTable] = useState(null);
    const [selectedTableIds, selectTables] = useState(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedShapeId, selectShape] = useState(null);
    const [tab, setTab] = useState<number>(!!experienceId ? 3 : 0);
    const containerRef = useRef(null);
    const selectedTableRef = useRef<number>();
    const selectedShapeRef = useRef<number>();
    const mounted = useRef(false);
    const eventOrExperience = !!experienceType && experienceType === ExperienceType.Event ? 'Event' : 'Experience';
    const eventOrExperienceToLower = eventOrExperience.toLowerCase();
    const NOAREASMESSAGE = `There are no custom areas created for this ${eventOrExperienceToLower}. Only areas created for the ${eventOrExperienceToLower} are editable here.`

    const moveItemWithKeyboard = (e: KeyboardEvent) => {
        const selectedTable: ExtendedTable = layout?.find(x => x.id === selectedTableRef.current);
        const selectedShape: ExtendedShape = shapes?.find(x => x.id === selectedShapeRef.current);
        if (selectedTable || selectedShape) {
            const shapeOrTable = (selectedTable || selectedShape);
            switch (e.key) {
                case "ArrowLeft":
                    e.preventDefault();
                    shapeOrTable.x -= 1;
                    moveEnd(shapeOrTable.x, shapeOrTable.y)
                    break;
                case "ArrowRight":
                    e.preventDefault();
                    shapeOrTable.x += 1;
                    moveEnd(shapeOrTable.x, shapeOrTable.y)
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    shapeOrTable.y -= 1;
                    moveEnd(shapeOrTable.x, shapeOrTable.y)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    shapeOrTable.y += 1;
                    moveEnd(shapeOrTable.x, shapeOrTable.y)
                    break;
            }
        }
    }

    const moveEnd = (x: number, y: number) => {
        if (selectedShapeRef.current !== undefined) {
            const newShapes = cloneDeep(shapes);
            const newShape = newShapes.find(x => x.id === selectedShapeRef.current);
            newShape.x = x;
            newShape.y = y;
            setShapes(newShapes);
        }
        if (selectedTableRef.current !== undefined) {
            const newTables = cloneDeep(layout);
            const newTable = newTables.find(x => x.id === selectedTableRef.current);
            newTable.x = x;
            newTable.y = y;
            setLayout(newTables);
        }
    }

    useEffect(() => {
        if (businessData && !mounted.current) {
            mounted.current = true;
            ApiService.bookingtablesetup.List__GET(businessData.id).then(data => {
                const newData: ExtendedTable[] = data.map((item: ExtendedTable) => {
                    item.width = TABLEWIDTH;
                    const validity = tableIsValid(item, data);
                    item.valid = validity.occupancyValid && validity.tableNameValid;
                    return item;
                })
                setLayout(newData)
            })
            ApiService.shapes.List__GET(businessData.id).then((data) => {
                setShapes(data)
            })
            ApiService.tablesetupareas.List__GET(businessData.id).then(data => {
                setAreas(data)
                selectArea(data.length > 0 ? data[0].id : undefined)
            })
        }
    }, [businessLoaded])

    useEffect(() => {
        if (!layout || areas?.length === 0) return;
        layout.forEach(element => {
            if (!element.areaId) {
                element.areaId = areas[0].id;
                save(element)
            }
        });
        shapes.forEach(element => {
            if (!element.areaId) {
                element.areaId = areas[0].id;
                saveShape(element)
            }
        });
        setLayout(layout)
    }, [layout, areas])

    const updateSelectedTable = (id?: number) => {
        selectTables(undefined)
        if (selectedTableRef.current == id) return;
        setTableLoading(true)

        setTimeout(() => {
            selectTable(id);
            selectedTableRef.current = id;
            setTableLoading(false)
            if (id) {
                document.addEventListener('keydown', (e) => moveItemWithKeyboard(e));
            } else {
                document.removeEventListener('keydown', (e) => moveItemWithKeyboard(e));
            }
        }, 1);
    }

    const updateSelectedShape = (id?: number) => {
        selectShape(id); selectedShapeRef.current = id;
        if (id) {
            document.addEventListener('keydown', (e) => moveItemWithKeyboard(e));
        } else {
            document.removeEventListener('keydown', (e) => moveItemWithKeyboard(e));
        }
    }

    if (!layout || !areas) return <Loader />;

    if (!experienceId && areas.length === 0) return <>
        <AreaForm
            areas={areas}
            selectedArea={undefined}
            businessId={businessData.id}
            selectArea={selectArea}
            updateData={setAreas}
        />
    </>

    const selectedArea: ExtendedArea = areas?.find(x => x.id === selectedAreaId);
    const selectedTable: ExtendedTable = layout?.find(x => x.id === selectedTableId);
    const selectedShape: ExtendedShape = shapes?.find(x => x.id === selectedShapeId);

    const save = (item: BaseBookingTableSetup) => {
        ApiService.bookingtablesetup.Update__POST(item)
            .catch(() => NotificationService.Error('Could not save last update'))
    }

    const saveShape = (item: BaseShape) => {
        ApiService.shapes.Update__POST(item)
            .catch(() => NotificationService.Error('Could not save last update'))
    }

    const saveArea = (item: BaseTableSetupArea) => {
        ApiService.tablesetupareas.Update__POST(item)
            .catch(() => NotificationService.Error('Could not save last update'))
    }

    const setArea = (area: ExtendedArea) => {
        const data = cloneDeep(areas);
        const item = data.find(x => x.id === area.id);
        item.scale = area.scale;
        saveArea(area);
        setAreas(data);
    }

    const dragEnd = (x: number, y: number) => {
        if (dragItem.current) tableDragEnd(x, y);
        if (dragItemShape.current) shapeDragEnd(x, y);
    }

    const getTableNumber = (tableNumber: number) => {
        if (!layout.find(x => x.tableName === tableNumber.toString())) {
            return tableNumber;
        }
        return getTableNumber(tableNumber + 1);
    }

    const tableDragEnd = (x: number, y: number) => {
        dragItem.current.x = x;
        dragItem.current.y = y;
        dragItem.current.bookableOnline = true;
        dragItem.current.tableName = getTableNumber(layout.length + 1).toString();

        ApiService.bookingtablesetup.Insert__PUT(dragItem.current).then(response => {
            dragItem.current.id = +response.info;
            dragItem.current.minimumOccupancy = 1;
            const newData = [...layout, { ...dragItem.current, valid: true }]
            setLayout(newData)
            dragItem.current = undefined;
        })
    }

    const shapeDragEnd = (x: number, y: number) => {
        dragItemShape.current.x = x;
        dragItemShape.current.y = y;
        ApiService.shapes.Insert__PUT(dragItemShape.current).then(response => {
            const data = cloneDeep(shapes);
            dragItemShape.current.id = +response.info;
            data.push({ ...dragItemShape.current });
            setShapes(data);
            dragItemShape.current = undefined
        })
    }

    const updateLayout = (newData: ExtendedTable[]) => {
        setLayout(newData.map(item => {
            const validity = tableIsValid(item, newData);
            item.valid = validity.occupancyValid && validity.tableNameValid;
            return item;
        }))
    }

    const changeTab = (tabIndex: number) => {
        if (experienceId && selectedArea?.experienceId != experienceId) {
            const newArea = areas.find(x => x.experienceId === experienceId)?.id;
            if (tabIndex === 3 && !newArea) {
                selectArea(areas.length > 0 ? areas[0].id : undefined);
            } else {
                selectArea(newArea)
            }
        }
        setTab(tabIndex)
    }

    const ContainerItem = !selectedShape && !selectedTable ? DragDropItems : BlankDiv;
    const hasDuplicates = hasDuplicateKeyValues<ExtendedTable>(layout, 'tableName');
    let selectableAreas = areas;

    if (!!experienceId) {
        if (tab !== 3) {
            selectableAreas = areas.filter(x => x.experienceId == experienceId)
        } else {
            selectableAreas = areas.filter(x => !x.experienceId || x.experienceId == experienceId)
        }
    } else {
        selectableAreas = areas.filter(x => !x.experienceId)
    }

    if (areas && areas.length == 0) return <ActionBox
        icon={'dining-table'}
        url={`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/table-setup`}
        title='Additional configuration needed'
    >
        Additional configuration is needed before you can make bookings.
        <br />
        Please visit the booking setup page and configure at least 1 table.
    </ActionBox>

    return (
        <>
            {!experienceId && <br />}
            {!experienceId && tab === 0 && <DocsButton url='bookings/floor-plans#accessing-the-floor-plan' />}
            {!experienceId && tab === 1 && <DocsButton url='bookings/table-combinations' />}
            {!experienceId && tab === 2 && <DocsButton url='bookings/floor-plans#managing-areas' />}
            {!!experienceId &&
                <TabBar>
                    <TabButton onClick={() => changeTab(3)} active={tab === 3}>{eventOrExperience} area/table selection</TabButton>
                    <TabButton onClick={() => changeTab(2)} active={tab === 2}>Custom areas</TabButton>
                    <TabButton onClick={() => changeTab(0)} active={tab === 0}>Tables</TabButton>
                    <TabButton onClick={() => changeTab(1)} active={tab === 1}>Combinability</TabButton>
                </TabBar>
            }
            {!experienceId &&
                <TabBar>
                    <TabButton onClick={() => changeTab(0)} active={tab === 0}>Tables</TabButton>
                    <TabButton onClick={() => changeTab(1)} active={tab === 1}>Combinability</TabButton>
                    <TabButton onClick={() => changeTab(2)} active={tab === 2}>Areas</TabButton>
                </TabBar>
            }
            {tab === 0 &&
                <>
                    <Wrapper>
                        {selectedArea &&
                            <>
                                <Canvas ref={containerRef}>
                                    <MainStage
                                        displayGrid
                                        tables={layout}
                                        shapes={shapes}
                                        setLayout={updateLayout}
                                        setShapes={setShapes}
                                        setArea={setArea}
                                        save={save}
                                        saveShape={saveShape}
                                        selectShape={updateSelectedShape}
                                        selectTable={updateSelectedTable}
                                        selectMultipleTables={selectTables}
                                        dragEnd={dragEnd}
                                        selectedTables={selectedTableIds || [selectedTableId]}
                                        selectedShape={selectedShapeId}
                                        selectedArea={selectedArea} />
                                </Canvas>
                                <SidePanel>
                                    <AreaActionBar>
                                        <StyledDropdown
                                            onChange={e => selectArea(+e.target.value)}
                                            value={selectedArea.id}
                                            label='Selected area:'
                                            addDefault={false}
                                            items={selectableAreas.map(a => ({ value: a.id.toString(), text: a.name } as DropdownItem))}
                                        />
                                    </AreaActionBar>
                                    <ContainerItem>
                                        <>
                                            {!selectedShapeId &&
                                                <TableForm
                                                    tables={layout}
                                                    selectedTable={selectedTable}
                                                    selectedArea={selectedArea}
                                                    businessId={businessData.id}
                                                    selectTable={selectTable}
                                                    updateData={updateLayout}
                                                    setDrag={(item) => { dragItem.current = item; dragItemShape.current = undefined; }}
                                                    save={save}
                                                    loading={tableLoading}
                                                />
                                            }
                                            {!selectedTableId &&
                                                <ShapeForm
                                                    shapes={shapes}
                                                    selectedShape={selectedShape}
                                                    selectedArea={selectedArea}
                                                    businessId={businessData.id}
                                                    selectShape={selectShape}
                                                    updateData={setShapes}
                                                    setDrag={(item) => { dragItemShape.current = item; dragItem.current = undefined; }}
                                                    save={saveShape}
                                                />
                                            }
                                        </>
                                    </ContainerItem>
                                </SidePanel>
                            </>
                        }
                        {!selectedArea &&
                            <InfoMessage>{NOAREASMESSAGE}</InfoMessage>
                        }
                    </Wrapper>
                </>
            }
            {tab == 1 &&
                <Wrapper>
                    {selectedArea &&
                        <Combinability
                            areas={selectableAreas}
                            tables={layout}
                            shapes={shapes}
                            selectArea={selectArea}
                            selectedArea={selectedArea}
                            experienceId={experienceId}
                        />
                    }
                    {!selectedArea &&
                        <InfoMessage>{NOAREASMESSAGE}</InfoMessage>
                    }
                </Wrapper>
            }
            {tab === 2 &&
                <AreaForm
                    areas={selectableAreas}
                    selectArea={selectArea}
                    selectedArea={selectedArea}
                    businessId={businessData.id}
                    updateData={(newAreas) => experienceId ? setAreas([...areas.filter(x => !x.experienceId), ...newAreas]) : setAreas(newAreas)}
                    experienceId={experienceId}
                    experienceType={experienceType}
                />
            }
            {tab === 3 &&
                <Wrapper>
                    {selectedArea &&
                        <ExperienceSelection
                            areas={selectableAreas}
                            tables={layout}
                            shapes={shapes}
                            selectedArea={selectedArea}
                            experienceId={experienceId}
                            selectArea={selectArea}
                        />
                    }
                </Wrapper>
            }
            {hasDuplicates &&
                <FloatingActionBar>
                    <WarningMessage>Some of your tables have duplicate names. Please rename the duplicates.</WarningMessage>
                </FloatingActionBar>
            }
        </>
    );
};

const AreaActionBar = styled.div`
    border-bottom: 1px solid ${props => props.theme.borderColor};
    padding: 0.5rem;
    margin-bottom: 0.5rem;
`

export default BookingTableLayout;