import { UseCommaInCurrency } from "./currency-helper";
import { isNullOrWhitespace } from "./text-helpers";

export function formatNumberToText(number: number | string, decimalPlaces: number): String {
  number = +number;
  if (isNullOrWhitespace(number) || Number.isNaN(number)) {
    return ''
  } else if (decimalPlaces && !isNullOrWhitespace(number)) {
    return number.toFixed(decimalPlaces);
  }

  return String(number);
}

export function formatNumber(number: number | string, decimalPlaces: number): number {
  number = +number;
  if (decimalPlaces && number) {
    return parseFloat(number.toFixed(decimalPlaces));
  } else if (number === undefined || number === null || Number.isNaN(number)) {
    return null;
  }

  return number;
}

export function cleanNumber(e: any, wholeNumbersOnly) {
  const allowedKeys = [
    8, 46, 37, 39, 9, 110, 109, 189, '.', '-', (UseCommaInCurrency() ? ',' : null), "KeyV", "KeyC"
  ]
  const keyVal = e.keyCode || e.charCode;

  if (!isFinite(e.key) && !allowedKeys.find(x => x === keyVal) && !allowedKeys.find(x => x === e.key) && !allowedKeys.find(x => x === e.code)) return e.preventDefault();
  if (e.key === '+') return e.preventDefault();
  if (allowedKeys.find(x => x === e.key) && wholeNumbersOnly) return e.preventDefault();
}

export function isValidValue(value) {
  return !!value || value === 0;
}

export function getNumberArray(to: number, from: number = 0) {
  const numbers: number[] = [];
  for (let i = from; i <= to; i++) {
    numbers.push(i);
  }
  return numbers;
}