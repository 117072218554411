import React from 'react';
import styled from 'styled-components';
import BREAKPOINTS from '../../config/breakpoints';

interface ComponentProps {
    src: string;
    small?: boolean;
    customSize?: string;
}

interface ProfileImageProps {
  size: string;
}

interface ProfileImageInnerProps extends ProfileImageProps {
  src: string;
}

const ProfileImageInner = styled.div`
  width: ${(props: ProfileImageInnerProps) => props.size};
  height: ${(props: ProfileImageInnerProps) => props.size};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${(props: ProfileImageInnerProps) => props.src}');
  border-radius: 100%;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    margin: 0 auto;
  }
`;

const ProfileImage = ({ src, small, customSize }: ComponentProps) => {
  return (
    <ProfileImageInner id='profileImage' size={small ? '3rem' : customSize || '7rem'} src={src} />
  );
};

export default ProfileImage;