import React, { useState } from 'react';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import styled from 'styled-components';
import Icon from '../../../../../../components/Media/Icon';
import { BaseBoxShadowStyle, BaseBoxShadowStyleWithHover } from '../../../../../../theme';
import { ApiMenu, ApiMenuListResponse } from '../../../../../../api/api-definitions';
import { cloneDeep } from 'lodash';
import { moveInArray } from '../../../../../../utils/data-helpers';
import { Badge } from '@chakra-ui/react';
import { ExtendedApiMenu } from '.';

interface ComponentProps {
    menu: ApiMenu;
    parentItem?: ApiMenu;
    menuList: ApiMenuListResponse;
    isFirst: boolean;
    isLast: boolean;
    editMenu: (menu: ExtendedApiMenu) => void;
    deleteMenu: (menu: ApiMenu) => void;
    saveMenu: (menu: ApiMenu) => void;
}

const MenuItem = ({ menu, menuList, parentItem, isFirst, isLast, editMenu, deleteMenu, saveMenu }: ComponentProps) => {
    const [expanded, setExpanded] = useState(false);
    const childItems = menu.order?.split('|') || [];
    const subMenus = menuList.subItems[menu.rowKey] as ApiMenu[] || [];

    const moveItem = (increment: boolean) => {
        const newItem = cloneDeep(parentItem);
        const childOrder: string[] = newItem.order?.split('|') || [];
        const currentIndex = childOrder.findIndex(x => x == menu.rowKey);
        const newIndex = increment ? currentIndex - 1 : currentIndex + 1;
        moveInArray(childOrder, currentIndex, newIndex);
        newItem.order = childOrder.join('|');
        saveMenu(newItem);
    }

    return (
        <div>
            <ComponentDropLine />
            <Component>
                <ContentContainer>
                    <ComponentInner onClick={() => setExpanded(!expanded)}>
                        {menu.name} <UrlLabel colorPalette='blue'>{menu.url}</UrlLabel>
                        <RightIcon name={expanded ? 'chevron-down' : 'chevron-right'} />
                    </ComponentInner>
                    <DeleteButton onClick={() => deleteMenu(menu)}><Icon name='trash' /></DeleteButton>
                    <ComponentButton onClick={() => editMenu(menu)}><Icon name='sliders' /></ComponentButton>
                    {parentItem && <ComponentButton disabled={isFirst} onClick={() => moveItem(true)}><Icon name='chevron-up' /></ComponentButton>}
                    {parentItem && <ComponentButton disabled={isLast} onClick={() => moveItem(false)}><Icon name='chevron-down' /></ComponentButton>}
                </ContentContainer>
            </Component>
            <ComponentDropLine />
            {expanded &&
                <ComponentChildren>
                    {subMenus.sort((a, b) => childItems.indexOf(a.rowKey) - childItems.indexOf(b.rowKey))
                        .map((item: ApiMenu, index: number) => (
                            <MenuItem
                                key={item.rowKey}
                                parentItem={menu}
                                menu={item}
                                isFirst={index == 0}
                                isLast={index == subMenus.length - 1}
                                menuList={menuList}
                                editMenu={editMenu}
                                saveMenu={saveMenu}
                                deleteMenu={deleteMenu}
                            />
                        ))}
                    <AddButton onClick={() => editMenu({ parentKey: menu.rowKey, menuKey: menu.menuKey, internal: true })}>Add menu link</AddButton>
                </ComponentChildren>
            }
        </div>
    );
};

const AddButton = styled(DashboardAddButton)`
    margin-top: 0.5rem;
`

const ComponentDropLine = styled.div<{ active?: boolean }>`
    border-bottom: 2px solid ${props => props.active ? props.theme.secondary : 'rgba(255, 255, 255, 0)'};
    height: 1px;
`

const ComponentChildren = styled.div`
    padding-left: 2rem;
    margin-bottom: 1rem;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 17px;
        border-left: 1px solid ${props => props.theme.borderColor};
        top: -20px;
        width: 1px;
        height: 100%;
        z-index: 0;
    }

    &::after {
        content: "";
        position: absolute;
        left: 17px;
        border-bottom: 1px solid ${props => props.theme.borderColor};
        bottom: 19px;
        width: 20px;
        height: 1px;
        z-index: 0;
    }
`

const PlaceHolderTitle = styled.div`
    font-weight: bold;
    margin-bottom: 1rem;
`

const Component = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    z-index: 1;
`

const ComponentInner = styled.div`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    flex: 1 0 auto;
`

const ContentContainer = styled.div`
    flex: 1 0 auto;
    display: flex;
    z-index: 1;
`

const DragIcon = styled(Icon)`
    cursor: grab !important;
`

const ComponentButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    margin-left: 1rem;

    &:enabled {
        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        &:hover {
            background: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    }

    &:disabled {
        ${BaseBoxShadowStyle}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        opacity: 0.4;
    }
`

const DragButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: ${props => props.theme.dashboardElevatedBackground};
    padding: 1rem 0rem;
    margin-right: 0.5rem;
    cursor: grab !important;

    &:enabled {
        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        &:hover {
            background: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    }

    &:disabled {
        ${BaseBoxShadowStyle}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        opacity: 0.4;
    }
`

const RightIcon = styled(Icon)`
    float: right;
    margin-top: 0.3rem;
`

const DeleteButton = styled(ComponentButton)`
    &:hover {
        background: ${props => props.theme.negative} !important;
        color: ${props => props.theme.negativeContrast} !important;
    }
`

const UrlLabel = styled(Badge)`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 1rem;
`

export default MenuItem;