import { useParams } from 'react-router';
import Icon from '../../../components/Media/Icon';
import { forwardRef, useEffect, useRef, useState } from 'react';
import Loader from '../../../components/Layout/Loader';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, DEFAULT_DATE_FORMAT, businessNowTime, createMomentFromValue, formatDate, isValidDate } from '../../../utils/date-helpers';
import moment, { Moment } from 'moment';
import { ApiService } from '../../../api/api-connectors';
import { ApiBookingTimesWidget, ApiBusinessBookingDetails, BookingTimeSlots, ApiBookingTimesRequest, DateAndTimeSlotsWidget } from '../../../api/api-definitions';
import { getQueryParams, pushEvent } from '../../../utils/data-helpers';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { NotificationService } from '../../../services/NotificationService';
import { captureException } from '@sentry/react';
import { BookingFormData, desktopSize, generatePeopleList, getClosestFifteenMinutes, mobileSize, tabletSize } from '../Bookings';
import { Button, ButtonBack, ButtonOutline, ButtonTime, FormRow, GlobalWidgetStyle, InfoMessage, Message, TimeRow } from '../../booking.styles';
import styled from 'styled-components';
import FormWrapper from '../../../components/Forms/FormWrapper';
import { Column } from '../../../components/Layout/Grid';
import { StyledDropdown, StyledCalendar, StyledTextInput } from '../../../theme/input.styles';
import { DropdownItem } from '../../../components/Forms/Dropdown';

interface ComponentProps {
    previewLocation?: string;
    previewBusinessData?: ApiBusinessBookingDetails;
    defaultDateTime?: Moment;
}

const TIMEFORMAT = 'h:mm A';
const ERROR = 'Something went wrong, please try again later.';
const ERRORMESSAGE = 'Sorry, we were unable to complete your booking, please try again.';
const AMENDERRORMESSAGE = 'Sorry, we were unable to amend your booking, please try again.';
const HOLDERRORMESSAGE = 'Sorry, we were unable to hold the selected time slot for your booking.';

const BookingWidgetSmall = ({
    previewLocation,
    previewBusinessData,
    defaultDateTime,
}: ComponentProps) => {
    const routeParams: any = useParams();
    const params = getQueryParams();
    const [loading, setLoading] = useState(true)
    const [selectedTimeDropdown, setSelectedTimeDropdown] = useState<string>(defaultDateTime ? '0001-01-01T' + defaultDateTime.format('HH:mm') + ':00' : undefined);
    const [business, setBusiness] = useState<ApiBusinessBookingDetails>();
    const businessRef = useRef<ApiBusinessBookingDetails>();
    const [searchableExperience, setSearchableExperience] = useState<string>();
    const specialEventDates = useRef<Date[]>([])
    const [times, setTimes] = useState<DateAndTimeSlotsWidget>();
    const [formData, setFormData] = useState<BookingFormData>({
        guests: 2,
        date: defaultDateTime || businessNowTime(),
        time: defaultDateTime ? defaultDateTime.format(TIMEFORMAT) : getClosestFifteenMinutes()
    })
    const lastDateSearch = useRef<string>();
    const [hasLoadedFirstData, setHasLoadedFirstData] = useState<boolean>(false);
    const maxPeople = useRef<number>();
    const availableTimes = useRef<BookingTimeSlots>();
    const partyTooBig = formData.guests?.toString() == 'MAX' || (maxPeople.current ? +formData.guests > maxPeople.current : false);


    useEffect(() => {
        if (previewLocation) {
            availableTimes.current = {
                timeSlots: {
                    Monday: true,
                    Tuesday: true,
                    Wednesday: true,
                    Thursday: true,
                    Friday: true,
                    Saturday: true,
                    Sunday: true,
                }
            }
            maxPeople.current = 2;
            setBusiness(previewBusinessData)
            businessRef.current = previewBusinessData;
            setLoading(false)
            setTimes({
                date: formatDate(businessNowTime(), DATABASE_TIME_FORMAT),
                shiftLabelAndTimeSlots: {
                    'Lunch': {
                        slots: [
                            {
                                slot: '0001-01-01T14:00:00'
                            },
                            {
                                slot: '0001-01-01T14:15:00'
                            },
                            {
                                slot: '0001-01-01T14:30:00'
                            },
                            {
                                slot: '0001-01-01T14:45:00'
                            },
                            {
                                slot: '0001-01-01T15:00:00'
                            }
                        ]
                    },
                    'Dinner': {
                        slots: [
                            {
                                slot: '0001-01-01T18:00:00'
                            },
                            {
                                slot: '0001-01-01T18:15:00'
                            },
                            {
                                slot: '0001-01-01T18:30:00'
                            },
                            {
                                slot: '0001-01-01T18:45:00'
                            },
                            {
                                slot: '0001-01-01T19:00:00'
                            }
                        ]
                    }
                }
            })
            setHasLoadedFirstData(true)
        } else {
            if (isNullOrWhitespace(routeParams.location)) {
                pushEvent('bookingAppLoadError', { 'locationId': 'Not specified' })
                return;
            }
            ApiService.weblocation.Getlocation__GET(routeParams.location, !!routeParams.ref).then(data => {
                if (isNullOrWhitespace(data?.name)) {
                } else {
                    if (data.specialEventDates) {
                        specialEventDates.current = Object.keys(data.specialEventDates).map(x => createMomentFromValue(x).toDate())
                    }
                    moment.tz.setDefault(data.timeZone);
                    if (data.timeSlotsPerDay?.timeSlots) {
                        availableTimes.current = data.timeSlotsPerDay;
                        maxPeople.current = data.maximumBookableParty;
                        const todaysSlots = data.timeSlotsPerDay.timeSlots[createMomentFromValue(formData.date).format('dddd')];
                        if (!defaultDateTime && !todaysSlots) {
                            setFormData({
                                ...formData,
                                date: createMomentFromValue(data.firstAvailableDate) || null
                            })
                        } else if (!defaultDateTime && !!data.firstAvailableDate) {
                            setFormData({
                                ...formData,
                                date: createMomentFromValue(data.firstAvailableDate) || formData.date
                            })
                        }
                        setBusiness(data)
                        businessRef.current = data;
                        document.body.style.backgroundColor = data.theme.backgroundColour;
                        document.body.style.color = data.theme.textColour;
                        var css = `#pageContent { --ion-background-color: ${data.theme.backgroundColour} !important; }`,
                            head = document.head || document.getElementsByTagName('head')[0],
                            style = document.createElement('style');

                        head.appendChild(style);
                        // @ts-ignore
                        style.type = 'text/css';
                        // @ts-ignore
                        if (style.styleSheet) {
                            // This is required for IE8 and below.
                            // @ts-ignore
                            style.styleSheet.cssText = css;
                        } else {
                            style.appendChild(document.createTextNode(css));
                        }
                        if (data.specialEvents) {
                            if (params.event) {
                                let peopleToSearchFor = 2;
                                if (data.specialEvents[params.event].minimumPeople && data.specialEvents[params.event].minimumPeople > 2) {
                                    peopleToSearchFor = data.specialEvents[params.event].minimumPeople;
                                }
                                if (data.specialEvents[params.event].maximumPeople && data.specialEvents[params.event].maximumPeople < 2) {
                                    peopleToSearchFor = 1;
                                }
                                // nextAvailSearch(+params.event, peopleToSearchFor)
                            }
                        }
                        if (defaultDateTime) {
                            dateChange(defaultDateTime, true);
                        } else {
                            dateChange(createMomentFromValue(data.firstAvailableDate) || formData.date, true);
                        }
                        setLoading(false)
                    } else {
                        pushEvent('bookingAppLoadError', { 'locationId': routeParams.location })
                    }
                }
            }).catch((e) => {
                captureException(e);
                NotificationService.Error('Sorry, there has been an error loading the application.')
            })
        }
    }, [])

    const getApiBookingTimeRequest = (date: string | Moment | Date, guests?: number, experienceGuid?: string): ApiBookingTimesRequest => {
        return {
            requestedTime: formatDate(createMomentFromValue(date).startOf('day'), DATABASE_TIME_FORMAT),
            guests: guests || formData.guests,
            locationId: routeParams.location,
            bookingReference: undefined,
            experienceGuid
        }
    }

    const dateChange = (date: Date | Moment, firstLoad?: boolean, guests?: number, experienceGuid?: string, noExperience?: boolean) => {
        const formattedTime = '0001-01-01T' + formatDate(date, 'HH:mm') + ':00';
        if (!firstLoad && formatDate(date, DATEONLYFORMAT) == formatDate(formData.date, DATEONLYFORMAT) && guests == formData.guests && searchableExperience === experienceGuid) return;
        const formattedLastSearch = formatDate(date, DATEONLYFORMAT) + '-' + (guests || formData.guests) + '-' + (noExperience ? '0' : (experienceGuid || searchableExperience) ? (experienceGuid || searchableExperience) : '0');
        if (!previewLocation && (guests || formData.guests) && (guests || formData.guests)?.toString() !== 'MAX' && lastDateSearch.current != formattedLastSearch) {
            const request = getApiBookingTimeRequest(date, (guests || formData.guests), noExperience ? undefined : (experienceGuid || searchableExperience))
            lastDateSearch.current = formattedLastSearch;
            ApiService.makebooking.GetForDate__POST(request).then((response) => {
                setTimes(response);
                setFormData({ ...formData, date: createMomentFromValue(date), guests: guests || formData.guests })
                if (!hasLoadedFirstData) setHasLoadedFirstData(true);
                if (!firstLoad) {
                    setSelectedTimeDropdown('');
                } else if (guests) {
                    setSelectedTimeDropdown(formattedTime);
                }
            })
        }
    }

    const hold = () => {
        window.open(`${window.location.origin}/l/${routeParams.location}?searchDate=${formatDate(formData.date, 'YYYY-MM-DD')}&searchTime=${selectedTimeDropdown}&guests=${formData.guests}&hold=true`, '_blank');
    }

    const nextAvailSearch = () => {
        window.open(`${window.location.origin}/l/${routeParams.location}?searchDate=${formatDate(formData.date, 'YYYY-MM-DD')}&guests=${formData.guests}&nextAvail=true`, '_blank');
    }

    let hasTimeSlots = false;
    let timeOptions: DropdownItem[] = [];

    if (times) Object.keys(times.shiftLabelAndTimeSlots).forEach((label) => {
        if (times.shiftLabelAndTimeSlots[label].slots.length > 0) hasTimeSlots = true;
        times.shiftLabelAndTimeSlots[label].slots.forEach(time => {
            timeOptions.push({
                value: time.slot,
                text: moment(time.slot).format(TIMEFORMAT) + (time.eventGuids?.length > 0 ? '*' : '') + (time.eventOnly ? ' (event only)' : ''),
                group: Object.keys(times.shiftLabelAndTimeSlots).length > 1 ? label : undefined
            })
        });
    });

    const CustomInput = forwardRef(({ dateVal, onClick }: any, ref: any) => (
        <div onClick={onClick} ref={ref}>
            <StyledTextInput icon='calendar' unlink value={createMomentFromValue(formData.date).format('ddd, Do MMM yyyy')} />
        </div>
    ));

    return (
        <>
            <GlobalWidgetStyle widgetTheme={business?.theme} />
            <Wrapper>
                {!loading && business &&
                    <FormWrapper onUpdate={(newData) => loading ? {} : setFormData({ ...newData })}>
                        {() => (
                            <>
                                <FormRow widgetTheme={business?.theme}>
                                    <StyledDropdown icon='users' model='guests' value={formData.guests} items={generatePeopleList(business.maximumBookableParty || 20)} />
                                </FormRow>
                                <Breaker />
                                {partyTooBig &&
                                    <InfoMessage marginTop widgetTheme={business?.theme}>
                                        For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                                    </InfoMessage>
                                }
                                {!partyTooBig &&
                                    <>
                                        {business.firstAvailableDate && createMomentFromValue(business.firstAvailableDate).format(DEFAULT_DATE_FORMAT) !== moment().format(DEFAULT_DATE_FORMAT) &&
                                            <InfoMessage marginTop widgetTheme={business?.theme}>
                                                The first available date has been pre-selected.
                                            </InfoMessage>
                                        }
                                        <StyledCalendar
                                            onChange={dateChange}
                                            model='date'
                                            value={formData.date}
                                            months={1}
                                            calendarProps={{
                                                filterDate: isValidDate,
                                                highlightDates: business.specialEventDates && Object.keys(business.specialEventDates).length > 0 ? [
                                                    {
                                                        'datepicker__event-day': specialEventDates.current
                                                    }
                                                ] : undefined,
                                                onKeyDown: (e) => {
                                                    e.preventDefault();
                                                },
                                                withPortal: true
                                            }}
                                            customInput={<CustomInput />}
                                        />
                                    </>
                                }

                                {!partyTooBig && times && hasTimeSlots &&
                                    <>
                                        <Breaker />
                                        <FormRow widgetTheme={business?.theme}>
                                            <StyledDropdown icon='clock' value={selectedTimeDropdown} items={timeOptions} onChange={(e) => setSelectedTimeDropdown(e.target.value)} defaultText='Please select a time' />
                                        </FormRow>
                                        <Button disabled={isNullOrWhitespace(selectedTimeDropdown) || !isNullOrWhitespace(previewLocation)} widgetTheme={business?.theme} type='button' onClick={() => hold()}>
                                            {isNullOrWhitespace(business.reserveButtonTextStepOne) ? 'Reserve' : business.reserveButtonTextStepOne}
                                        </Button>

                                    </>
                                }
                                {!partyTooBig && !loading && times && !hasTimeSlots &&
                                    <div>
                                        <Breaker />
                                        <InfoMessage widgetTheme={business?.theme}>
                                            No availability{searchableExperience && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} found on this day.
                                        </InfoMessage>
                                        <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch()}>
                                            <Icon name='search' /> Search next available{searchableExperience && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>}
                                        </ButtonOutline>
                                    </div>
                                }
                                {loading && <Loader />}
                            </>
                        )}
                    </FormWrapper>
                }
                {loading &&
                    <Loader />
                }
            </Wrapper>
        </>
    );
};

const Breaker = styled.div`
    height: 0.5rem;
`

const BaseCalendarInput = styled.div`
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #d6d6d6;
    transition: all ease 0.5s;
    outline: none;

    &.error {
        border: 1px solid red;
    }

    &:focus {
        border-color: black;
    }
`

const Wrapper = styled.div`
    padding: 0.5rem;
`

export default BookingWidgetSmall;