import React, { useEffect, useState } from 'react';
import { BaseSupportTicket, BaseSupportTicketResponse, SupportTicketPriority } from '../../../../../api/api-definitions';
import CoreModal from '../../../../../components/Layout/CoreModal';
import { ApiService } from '../../../../../api/api-connectors';
import Loader from '../../../../../components/Layout/Loader';
import { StyledTextarea } from '../../../../../theme/input.styles';
import CoreButton from '../../../../../components/Forms/Button';
import styled from 'styled-components';
import { DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT, createMomentFromValueUtc, formatDate } from '../../../../../utils/date-helpers';
import SupportTicketStatusLabel from './supportTicketStatusLabel';
import SupportTicketPriorityLabel from './supportTicketPriorityLabel';
import { Constants } from '../../../../../constants';
import { useRecoilValue } from 'recoil';
import { LoginState } from '../../../../../components/Authentication/AuthenticationWrapper';
import Button from '../../../../../components/Forms/Button';
import { ModalService } from '../../../../../services/ModalService';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import { Badge } from '@chakra-ui/react';
import RichTextEdior from '../../../../../components/Forms/RichTextEditor';

interface ComponentProps {
    ticket: BaseSupportTicket;
    onClose: (refesh?: boolean) => void;
}

const CommentBox = styled.div`
    border: 1px solid ${props => props.theme.borderColor};
    border: radius: 5px;
    margin-bottom: 1rem;
`

const Comment = styled.div`
    padding: 1rem;
    white-space: pre-line;
`

const CommentTitle = styled.div`
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast}
`

const ViewSupportTicket = ({ ticket, onClose }: ComponentProps) => {
    const [data, setData] = useState<BaseSupportTicket>();
    const [comment, setComment] = useState('');
    const [devLogOpen, setDevLogOpen] = useState(false);
    const loginValue = useRecoilValue(LoginState);

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        setComment('')
        ApiService.supportTicket.Get__GET(ticket.id).then(setData)
    }

    const addComment = () => {
        const request: BaseSupportTicketResponse = {
            id: undefined,
            supportTicketId: ticket.id,
            userId: undefined,
            dateAddedUtc: undefined,
            description: comment
        }
        ApiService.supportTicketResponse.Add__PUT(request).then(load)
    }

    const businessName = !isNullOrWhitespace(ticket.businessName) ? ticket.businessName : ticket.parentBusinessName;

    return (
        <CoreModal onClose={onClose} title={<><Badge colorPalette='cyan'>#{ticket.ticketReference}</Badge> {ticket.summary}</>} cancelText='Close'>
            <SupportTicketStatusLabel status={ticket.status} /> <SupportTicketPriorityLabel status={ticket.priority} />
            <br />
            <br />
            <strong>Description</strong>
            <br />
            <div dangerouslySetInnerHTML={{__html: ticket.description}} />
            <br />
            <strong>Raised by</strong>
            <br />
            <p>{ticket.username}{!isNullOrWhitespace(businessName) && `, ${businessName}`}</p>
            <br />
            <strong>Date created</strong>
            <br />
            <i>{formatDate(ticket.dateRaisedUtc, DISPLAY_DATE_TIME_FORMAT, true)}</i><br />
            {loginValue.data.isSuperAdmin &&
                <>
                    {devLogOpen &&
                        <CoreModal onClose={() => setDevLogOpen(false)}>
                            <code>{data.devLogs}</code>
                        </CoreModal>
                    }
                    <br />
                    <Button onClick={() => setDevLogOpen(true)}>Open dev log</Button>
                    <br />
                </>
            }
            {!data && <Loader />}
            {data &&
                <>
                    <br />
                    <strong>Comments</strong>
                    <br />
                    <br />
                    {data.responses?.length === 0 &&
                        <>No comments added yet</>
                    }
                    {data.responses.map(item => (
                        <CommentBox>
                            <CommentTitle>{item.isAdmin ? Constants.businessName : item.username} at {createMomentFromValueUtc(item.dateAddedUtc).format(`${DISPLAY_DATE_FORMAT} HH:mm`)}</CommentTitle>
                            <Comment dangerouslySetInnerHTML={{__html: item.description}} />
                        </CommentBox>
                    ))}
                    <br />
                    <hr />
                    <br />
                    <RichTextEdior value={comment} onChange={e => setComment(e)} />
                    <CoreButton onClick={addComment}>Add comment</CoreButton>
                </>
            }
        </CoreModal>
    );
};

export default ViewSupportTicket;