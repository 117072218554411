import React from 'react';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import { Badge, Code } from '@chakra-ui/react';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import { Constants } from '../../../../../../constants';

interface ComponentProps {
    experienceGuid?: string;
}

const WebisteIntegration = ({ experienceGuid }: ComponentProps) => {
    const [loaded, businessData] = useBusiness()
    return (
        <DashboardPanel title='Website integration'>
            {experienceGuid &&
            <InfoMessage>To place a book button on your website linking directly to this event, please follow the instructions below. </InfoMessage>
            }
            {!experienceGuid &&
            <InfoMessage>To place a book button on your website linking to our booking widget, please follow the instructions below. </InfoMessage>
            }
            <br />
            <strong>Option 1: Button with modal (recommended)</strong><br />
            Place this script where you want the button to be.
            <br />
            <br />
            <Code>
                {`<div><script async data-with-button="true" id="DishForagerBookingScript" src="${'https://bookings.' + window.location.host + '/' + businessData.locationId + (experienceGuid ? `?event=${experienceGuid}` : '')}"></script></div>`}
            </Code>
            <br />
            <br />
            You can style the book button using css on your own website. By default it will have {Constants.businessName}'s styling and colours. 
            <br />
            To style the button with your branding, place the code below in your css stylesheet, and define the styling inside the curly braces.
            <br />
            <br />
            <Code>
                {`.DishForagerBookButton {}`}
            </Code>
            <br />
            <br />
            <strong>Option 2: Modal with custom function call</strong><br />
            You can also trigger the modal to open via a javascript function call.
            <br />
            <br />
            Place the following code in the <Badge backgroundColor='gray.50'>{`<head>`}</Badge> of your page.
            <br />
            <br />
            <Code>
                {`<script async id="DishForagerBookingScript" src="${'https://bookings.' + window.location.host + '/' + businessData.locationId}"></script>`}
            </Code>
            <br />
            <br />
            Use this function call to trigger the modal.
            <br />
            <br />
            <Code>
                window.bookingModalOpen('{businessData.locationId}'{experienceGuid ? `, ${experienceGuid}` : ''})
            </Code>
            <br />
            <br />
            <strong>Option 3: iFrame embed</strong><br />
            Use the following URL to embed the widget into your website.<br /><br />
            <Code>
                {'https://bookings.' + window.location.host + '/' + businessData.locationId + (experienceGuid ? `?event=${experienceGuid}` : '')}
            </Code>
        </DashboardPanel>
    );
};

export default WebisteIntegration;