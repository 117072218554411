import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TabBar, TabButton } from '.';
import TabItem from './TabItem';
import { Tabs } from '@chakra-ui/react';

interface ComponentProps {
    children: any;
    fullWidth?: boolean;
    noMargin?: boolean;
}

const TabContent = styled.div`
    width: 100%;
`;

const TopBarPaddingVerticle = '0.5rem';
const TopBarPaddingHorizontal = '1.5rem';
const TopBarBorderSize = '2px';

const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    font-weight: bold;
    border-bottom: ${TopBarBorderSize} solid ${props => props.theme.primary};
`;

const TopBarSpacer = styled.div`
    flex: 1 1 auto;
    padding: ${TopBarPaddingVerticle} ${TopBarPaddingHorizontal} ${TopBarPaddingVerticle} 0rem;
`;

interface TopBarButtonProps {
    active?: boolean;
}

const TopBarButton = styled.div<TopBarButtonProps>`
    flex: 0 1 auto;
    padding: ${TopBarPaddingVerticle} ${TopBarPaddingHorizontal};
    cursor: pointer;
    color: white;

    &:hover {
        text-decoration: underline;
    }

    ${(props) => props.active ? `
        border-top: ${TopBarBorderSize} solid ${props.theme.primary};
        border-left: ${TopBarBorderSize} solid ${props.theme.primary};
        border-right: ${TopBarBorderSize} solid ${props.theme.primary};
        color: white;
        background-color: ${props.theme.primary};

        &:hover {
            color: white;
            background-color: ${props.theme.primary};
        }
    ` : `
        border-right: 2px solid ${props.theme.primary};
        border-bottom: none;
        color: inherit;
    `}
`;



const TabContainer = ({ children, fullWidth, noMargin }: ComponentProps) => {
    const [selectedIndex, changeIndex] = useState(0);
    const theme = useTheme();
    const tabNames: string[] = [];
    children.forEach((child: any) => {
        if (child) tabNames.push(child.props.title);
    });
    return (
        <Tabs.Root colorPalette={theme.secondary} defaultValue='tab-button-0'>
            <Tabs.List>
                {tabNames.map((tab: string, index: number) => (
                    <Tabs.Trigger key={'tab-button-' + index} value={'tab-button-' + index} onClick={() => changeIndex(index)}>{tab}</Tabs.Trigger>
                ))}
            </Tabs.List>
            {children.map((child, index) => (
                <Tabs.Content value={'tab-button-' + index}>
                    {child}
                </Tabs.Content>
            ))}
        </Tabs.Root>
    );
};

export default TabContainer;