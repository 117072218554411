import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import Loader from '../../../../../../components/Layout/Loader';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import CoreButton from '../../../../../../components/Forms/Button';
import DataTable, { DataTableItem } from '../../../../../../components/Layout/Datatable';
import { Badge } from '@chakra-ui/react';
import AddExperienceModal from './addExperienceModal';
import { BaseExperience, ExperienceType } from '../../../../../../api/api-definitions';
import { ApiService } from '../../../../../../api/api-connectors';
import Icon from '../../../../../../components/Media/Icon';

const ExperienceList = () => {
    const [businessLoaded, business] = useBusiness();
    const [experiences, setExperiences] = useState<BaseExperience[]>();
    const [addExperience, setAddExperience] = useState<boolean>(false);
    const loaded = useRef(false)

    useEffect(() => {
        if (!loaded.current && business) {
            loaded.current = true;
            ApiService.experience.List__GET(business.id, false).then(setExperiences)
        }
    }, [business])

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        experiences.forEach((item) => {
            const dataItem: DataTableItem = {
                data: {
                    'Name': {
                        value: item.displayName,
                        sortable: true,
                    },
                    'Type': {
                        value: item.type == ExperienceType.Event ? <Badge colorPalette='cyan'>Event</Badge> : <Badge colorPalette='green'>Experience</Badge>,
                        sortable: true,
                        sortValue: item.type == ExperienceType.Event ? 1 : 2
                    },
                    'Published': {
                        value: item.published ? <Icon name='check' /> : <Icon name='times' />,
                        sortable: true,
                        sortValue: item.published
                    },
                    'View': {
                        value: <CoreButton to={'experiences/' + item.id}>View</CoreButton>,
                        hideName: true,
                    },
                },
            }
            items.push(dataItem);
        });
        return items;
    }

    return (
        <>
            <DashboardHeader title='Experiences / events' icon='sparkles' />
            {addExperience &&
                <AddExperienceModal onCancel={() => setAddExperience(false)} businessId={business.id} />
            }
            {!experiences && <Loader />}
            {experiences && experiences.length > 0 && <DataTable data={gentable()} />}
            {experiences && experiences.length === 0 && <InfoMessage>No experiences or events are set up</InfoMessage>}
            <br />
            {experiences && <DashboardAddButton onClick={() => setAddExperience(true)}>Add experience or event</DashboardAddButton>}
        </>
    );
};

export default ExperienceList;