/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, ChangeEvent } from 'react';
import moment from 'moment';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import FormController from '../../../controllers/form';
import { createMomentFromValue, DATABASE_TIME_FORMAT, formatDate } from '../../../utils/date-helpers';
import i18n from '../../../utils/i18n';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { BaseErrorMessage, BaseInputStyle, BaseLabelStyle } from '../../../theme/input.core.styles';

interface TimeState {
  name: string;
  invalidPath: boolean;
  internalValue: Date;
}

interface TimeProps {
  model?: string;
  parentContext?: any;
  onChange?: (event: { target: { value: string } }, valid: boolean) => void;
  inputName?: string;
  value?: string | Date | moment.Moment;
  required?: boolean;
  boldLabel?: boolean;
  formId?: string;
  label?: React.ReactNode;
  id?: string;
  outputAsTimespan?: boolean;
  unlink?: boolean;
  minutesStep?: number;
  disabled?: boolean;
  hideValue?: boolean;
  testingId?: string;
  inputStyle?: React.CSSProperties;
  onBlur?: Function;
  min?: string;
  max?: string;
  className?: string;
}

const DEFAULT_MINUTES_STEP = 5;

/**
 * Pass in property path in state as model.
 * Do NOT inlude keywords 'this' or 'state' as the component handles it.
 * The parentContext should always almost be 'parentContext={this}'
 **/
export default class Time extends Component<TimeProps, TimeState> {
  static contextType = FormContext;
  param: any;

  constructor(props: TimeProps) {
    super(props);

    this.state = {
      name: '',
      invalidPath: false,
      internalValue: new Date(),
    };

    this.param;
  }

  get formId(): any {
    if (this.props.unlink) return null;
    return this.props.formId ? this.props.formId : this.context;
  }

  get minutesStep() {
    if (this.props.minutesStep) {
      return this.props.minutesStep;
    }

    return DEFAULT_MINUTES_STEP;
  }

  parseHoursAndMinutesString(date: Date) {
    return {
      hours: date.toISOString().substr(1, 2),
      minutes: date.toISOString().substr(14, 2),
    }
  }

  componentWillMount() {
    FormController.setState(this, this.state);
  }

  componentDidMount = () => {
    const { value, model, required } = this.props;

    if (model && this.formId) {
      FormController.updateFormState(value, model, !required || !isNullOrWhitespace(value), this.formId);
    }

    FormController.setState(this, { internalValue: this.getTimeFromValue(value) });
    FormController.registerForceUpdate(this);
  }


  componentDidUpdate(prevProps: TimeProps) {
    const { value, model, required } = this.props;

    if (value !== prevProps.value || required != prevProps.required) {
      if (model && this.formId) {
        FormController.updateFormState(value, model, !required || !isNullOrWhitespace(value), this.formId);
      }

      FormController.setState(this, { internalValue: this.getTimeFromValue(value) });
    }
  }

  getTimeFromValue(value) {
    if (value && (typeof value !== 'string' || value.indexOf('T') > -1) && createMomentFromValue(value).isValid()) {
      return formatDate(value, DATABASE_TIME_FORMAT).split('T')[1].substring(0, 5)
    }
    return value
  }

  getCleanedValue(currentValue, newValue) {

    if (this.props.outputAsTimespan) return newValue;

    if (!isNullOrWhitespace(newValue)) {
      const formattedTime = newValue + ':00';
      const utcNow = Date.UTC(1970, 0, 1);
      return new Date(`${utcNow} ${formattedTime}`);
    }

    return null;
  }

  setValue = (e: ChangeEvent<HTMLInputElement>, blur: boolean) => {
    const {
      model,
      onChange,
      value,
      required
    } = this.props;

    let newValue = e.target.value;
    newValue = this.getCleanedValue(value, newValue);
    
    if (isNullOrWhitespace(newValue) && blur && required) {
      const error = i18n('The value must be set');
      FormController.setState(this, { error, internalValue: newValue });

      if (model && this.formId) {
        FormController.updateFormState(newValue, model, false, this.formId);
      }
    } else {
      if (model && this.formId) {
        FormController.updateFormState(newValue, model, true, this.formId);
      }

      if (onChange) {
        onChange({
          target: {
            value: newValue
          }
        }, true);
      }

      FormController.setState(this, { internalValue: e.target.value, error: null });
    }
  }

  render = () => {
    const { name, invalidPath, error, internalValue } = FormController.getState(this);

    let {
      inputName,
      value,
      label,
      model,
      id,
      parentContext,
      disabled,
      testingId,
      inputStyle,
      required,
      boldLabel,
      min,
      max,
      hideValue
    } = this.props;

    let valueToUse = parentContext ? this.param ? this.param : value : internalValue;

    if (isNullOrWhitespace(valueToUse)) {
      valueToUse = '';
    } else {
      valueToUse = this.getTimeFromValue(valueToUse)
    }

    const nameToUse = name ? name : inputName ? inputName : model;

    if (invalidPath) return (
      <BaseErrorMessage>Invalid spath specified. Please use a path in state.</BaseErrorMessage>
    );

    return (
      <div className={this.props.className}>
        <FormAttibuteContext.Consumer>
          {attr => (
            <>
              {label &&
                <BaseLabelStyle required={required} htmlFor={id ? id : nameToUse}>
                  {boldLabel &&
                    <strong>
                      {label}
                    </strong>
                  }
                  {!boldLabel && label}
                </BaseLabelStyle>
              }
              {!hideValue &&
                <BaseInputStyle
                  type='time'
                  disabled={disabled || attr.disabled}
                  data-testid={testingId ? testingId : (id ? id : nameToUse)}
                  value={valueToUse}
                  style={inputStyle}
                  min={min}
                  max={max}
                  className={error ? 'txt ng-invalid ng-touched' : 'txt'}
                  onBlur={(e) => { this.setValue(e, true); if (this.props.onBlur) this.props.onBlur(e); }}
                  onChange={(e) => this.setValue(e, false)}
                />
              }

              {hideValue && <BaseInputStyle readOnly disabled />}

              {error && !disabled &&
                <BaseErrorMessage>
                  {error}
                </BaseErrorMessage>
              }
            </>
          )}
        </FormAttibuteContext.Consumer>
      </div>
    )
  }
}
