import React, { useEffect, useRef } from 'react';
import { dark, IonicDarkTheme, IonicLightTheme, light } from '../../theme';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BODY_FONT } from '../../constants';
import ThemeService from '../../services/ThemeService';

const Global = createGlobalStyle<{ excludeScrollbar?: boolean }>`
    ${props => {
        return props.theme.name === 'Light' ? IonicLightTheme : IonicDarkTheme
    }}

    #webpack-dev-server-client-overlay {
        display: none;
    }

    .chakra-select__content {
        max-height: 15rem;
    }

    * {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

    ${props => !props.excludeScrollbar && `
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
            scrollbar-width: 5px;
            scrollbar-color: ${props.theme.secondary} #ffffff;
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 5px;
            background: rgba(0,0,0,0);
        }

        *::-webkit-scrollbar-track {
            background: rgba(0,0,0,0);
        }

        *::-webkit-scrollbar-thumb {
            background-color: ${props.theme.secondary};
            border-radius: 5px;
        }
    `}

    body, html {
        ${BODY_FONT}
        font-size: 18px;
        line-height: 30px;
    }

    :root {
        --ion-${BODY_FONT}
    }

    .chakra-badge {
        text-transform: uppercase;
        font-weight: 700;
    }

    [data-scope="select"][data-part="item-group-label"] {
        font-weight: bold;
    }

    [data-scope="radio-group"][data-part="item-control"] {
        border-color: ${props => props.theme.secondary};
    }

    [data-scope="radio-group"][data-part="item-control"][data-state="checked"] {
        background-color: ${props => props.theme.secondary};
    }

    .chakra-input__left-addon {
        border-color: #aeaeae !important;
    }

    ion-button {
        --border-radius: 0rem !important;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        font-size: 0.8rem;
        ${BODY_FONT}

        &.button-small {
            --padding-top: 0.5rem;
            --padding-bottom: 0.5rem;
        }

        &.button-outline-inverse {
            --ion-color-base: #FFF !important;
        }
    }

    @media print {

        @page {
          size: A4 landscape;
        }
    
        html, body {
          height: 200px; 
          overflow: hidden;   
        }
    
        body * {
            visibility: hidden;
        }
        #section-to-print, #section-to-print * {
            visibility: visible;
        }
        #section-to-print {
            -webkit-print-color-adjust: exact !important;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
`;

interface ComponentProps {
    children: React.ReactNode;
    excludeScrollbar?: boolean;
}

const ThemeWrapper = ({ children, excludeScrollbar }: ComponentProps) => {
    const [darkTheme, setDarkTheme] = React.useState(false);
    const darkThemeRef = useRef(false);

    useEffect(() => {
        ThemeService.setSwitcher(switchTheme)
    }, [])

    const switchTheme = () => {
        setDarkTheme(!darkThemeRef.current);
        darkThemeRef.current = !darkThemeRef.current;
    }

    return <ThemeProvider theme={darkTheme ? dark : light}>
        <Global excludeScrollbar={excludeScrollbar} />
        {children}
    </ThemeProvider>;
};

export default ThemeWrapper;