import React from 'react';
import { ButtonBack, ButtonOutline, ButtonTime, FormRow, InfoMessage, Message, SectionTitle, TimeRow } from '../../../booking.styles';
import { StyledDropdown } from '../../../../theme/input.styles';
import { ApiBookingTimesWidget, ApiBusinessBookingDetails, DateAndTimeSlotsWidget, TimeSlotWidget } from '../../../../api/api-definitions';
import { DropdownItem } from '../../../../components/Forms/Dropdown';
import BookingExperienceOption from './BookingExperienceOption';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import Loader from '../../../../components/Layout/Loader';
import Icon from '../../../../components/Media/Icon';
import { Column } from '../../../../components/Layout/Grid';
import moment, { Moment } from 'moment';
import { formatDate, TIMEFORMAT } from '../../../../utils/date-helpers';
import { BookingFormData, desktopSize, mobileSize, tabletSize } from '..';
import { getMaximumPartyForEvent } from '../utils';
import TimeSlotsWithDescriptions from './TimeSlotsWithDescriptions';

interface ComponentProps {
    nextAvail: ApiBookingTimesWidget;
    nextAvailLoading: boolean;
    business: ApiBusinessBookingDetails;
    eventGuests: number;
    previewLocation: string;
    loading: boolean;
    searchableEvent: string;
    nextAvailLoadingMore: boolean;
    nextAvailEndOfResults: boolean;
    eventBreaksGuestRules: boolean;
    nextAvailHasMultipleShifts: DateAndTimeSlotsWidget;
    formData: BookingFormData;
    generatePeopleList: (limit: number) => DropdownItem[];
    setEventGuests: (value: React.SetStateAction<number>) => void;
    nextAvailSearch: (experienceGuid?: string, guestsOverride?: number, isEventSearch?: boolean, nextPage?: boolean) => void;
    setFormData: (value: React.SetStateAction<BookingFormData>) => void;
    setSelectedTimeDropdown: (value: React.SetStateAction<string>) => void;
    hold: (date?: Moment, time?: string, slot?: TimeSlotWidget) => void;
    setNextAvail: (value: React.SetStateAction<ApiBookingTimesWidget>) => void;
    changeTab: (index: number) => void;
    showExploreTab: boolean;
}

const ExperienceTab = ({
    nextAvail,
    nextAvailLoading,
    business,
    eventGuests,
    previewLocation,
    loading,
    searchableEvent,
    eventBreaksGuestRules,
    nextAvailEndOfResults,
    nextAvailLoadingMore,
    nextAvailHasMultipleShifts,
    formData,
    generatePeopleList,
    setEventGuests,
    nextAvailSearch,
    setFormData,
    setSelectedTimeDropdown,
    hold,
    setNextAvail,
    changeTab,
    showExploreTab
}: ComponentProps) => {
    const eventOrExperienceDetails = searchableEvent ? business?.specialEvents[searchableEvent] || business?.experiences[searchableEvent] : undefined;
    const defaultMaximumParty = 30;

    const maxPeopleForEvents = () => {
        let max = 0;
        [...Object.keys(business.specialEvents || {}), ...Object.keys(business.experiences || {})].forEach(key => {
            const eventMax = getMaximumPartyForEvent(key, business);
            if (!eventMax && defaultMaximumParty > max) max = defaultMaximumParty;
            if (eventMax > max) max = eventMax;
        });
        if (max == 0) return defaultMaximumParty;
        return max;
    }

    const invalidEvent = searchableEvent && (!eventOrExperienceDetails || !eventOrExperienceDetails?.name);

    const handleBackButton = () => {
        setNextAvail(undefined);
        const tabToSelect = showExploreTab ? 1 : 0;
        changeTab(tabToSelect);
    }
    return (
        <>
            {!nextAvail && !nextAvailLoading &&
                <>
                    <FormRow widgetTheme={business?.theme}>
                        <StyledDropdown
                            icon='users'
                            model='guests'
                            value={eventGuests}
                            addDefault={false}
                            items={generatePeopleList(maxPeopleForEvents())}
                            onChange={(e) => setEventGuests(+e.target.value)}
                            unlink
                        />
                    </FormRow>
                    {business?.specialEvents && Object.keys(business?.specialEvents).length > 0 &&
                        <>
                            <SectionTitle widgetTheme={business?.theme}>Our events</SectionTitle>
                        </>
                    }
                    {business?.specialEvents && Object.keys(business?.specialEvents).map((key: string) => (
                        <BookingExperienceOption
                            guid={key}
                            key={'event-' + key}
                            disabled={!isNullOrWhitespace(previewLocation)}
                            layout={business?.specialEvents[key].layout}
                            business={business}
                            location={business?.specialEvents[key].location}
                            title={business?.specialEvents[key].name}
                            description={business?.specialEvents[key].description}
                            image={business?.specialEvents[key].imageUrl}
                            menuUrl={business?.specialEvents[key].menuUrl}
                            price={business?.specialEvents[key].price}
                            onSearch={() => nextAvailSearch(key, eventGuests, true)}
                            type={business?.specialEvents[key].type}
                        />
                    ))}
                    {business?.experiences && Object.keys(business?.experiences).length > 0 &&
                        <SectionTitle widgetTheme={business?.theme}>Our experiences</SectionTitle>
                    }
                    {business?.experiences && Object.keys(business?.experiences).map((key: string) => (
                        <BookingExperienceOption
                            guid={key}
                            key={'event-' + key}
                            business={business}
                            layout={business?.experiences[key].layout}
                            location={business?.experiences[key].location}
                            title={business?.experiences[key].name}
                            image={business?.experiences[key].imageUrl}
                            menuUrl={business?.experiences[key].menuUrl}
                            description={business?.experiences[key].description}
                            price={business?.experiences[key].price}
                            onSearch={() => nextAvailSearch(key, eventGuests, true)}
                            type={business?.experiences[key].type}
                        />
                    ))}
                </>
            }
            {nextAvailLoading && <Loader />}
            {nextAvail && !loading && !nextAvailLoading && invalidEvent &&
                <>
                    <InfoMessage widgetTheme={business?.theme}>
                        The requested experience is not open for bookings.
                    </InfoMessage>
                    <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => handleBackButton()}>
                        <Icon name='arrow-left' duo doNotStyleDuo /> Back to booking calendar
                    </ButtonBack>
                </>
            }
            {nextAvail && !loading && !nextAvailLoading && (!searchableEvent || !invalidEvent) &&
                <>
                    {nextAvail && searchableEvent &&
                        <div style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                            <br />
                            <strong>Next availability for {eventOrExperienceDetails?.name}</strong>
                        </div>
                    }
                    <FormRow widgetTheme={business?.theme}>
                        <StyledDropdown
                            icon='users'
                            model='guests'
                            value={eventGuests}
                            addDefault={false}
                            items={generatePeopleList(maxPeopleForEvents())}
                            onChange={(e) => {
                                if (+e.target.value != eventGuests) nextAvailSearch(searchableEvent, +e.target.value)
                            }}
                            unlink
                        />
                    </FormRow>
                    <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => handleBackButton()}>
                        <Icon name='arrow-left' duo doNotStyleDuo /> Back
                    </ButtonBack>
                    {eventBreaksGuestRules &&
                        <>
                            <InfoMessage marginTop widgetTheme={business?.theme}>
                                For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                            </InfoMessage>
                            <br />
                        </>
                    }
                    {!eventBreaksGuestRules && nextAvail.availability?.length === 0 &&
                        <>
                            <InfoMessage widgetTheme={business?.theme}>
                                There is no availability{searchableEvent && <> for <strong>{eventOrExperienceDetails?.name}</strong></>} in the near future. Please check again later.
                            </InfoMessage>
                            <br />
                        </>
                    }
                    {!eventBreaksGuestRules && nextAvail.availability.map((slot, index) => (
                        <>
                            <Message widgetTheme={business?.theme}>
                                <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                            </Message>
                            <br />
                            <TimeSlotsWithDescriptions
                                index={index}
                                keyName='event-avail'
                                slot={slot}
                                desktopSize={desktopSize}
                                tabletSize={tabletSize}
                                mobileSize={mobileSize}
                                setFormData={setFormData}
                                setSelectedTimeDropdown={setSelectedTimeDropdown}
                                hold={hold}
                                business={business}
                                formData={formData}
                                eventGuests={eventGuests}
                                eventAvailiabilitySearch={true}>
                            </TimeSlotsWithDescriptions>
                        </>
                    ))}
                    {!nextAvailLoadingMore && !nextAvailEndOfResults && nextAvail.availability.length > 4 &&
                        <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch(searchableEvent, eventGuests, true, true)}>
                            <Icon name='search' /> Show more results
                        </ButtonOutline>
                    }
                    {nextAvailEndOfResults && <InfoMessage widgetTheme={business?.theme}>No more availability found.</InfoMessage>}
                    {nextAvailLoadingMore && <Loader />}
                    <br />
                </>
            }
        </>
    );
};

export default ExperienceTab;