import { Badge } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from 'styled-components';

type colorOptions = "primary" | "secondary" | "danger" | "messenger" | "border" | "bg" | "current" | "transparent" | "black" | "white" | "whiteAlpha" | "blackAlpha" | "gray" | "red" | "orange" | "yellow" | "green" | "teal" | "blue" | "cyan" | "purple" | "pink";
interface ComponentProps {
    colorPalette: colorOptions;
    children: React.ReactNode;
    className?: string;
}

const CoreBadge = ({ children, colorPalette, className }: ComponentProps) => {
    const theme = useTheme();

    function getProps(colorPalette: colorOptions) {
        if (colorPalette == 'cyan') return { color: '#086F83', backgroundColor: '#C4F1F9' };
        if (colorPalette == 'messenger') return { color: '#35546c', backgroundColor: '#bee2f8' };
        if (colorPalette == 'primary') return { color: theme.primaryContrast, backgroundColor: theme.primary };
        if (colorPalette == 'secondary') return { color: theme.secondaryContrast, backgroundColor: theme.secondary };
        if (colorPalette == 'danger') return { color: theme.negativeContrast, backgroundColor: theme.negative };
        return {
            colorPalette
        }
    }

    return (
        <Badge {...getProps(colorPalette)} className={className}>{children}</Badge>
    );
};

export default CoreBadge;