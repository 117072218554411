import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { ApiService } from '../../../../../../api/api-connectors';
import { AllergenStatus, BaseDish, BaseMenu, BaseMenuSection, GenericResponse, SpiceLevel } from '../../../../../../api/api-definitions';
import FoodResult from '../../../../../../components/Cta/FoodResult';
import CoreButton from '../../../../../../components/Forms/Button';
import BoxOutline from '../../../../../../components/Layout/BoxOutline';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import Loader from '../../../../../../components/Layout/Loader';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import Icon from '../../../../../../components/Media/Icon';
import { useMergeState } from '../../../../../../hooks/useMergeState';
import { NotificationService } from '../../../../../../services/NotificationService';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import DashboardFoodItem from './DashboardFoodItem';
import MenuItemForm from './MenuItemForm';
import QrReference from '../QrReference';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import MenuDetailsForm from './MenuDetailsForm';
import ImportMenu from './InportMenu';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import NewSectionForm from './NewSectionForm';
import { MenuSectionDescriptionSize, MenuSectionNameSize } from '../../../../../../constants';
import { InfoMessage } from '../../../../../../components/Forms/Messaging';
import { StyledTextInput, StyledTextarea } from '../../../../../../theme/input.styles';
import { DATABASE_TIME_FORMAT, formatDate } from '../../../../../../utils/date-helpers';

const addStyle = (props: any) => `
    border: 2px dashed ${props.theme.primary};
    color: ${props.theme.primary};
    font-size: 1.2rem;
    text-align: center;
    cursor: pointer;

    &:hover {
        border: 2px solid ${props.theme.primary};
    }
`;

const AddSectionButton = styled(BoxOutline)`
    ${props => addStyle(props)}
`;

const AddMenuItemButton = styled.div`
  ${props => addStyle(props)}
  width: 100%;
  height: 100%;
  line-height: 2.5rem;
`;

const SectionWrapper = styled(Row)`
  position: relative;
`;

const SectionButtonWrapper = styled.div`

`;

const SectionButton = styled.span<{ active?: boolean }>`
    border: 1px solid ${props => props.theme.primary};
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    display: inline-block;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.primaryContrast};
    }

    ${props => props.active ? `
        background-color: ${props.theme.primary};
        color: ${props.theme.primaryContrast};
    ` : null}
`

interface ComponentState {
    dishData?: BaseDish;
    dishDataOpen?: boolean;
}

const ViewMenu = () => {
    const params: any = useParams();
    const parentBusinessId = params.parentId;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [dishSaving, setDishSaving] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [state, setState] = useMergeState<ComponentState>({});
    const [filteredSection, setFilteredSection] = useState<number>();
    const [menu, setMenu] = useState<BaseMenu>();
    const history = useHistory();
    const { dishDataOpen, dishData } = state;
    const initialMenuState = useRef<BaseMenu>();
    
    const hasChanges = JSON.stringify({
        ...(initialMenuState.current || {}),
        menuSections: undefined,
        validFrom: initialMenuState.current?.validFrom || null,
        validTo: initialMenuState.current?.validTo || null,
    }) != JSON.stringify({
        ...(menu || {}),
        menuSections: undefined,
        validFrom: menu?.validFrom || null,
        validTo: menu?.validTo || null,
    });

    useEffect(() => {
        loadMenu();
    }, [])

    const loadMenu = () => {
        if (menu) setMenu(undefined);
        ApiService.menu.Get__GET(params.menuid).then(response => {
            setMenu(response)
            initialMenuState.current = cloneDeep(response);
        })
    }

    if (!menu || loading) return <Loader />;

    const updateItem = (item: BaseDish) => {
        const newMenu = cloneDeep(menu);
        const menuSection = newMenu.menuSections.find(x => x.id == item.menuSectionId);
        const existing = menuSection.dishes.find(x => x.id === item.id);
        Object.assign(existing, item);
        setMenu(newMenu);
    }

    const insertMenuItem = (item: BaseDish, keepOpen: boolean) => {
        setDishSaving(true);
        ApiService.dish.Add__PUT(item).then((response) => {
            const newMenu = cloneDeep(menu);
            const menuSection = newMenu.menuSections.find(x => x.id == item.menuSectionId);
            item.id = parseInt(response.info);
            menuSection.dishes.push(item);
            setMenu(newMenu);
            setState({
                dishDataOpen: false,
                dishData: null
            });
            if (keepOpen) {
                setState({
                    dishDataOpen: true,
                    dishData: {
                        menuSectionId: item.menuSectionId,
                        menuId: item.menuId,
                        businessId: null,
                        parentBusinessId: item.parentBusinessId,
                        name: ''
                    } as BaseDish
                })
            }
            setDishSaving(false)
            NotificationService.Confirm('Saved dish info')
        })
    }

    const addMenuSection = (newSection: BaseMenuSection) => {
        const newMenu = cloneDeep(menu);
        if (!newMenu.menuSections) newMenu.menuSections = [];
        newMenu.menuSections.push(newSection);
        setFilteredSection(newSection.id)
        setMenu(newMenu)
    }

    const updateFormData = (newData: BaseMenu) => {
        if (loading) return;
        const newMenu = cloneDeep(menu);
        Object.assign(newMenu, newData);
        setMenu(newMenu)
    }

    const saveData = () => {
        setSaving(true)
        if (!isNullOrWhitespace(menu.validFrom)) formatDate(menu.validFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(menu.validTo)) formatDate(menu.validTo, DATABASE_TIME_FORMAT);
        ApiService.menu.Save__POST(menu).then((response) => {
            if (response.success) {
                initialMenuState.current = cloneDeep(menu);
            }
            NotificationService.ConfirmOrError('Saved menu details', 'Failed to save details', response.success);
        }).catch(() => {
            NotificationService.Error('Failed to save details');
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteMenu = () => {
        setLoading(true);
        ApiService.menu.Delete__DELETE(menu).then((response) => {
            NotificationService.ConfirmOrError('Menu deleted', 'Failed to delete menu', response.success);
            history.push(`/dashboard/${parentBusinessId}/menus`)
        }).catch(() => {
            NotificationService.Error('Failed to delete menu');
        })
    }

    const updateMenuSection = (index, property, value) => {
        const newMenu = cloneDeep(menu);
        newMenu.menuSections[index][property] = value;
        ApiService.menuSection.Save__POST(newMenu.menuSections[index]).then((response: GenericResponse) => {
            setMenu(newMenu)
            if (!response.success) NotificationService.Error('Failed to save changes');
        }).catch(() => {
            NotificationService.Error('Failed to save changes');
        })
    }

    return (
        <>
            <DashboardHeader icon='book-open' title='Menu management'></DashboardHeader>
            <MenuItemForm
                onClose={() => setState({ dishDataOpen: false })}
                loading={dishSaving}
                isOpen={dishDataOpen}
                dishData={dishData}
                onSave={insertMenuItem}
            />

            {menu &&
                <MenuDetailsForm
                    menu={menu}
                    updateMenu={updateFormData}
                    saveMenu={saveData}
                    deleteMenu={deleteMenu}
                    hasChanges={hasChanges}
                    saving={saving}
                />
            }

            <TabBar>
                <TabButton active={tabIndex === 0} onClick={() => setTabIndex(0)}><Icon name='th-large' /> Simple view</TabButton>
                <TabButton active={tabIndex === 1} onClick={() => setTabIndex(1)}><Icon name='search' /> Preview</TabButton>
                <TabButton active={tabIndex === 2} onClick={() => setTabIndex(2)}><Icon name='qrcode' /> QR Code</TabButton>
            </TabBar>
            {tabIndex === 0 &&
                <>
                    <ImportMenu setLoading={setLoading} loadMenu={loadMenu} menu={menu} />

                    <DashboardPanel title='Filter by section'>
                        <SectionButtonWrapper>
                            {menu.menuSections.length == 0 &&
                                <InfoMessage>You have no menu sections. Menu will not be visible until there is at least 1 section and 1 menu item.</InfoMessage>
                            }
                            {menu.menuSections.length > 0 &&
                                <SectionButton onClick={() => setFilteredSection(undefined)} active={isNullOrWhitespace(filteredSection)}>
                                    View all
                                </SectionButton>
                            }
                            {menu.menuSections && menu.menuSections.map((section, sectionIndex) => (
                                <SectionButton onClick={() => setFilteredSection(section.id)} active={filteredSection === section.id}>
                                    {section.name}
                                </SectionButton>
                            ))}
                        </SectionButtonWrapper>
                    </DashboardPanel>

                    {menu.menuSections && menu.menuSections.filter(x => filteredSection !== undefined ? x.id === filteredSection : true).map((section, sectionIndex) => (
                        <DashboardPanel title={!filteredSection && section.name} options={<>
                            <CoreButton type='danger' full onClick={() => { }}>
                                Delete section <Icon name='trash-alt' />
                            </CoreButton>
                        </>}>
                            <SectionWrapper>
                                <Column size={6}>
                                    <StyledTextInput max={MenuSectionNameSize} value={section.name} required label='Section name' onBlur={(e) => updateMenuSection(sectionIndex, 'name', e.target.value)} inputName={`sectionName${sectionIndex}`} />
                                </Column>
                                <Column size={6}>
                                    <StyledTextarea model='description' rows={3} maxLength={MenuSectionDescriptionSize} label='Description' value={section.description} onBlur={(e) => updateMenuSection(sectionIndex, 'description', e.target.value)} inputName={`sectionDescription${sectionIndex}`} />
                                </Column>
                                {section.dishes && section.dishes.map((dish, dishIndex) => (
                                    <Column size={6} key={dishIndex}>
                                        <DashboardFoodItem
                                            dish={dish}
                                            onEdit={updateItem}
                                        />
                                    </Column>
                                ))}
                                <Column size={12}>
                                    <DashboardAddButton onClick={() => setState({ dishData: { menuSectionId: section.id, menuId: menu.id, businessId: null, parentBusinessId } as BaseDish, dishDataOpen: true })}>
                                        Add menu item
                                    </DashboardAddButton>
                                </Column>
                            </SectionWrapper>
                        </DashboardPanel>
                    ))}
                    <NewSectionForm onSubmit={addMenuSection} defaultData={{
                        name: '',
                        description: '',
                        dishes: [],
                        id: 0,
                        menuId: menu.id,
                        businessId: null,
                        parentBusinessId: parentBusinessId,
                        sortOrder: menu.menuSections.length
                    }} />
                </>
            }
            {tabIndex === 1 && menu.menuSections && menu.menuSections.map((section, sectionIndex) => (
                <DashboardPanel key={sectionIndex} title={section.name}>
                    <Row>
                        {section.dishes.map((dish, dishIndex) => (
                            <FoodResult
                                dish={dish}
                                place={''}
                                menuName={menu.name}
                                sectionName={section.name}
                                key={dishIndex}
                                currencyCode='GBP'
                                size={4}
                            />
                        ))}
                    </Row>
                </DashboardPanel>
            ))}
            {tabIndex === 2 &&
                <DashboardPanel>
                    <QrReference locationId={menu.id.toString()} />
                </DashboardPanel>
            }
        </>
    );
};

export default ViewMenu;