import { cloneDeep } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { BaseDishTagLink, ObjectStates } from '../../../../../api/api-definitions';
import Checkbox from '../../../../../components/Forms/Checkbox';
import Icon from '../../../../../components/Media/Icon';
import { H3 } from '../../../../../components/Typography/Headings';
import { BaseAllergenTags, BaseDietTags } from '../../../../../constants';
import Tag from '../../../../../components/Forms/Tags/Tag';

interface ComponentProps {
    dishId: number;
    onUpdate: Function;
    updateAllergenToggle: (value: boolean) => void;
    data: BaseDishTagLink[];
    dishAllergenDetailsProvided: boolean;
}

const DishTagList = ({ dishId, onUpdate, data, dishAllergenDetailsProvided, updateAllergenToggle }: ComponentProps) => {
    if (!data) data = [];

    function onClick(index) {
        const tags = cloneDeep(data);
        const foundTag = tags.find(x => x.tagType === index);
        if (foundTag && foundTag.state !== ObjectStates.Deleted) {
            if (foundTag.id == null || foundTag.id === undefined) {
                onUpdate(tags.filter(x => x.tagType !== index));
            } else {
                foundTag.state = ObjectStates.Deleted;
                onUpdate(tags);
            }
        } else {
            if (foundTag) {
                foundTag.state = ObjectStates.Unchanged;
            } else {
                tags.push({
                    tagType: index,
                    dishId,
                    state: ObjectStates.Added
                });
            }
            onUpdate(tags);
        }
    }
    const allergens = Object.keys(BaseAllergenTags);
    const diet = Object.keys(BaseDietTags);
    
    return (
        <div>
            <H3 marginBottom={0} textCenter={false}>Dietary</H3>
            <div>
                {diet.map(key => (
                    <Tag key={key} onClick={() => onClick(key)} selected={!!data.find(x => x.tagType === key && x.state !== ObjectStates.Deleted)}>
                        {BaseDietTags[key].icon ? <Icon name={BaseDietTags[key].icon} /> : ''}{BaseDietTags[key].name}
                    </Tag>
                ))}
            </div>
            <H3 marginBottom={0} textCenter={false}>Allergens</H3>
            <Checkbox asToggle label="Provide allergen details" model='dishAllergenDetailsProvided' checked={dishAllergenDetailsProvided} onChange={() => updateAllergenToggle(!dishAllergenDetailsProvided)} />
            <div>
                <br/>
                {allergens.map(key => (
                    <Tag key={key} disabled={!dishAllergenDetailsProvided} onClick={() => onClick(key)} selected={!!data.find(x => x.tagType === key && x.state !== ObjectStates.Deleted)}>
                        {BaseAllergenTags[key].icon ? <Icon name={BaseAllergenTags[key].icon} /> : ''}{BaseAllergenTags[key].name}
                    </Tag>
                ))}
            </div>
        </div>
    );
};

export default DishTagList;