import React from 'react';
import { ApiBusinessBookingDetails, DepositCancellationDurationType, DepositPaymentType, HeldBookingResponse } from '../../../../api/api-definitions';
import { InfoMessage } from '../../../../components/Forms/Messaging';
import { depositUponMakeBookingRequired, noShowOrCancellationPaymentRequired, noShowPaymentRequired } from '../utils';
import { Table } from '@chakra-ui/react';
import styled from 'styled-components';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import { Column, Row } from '../../../../components/Layout/Grid';
import Checkbox from '../../../../components/Forms/Checkbox';

interface ComponentProps {
    holdDetails: HeldBookingResponse;
    hideNextScreenWording?: boolean;
    nameToDisplay?: string;
    business: ApiBusinessBookingDetails;
    setDepositError?: (error: boolean) => void;
}
const DepositInfo = ({ holdDetails, hideNextScreenWording, nameToDisplay, business, setDepositError }: ComponentProps) => {

    const formattedDeposit = ConvertCurrency({ code: business.currencyCode, amount: holdDetails?.depositAmount });
    const formattedBookingFee = ConvertCurrency({ code: business.currencyCode, amount: holdDetails?.bookingFee });
    const frequency = holdDetails?.cancellationDurationType === DepositCancellationDurationType.Days ? 'days' : 'hours';

    const getCancellationPolicy = () => {
        let cancellationPolicyBuilder = '';

        if (holdDetails.depositAmount > 0) {
            if (holdDetails.paymentType === DepositPaymentType.TakeNow) {
                if (holdDetails.cancellationDuration > 0 &&
                    holdDetails.cancellationDurationType !== DepositCancellationDurationType.Unknown) {
                    cancellationPolicyBuilder += `The deposit amount of ${formattedDeposit} will be refunded if the booking is cancelled at least ${holdDetails.cancellationDuration} ${frequency} in advance.\n`;
                }
            } else if (holdDetails.paymentType === DepositPaymentType.TakeOnCancelAndNoShow) {
                if (holdDetails.cancellationDuration > 0 &&
                    holdDetails.cancellationDurationType !== DepositCancellationDurationType.Unknown) {
                    cancellationPolicyBuilder += `The deposit amount of ${formattedDeposit} will be charged in case of no show or if the booking is cancelled less than ${holdDetails.cancellationDuration} ${frequency} in advance.\n`;
                }
            } else if (holdDetails.paymentType === DepositPaymentType.TakeOnNoShow) {
                if (holdDetails.cancellationDuration > 0 &&
                    holdDetails.cancellationDurationType !== DepositCancellationDurationType.Unknown) {
                    cancellationPolicyBuilder += `The deposit amount of ${formattedDeposit} will be charged in case of no show.\n`;
                }
            }
        }

        if (holdDetails.bookingFee > 0) {
            cancellationPolicyBuilder += `Please note, the booking fee of ${formattedBookingFee} is non-refundable.\n`;
        }

        return cancellationPolicyBuilder;
    }

    const depositPolicyDescription = getCancellationPolicy();

    return (
        <div>
            {noShowPaymentRequired(holdDetails) &&
                <>
                    <br />
                    <InfoMessage title='No show charges apply for this booking.'>
                        {!hideNextScreenWording && 'You will be asked for your card details on the next screen. '}The no show charge for this booking is: {formattedDeposit}.
                    </InfoMessage>
                </>
            }
            {noShowOrCancellationPaymentRequired(holdDetails) &&
                <>
                    <br />
                    <InfoMessage title='Cancellation or no show charges apply for this booking.'>
                        {!hideNextScreenWording && 'You will be asked for your card details on the next screen. '}No shows and cancellations made less than {holdDetails.cancellationDuration} {frequency} in advance are subject to a fee of {formattedDeposit}.
                    </InfoMessage>
                </>
            }
            {depositUponMakeBookingRequired(holdDetails) &&
                <>
                    <br />
                    <InfoMessage title='A deposit is required for this booking.'>
                        {!hideNextScreenWording && 'You will be asked for your card details on the next screen. '}
                    </InfoMessage>
                    <TableWrapper>
                        <strong>Payment summary</strong>
                        <Table.Root size="sm" variant="outline">
                            <Table.ColumnGroup>
                                <Table.Column htmlWidth="80%" />
                                <Table.Column />
                            </Table.ColumnGroup>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeader></Table.ColumnHeader>
                                    <Table.ColumnHeader textAlign="end">Price</Table.ColumnHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{nameToDisplay} deposit</Table.Cell>
                                    <Table.Cell textAlign="end">{formattedDeposit}</Table.Cell>
                                </Table.Row>
                                {holdDetails.bookingFee > 0 &&
                                    <Table.Row>
                                        <Table.Cell>Booking fee</Table.Cell>
                                        <Table.Cell textAlign="end">{formattedBookingFee}</Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row fontWeight='bold'>
                                    <Table.Cell>Total</Table.Cell>
                                    <Table.Cell textAlign="end">{ConvertCurrency({ code: business.currencyCode, amount: holdDetails.depositAmount + holdDetails.bookingFee })}</Table.Cell>
                                </Table.Row>
                            </Table.Footer>
                        </Table.Root>
                    </TableWrapper>
                </>
            }
            {!!depositPolicyDescription && !hideNextScreenWording &&
                <>
                    <br />
                    <Row>
                        <Column size={12} mobile={12}>
                            <Checkbox onChange={e => setDepositError ? setDepositError(!e.target.checked) : undefined} model='depositPolicy' required inputName='depositPolicy' label={<><strong>Deposit policy acknowledgement: </strong>{depositPolicyDescription}</>} />
                        </Column>
                    </Row>
                </>
            }
        </div>
    );
};

const TableWrapper = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;

    strong {
        margin-bottom: 0.3rem;
        display: block;
    }
`

export default DepositInfo;