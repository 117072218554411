import React from 'react';
import { BaseActiveBooking, ExperienceType, SpecialOccasion } from '../../../../../../api/api-definitions';
import styled from 'styled-components';
import CoreBadge from '../../../../../../components/CoreBadge';
import Icon from '../../../../../../components/Media/Icon';
import { Column } from '../../../../../../components/Layout/Grid';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import { createMomentFromValue, DISPLAY_DATE_TIME_FORMAT, formatDate, getDurationText, minutesToTime, TIMEFORMAT } from '../../../../../../utils/date-helpers';
import { BookingSpecialOccasions } from '../../../../../../constants';
import { ExtendedBookingManagementResponse } from '..';
import { cloneDeep } from 'lodash';

interface ComponentProps {
    booking: BaseActiveBooking;
    bookingManagementData: ExtendedBookingManagementResponse;
}

const BookingInfoSummary = ({ booking, bookingManagementData }: ComponentProps) => {
    const momentStart = createMomentFromValue(booking.startDateTime);
    const momentEndWithoutChangeover = createMomentFromValue(booking.endDateTimeWithoutChangeover);
    const minutesAndHours = minutesToTime(booking.bookingDurationInMinutes);
    const durationInMinutesAndHours = getDurationText(minutesAndHours);

    return (
        <>
            <Column size={4} tablet={6} mobile={12}><IconContainer><Icon name='calendar' duo /></IconContainer>{momentStart.format('DD MMM YYYY')}</Column>
            <Column size={4} tablet={6} mobile={12}><IconContainer><Icon name='clock' duo /></IconContainer>{momentStart.format(TIMEFORMAT)} to {momentEndWithoutChangeover.format(TIMEFORMAT)} ({durationInMinutesAndHours})</Column>
            <Column size={4} tablet={6} mobile={12}><IconContainer><Icon name='users' duo /></IconContainer>{booking.guests} cover{booking.guests > 1 ? 's' : ''}</Column>
            <Column size={4} tablet={6} mobile={12}><IconContainer><Icon name='dining-table' /></IconContainer>
                {booking.tables.length > 0 && <>Table{booking.tables.length > 1 ? 's' : ''} {booking.tables.map(tableId => cloneDeep(bookingManagementData.tables).find(table => table.id === tableId)?.tableName).join(', ')}</>}
                {booking.tables.length === 0 && <WarningMessage>No tables are allocated for this booking</WarningMessage>}
            </Column>
            <Column size={4} tablet={6} mobile={12}><strong>Booked at:</strong> {formatDate(booking.bookedAtInBusinessTimezone, DISPLAY_DATE_TIME_FORMAT)}</Column>
            {!!booking.cancelledDateInBusinessTimezone && <Column size={4} tablet={6} mobile={12}><strong>Cancelled at:</strong> {formatDate(booking.cancelledDateInBusinessTimezone, DISPLAY_DATE_TIME_FORMAT)}</Column>}
            {booking.specialOccasion != SpecialOccasion.NotSet &&
                <Column size={4} tablet={6} mobile={12}>
                    <Icon name={BookingSpecialOccasions()[booking.specialOccasion].icon} /> <strong>{BookingSpecialOccasions()[booking.specialOccasion].label}</strong>
                </Column>
            }
            {!!booking.experienceId &&
                <Column size={4} tablet={6} mobile={12}>
                    <CoreBadge colorPalette='messenger'><Icon name={bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[booking.experienceId]?.type == ExperienceType.Event ? 'calendar' : 'sparkles'} /> {bookingManagementData?.upcomingExperiencesAndEvents?.eventIdAndInfo[booking.experienceId]?.name}</CoreBadge>
                </Column>
            }
        </>
    );
};

const IconContainer = styled.div`
    display: inline-block;
    width: 3rem;
`

export default BookingInfoSummary;