import React, { useContext } from 'react';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import styled from 'styled-components';
import { Radio } from '../../ui/radio';

interface RadioProps {
    model?: string;
    disabled?: boolean;
    label?: string;
    id?: string;
    value: string;
    unlink?: boolean;
    inputName?: string;
    className?: string;
    asButton?: boolean;
}

const EasyRadioInput = ({
    model,
    disabled,
    label,
    id,
    value,
    unlink,
    inputName,
    className,
    asButton
}: RadioProps) => {
    const context = useContext(FormContext);
    const nameToUse = inputName ? inputName : model;
    return (
        <div className={className}>
            <FormAttibuteContext.Consumer>
                {attr => (
                    <>
                        <RadioInput
                            hidden={asButton}
                            id={id || nameToUse}
                            disabled={disabled || attr.disabled}
                            value={value}>
                                {label}
                        </RadioInput>
                    </>
                )}
            </FormAttibuteContext.Consumer>
        </div>
    );
};

const RadioButton = styled.label<{selected?: boolean;}>`
    height: 3rem;
    width: 100%;
    line-height: 3rem !important;
    border: 1px solid ${props => props.theme.borderColor};
    text-align: center;
    cursor: pointer;
    border-radius: 0.5rem;

    ${props => props.selected && `
        background-color: ${props.theme.secondary};
        color: ${props.theme.secondaryContrast};
    `}

    ${props => !props.selected && `
        &:hover {
            background-color: #f7f7f7;
        }
    `}
`;

const RadioInput = styled(Radio)<{hidden?: boolean}>`
    ${props => props.hidden && `
        opacity: 0;
        width: 0;
        height: 0;
        user-select: none;
    `}
`

export default EasyRadioInput;