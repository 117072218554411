// This file is generated by @lib/ui/country/codegen/generateFlags.ts
import React, { Suspense } from 'react'

const countryFlagRecord: {[code: string]: React.LazyExoticComponent<() => JSX.Element>} = {
  AF: React.lazy(() => import('./AfFlag')),
  AL: React.lazy(() => import('./AlFlag')),
  DZ: React.lazy(() => import('./DzFlag')),
  AS: React.lazy(() => import('./AsFlag')),
  AD: React.lazy(() => import('./AdFlag')),
  AO: React.lazy(() => import('./AoFlag')),
  AI: React.lazy(() => import('./AiFlag')),
  AQ: React.lazy(() => import('./AqFlag')),
  AG: React.lazy(() => import('./AgFlag')),
  AR: React.lazy(() => import('./ArFlag')),
  AM: React.lazy(() => import('./AmFlag')),
  AW: React.lazy(() => import('./AwFlag')),
  AU: React.lazy(() => import('./AuFlag')),
  AT: React.lazy(() => import('./AtFlag')),
  AZ: React.lazy(() => import('./AzFlag')),
  BS: React.lazy(() => import('./BsFlag')),
  BH: React.lazy(() => import('./BhFlag')),
  BD: React.lazy(() => import('./BdFlag')),
  BB: React.lazy(() => import('./BbFlag')),
  BY: React.lazy(() => import('./ByFlag')),
  BE: React.lazy(() => import('./BeFlag')),
  BZ: React.lazy(() => import('./BzFlag')),
  BJ: React.lazy(() => import('./BjFlag')),
  BM: React.lazy(() => import('./BmFlag')),
  BT: React.lazy(() => import('./BtFlag')),
  BO: React.lazy(() => import('./BoFlag')),
  BA: React.lazy(() => import('./BaFlag')),
  BW: React.lazy(() => import('./BwFlag')),
  BV: React.lazy(() => import('./BvFlag')),
  BR: React.lazy(() => import('./BrFlag')),
  IO: React.lazy(() => import('./IoFlag')),
  BN: React.lazy(() => import('./BnFlag')),
  BG: React.lazy(() => import('./BgFlag')),
  BF: React.lazy(() => import('./BfFlag')),
  BI: React.lazy(() => import('./BiFlag')),
  KH: React.lazy(() => import('./KhFlag')),
  CM: React.lazy(() => import('./CmFlag')),
  CA: React.lazy(() => import('./CaFlag')),
  CW: React.lazy(() => import('./CwFlag')),
  XK: React.lazy(() => import('./XkFlag')),
  BL: React.lazy(() => import('./BlFlag')),
  SX: React.lazy(() => import('./SxFlag')),
  TW: React.lazy(() => import('./TwFlag')),
  CV: React.lazy(() => import('./CvFlag')),
  KY: React.lazy(() => import('./KyFlag')),
  CF: React.lazy(() => import('./CfFlag')),
  TD: React.lazy(() => import('./TdFlag')),
  CL: React.lazy(() => import('./ClFlag')),
  CN: React.lazy(() => import('./CnFlag')),
  CX: React.lazy(() => import('./CxFlag')),
  CC: React.lazy(() => import('./CcFlag')),
  CO: React.lazy(() => import('./CoFlag')),
  KM: React.lazy(() => import('./KmFlag')),
  CG: React.lazy(() => import('./CgFlag')),
  CK: React.lazy(() => import('./CkFlag')),
  CR: React.lazy(() => import('./CrFlag')),
  HR: React.lazy(() => import('./HrFlag')),
  CU: React.lazy(() => import('./CuFlag')),
  CY: React.lazy(() => import('./CyFlag')),
  CZ: React.lazy(() => import('./CzFlag')),
  DK: React.lazy(() => import('./DkFlag')),
  DJ: React.lazy(() => import('./DjFlag')),
  DM: React.lazy(() => import('./DmFlag')),
  DO: React.lazy(() => import('./DoFlag')),
  EC: React.lazy(() => import('./EcFlag')),
  EG: React.lazy(() => import('./EgFlag')),
  SV: React.lazy(() => import('./SvFlag')),
  GQ: React.lazy(() => import('./GqFlag')),
  ER: React.lazy(() => import('./ErFlag')),
  EE: React.lazy(() => import('./EeFlag')),
  ET: React.lazy(() => import('./EtFlag')),
  FK: React.lazy(() => import('./FkFlag')),
  FO: React.lazy(() => import('./FoFlag')),
  FJ: React.lazy(() => import('./FjFlag')),
  FI: React.lazy(() => import('./FiFlag')),
  FR: React.lazy(() => import('./FrFlag')),
  GF: React.lazy(() => import('./GfFlag')),
  PF: React.lazy(() => import('./PfFlag')),
  TF: React.lazy(() => import('./TfFlag')),
  GA: React.lazy(() => import('./GaFlag')),
  GM: React.lazy(() => import('./GmFlag')),
  GE: React.lazy(() => import('./GeFlag')),
  DE: React.lazy(() => import('./DeFlag')),
  GH: React.lazy(() => import('./GhFlag')),
  GI: React.lazy(() => import('./GiFlag')),
  GR: React.lazy(() => import('./GrFlag')),
  GL: React.lazy(() => import('./GlFlag')),
  GD: React.lazy(() => import('./GdFlag')),
  GP: React.lazy(() => import('./GpFlag')),
  GU: React.lazy(() => import('./GuFlag')),
  GT: React.lazy(() => import('./GtFlag')),
  GG: React.lazy(() => import('./GgFlag')),
  GN: React.lazy(() => import('./GnFlag')),
  GW: React.lazy(() => import('./GwFlag')),
  GY: React.lazy(() => import('./GyFlag')),
  HT: React.lazy(() => import('./HtFlag')),
  HM: React.lazy(() => import('./HmFlag')),
  VA: React.lazy(() => import('./VaFlag')),
  HN: React.lazy(() => import('./HnFlag')),
  HK: React.lazy(() => import('./HkFlag')),
  HU: React.lazy(() => import('./HuFlag')),
  IS: React.lazy(() => import('./IsFlag')),
  IN: React.lazy(() => import('./InFlag')),
  ID: React.lazy(() => import('./IdFlag')),
  IR: React.lazy(() => import('./IrFlag')),
  IQ: React.lazy(() => import('./IqFlag')),
  IE: React.lazy(() => import('./IeFlag')),
  IM: React.lazy(() => import('./ImFlag')),
  IL: React.lazy(() => import('./IlFlag')),
  IT: React.lazy(() => import('./ItFlag')),
  CI: React.lazy(() => import('./CiFlag')),
  JM: React.lazy(() => import('./JmFlag')),
  JP: React.lazy(() => import('./JpFlag')),
  JE: React.lazy(() => import('./JeFlag')),
  JO: React.lazy(() => import('./JoFlag')),
  KZ: React.lazy(() => import('./KzFlag')),
  KE: React.lazy(() => import('./KeFlag')),
  KI: React.lazy(() => import('./KiFlag')),
  KW: React.lazy(() => import('./KwFlag')),
  KG: React.lazy(() => import('./KgFlag')),
  LA: React.lazy(() => import('./LaFlag')),
  LV: React.lazy(() => import('./LvFlag')),
  LB: React.lazy(() => import('./LbFlag')),
  LS: React.lazy(() => import('./LsFlag')),
  LR: React.lazy(() => import('./LrFlag')),
  LY: React.lazy(() => import('./LyFlag')),
  LI: React.lazy(() => import('./LiFlag')),
  LT: React.lazy(() => import('./LtFlag')),
  LU: React.lazy(() => import('./LuFlag')),
  MO: React.lazy(() => import('./MoFlag')),
  MK: React.lazy(() => import('./MkFlag')),
  MG: React.lazy(() => import('./MgFlag')),
  MW: React.lazy(() => import('./MwFlag')),
  MY: React.lazy(() => import('./MyFlag')),
  MV: React.lazy(() => import('./MvFlag')),
  ML: React.lazy(() => import('./MlFlag')),
  MT: React.lazy(() => import('./MtFlag')),
  MH: React.lazy(() => import('./MhFlag')),
  MQ: React.lazy(() => import('./MqFlag')),
  MR: React.lazy(() => import('./MrFlag')),
  MU: React.lazy(() => import('./MuFlag')),
  YT: React.lazy(() => import('./YtFlag')),
  MX: React.lazy(() => import('./MxFlag')),
  FM: React.lazy(() => import('./FmFlag')),
  MD: React.lazy(() => import('./MdFlag')),
  MC: React.lazy(() => import('./McFlag')),
  MN: React.lazy(() => import('./MnFlag')),
  ME: React.lazy(() => import('./MeFlag')),
  MS: React.lazy(() => import('./MsFlag')),
  MA: React.lazy(() => import('./MaFlag')),
  MZ: React.lazy(() => import('./MzFlag')),
  MM: React.lazy(() => import('./MmFlag')),
  NA: React.lazy(() => import('./NaFlag')),
  NR: React.lazy(() => import('./NrFlag')),
  NP: React.lazy(() => import('./NpFlag')),
  NL: React.lazy(() => import('./NlFlag')),
  NC: React.lazy(() => import('./NcFlag')),
  NZ: React.lazy(() => import('./NzFlag')),
  NI: React.lazy(() => import('./NiFlag')),
  NE: React.lazy(() => import('./NeFlag')),
  NG: React.lazy(() => import('./NgFlag')),
  NU: React.lazy(() => import('./NuFlag')),
  NF: React.lazy(() => import('./NfFlag')),
  KP: React.lazy(() => import('./KpFlag')),
  GB: React.lazy(() => import('./GbFlag')),
  MP: React.lazy(() => import('./MpFlag')),
  NO: React.lazy(() => import('./NoFlag')),
  OM: React.lazy(() => import('./OmFlag')),
  PK: React.lazy(() => import('./PkFlag')),
  PW: React.lazy(() => import('./PwFlag')),
  PS: React.lazy(() => import('./PsFlag')),
  PA: React.lazy(() => import('./PaFlag')),
  PG: React.lazy(() => import('./PgFlag')),
  PY: React.lazy(() => import('./PyFlag')),
  PE: React.lazy(() => import('./PeFlag')),
  PH: React.lazy(() => import('./PhFlag')),
  PN: React.lazy(() => import('./PnFlag')),
  PL: React.lazy(() => import('./PlFlag')),
  PT: React.lazy(() => import('./PtFlag')),
  PR: React.lazy(() => import('./PrFlag')),
  QA: React.lazy(() => import('./QaFlag')),
  RE: React.lazy(() => import('./ReFlag')),
  RO: React.lazy(() => import('./RoFlag')),
  RU: React.lazy(() => import('./RuFlag')),
  RW: React.lazy(() => import('./RwFlag')),
  SH: React.lazy(() => import('./ShFlag')),
  KN: React.lazy(() => import('./KnFlag')),
  LC: React.lazy(() => import('./LcFlag')),
  PM: React.lazy(() => import('./PmFlag')),
  VC: React.lazy(() => import('./VcFlag')),
  WS: React.lazy(() => import('./WsFlag')),
  SM: React.lazy(() => import('./SmFlag')),
  ST: React.lazy(() => import('./StFlag')),
  SA: React.lazy(() => import('./SaFlag')),
  SN: React.lazy(() => import('./SnFlag')),
  RS: React.lazy(() => import('./RsFlag')),
  SC: React.lazy(() => import('./ScFlag')),
  SL: React.lazy(() => import('./SlFlag')),
  SG: React.lazy(() => import('./SgFlag')),
  SK: React.lazy(() => import('./SkFlag')),
  SI: React.lazy(() => import('./SiFlag')),
  SB: React.lazy(() => import('./SbFlag')),
  SO: React.lazy(() => import('./SoFlag')),
  ZA: React.lazy(() => import('./ZaFlag')),
  GS: React.lazy(() => import('./GsFlag')),
  KR: React.lazy(() => import('./KrFlag')),
  SS: React.lazy(() => import('./SsFlag')),
  ES: React.lazy(() => import('./EsFlag')),
  LK: React.lazy(() => import('./LkFlag')),
  SD: React.lazy(() => import('./SdFlag')),
  SR: React.lazy(() => import('./SrFlag')),
  SJ: React.lazy(() => import('./SjFlag')),
  SZ: React.lazy(() => import('./SzFlag')),
  SE: React.lazy(() => import('./SeFlag')),
  CH: React.lazy(() => import('./ChFlag')),
  SY: React.lazy(() => import('./SyFlag')),
  TJ: React.lazy(() => import('./TjFlag')),
  TZ: React.lazy(() => import('./TzFlag')),
  TH: React.lazy(() => import('./ThFlag')),
  CD: React.lazy(() => import('./CdFlag')),
  TL: React.lazy(() => import('./TlFlag')),
  TG: React.lazy(() => import('./TgFlag')),
  TK: React.lazy(() => import('./TkFlag')),
  TO: React.lazy(() => import('./ToFlag')),
  TT: React.lazy(() => import('./TtFlag')),
  TN: React.lazy(() => import('./TnFlag')),
  TR: React.lazy(() => import('./TrFlag')),
  TM: React.lazy(() => import('./TmFlag')),
  TC: React.lazy(() => import('./TcFlag')),
  TV: React.lazy(() => import('./TvFlag')),
  UG: React.lazy(() => import('./UgFlag')),
  UA: React.lazy(() => import('./UaFlag')),
  AE: React.lazy(() => import('./AeFlag')),
  US: React.lazy(() => import('./UsFlag')),
  UM: React.lazy(() => import('./UmFlag')),
  UY: React.lazy(() => import('./UyFlag')),
  UZ: React.lazy(() => import('./UzFlag')),
  VU: React.lazy(() => import('./VuFlag')),
  VE: React.lazy(() => import('./VeFlag')),
  VN: React.lazy(() => import('./VnFlag')),
  VG: React.lazy(() => import('./VgFlag')),
  VI: React.lazy(() => import('./ViFlag')),
  WF: React.lazy(() => import('./WfFlag')),
  EH: React.lazy(() => import('./EhFlag')),
  YE: React.lazy(() => import('./YeFlag')),
  ZM: React.lazy(() => import('./ZmFlag')),
  ZW: React.lazy(() => import('./ZwFlag')),
}

interface CountryFlagProps {
  code: string
}

export const CountryFlag = (props: CountryFlagProps) => {
  const Component = countryFlagRecord[props.code];

  if (!Component) return <>??</>;

  const fallback = <></>

  return (
      <Suspense fallback={fallback}>
        <Component   />
      </Suspense>
  )
}

export default CountryFlag
