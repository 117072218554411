import React from 'react';
import { BoxShadowStyle } from "../../../theme"
import CoreButton from "../Button"
import Icon from "../../Media/Icon"
import styled from "styled-components"
import { Alert } from '../../ui/alert';

export const ErrorMessage = ({ children, className, title }: { children: React.ReactNode, className?: string, title?: React.ReactNode }) => (
    <Alert status='error' variant='surface' className={className} title={title}>
        <AlertText>{children}</AlertText>
    </Alert>
)

export const WarningMessage = ({ children, className, inline, title }: { children: React.ReactNode, className?: string, inline?: boolean, title?: React.ReactNode }) => (
    <Alert status='warning' variant='surface' title={title} className={className}  width={inline ? 'fit-content' : undefined}>
        <AlertText>{children}</AlertText>
    </Alert>
)

export const InfoMessage = ({ children, className, title, hideIcon = false }: { children: React.ReactNode, className?: string, hideIcon?: boolean, title?: React.ReactNode }) => (
    <Alert status='info' icon={hideIcon ? <></> : undefined} variant='subtle' className={className} title={title}>
        <AlertText>{children}</AlertText>
    </Alert>
)

export const SuccessMessage = ({ children, className, title }: { children: React.ReactNode, className?: string, title?: React.ReactNode }) => (
    <Alert status='success' variant='surface' className={className} title={title}>
        <AlertText>{children}</AlertText>
    </Alert>
)


interface ActionBoxProps {
    title?: string;
    icon?: string;
    image?: string;
    children?: React.ReactNode;
    url?: string;
    buttonText?: string;
    inline?: boolean;
    onClick?: () => void;
}

const AlertText = styled.div`
    display: block;
`

const ActionBoxIcon = styled(Icon)`
    font-size: 3rem;
    padding: 1rem;
    color: ${props => props.theme.secondary};
`

const ActionBoxContainer = styled(BoxShadowStyle)<{inline?: boolean; canClick?: boolean; }>`
    text-align: center;
    background-color: ${props => props.theme.background};
    ${props => !props.inline && `
        margin: 1rem auto;
        max-width: 30rem;
    `}
    ${props => props.inline && `width: 100%;`}
    ${props => props.canClick && `
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: ${props.theme.primary};
            color: ${props.theme.primaryContrast};

            ${ActionBoxIcon} {
                color: ${props.theme.primaryContrast} !important;
            }
        }
    `}
    padding: 2rem 1rem;
`

const ActionBoxTitle = styled.div`
    font-size: 1.8rem;
`

const ActionBoxMessage = styled.div`
    padding: 1rem;
`

const ActionBoxButtonContainer = styled.div`
`

const ActionBoxImage = styled.img`
    max-width: 4rem;
    margin: 1rem auto;
`

const ActionBox = ({ title, icon, image, children, url, inline, onClick, buttonText = 'Take me there' }: ActionBoxProps) => {
    return (
        <ActionBoxContainer inline={inline} onClick={onClick} canClick={!!onClick}>
            {icon && <ActionBoxIcon name={icon} duo />}
            {image && <ActionBoxImage src={`/assets/graphics/${image}.svg`} />}
            {title && <ActionBoxTitle>{title}</ActionBoxTitle>}
            {children && <ActionBoxMessage>{children}</ActionBoxMessage>}
            {url && <ActionBoxButtonContainer>
                <CoreButton to={url}>{buttonText}<Icon name='arrow-right' /></CoreButton>
            </ActionBoxButtonContainer>}
        </ActionBoxContainer>
    );
};

export default ActionBox;