import React from 'react';
import { AccordionItemContent, AccordionItemTrigger, AccordionItem } from '../../../../components/ui/accordion';

interface ComponentProps {
    children: React.ReactNode;
    label: string;
}

const AccordianOption = ({ children, label }: ComponentProps) => {
    return (
        <AccordionItem value={label}>
            <AccordionItemTrigger>
                {label}
            </AccordionItemTrigger>
            <AccordionItemContent pb={4}>
                {children}
            </AccordionItemContent>
        </AccordionItem>
    );
};

export default AccordianOption;