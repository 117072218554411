import React, { useRef, useState } from 'react';
import { ApiComponent, ApiPage, ApiPageComponent } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import CoreButton from '../../../../../../components/Forms/Button';
import { ApiService } from '../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../services/NotificationService';
import { cloneDeep } from 'lodash';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import styled from 'styled-components';
import { BoxShadowStyleWithHover } from '../../../../../../theme';
import Icon from '../../../../../../components/Media/Icon';

interface ComponentProps {
  components: ApiComponent[];
  categoryList: string[];
  closeModal: (refresh?: boolean) => void;
  addComponent: (component: ApiPageComponent) => void;
}

const PrimaryIcon = styled(Icon)`
  color: ${props => props.theme.secondary};
`

const AddComponentModal = ({ components, categoryList, closeModal, addComponent }: ComponentProps) => {
  const [applicableComponents, setApplicableComponents] = useState<ApiComponent[]>();

  const onCategorySelect = (category: string) => {
    setApplicableComponents(components.filter(x => x.category === category));
  }

  return (
    <CoreModal title='Add component' small onClose={closeModal}>
      {!applicableComponents &&
        <>
          Select a category
          <br />
          <br />
          <Row>
            {categoryList.map((category, index) => (
              <Column size={4} key={index}>
                <Folder onClick={() => onCategorySelect(category)}>
                  <PrimaryIcon name='folder' /> {category}
                </Folder>
              </Column>
            ))}
          </Row>
        </>
      }
      {applicableComponents &&
        <>
        Select a component to add
        <br />
        <CoreButton outline type='secondary' onClick={() => setApplicableComponents(undefined)}><Icon name='chevron-left' /> Back</CoreButton>
        <br />
        <br />
        <Row>
          {applicableComponents.map((component, index) => (
            <Column size={4} key={index}>
              <Folder onClick={() => addComponent(component.template)}>
                <PrimaryIcon name='plus' /> {component.name}
              </Folder>
            </Column>
          ))}
        </Row>
      </>
      }
    </CoreModal>
  );
};

const Folder = styled(BoxShadowStyleWithHover)`
  text-align: center;
  padding: 1rem;
  border: 1px solid ${props => props.theme.borderColor};
`

export default AddComponentModal;