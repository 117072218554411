import React, { forwardRef } from 'react';
import Icon from '../../../../components/Media/Icon';
import moment, { Moment } from 'moment';
import { desktopSize, tabletSize, mobileSize, BookingFormData } from '..';
import { DropdownItem } from '../../../../components/Forms/Dropdown';
import FormWrapper from '../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../components/Layout/Grid';
import Loader from '../../../../components/Layout/Loader';
import { StyledDropdown, StyledCalendar, StyledTextInput } from '../../../../theme/input.styles';
import { createMomentFromValue, DATABASE_TIME_FORMAT, DEFAULT_DATE_FORMAT, formatDate, TIMEFORMAT } from '../../../../utils/date-helpers';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { FormRow, InfoMessage, ExperienceOptionContainer, ExperienceOption, ButtonBack, TimeRow, ButtonTime, ButtonOutline, Button, Message, BookingErrorMessage, DayContainer } from '../../../booking.styles';
import { ApiBookingTimesWidget, ApiBusinessBookingDetails, BookingTimeSlots, DateAndTimeSlots, DateAndTimeSlotsWidget, TimeSlotWidget } from '../../../../api/api-definitions';
import styled from 'styled-components';
import TimeSlotsWithDescriptions from './TimeSlotsWithDescriptions';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    loading: boolean;
    formData: BookingFormData;
    nextAvail: ApiBookingTimesWidget;
    partyTooBig: boolean;
    searchableExperience: string;
    nextAvailEndOfResults: boolean;
    nextAvailLoading: boolean;
    nextAvailLoadingMore: boolean;
    bookingReference: string;
    specialEventDates: React.MutableRefObject<Date[]>;
    nextAvailHasMultipleShifts: DateAndTimeSlotsWidget;
    hasTimeSlots: boolean;
    selectedTimeDropdown: string;
    bookingError: string;
    bookingRef: string;
    timeOptions: DropdownItem[];
    previewLocation: string;
    times: DateAndTimeSlotsWidget;
    availableTimes: React.MutableRefObject<BookingTimeSlots>;
    setFormData: (value: React.SetStateAction<BookingFormData>) => void;
    generatePeopleList: (limit: number) => DropdownItem[];
    onUpdateSearchableExperience: (experienceGuid?: string) => void;
    setNextAvail: (value: React.SetStateAction<ApiBookingTimesWidget>) => void;
    setSelectedTimeDropdown: (value: React.SetStateAction<string>) => void;
    hold: (date?: Moment, time?: string, slot?: TimeSlotWidget) => void;
    nextAvailSearch: (experienceGuid?: string, guestsOverride?: number, isEventSearch?: boolean, nextPage?: boolean) => void;
    dateChange: (date: Date | Moment, firstLoad?: boolean, guests?: number, experienceGuid?: string, noExperience?: boolean) => void;
}

const SearchForm = ({
    business,
    loading,
    formData,
    nextAvail,
    partyTooBig,
    searchableExperience,
    nextAvailLoading,
    nextAvailLoadingMore,
    bookingReference,
    nextAvailEndOfResults,
    specialEventDates,
    nextAvailHasMultipleShifts,
    hasTimeSlots,
    times,
    selectedTimeDropdown,
    bookingError,
    bookingRef,
    timeOptions,
    previewLocation,
    availableTimes,
    setFormData,
    generatePeopleList,
    onUpdateSearchableExperience,
    setNextAvail,
    setSelectedTimeDropdown,
    hold,
    nextAvailSearch,
    dateChange,
}: ComponentProps) => {

    const isValidDate = (date): boolean => {
        const momentDate = createMomentFromValue(date);
        const formattedMomentDate = formatDate(momentDate, DATABASE_TIME_FORMAT);
        const dayOrWeek = momentDate.day(); // 0 is sunday. If the way we get day changes here, consider changing GetExperienceValiditySummary function in backend (ExperienceService.cs)
        if (business.specialEventDates && business.specialEventDates[formattedMomentDate]) return true;
        if (momentDate.isBefore(moment().subtract(1, 'days'))) {
            return false;
        }
        if (business.maximumFutureDaysForCalendar && momentDate.isAfter(moment().add(business.maximumFutureDaysForCalendar - 1, 'days'))) {
            return false;
        }
        const exceptionDate = momentDate.format('YYYY-MM-DD') + 'T00:00:00';
        if (business.exceptionDays && business.exceptionDays.hasOwnProperty(exceptionDate)) {
            return !business.exceptionDays[exceptionDate];
        }

        if (!!searchableExperience && business.experienceAndValidityDates && business.experienceAndValidityDates[searchableExperience]) {
            if (!business.experienceAndValidityDates[searchableExperience].some(x => formattedMomentDate >= x.dateFrom && formattedMomentDate <= x.dateTo && x.daysOfWeek && x.daysOfWeek[dayOrWeek])) {
                return false;
            }
        }
        // if adding new logic here, consider adding to NewBookingModal.tsx isOpen function and AmendBookingModal.tsx isOpen function - calendar for admin book and amend for admin.

        const slot = availableTimes.current?.timeSlots[momentDate.format('dddd')];
        return slot;
    }

    const CustomInput = forwardRef(({ dateVal, onClick }: any, ref: any) => (
        <div onClick={onClick} ref={ref}>
            <StyledTextInput icon='calendar' unlink value={createMomentFromValue(formData.date).format('ddd, Do MMM yyyy')} />
        </div>
    ));

    const updateForm = (data: BookingFormData) => {
        if (!loading) {
            setFormData(data)
        }
    }

    if (loading) return <Loader />;

    return (
        <>
            <FormWrapper onUpdate={(newData) => loading ? {} : updateForm({ ...(nextAvail ? formData : newData) })}>
                {({ id, valid }) => business.showTimesAsBlocks ? (
                    <>
                        {partyTooBig && !loading &&
                            <InfoMessage marginTop widgetTheme={business?.theme}>
                                For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                            </InfoMessage>
                        }
                        {!loading && !partyTooBig &&
                            <>
                                {!bookingReference && business.firstAvailableDate && createMomentFromValue(business.firstAvailableDate).format(DEFAULT_DATE_FORMAT) !== moment().format(DEFAULT_DATE_FORMAT) &&
                                    <InfoMessage marginTop widgetTheme={business?.theme}>
                                        The first available date has been pre-selected.
                                    </InfoMessage>
                                }
                                <FormRow widgetTheme={business?.theme}>
                                    <GroupedSearchBar>
                                        <GroupItemWrapper>
                                            <StyledDropdown
                                                icon='users'
                                                model='guests'
                                                addDefault={false}
                                                value={formData.guests}
                                                items={generatePeopleList(business.maximumBookableParty || 20)}
                                                onChange={() => setNextAvail(undefined)}
                                            />
                                        </GroupItemWrapper>
                                        <GroupItemWrapper>
                                            <StyledCalendar
                                                onChange={date => { setNextAvail(undefined); dateChange(date); }}
                                                model='date'
                                                value={formData.date}
                                                months={1}
                                                calendarProps={{
                                                    filterDate: isValidDate,
                                                    highlightDates: business.specialEventDates && Object.keys(business.specialEventDates).length > 0 ? [
                                                        {
                                                            'datepicker__event-day': specialEventDates.current
                                                        }
                                                    ] : undefined,
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                    },
                                                    withPortal: true
                                                }}
                                                customInput={<CustomInput />}
                                            />
                                        </GroupItemWrapper>
                                    </GroupedSearchBar>
                                </FormRow>
                            </>
                        }
                        {business?.experiences && !loading && Object.keys(business?.experiences).length > 0 &&
                            <>
                                {Object.keys(business?.experiences).length == 1 && false &&
                                    <ExperienceOptionContainer>
                                        <ExperienceOption
                                            widgetTheme={business?.theme}
                                            selected={!searchableExperience}
                                            minimal
                                            onClick={() => onUpdateSearchableExperience(undefined)}>
                                            All
                                        </ExperienceOption>
                                        <ExperienceOption
                                            widgetTheme={business?.theme}
                                            selected={searchableExperience == 'standard'}
                                            minimal
                                            minimalStandard
                                            onClick={() => onUpdateSearchableExperience('standard')}>
                                            {isNullOrWhitespace(business?.standardBookingName) ? 'Standard' : business?.standardBookingName}
                                        </ExperienceOption>
                                        {Object.keys(business?.experiences).map((key, index) => (
                                            <ExperienceOption
                                                widgetTheme={business?.theme}
                                                selected={searchableExperience == key}
                                                last={index == Object.keys(business?.experiences).length - 1}
                                                onClick={() => onUpdateSearchableExperience(key)}>
                                                {business?.experiences[key].name}
                                            </ExperienceOption>
                                        ))}
                                    </ExperienceOptionContainer>
                                }
                                {Object.keys(business?.experiences).length > 0 &&
                                    <FormRow widgetTheme={business?.theme} style={{ marginTop: '0.5rem' }}>
                                        <StyledDropdown
                                            icon='sparkles'
                                            unlink
                                            value={searchableExperience}
                                            defaultText='Any booking type'
                                            items={[{ value: 'standard', text: isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking only' : business?.standardBookingName }, ...Object.keys(business?.experiences).map(key => ({ value: key, text: business?.experiences[key].name } as DropdownItem))]}
                                            onChange={(e) => onUpdateSearchableExperience(isNullOrWhitespace(e.target.value) ? undefined : e.target.value)}
                                        />
                                    </FormRow>
                                }
                            </>
                        }
                        {!loading && !nextAvail && !nextAvailLoading && times &&
                            <>
                                <br />
                                <Message widgetTheme={business?.theme}>
                                    <strong>{formatDate(moment(formData.date), 'dddd, Do MMMM YYYY')}</strong>
                                </Message>
                                <br />
                                <TimeSlotsWithDescriptions
                                    index={0}
                                    keyName='normal-avail'
                                    slot={times}
                                    desktopSize={desktopSize}
                                    tabletSize={tabletSize}
                                    mobileSize={mobileSize}
                                    setFormData={setFormData}
                                    setSelectedTimeDropdown={setSelectedTimeDropdown}
                                    hold={hold}
                                    business={business}
                                    formData={formData}>
                                </TimeSlotsWithDescriptions>
                            </>
                        }
                        {nextAvailLoading && <Loader />}
                        {nextAvail && !loading && !nextAvailLoading &&
                            <>
                                {nextAvail.availability?.length > 0 && searchableExperience && searchableExperience != 'standard' &&
                                    <div style={{ textAlign: 'center' }}>
                                        <br />
                                        <strong>Next availability for {business?.experiences[searchableExperience].name}</strong>
                                    </div>
                                }
                                {nextAvail.availability?.length === 0 &&
                                    <>
                                        <br />
                                        <InfoMessage widgetTheme={business?.theme}>
                                            There is no availability{searchableExperience && searchableExperience != 'standard' && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} in the near future. Please check again later.
                                        </InfoMessage>
                                    </>
                                }
                                {nextAvail.availability.map((slot, index) => (
                                    <>
                                        <br />
                                        <Message widgetTheme={business?.theme}>
                                            <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                                        </Message>
                                        <br />
                                        <TimeSlotsWithDescriptions
                                            index={index}
                                            keyName='next-avail'
                                            slot={slot}
                                            desktopSize={desktopSize}
                                            tabletSize={tabletSize}
                                            mobileSize={mobileSize}
                                            setFormData={setFormData}
                                            setSelectedTimeDropdown={setSelectedTimeDropdown}
                                            hold={hold}
                                            business={business}
                                            formData={formData}>
                                        </TimeSlotsWithDescriptions>
                                    </>
                                ))}
                            </>
                        }
                        {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && hasTimeSlots &&
                            <>
                                <FormRow widgetTheme={business?.theme}>
                                    <StyledDropdown hideCheckmark icon='clock' value={selectedTimeDropdown} items={timeOptions} onChange={(e) => setSelectedTimeDropdown(e.target.value)} defaultText='Please select a time' />
                                </FormRow>
                                {bookingError && !bookingRef &&
                                    <>
                                        <BookingErrorMessage marginTop widgetTheme={business?.theme}>{bookingError}</BookingErrorMessage>
                                    </>
                                }
                                <Button disabled={isNullOrWhitespace(selectedTimeDropdown) || !isNullOrWhitespace(previewLocation)} widgetTheme={business?.theme} type='button' onClick={() => hold()}>
                                    {isNullOrWhitespace(business.reserveButtonTextStepOne) ? 'Reserve' : business.reserveButtonTextStepOne}
                                </Button>

                            </>
                        }
                        {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && !hasTimeSlots &&
                            <div>
                                <InfoMessage widgetTheme={business?.theme}>
                                    No availability{searchableExperience && searchableExperience != 'standard' && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} found on this day.
                                </InfoMessage>
                                <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch(searchableExperience)}>
                                    <Icon name='search' /> Search next available {searchableExperience && searchableExperience != 'standard' && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>}
                                </ButtonOutline>
                            </div>
                        }
                    </>
                ) : (
                    <>
                        {!loading &&
                            <FormRow widgetTheme={business?.theme}>
                                <StyledDropdown icon='users' model='guests' value={formData.guests} items={generatePeopleList(business.maximumBookableParty || 20)} onChange={() => setNextAvail(undefined)} />
                            </FormRow>
                        }
                        {partyTooBig && !loading &&
                            <InfoMessage marginTop widgetTheme={business?.theme}>
                                For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                            </InfoMessage>
                        }
                        {business?.experiences && !loading && Object.keys(business?.experiences).length > 0 &&
                            <>
                                {Object.keys(business?.experiences).length > 0 &&
                                    <FormRow widgetTheme={business?.theme} style={{ marginTop: '0.5rem' }}>
                                        <StyledDropdown
                                            icon='sparkles'
                                            unlink
                                            value={searchableExperience}
                                            defaultText='Any booking type'
                                            items={[{ value: 'standard', text: isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking only' : business?.standardBookingName }, ...Object.keys(business?.experiences).map(key => ({ value: key, text: business?.experiences[key].name } as DropdownItem))]}
                                            onChange={(e) => onUpdateSearchableExperience(isNullOrWhitespace(e.target.value) ? undefined : e.target.value)}
                                        />
                                    </FormRow>
                                }
                            </>
                        }
                        {!loading && !nextAvailLoading && !nextAvail && !partyTooBig &&
                            <>
                                {!bookingReference && business.firstAvailableDate && createMomentFromValue(business.firstAvailableDate).format(DEFAULT_DATE_FORMAT) !== moment().format(DEFAULT_DATE_FORMAT) &&
                                    <InfoMessage marginTop widgetTheme={business?.theme}>
                                        The first available date has been pre-selected.
                                    </InfoMessage>
                                }
                                <StyledCalendar
                                    onChange={dateChange}
                                    inline
                                    placeholder='Select date'
                                    model='date'
                                    value={formData.date}
                                    months={1}
                                    calendarProps={{
                                        filterDate: isValidDate,
                                        highlightDates: business.specialEventDates && Object.keys(business.specialEventDates).length > 0 ? [
                                            {
                                                'datepicker__event-day': specialEventDates.current
                                            }
                                        ] : undefined
                                    }} />
                            </>
                        }

                        {nextAvailLoading && <Loader />}

                        {nextAvail && !loading && !nextAvailLoading &&
                            <>
                                {nextAvail.availability?.length > 0 && searchableExperience && searchableExperience != 'standard' &&
                                    <div style={{ textAlign: 'center' }}>
                                        <br />
                                        <strong>Next availability for {business?.experiences[searchableExperience].name}</strong>
                                    </div>
                                }
                                <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => setNextAvail(undefined)}>
                                    <Icon name='arrow-left' duo doNotStyleDuo /> Back
                                </ButtonBack>
                                {nextAvail.availability?.length === 0 &&
                                    <>
                                        <InfoMessage widgetTheme={business?.theme}>
                                            There is no availability{searchableExperience && searchableExperience != 'standard' && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} in the near future. Please check again later.
                                        </InfoMessage>
                                    </>
                                }
                                {nextAvail.availability.map((slot, index) => (
                                    <DayContainer alternate={index % 2 != 0}>
                                        <Message widgetTheme={business?.theme}>
                                            <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                                        </Message>
                                        <br />
                                        <TimeSlotsWithDescriptions
                                            index={index}
                                            keyName='next-avail'
                                            slot={slot}
                                            desktopSize={desktopSize}
                                            tabletSize={tabletSize}
                                            mobileSize={mobileSize}
                                            setFormData={setFormData}
                                            setSelectedTimeDropdown={setSelectedTimeDropdown}
                                            hold={hold}
                                            business={business}
                                            formData={formData}>
                                        </TimeSlotsWithDescriptions>
                                    </DayContainer>
                                ))}
                                {!nextAvailLoadingMore && !nextAvailEndOfResults && nextAvail.availability.length > 4 &&
                                    <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch(searchableExperience, undefined, undefined, true)}>
                                        <Icon name='search' /> Show more results
                                    </ButtonOutline>
                                }
                                {nextAvailEndOfResults && <InfoMessage widgetTheme={business?.theme}>No more availability found.</InfoMessage>}
                                {nextAvailLoadingMore && <Loader />}
                                <br />
                            </>
                        }

                        {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && hasTimeSlots &&
                            <>
                                <FormRow widgetTheme={business?.theme}>
                                    <StyledDropdown hideCheckmark icon='clock' value={selectedTimeDropdown} items={timeOptions} onChange={(e) => setSelectedTimeDropdown(e.target.value)} defaultText='Please select a time' />
                                </FormRow>
                                {bookingError && !bookingRef &&
                                    <>
                                        <BookingErrorMessage marginTop widgetTheme={business?.theme}>{bookingError}</BookingErrorMessage>
                                    </>
                                }
                                <Button disabled={isNullOrWhitespace(selectedTimeDropdown) || !isNullOrWhitespace(previewLocation)} widgetTheme={business?.theme} type='button' onClick={() => hold()}>
                                    {isNullOrWhitespace(business.reserveButtonTextStepOne) ? 'Reserve' : business.reserveButtonTextStepOne}
                                </Button>

                            </>
                        }
                        {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && !hasTimeSlots &&
                            <div>
                                <InfoMessage widgetTheme={business?.theme}>
                                    No availability{searchableExperience && searchableExperience != 'standard' && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} found on this day.
                                </InfoMessage>
                                <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch(searchableExperience)}>
                                    <Icon name='search' /> Search next available{searchableExperience && searchableExperience != 'standard' && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>}
                                </ButtonOutline>
                            </div>
                        }
                        {loading && <Loader />}
                    </>
                )}
            </FormWrapper>
        </>
    );
};

const GroupedSearchBar = styled.div`
    display: flex;
    width: 100%;
`

const GroupItemWrapper = styled.div`
    flex: 1;

    &:first-child {
        max-width: 50%;
        padding-right: 0.5rem;
    }
`

export const ButtonTimeLabel = styled.div`
    font-size: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export const Pipe = styled.span`
    font-size: 0.6rem;
`

export default SearchForm;