const Translations = {
  'Required': {
    'fr-fr': 'Obligatoire',
    'hr-hr': 'Potreban',
  },
  'Total Price': {
    'fr-fr': 'Prix Total',
  },
  'Total Cost': {
    'fr-fr': 'Coût Total',
  },
  'Cancel': {
    'fr-fr': 'Annuler',
  }
}

export default Translations;
