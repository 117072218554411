import { ApiService } from "../api/api-connectors";
import { BaseBusiness } from "../api/api-definitions";
import BusinessCacheService from "./BusinessCacheService";
import { NotificationService } from "./NotificationService";

export default class BusinessService {
    private static _promise: Promise<BaseBusiness> = null;
    private static _business: BaseBusiness = null;

    public static Clear(): void {
        this._business = null;
        this._promise = null;
    }

    public static async GetBusiness(id: string): Promise<BaseBusiness> {
        var containsCharactersThatDontGetEncoded = this._business?.locationId.indexOf("'") > -1; // consider adding more checks for !, ', (, ), and *
        if (containsCharactersThatDontGetEncoded && decodeURIComponent(this._business?.locationId) !== id) {
            this._business = null;
            this._promise = null;
        }
        if (this._business) {
            return new Promise(resolve => {
                resolve(this._business)
            });
        } else {
            this._business = null;
            if (!this._promise) {
                this._promise = ApiService.business.GetBusinessDetails__GET(encodeURIComponent(id));
            }
            await this._promise.then(data => {
                BusinessCacheService.UpsertBusiness(id, data)
                this._business = data;
            }).catch(() => {
                NotificationService.Error('We encountered an error when trying to load your dashboard.')
            })

            return this._business;
        }
    }
}