import React, { useContext, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { BaseErrorMessage } from '../../../theme/input.core.styles';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { useFormUpdate } from '../../../hooks/formState';
import { createUUID } from '../../../utils/data-helpers';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import styled, { useTheme } from 'styled-components';
import Icon from '../../Media/Icon';
import { Radio, RadioGroup } from '../../ui/radio';

interface RadioProps {
    model?: string;
    onChange?: (e: string) => void;
    disabled?: boolean;
    id?: string;
    value: string;
    unlink?: boolean;
    inputName?: string;
    className?: string;
    children: React.ReactNode;
}

const CoreRadioGroup = ({
    model,
    onChange,
    disabled,
    id,
    value,
    unlink,
    inputName,
    className,
    children
}: RadioProps) => {
    const theme = useTheme()
    const context = useContext(FormContext);
    const uuid = useRef(createUUID());
    const formId = unlink || !context ? null : context;
    const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
    const setComponentData = useFormUpdate(formId, model);
    const { error, internalValue } = componentState;

    const setValue = (e: string) => {
        if (model && formId) setComponentData(e, true);

        if (onChange) onChange(e);

        setComponentState({ internalValue: e })
    }

    return (
        <RadioGroup 
            colorPalette={theme.secondary}
            size='lg'
            disabled={disabled}
            id={id || model}
            value={value || internalValue}
            onValueChange={(e) => setValue(e.value)}
            className={className}>
            {children}
        </RadioGroup>
    );
};

export default CoreRadioGroup;