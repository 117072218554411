import React from 'react';
import styled from 'styled-components';
import { BaseBoxShadowStyleWithHover, BoxShadowStyle, BoxShadowStyleWithHover } from '../../theme';
import { FlexStyles } from '../Layout/Flex';
import TagList from './TagList';

interface ComponentProps {
    imageUrl?: string;
    children?: any;
    title?: string;
    category?: string;
}

const PanelWrap = styled(FlexStyles.Item)`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    cursor: pointer;
`;

const PanelContainer = styled(FlexStyles.Content)`
    flex: 1;
`;

const PanelImage = styled.img`
    height: 8rem;
    background-color: ${props => props.theme.background};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('${(props: ComponentProps) => props.imageUrl}');
`;

const PanelTitle = styled.div`
    background-color: #FFF;
    margin: 0 auto;
    margin-top: -2rem;
    width: 75%;
    font-size: 1.2rem;
    text-align: center;
    padding: 1rem;
`;

const PanelCategory = styled.div`
    width: 75%;
    font-size: 1.1rem;
    text-align: center;
    padding: 1rem;
    color: #727272;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    line-height: 1rem;
    margin-top: -1rem;
`;


const FoodBusinessInfoPanel = ({ imageUrl, title, category, children }: ComponentProps) => {
    return (
        <PanelWrap>
            <PanelContainer>
                {imageUrl && <PanelImage imageUrl={imageUrl} /> }
                {title && <PanelTitle>{title}</PanelTitle> }
                {category && <PanelCategory>{category}</PanelCategory>}
                {/* <TagList tags={['Dinner', 'Lunch', 'Set Menu', 'Tasting Menu']} center /> */}
            </PanelContainer>
        </PanelWrap>
    );
};

export default FoodBusinessInfoPanel;