import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../../../components/Media/Icon';
import { HEADING_FONT } from '../../../../constants';
import { BoxShadowStyle, BaseBoxShadowStyleWithHover, BoxShadowStyleWithHover } from '../../../../theme';
import LocationNav from './LocationNav';
import ParentNav from './ParentNav';
import BREAKPOINTS from '../../../../config/breakpoints';
import { useRecoilState } from 'recoil';
import { LoginState } from '../../../../components/Authentication/AuthenticationWrapper';
import AdminNav from './AdminNav';
import ThemeService from '../../../../services/ThemeService';

interface ComponentProps {
}

const LeftBarContainer = styled.div`
    padding: 1rem;
    height: calc(100vh - 63px);
    overflow-y: hidden;

    a {
        display: block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 1rem;
        color: inherit;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        // pointer-events: none;

        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};
    }
`;

const ThemeOption = styled(BoxShadowStyleWithHover)`
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    color: inherit;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    margin-top: 1rem;
`

export const NavBarTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0;
    transition: opacity 0.5s ease;
    ${HEADING_FONT}

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        color: ${props => props.theme.primaryContrast};
        opacity: 1;
    }
`;

const Sidebar = styled(BoxShadowStyle)<{ noOpen: boolean }>`
    height: 100vh;
    position: fixed;
    overflow-y: hidden;
    width: 4.3rem;
    background-color: ${props => props.theme.dashboardElevatedBackground};
    transition: width 0.5s ease;
    z-index: 4;

    &:hover {
        z-index: 100;
    }

    .icon {
        width: 1rem;
        text-align: center;
    }

    ${props => !props.noOpen ? `
        @media (min-width: ${BREAKPOINTS.desktop}px) {
            &:hover {
                width: 18rem;

                ${NavBarTitle} {
                    opacity: 1;
                }

                ${LeftBarContainer} {
                    overflow-y: auto;
                }

                a {
                    // pointer-events: all;
                }
            }
        }
    ` : ''}

    @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
        position: relative;
        height: 3.5rem;
        width: 100vw;
    }
`;

const DashboardLogo = styled(Link)`
    background-color: ${props => props.theme.primary};
    padding: 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    display: block;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;

    span {
        margin-left: 0.7rem;
        margin-right: 2rem;
    }

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        display: none;
    }
`;

const HamburgerContainer = styled.div`
    display: none;
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast};
    font-size: 1.5rem;
    text-align: right;
    line-height: 3.5rem;
    width: 100%;
    padding: 0 0.5rem;

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

const HamburgerMenu = styled.div`
    display: inline-block;
`

const SlideOutMenu = styled.div<{ active?: boolean; }>`
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    right: ${props => props.active ? '0' : '-100vw'};
    transition: right ease 0.5s;
    background-color: ${props => props.theme.secondary};
    color: ${props => props.theme.secondaryContrast};
    width: 100vw;
    z-index: 99999;
    max-width: 30rem;

    a {
        font-size: 1rem;        
        padding: 1rem;
        background-color: white;
        color: black;
        display: block;
        line-height: 1rem;
        letter-spacing: 0.02857em;
        text-decoration: none;
        border-bottom: 1px solid ${props => props.theme.borderColor};

        &:hover {
            background-color: #f8f9fa;
        }

        &::last-child {
            border: none;
        }
    }

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

const SlideOutMenuBehind = styled.div<{ active?: boolean; }>`
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: ${props => props.active ? '1' : '0'};
    transition: opacity ease 0.5s;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    z-index: 99998;
    pointer-events: ${props => props.active ? 'all' : 'none'};

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        display: block;
    }
`

const SlideOutCloseBar = styled.div`
    line-height: 2rem;
    font-size: 1.5rem;
    background-color: ${props => props.theme.primary};
    text-align: right;
    padding: 0.5rem;
    height: 3rem;
`

const MenuLink = styled(Link)`
    margin: 1rem 1.5rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    &:hover {
        border-bottom: 1px solid white;
    }
`;

const LogoLink = styled(MenuLink)`
    margin: 0.5rem 1.5rem;
    height: 3rem;
`;

const ToolbarTitleMobileNav = styled(LogoLink)`
    background-image: url('/assets/logo3.svg');
    background-position: left center;
    background-size: 12rem auto;
    background-repeat: no-repeat;
    background-color: transparent !important;
    width: 17rem;
    display: none;
    float: left;
    margin: 0 1rem;
    height: 2rem;
    position: absolute;
    left: 0;
    text-decoration: none;
    border: none !important;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: inline-block;
    }
`

const SlideOutNavbar = styled.div`
    height: calc(100vh - 3rem);
    overflow-y: auto;
    padding: 0.5rem;
`

const DashboardLeftNav = ({ }: ComponentProps) => {
    const params: any = useParams();
    const [noOpen, setNoOpen] = useState<boolean>(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [loginValue, setLoginValue] = useRecoilState(LoginState);
    const toggleOpen = () => {
        setNoOpen(true);
        setTimeout(() => {
            setNoOpen(false)
        }, 600);
    }
    const location = useLocation();
    useEffect(() => {
        setMobileMenuOpen(false)
    }, [location]);
    return (
        <>
            <SlideOutMenuBehind active={mobileMenuOpen} onClick={() => setMobileMenuOpen(false)} />
            <SlideOutMenu active={mobileMenuOpen}>
                <SlideOutCloseBar>
                    <ToolbarTitleMobileNav title='Home page link' to='/' onClick={() => setMobileMenuOpen(false)} />
                    <Icon name='xmark' onClick={() => setMobileMenuOpen(false)} />
                </SlideOutCloseBar>
                <SlideOutNavbar>
                    <Link to='/'><Icon name='home' /> Back to main site</Link>
                    <Link to='/dashboard'><Icon name='globe-europe' /> My businesses</Link>
                    {!loginValue?.data?.isSuperAdmin &&
                        <Link to='/dashboard/support'><Icon name='messages-question' /> Support</Link>
                    }
                    {params.parentId &&
                        <ParentNav parentId={params.parentId} toggleOpen={toggleOpen} />
                    }
                    {params.id &&
                        <LocationNav toggleOpen={toggleOpen} />
                    }
                    {loginValue?.data?.isSuperAdmin &&
                        <AdminNav toggleOpen={toggleOpen} />
                    }
                </SlideOutNavbar>
            </SlideOutMenu>
            <Sidebar noOpen={noOpen}>
                <DashboardLogo to={`/`}><Icon name='home' /> Back to main site</DashboardLogo>
                <ToolbarTitleMobileNav style={{ marginTop: '0.7rem' }} title='Home page link' to='/' onClick={() => setMobileMenuOpen(false)} />
                <HamburgerContainer>
                    <HamburgerMenu onClick={() => setMobileMenuOpen(true)}>
                        <Icon name='bars' />
                    </HamburgerMenu>
                </HamburgerContainer>
                <LeftBarContainer>
                    <Link to='/dashboard'><Icon name='globe-europe' /> My businesses</Link>
                    {!loginValue?.data?.isSuperAdmin &&
                        <Link to='/dashboard/support'><Icon name='messages-question' /> Support</Link>
                    }
                    {params.parentId &&
                        <ParentNav parentId={params.parentId} toggleOpen={toggleOpen} />
                    }
                    {params.id &&
                        <LocationNav toggleOpen={toggleOpen} />
                    }
                    {loginValue?.data?.isSuperAdmin &&
                        <AdminNav toggleOpen={toggleOpen} />
                    }
                    {/* <ThemeOption onClick={() => ThemeService.switchTheme()}><Icon name='sun' /> Toggle Theme</ThemeOption> */}
                </LeftBarContainer>
            </Sidebar>
        </>
    );
};

export default DashboardLeftNav;
