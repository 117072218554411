import React from 'react';
import { ApiPage } from '../../../../../../api/api-definitions';
import styled from 'styled-components';
import { BaseBoxShadowStyleWithHover } from '../../../../../../theme';
import Icon from '../../../../../../components/Media/Icon';
import { Link, useParams } from 'react-router-dom';
import useParentBusiness from '../../../../../../hooks/useParentBusiness';
import { Badge } from '@chakra-ui/react';

interface ComponentProps {
    page: ApiPage;
    children?: ApiPage[];
    getChildren: (id: string) => ApiPage[];
    openAddModal?: (page: ApiPage) => void;
    openDeleteModal?: (page: ApiPage) => void;
}

const PageContainer = styled.div`
`

const PageChildren = styled.div`
    padding-left: 2rem;
    padding-top: 1rem;
`

const Page = styled.div`
    display: flex;
`

const PageInner = styled(Link)`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    flex: 1 0 auto;
`

const PageButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    margin-left: 1rem;
`

const RightIcon = styled(Icon)`
    float: right;
    margin-top: 0.3rem;
`

const DeleteButton = styled(PageButton)`
    &:hover {
        background: ${props => props.theme.negative};
        color: ${props => props.theme.negativeContrast};
    }
`

const AddButton = styled(PageButton)`
    &:hover {
        background: ${props => props.theme.positive};
        color: ${props => props.theme.positiveContrast};
    }
`

const UrlLabel = styled(Badge)`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 1rem;
`

const PageItem = ({ page, children, getChildren, openAddModal, openDeleteModal }: ComponentProps) => {
    const params: any = useParams();

    return (
        <PageContainer>
            <Page>
                <PageInner to={`/dashboard/${params.parentId}/site-builder/${page.rowKey}/edit-page`}><strong>{page.url == '{m}' ? 'Master layout' : page.name}</strong> {page.url !== '{m}' && <UrlLabel colorPalette='blue'>{page.url.replace(/\\(.)/mg, "$1")}</UrlLabel>} <RightIcon name='chevron-right' /></PageInner>
                {page.url !== '{m}' &&
                    <>
                        <AddButton type='button' onClick={() => openAddModal(page)}><Icon name='plus' /></AddButton>
                        <DeleteButton type='button' onClick={() => openDeleteModal(page)}><Icon name='trash' /></DeleteButton>
                    </>
                }
            </Page>
            {children &&
                <PageChildren>
                    {children.map((child) => (
                        <PageItem
                            page={child}
                            key={child.rowKey}
                            children={getChildren(child.rowKey)}
                            openAddModal={openAddModal}
                            openDeleteModal={openDeleteModal}
                            getChildren={getChildren} />
                    ))}
                </PageChildren>
            }
        </PageContainer>
    );
};

export default PageItem;