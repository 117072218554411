import React, { useState } from 'react';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { Constants } from '../../../../../../constants';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { BaseEventAddress, ExtendedBaseExperience } from '../../../../../../api/api-definitions';
import { clone, cloneDeep } from 'lodash';
import { NotificationService } from '../../../../../../services/NotificationService';
import CoreButton from '../../../../../../components/Forms/Button';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../../theme/input.styles';
import { ApiService } from '../../../../../../api/api-connectors';
import styled from 'styled-components';
import { ModalService } from '../../../../../../services/ModalService';

interface ComponentProps {
    typeName: string;
    data: ExtendedBaseExperience;
    setData: (data: ExtendedBaseExperience) => void;
}

const DefaultModalData: BaseEventAddress = { id: undefined, longitude: undefined, latitude: undefined };

const ExperienceLocation = ({ typeName, data, setData }: ComponentProps) => {
    const [businessLoaded, businessData] = useBusiness();
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [modalData, setModalData] = useState<BaseEventAddress>(clone(DefaultModalData));
    const [placeId, setPlaceId] = useState<string>();
    const [manualAddress, setManualAddress] = useState(false);
    const [saving, setSaving] = useState(false)

    const closeModal = () => {
        setAddModalOpen(false)
        setModalData(clone(DefaultModalData))
        setManualAddress(false)
        setPlaceId(undefined)
    }

    const setValue = (googleData) => {
        const id = googleData.value.place_id;
        geocodeByPlaceId(id)
            .then(results => {
                const newData = cloneDeep(modalData)
                const location = results[0].geometry.location;
                const addressArray = results[0].address_components;
                newData.formattedName = googleData.value.structured_formatting.main_text;
                newData.road = addressArray.find(x => x.types.find(y => y == 'route'))?.long_name;
                if (!newData.road) {
                    newData.road = addressArray.find(x => x.types.find(y => y == 'sublocality'))?.long_name;
                }
                newData.city = addressArray.find(x => x.types.find(y => y == 'postal_town'))?.long_name;
                newData.county = addressArray.find(x => x.types.find(y => y == 'administrative_area_level_1'))?.long_name;
                newData.country = addressArray.find(x => x.types.find(y => y == 'country'))?.long_name;
                newData.postCode = addressArray.find(x => x.types.find(y => y == 'postal_code'))?.long_name;
                setPlaceId(id);
                newData.longitude = location.lng();
                newData.latitude = location.lat();
                setModalData(newData);
            })
            .catch(error => {
                console.error(error)
                NotificationService.Error('Failed to process API request')
            });
    }

    const send = () => {
        setSaving(true)
        ApiService.googleaddress.AddEventAddress__PUT({
            eventId: data.id,
            placeId: manualAddress ? null : placeId,
            manualAddress: manualAddress ? modalData : null
        }).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Location added successfully');
                setData({ ...data, displayLocation: response.info })
                closeModal();
            } else {
                NotificationService.Error('Failed to add location');
            }
        }).catch(() => {
            NotificationService.Error('Failed to add location');
        }).finally(() => setSaving(false))
    }

    const onDeleteConfirm = () => {
        setSaving(true)
        ModalService.Close();
        ApiService.googleaddress.Delete__DELETE(data.id).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Location unlinked successfully');
                setData({ ...data, displayLocation: undefined })
            } else {
                NotificationService.Error('Failed to unlink location');
            }
        }).catch(() => {
            NotificationService.Error('Failed to unlink location');
        }).finally(() => setSaving(false))
    }

    return (
        <div>
            {addModalOpen &&
                <CoreModal title='Add location' hasCancel={!saving} onClose={closeModal} small mediumPanel actionBar={<CoreButton disabled={saving} requesting={saving} onClick={send}>Confirm</CoreButton>}>
                    <ContentWrapper>
                        {!manualAddress &&
                            <GooglePlacesAutocomplete
                                apiKey={Constants.googleMapsApiKey}
                                autocompletionRequest={{
                                    types: ['restaurant', 'cafe', 'bar'],
                                    componentRestrictions: {
                                        country: ['uk'],
                                    }
                                }}
                                selectProps={{
                                    onChange: setValue,
                                    placeholder: 'Start typing for suggestions...',
                                    isDisabled: saving
                                }}
                                withSessionToken
                            />
                        }
                        {!manualAddress && placeId &&
                            <>
                                <br />
                                <br />
                                {modalData.formattedName}<br />
                                {modalData.road}<br />
                                {modalData.city}<br />
                                {modalData.county}<br />
                                {modalData.country}<br />
                                {modalData.postCode}<br />
                                <CoreButton icon='pencil' noOutline onClick={() => setManualAddress(true)}>Not the right details? Enter manually.</CoreButton>
                                <br /><br />
                            </>
                        }
                        {!manualAddress && !placeId &&
                            <>
                                <br />
                                <CoreButton icon='pencil' noOutline onClick={() => setManualAddress(true)}>Can't find the address? Enter manually.</CoreButton>
                                <br /><br />
                            </>
                        }
                        {manualAddress &&
                            <Row>
                                <Column size={6}>
                                    <StyledTextInput disabled={saving} label='Business name' required value={modalData.formattedName} onChange={(e) => setModalData({...modalData, formattedName: e.target.value })} />
                                </Column>
                                <Column size={6}>
                                    <StyledTextInput disabled={saving} required label='Street name' value={modalData.road} onChange={(e) => setModalData({...modalData, road: e.target.value })} />
                                </Column>
                                <Column size={6}>
                                    <StyledTextInput disabled={saving} required label='City' value={modalData.city} onChange={(e) => setModalData({...modalData, city: e.target.value })} />
                                </Column>
                                <Column size={6}>
                                    <StyledTextInput disabled={saving} label='County' value={modalData.county} onChange={(e) => setModalData({...modalData, county: e.target.value })} />
                                </Column>
                                <Column size={6}>
                                    <StyledTextInput disabled={saving} required label='Country' value={modalData.country} onChange={(e) => setModalData({...modalData, country: e.target.value })} />
                                </Column>
                                <Column size={6}>
                                    <StyledTextInput disabled={saving} required label='Post code' value={modalData.postCode} onChange={(e) => setModalData({...modalData, postCode: e.target.value })} />
                                </Column>
                            </Row>
                        }
                    </ContentWrapper>
                </CoreModal>
            }
            {data?.displayLocation || businessData.locationAddress?.formattedAddress} {data?.displayLocation && 
                <CoreButton
                    icon='trash'
                    type='danger'
                    noOutline
                    onClick={() => ModalService.Open({
                        title: 'Unlink location?',
                        medium: true,
                        actionBar: <CoreButton onClick={onDeleteConfirm}>Confirm</CoreButton>,
                    })}
                >Unlink</CoreButton>
            }
            <br />
            <br />
            <DashboardAddButton onClick={() => setAddModalOpen(true)}>Link {typeName} to a different address</DashboardAddButton>
        </div>
    );
};

const ContentWrapper = styled.div`
    min-height: 17rem;
`

export default ExperienceLocation;