import React, { useEffect, useRef, useState } from 'react';
import { BaseActiveBooking, BaseBookingPayment } from '../../../../../../../api/api-definitions';
import { ApiService } from '../../../../../../../api/api-connectors';
import CreateCustomerPayment from '../../CreateCustomerPayment';
import CoreButton from '../../../../../../../components/Forms/Button';
import Icon from '../../../../../../../components/Media/Icon';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { ConvertCurrency, GetCurrencySymbol } from '../../../../../../../utils/currency-helper';
import { Badge } from '@chakra-ui/react';
import Loader from '../../../../../../../components/Layout/Loader';
import DashboardPanel from '../../../../../../../components/Dashboard/Panel';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import { NotificationService } from '../../../../../../../services/NotificationService';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import { InfoMessage } from '../../../../../../../components/Forms/Messaging';
import { StyledCurrencyInput } from '../../../../../../../theme/input.styles';
import { Column, Row } from '../../../../../../../components/Layout/Grid';

interface ComponentProps {
  booking: BaseActiveBooking;
  sessionId?: string;
}

const BookingPayments = ({ booking, sessionId = '' }: ComponentProps) => {
  const [payments, setPayments] = useState<BaseBookingPayment[]>();
  const [paymentWindowOpen, setPaymentWindowOpen] = useState(false);
  const [deletePaymentItem, setDeletePaymentItem] = useState<BaseBookingPayment>();
  const [issueRefundItem, setIssueRefundItem] = useState<BaseBookingPayment>();
  const [chargeCardItem, setChargeCardItem] = useState<BaseBookingPayment>();
  const [refundAmount, setRefundAmount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const sessionIdRef = useRef('')

  useEffect(() => {
    if (sessionId !== sessionIdRef.current) {
      loadPayments();
    }
  }, [sessionId])

  const loadPayments = () => {
    ApiService.bookingPayment.List__GET(booking.id, booking.businessId).then((response) => {
      setPayments(response);
    })
  }

  const getBadge = (item: BaseBookingPayment) => {
    if (!item.fufilled) return <Badge colorPalette='yellow'>Pending</Badge>;
    if (item.refundedAmount == 0) return <Badge colorPalette='green'>Paid</Badge>;
    if ((item.amount - item.refundedAmount) == 0) return <Badge colorPalette='red'>Refunded</Badge>;
    return <Badge colorPalette='blackAlpha'>Partially refunded</Badge>;
  }

  const gentable = (): DataTableItem[] => {
    const items: DataTableItem[] = [];
    let totalTaken = 0;
    let totalOutstanding = 0;
    payments.forEach((item) => {
      if (item.fufilled) {
        totalTaken += item.amount - item.refundedAmount;
      } else {
        totalOutstanding += item.amount;
      }
      const dataItem: DataTableItem = {
        data: {
          'Payment amount': {
            value: ConvertCurrency({ code: item.currency, amount: item.amount }),
          },
          'Refunded': {
            value: item.refundedAmount != 0 ? ConvertCurrency({ code: item.currency, amount: item.refundedAmount }) : '--',
          },
          'Received': {
            value: ConvertCurrency({ code: item.currency, amount: item.amount - item.refundedAmount }),
          },
          'Method': {
            value: item.terminalPayment ? <Badge colorPalette='blue'>Terminal</Badge> : <Badge colorPalette='teal'>Online</Badge>,
          },
          'Status': {
            value: getBadge(item),
          },
          'Action': {
            value: item.amount == item.refundedAmount ? <></> : <>
              {item.fufilled ? <CoreButton type='tertiary' onClick={() => { setRefundAmount(item.amount - item.refundedAmount); setIssueRefundItem(item) }}>Refund</CoreButton> : <CoreButton onClick={() => setChargeCardItem(item)}>Charge card</CoreButton>}
            </>,
          },
        },
      }
      items.push(dataItem);
    });
    if (totalTaken != 0) items.push({
      data: {
        'Payment amount': {
          value: '',
          sortable: true,
        },
        'Refunded': {
          value: <strong>Total received</strong>,
          sortable: true,
        },
        'Received': {
          value: <strong>{ConvertCurrency({ code: payments[0].currency, amount: totalTaken })}</strong>,
          sortable: true,
        },
        'Method': {
          value: '',
          sortable: true,
        },
        'Status': {
          value: '',
          sortable: true,
        },
        'Action': {
          value: '',
        },
      },
    })
    if (totalOutstanding != 0) items.push({
      data: {
        'Payment amount': {
          value: '',
          sortable: true,
        },
        'Refunded': {
          value: <strong>Total pending</strong>,
          sortable: true,
        },
        'Received': {
          value: <strong>{ConvertCurrency({ code: payments[0].currency, amount: totalOutstanding })}</strong>,
          sortable: true,
        },
        'Method': {
          value: '',
          sortable: true,
        },
        'Status': {
          value: '',
          sortable: true,
        },
        'Action': {
          value: '',
        },
      },
    })
    return items;
  }

  const chargeCard = (payment: BaseBookingPayment) => {
    setLoading(true)
    ApiService.checkout.ChargePreAuthedPayment__POST(payment).then((response) => {
      if (response.success) {
        setChargeCardItem(undefined)
        NotificationService.Confirm('Card charged')
        loadPayments();
      }
    }).finally(() => setLoading(false))
  }

  const issueRefund = (payment: BaseBookingPayment) => {
    setLoading(true)
    ApiService.checkout.IssueRefund__POST({
      id: payment.id,
      amount: +refundAmount == payment.amount ? null : refundAmount,
    }).then((response) => {
      if (response.success) {
        setRefundAmount(null)
        setIssueRefundItem(undefined)
        NotificationService.Confirm('Refund issued')
        loadPayments();
      }
    }).finally(() => setLoading(false))
  }

  const removePayment = (payment: BaseBookingPayment) => {
    ApiService.bookingPayment.Delete__DELETE(payment).then((response) => {
      if (response.success) {
        setDeletePaymentItem(undefined)
        NotificationService.Confirm('Payment removed')
        loadPayments();
      }
    })
  }

  const closePaymentWindow = () => {
    loadPayments()
    setPaymentWindowOpen(false)
  }

  return (
    <div>
      {paymentWindowOpen &&
        <CreateCustomerPayment
          booking={booking}
          onClose={closePaymentWindow}
          totalTakenToDate={payments ? payments.reduce((sum, payment) => sum + (payment.fufilled ? payment.amount - payment.refundedAmount : 0), 0) : 0}
        />
      }
      {deletePaymentItem &&
        <CoreModal
          small
          title={`Are you sure you want to delete this payment?`}
          onClose={() => setDeletePaymentItem(undefined)}
          actionBar={<CoreButton disabled={loading} onClick={() => removePayment(deletePaymentItem)}>Confirm</CoreButton>}
        >
          {loading && <Loader />}
          <InfoMessage>{ConvertCurrency({ code: deletePaymentItem.currency, amount: deletePaymentItem.amount })}</InfoMessage>
        </CoreModal>
      }
      {issueRefundItem &&
        <CoreModal
          small
          title={`Are you sure you want to issue refund for this payment?`}
          onClose={() => setIssueRefundItem(undefined)}
          actionBar={<CoreButton requesting={loading} disabled={loading || refundAmount < 1 || refundAmount > (issueRefundItem.amount - issueRefundItem.refundedAmount)} onClick={() => issueRefund(issueRefundItem)}>Confirm</CoreButton>}
        >
          <InfoMessage>Remaining to refund: {ConvertCurrency({ code: issueRefundItem.currency, amount: issueRefundItem.amount })}</InfoMessage>
          <br />
          <Row>
            <Column size={4}>
              <StyledCurrencyInput value={refundAmount} disabled={loading} max={issueRefundItem.amount - issueRefundItem.refundedAmount} min={1} onChange={(e) => setRefundAmount(+e.target.value)} currencySymbol={GetCurrencySymbol(issueRefundItem.currency)} label='Refund amount' />
            </Column>
          </Row>
        </CoreModal>
      }
      {chargeCardItem &&
        <CoreModal
          small
          title={`Are you sure you want to make a charge for this payment?`}
          onClose={() => setChargeCardItem(undefined)}
          actionBar={<CoreButton disabled={loading} onClick={() => chargeCard(chargeCardItem)}>Confirm</CoreButton>}
        >
          {loading && <Loader />}
          <InfoMessage>{ConvertCurrency({ code: chargeCardItem.currency, amount: chargeCardItem.amount })}</InfoMessage>
        </CoreModal>
      }
      <CoreButton onClick={() => setPaymentWindowOpen(true)}><Icon name='credit-card-front' /> Take payment</CoreButton>
      <br />
      <br />
      <DashboardPanel title={`Payments`} onToggle={() => !payments ? loadPayments() : {}} defaultExpanded={false}>
        {!payments && <Loader />}
        {payments &&
          <DataTable data={gentable()} />
        }
      </DashboardPanel>
    </div>
  );
};

export default BookingPayments;