import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { BaseErrorMessage, BaseIconStyle, BaseInputWrapper, BaseLabelStyle, BaseSelectStyle } from '../../../theme/input.core.styles';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { useFormUpdate } from '../../../hooks/formState';
import { createUUID } from '../../../utils/data-helpers';
import i18n from '../../../utils/i18n';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import Icon from '../../Media/Icon';
import styled from 'styled-components';
import { SelectContent, SelectItem, SelectItemGroup, SelectTrigger, SelectValueText } from '../../ui/select';
import { createListCollection, Portal } from '@chakra-ui/react';
import { ModalContentContext } from '../../Layout/CoreModal';

interface TextInputProps {
    model?: string;
    onChange?: (e: DropdownSelectEvent, valid: boolean, item: DropdownItem) => void;
    onBlur?: (e: DropdownSelectEvent) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    inputName?: string;
    value?: any;
    required?: boolean;
    label?: React.ReactNode;
    id?: string;
    disabled?: boolean;
    testingId?: string;
    defaultValue?: any;
    unlink?: boolean;
    validateOnLoad?: boolean;
    placeholder?: string;
    className?: string;
    items?: DropdownItem[];
    defaultText?: string;
    addDefault?: boolean;
    icon?: string;
    minContentWidth?: string;
    inputDom?: React.ReactNode;
    hideCheckmark?: boolean;
}

export interface DropdownItem {
    value: string;
    text?: React.ReactNode | string;
    selectedDisplayText?: React.ReactNode | string;
    disabled?: boolean;
    group?: string;
    flag?: string;
}

interface DropdownSelectEvent {
    target: { value: string };
}

const Dropdown = ({
    model,
    onChange,
    onBlur,
    onFocus,
    inputName,
    value,
    required,
    label,
    id,
    disabled,
    testingId,
    defaultValue,
    unlink,
    validateOnLoad,
    minContentWidth,
    placeholder,
    className,
    items,
    icon,
    defaultText,
    inputDom,
    hideCheckmark,
    addDefault = true
}: TextInputProps) => {
    const context = useContext(FormContext);
    const uuid = useRef(createUUID());
    const formId = unlink || !context ? null : context;
    const nameToUse = inputName ? inputName : model;
    const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
    const setComponentData = useFormUpdate(formId, model);
    const mounted = useRef<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const openRef = useRef(false);
    const { error, internalValue } = componentState;
    const wrapperRef = useRef(null);
    const valueToUse = onChange || formId ? (isNullOrWhitespace(value) ? '' : value) : (isNullOrWhitespace(internalValue) ? '' : internalValue);

    // useEffect(() => {
    //     return () => {
    //         if (mounted.current) setComponentData(value, true);
    //     }
    // }, [model])

    useEffect(() => {
        if (model && formId) {
            setComponentData(value, isValid(value) || !required);
        }

        let newValue = value;

        if (defaultValue) {
            newValue = defaultValue;
        }

        setComponentState({ internalValue: newValue, error });

        if (validateOnLoad) validate(value);

        mounted.current = true;
    }, [])

    useEffect(() => {
        if (!valueMatches(internalValue, value) && mounted.current) {
            setComponentState({ internalValue: value, error })

            if (model && formId) {
                const [valid, newError] = validate(value)
                setComponentData(value, valid);
            }
        }
    }, [value])

    const toggleOpen = () => {
        setIsOpen(!openRef.current);
        openRef.current = !openRef.current;
    }

    const valueMatches = (val1: any, val2: any) => {
        val1 = val1?.toString();
        val2 = val2?.toString();
        return val1?.toLowerCase() === val2?.toLowerCase();
    }

    const isValid = (value) => {
        return !(value === null || value === undefined || value === '');
    }

    const setValue = (e: DropdownItem | null) => {
        let value: string = e?.value || '';

        const [valid, newError] = validate(value);

        if (model && formId) setComponentData(value, valid);

        if (onChange) onChange({ target: { value } }, valid, e);

        setComponentState({ internalValue: value, error: newError })

        toggleOpen()
    }

    const validate = (value): [boolean, string] => {
        if (!isValid(value) && required) {
            let error = i18n('Required');
            setComponentState({ error, internalValue: value });
            return [false, error];
        } else {
            setComponentState({ error: null, internalValue: value });
            return [true, null];
        }
    }

    const defaultTextToDisplay = defaultText ? defaultText : 'Please select';
    const valueMap: {[key: string]: DropdownItem} = {};

    const collection = createListCollection({
        items: items.map(item => {
            valueMap[item.value] = item;
            return {
                value: item.value,
                group: item.group,
            }
        }),
        itemToString: (item) => item.value,
        itemToValue: (item) => item.value,
    })

    const categories = collection.items.reduce(
        (acc, item) => {
            const group = acc.find((group) => group.group === item.group)
            if (group) {
                group.items.push(item)
            } else {
                acc.push({ group: item.group, items: [item] })
            }
            return acc
        },
        [] as { group: string; items: (typeof collection)["items"] }[],
    )

    return (
        <ModalContentContext.Consumer>
            {context => (
                <div className={className}>
                    <FormAttibuteContext.Consumer>
                        {attr => (
                            <>
                                {label &&
                                    <BaseLabelStyle className={required ? 'required' : ''} htmlFor={id ? id : nameToUse}>
                                        {label}
                                    </BaseLabelStyle>
                                }

                                <BaseInputWrapper
                                    hasLeftIcon={!isNullOrWhitespace(icon)}
                                    ref={wrapperRef}
                                    relativePosition={!inputDom}
                                >
                                    {icon && <BaseIconStyle><Icon name={icon} /></BaseIconStyle>}
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <BaseSelectStyle
                                            collection={collection}
                                            size="md"
                                            value={[valueToUse?.toString()]}
                                            onValueChange={(e) => setValue(e.items[0])}
                                            hasLeftIcon={!!icon}
                                            disabled={attr.disabled || disabled || false}
                                        >
                                            <SelectTrigger>
                                                <SelectedValue placeholder={defaultTextToDisplay}>
                                                    {(items: Array<DropdownItem>) => {
                                                        if (!items || items.length === 0 || items[0] == undefined) return defaultTextToDisplay;
                                                        const { value } = items[0]
                                                        if (!valueMap[value]) return defaultTextToDisplay;
                                                        return valueMap[value].selectedDisplayText || valueMap[value].text || value;
                                                    }}
                                                </SelectedValue>
                                            </SelectTrigger>
                                            <SelectContent portalRef={context} minWidth={minContentWidth}>
                                                {addDefault &&
                                                    <SelectItem hideCheckmark={hideCheckmark} item={{ value: '', text: defaultTextToDisplay }} key=''>
                                                        {defaultTextToDisplay}
                                                    </SelectItem>
                                                }
                                                {categories.length > 1 &&
                                                    <>
                                                        {categories.map((category) => (
                                                            <SelectItemGroup key={category.group} label={category.group}>
                                                                {category.items.map((item) => (
                                                                    <SelectItem hideCheckmark={hideCheckmark} item={item} key={item.value} justifyContent="flex-start">
                                                                        {valueMap[item.value].text || valueMap[item.value].value}
                                                                    </SelectItem>
                                                                ))}
                                                            </SelectItemGroup>
                                                        ))}
                                                    </>
                                                }
                                                {categories.length < 2 && collection.items.map((item) => (
                                                    <SelectItem hideCheckmark={hideCheckmark} item={item} key={item.value}>
                                                        {valueMap[item.value].text || valueMap[item.value].value}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </BaseSelectStyle>
                                    </div>
                                </BaseInputWrapper>

                                {error &&
                                    <BaseErrorMessage className='error-message'>
                                        {error}
                                    </BaseErrorMessage>
                                }
                            </>
                        )}
                    </FormAttibuteContext.Consumer>
                </div>
            )}
        </ModalContentContext.Consumer>
    );
};

const SelectedValue = styled(SelectValueText)`
    svg {
        width: 1.75em !important;
    }
`

export default Dropdown;