import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../Media/Icon';

interface ComponentProps {
    type: NotificationType;
    text: string;
    index: number;
    setDone: Function;
}

interface ContainerProps {
    active?: boolean;
    visible?: boolean;
    error: boolean
}

const NotificationContainer = styled.div<ContainerProps>`
    background-color: white;
    display: inline;
    padding: 1rem 3rem 1rem 1rem;
    box-shadow: 3px 2px 14px -6px rgba(0,0,0,0.94);
    line-height: 2rem;
    border-left: 7px solid ${(props) => props.error ? props.theme.negative : props.theme.positive};
    transition: all 1.2s ease;
    overflow: hidden;
    float: right;
    clear: both;
    margin-bottom: 1rem;
    max-width: 50vw;
    ${(props) => props.active ? 'opacity: 1;' : 'opacity: 0;'}
`;

const IconContainer = styled.div<ContainerProps>`
    background-color: ${(props) => props.error ? props.theme.negative : props.theme.positive};
    border-radius: 50%;
    color: white;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    text-align: center;
    flex: none;
`;

const NotificationText = styled.span`
    font-weight: bold;
    font-size: 1.2em;
`;

const NotificationInnerContainer = styled.div`
    max-width: 100%;
    display: flex;
`;

export enum NotificationType {
    Success,
    Error,
    Info,
    Warning
}

const Notification = ({ type, text, index, setDone }: ComponentProps) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if (!active) setActive(true);
        }, 400);

        setTimeout(() => {
            setDone(index);
        }, 5000)
    })

    return (
        <NotificationContainer active={active} error={type !== NotificationType.Success}>
            <NotificationInnerContainer>
                <IconContainer error={type !== NotificationType.Success}>
                    <Icon name={type == NotificationType.Success ? 'check' : 'exclamation'} />
                </IconContainer>
                <NotificationText>{text}</NotificationText>
            </NotificationInnerContainer>
        </NotificationContainer>
    );
};

export default Notification;