import React from 'react';
import styled from 'styled-components';
import { BoxShadowStyleWithHover } from '../../../../../../theme';
import Icon from '../../../../../../components/Media/Icon';

interface ComponentProps {
    scale: number;
    setScale: (value: number) => void;
}

const Container = styled.div`
    position: absolute;
    top: 0;
    left: -36px;
    z-index: 3;
`

const ZoomButton = styled(BoxShadowStyleWithHover)`
    display: inline-block;
    cursor: pointer;
    background-color: ${props => props.theme.dashboardElevatedBackground};
`

const Zoom = ({ scale, setScale }: ComponentProps) => {
  return (
    <Container>
        <ZoomButton onClick={() => setScale(scale + 0.1)}>
            <Icon name='plus' />
        </ZoomButton>
        <br />
        <ZoomButton onClick={() => setScale(scale > 0.4 ? scale - 0.1 : 0.4)}>
            <Icon name='minus' />
        </ZoomButton>
    </Container>
  );
};

export default Zoom;