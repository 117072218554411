import React, { useState } from 'react';
import moment from 'moment';
import { Badge } from '@chakra-ui/react';
import NotifyClient from './NotifyClient';
import CoreButton from '../../../../../../../components/Forms/Button';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { createMomentFromValue } from '../../../../../../../utils/date-helpers';
import Icon from '../../../../../../../components/Media/Icon';

interface ComponentProps {
    onGuestSeat: (item: WaitlistItem) => void;
}

export interface WaitlistItem {
    id: number;
    name: string;
    startTime: string;
    guests: number;
    quoted: number;
    notes?: string;
}

const WaitList = ({ onGuestSeat }: ComponentProps) => {
    const [data, setData] = useState<WaitlistItem[]>([
        {
            id: 1,
            name: 'John Simms',
            startTime: '2024-04-12T18:00:00',
            guests: 2,
            quoted: 45,
            notes: 'Green shirt, 1 child'
        },
        {
            id: 2,
            name: 'Jane Peters',
            startTime: '2024-04-12T19:00:00',
            guests: 2,
            quoted: 30
        }
    ]);
    const [messageActionId, setMessageActionId] = useState<number>()

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        data.forEach((item) => {
            const waitingTime = moment().diff(createMomentFromValue(item.startTime), 'minutes');
            const dataItem: DataTableItem = {
                data: {
                    'Remove': {
                        value: <CoreButton type='danger'><Icon name='trash' /></CoreButton>,
                        hideName: true
                    },
                    'Name': {
                        value: item.name,
                    },
                    'Guests': {
                        value: <Badge colorPalette='teal'><Icon name='users' /> {item.guests}&nbsp;&nbsp;</Badge>,
                    },
                    'Waiting for': {
                        value: <Badge colorPalette={waitingTime > item.quoted ? 'red' : 'blue'}><Icon name='hourglass-start' /> {waitingTime} mins waiting&nbsp;&nbsp;</Badge>,
                        sortValue: waitingTime
                    },
                    'Notes': {
                        value: item.notes,
                    },
                    'Quoted time': {
                        value: <Badge colorPalette='cyan'><Icon name='clock' /> {item.quoted} mins&nbsp;&nbsp;</Badge>,
                    },
                    'Message': {
                        value: <CoreButton type='primary' onClick={() => setMessageActionId(item.id)}><Icon name='envelope' /></CoreButton>,
                        hideName: true
                    },
                    'Seat': {
                        value: <CoreButton onClick={() => onGuestSeat(item)}><Icon name='dining-table' />Seat guest</CoreButton>,
                        hideName: true
                    },
                },
            }
            items.push(dataItem);
        });
        return items;
    }
    return (
        <div>
            {messageActionId &&
                <NotifyClient onClose={() => setMessageActionId(undefined)} waitlistId={messageActionId} />
            }
            <DataTable defaultSort='Waiting for' reverseSort data={gentable()} noResultsMessage='Nobody on waitlist' />
        </div>
    );
};

export default WaitList;