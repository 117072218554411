import React, { useState } from 'react';
import { ExtendedArea, ExtendedTable, TableDragItem, TableSetupBackButton, TableSetupWrapper, tableIsValid } from '.';
import CoreButton from '../../../../../../components/Forms/Button';
import { StyledTextInput, StyledNumberInput, StyledDropdown } from '../../../../../../theme/input.styles';
import Icon from '../../../../../../components/Media/Icon';
import { ApiService } from '../../../../../../api/api-connectors';
import { cloneDeep } from 'lodash';
import { DEFAULTSEATS, DEFAULTXY, SEATHEIGHT, SEATMARGIN, TABLEWIDTH } from './tableLayoutUtils';
import { Column } from '../../../../../../components/Layout/Grid';
import { NotificationService } from '../../../../../../services/NotificationService';
import { ModalService } from '../../../../../../services/ModalService';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { DeleteRequest } from '../../../../../../api/api-definitions';
import { priorityOptions } from '../bookingConstants';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import InfoButton from '../../../../../../components/Cta/InfoButton';
import styled from 'styled-components';

interface ComponentProps {
    tables: ExtendedTable[];
    selectedTable: ExtendedTable;
    selectedArea: ExtendedArea;
    businessId: number;
    setDrag: (item: ExtendedTable) => void;
    selectTable: (id: number) => void;
    updateData: (shapes: ExtendedTable[]) => void;
    save: (shape: ExtendedTable) => void;
    loading: boolean;
}

const defaultValue: ExtendedTable = {
    x: DEFAULTXY,
    y: DEFAULTXY,
    width: TABLEWIDTH,
    height: (SEATHEIGHT * DEFAULTSEATS) + (SEATMARGIN * DEFAULTSEATS),
    isDragging: false,
    seats: DEFAULTSEATS,
    shape: 'rect',
    rotation: 0
}

const tableConfigs = [
    { seats: 1, shape: 'square' },
    { seats: 2, shape: 'square' },
    { seats: 4, shape: 'square' },
    // { seats: 2, shape: 'rectangle' },
    { seats: 4, shape: 'rectangle' },
    { seats: 6, shape: 'rectangle' },
    { seats: 8, shape: 'rectangle' },
    { seats: 10, shape: 'rectangle' },
    { seats: 12, shape: 'rectangle' },
    { seats: 1, shape: 'circle' },
    { seats: 2, shape: 'circle' },
    { seats: 4, shape: 'circle' },
    { seats: 6, shape: 'circle' },
    { seats: 8, shape: 'circle' },
]

const TableForm = ({ tables, selectedTable, selectedArea, businessId, setDrag, selectTable, updateData, save, loading }: ComponentProps) => {
    const [invalidTableName, setInvalidTableName] = useState(false);
    
    const cloneTable = () => {
        const table = tables.find(x => x.id === selectedTable.id);
        const newTableData: ExtendedTable = {
            ...table,
            x: table.x + 10,
            y: table.y + 10,
            id: undefined,
            tableName: (tables.length + 1).toString()
        }
        ApiService.bookingtablesetup.Insert__PUT(newTableData).then(response => {
            newTableData.id = +response.info;
            const newData = [...tables, newTableData]
            updateData(newData)
        })
    }

    const removeTable = () => {
        const deleteParams: DeleteRequest = {
            id: selectedTable.id
        }
        ApiService.bookingtablesetup.Delete__DELETE(deleteParams).then((response) => {
            if (response.success) {
                updateData(tables.filter(x => x.id !== selectedTable.id))
                selectTable(undefined);
                ModalService.Close();
                NotificationService.Confirm('Table removed successfully.')
            } else {
                NotificationService.Error('Cannot delete. Table has future bookings.')
            }
        }).catch(() => {
            NotificationService.Error('Cannot delete. Table has future bookings.')
        })
    }

    const changeValue = (value: string | boolean, property: string) => {
        const newData = cloneDeep(tables);
        const item = newData.find(x => x.id === selectedTable.id);
        item[property] = property === 'tableName' && typeof(value) == 'string' ? value.substring(0, 3) : value;
        updateData(newData)
        save(item)
    }

    const defaultTable = {
        ...defaultValue,
        businessId: businessId,
        areaId: selectedArea.id,
        shape: 'rect',
        seats: 1,
        tableName: (tables.length + 1).toString()
    }

    if (loading) return <></>;

    if (!selectedTable) return (
        <>
            {tableConfigs.map((table) => (
                <Column size={4} tablet={12} key={table.shape + '-' + table.seats}>
                    <TableDragItem
                        draggable
                        onDragStart={(e) => {
                            setDrag({
                                ...defaultTable,
                                shape: table.shape,
                                seats: table.seats,
                            });
                        }}
                    >
                        <img src={`/assets/booking-elements/seated/${table.seats}-${table.shape}.svg`} />
                        {table.seats} seat{table.seats > 1 && 's'}, {table.shape}
                    </TableDragItem>
                </Column>
            ))}
        </>
    )

    return (
        <TableSetupWrapper>
            <TableSetupBackButton onClick={() => selectTable(undefined)}>
                <Icon name='arrow-left' /> Back
            </TableSetupBackButton>
            <StyledTextInput required id={`table-${selectedTable.id}`} label='Name:' max={3} value={selectedTable.tableName || ''} onChange={e => changeValue(e.target.value, 'tableName')} />
            {!tableIsValid(selectedTable, tables).tableNameValid && 
                <ErrorMessage>Your table name conflicts with an existing table.</ErrorMessage>
            }
            <br />
            <StyledNumberInput
                required
                wholeNumbersOnly
                min={1}
                max={selectedTable.seats}
                hasPlusMinus
                label='Min covers:'
                value={selectedTable.minimumOccupancy}
                onChange={e => changeValue(e.target.value, 'minimumOccupancy')}
            />
            <br />
            <StyledNumberInput disabled label='Max covers:' value={selectedTable.seats} />
            <br />
            <StyledDropdown defaultText='No priority' value={selectedTable.priority?.toString()} items={priorityOptions} label='Priority' model='priority' onChange={e => changeValue(e.target.value, 'priority')} />
            <br />
            <Checkbox asToggle label='Bookable online' model='bookableOnline' checked={selectedTable.bookableOnline} onChange={e => changeValue(e.target.checked, 'bookableOnline')} />
            <br />
            <Checkbox asToggle label='Is table extension' model='isTableExtension' checked={selectedTable.isTableExtension} onChange={e => changeValue(e.target.checked, 'isTableExtension')} />
            <br />
            {selectedTable.isTableExtension && <InfoMessage>
                Extension table will not be bookable by itself, it will only be bookable in combination with other tables.
            </InfoMessage>}
            <br />
            <CoreButton onClick={() => ModalService.Open({
                title: `Are you sure you want to delete table "${selectedTable.tableName}"?`,
                actionBar: <CoreButton type='danger' onClick={removeTable}>Delete table</CoreButton>,
                small: true
            })} type='danger'>Delete table</CoreButton>
            <CLoneButton onClick={cloneTable}>Clone table</CLoneButton>
        </TableSetupWrapper>
    );
};

const CLoneButton = styled(CoreButton)`
    margin-left: 1rem;
`

export default TableForm;