import React, { useEffect, useState } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { ApiMenu, ApiMenuListResponse, ApiPage } from '../../../../../../api/api-definitions';
import MenuItem from './menuItem';
import { PageEditorGlobalStyle } from '../EditPage/page/lagacy-page.style';
import { DashboardPage } from '../../../../DashboardLayout';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import styled from 'styled-components';
import { createUUID } from '../../../../../../utils/data-helpers';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { StyledDropdown, StyledRadioInput, StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import { cloneDeep } from 'lodash';
import Loader from '../../../../../../components/Layout/Loader';
import { NotificationService } from '../../../../../../services/NotificationService';
import { useParams } from 'react-router-dom';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import { RadioGroup } from '@chakra-ui/react';
import CoreRadioGroup from '../../../../../../components/Forms/RadioGroup';

export interface ExtendedApiMenu extends ApiMenu {
  internal?: boolean;
}

const MenuBuilder = () => {
  const params: any = useParams();
  const [menus, setMenus] = useState<ApiMenuListResponse>();
  const [pages, setPages] = useState<ApiPage[]>([]);
  const [editMenuItem, setEditMenuItem] = useState<ExtendedApiMenu>();
  const [deleteMenuItem, setDeleteMenuItem] = useState<ApiMenu>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    load()
    ApiService.page.List__GET(params.parentId).then((response) => setPages(response.pages.filter(x => x.url != '{m}')))
  }, [])

  const load = () => {
    ApiService.menuBuilderApi.List__GET(params.parentId).then(setMenus);
  }

  const saveMenu = (itemToSave: ApiMenu) => {
    setSaving(true)
    const isNewItem = !!!itemToSave.rowKey;
    const request = isNewItem ? { ...itemToSave, rowKey: createUUID() } : itemToSave;
    if (!request.parentKey) {
      request.menuKey === request.rowKey;
    }
    ApiService.menuBuilderApi.Save__POST(request, params.parentId).then(() => {
      const newMenus = cloneDeep(menus);
      if (isNewItem) {
        if (request.parentKey) {
          if (!newMenus.subItems[request.parentKey]) newMenus.subItems[request.parentKey] = [];
          newMenus.subItems[request.parentKey].push(request);
        } else {
          newMenus.menus.push(request);
        }
      } else {
        if (request.parentKey) {
          if (!newMenus.subItems[request.parentKey]) newMenus.subItems[request.parentKey] = [];
          const foundItem: ApiMenu = newMenus.subItems[request.parentKey].find((x: ApiMenu) => x.rowKey == request.rowKey);
          if (foundItem) {
            foundItem.name = request.name;
            foundItem.url = request.url;
            foundItem.order = request.order;
          }
        } else {
          const foundItem: ApiMenu = newMenus.menus.find(x => x.rowKey == request.rowKey);
          if (foundItem) {
            foundItem.name = request.name;
            foundItem.url = request.url;
            foundItem.order = request.order;
          }
        }
      }
      setMenus(newMenus);
      setEditMenuItem(undefined);
      NotificationService.Confirm('Menu saved')
    }).finally(() => {
      setSaving(false)
    })
  }

  const confirmDeleteMenu = (request: ApiMenu) => {
    setSaving(true)
    ApiService.menuBuilderApi.Delete__POST(request, params.parentId).then(() => {
      const newMenus = cloneDeep(menus);
      if (request.parentKey) {
        if (!newMenus.subItems[request.parentKey]) newMenus.subItems[request.parentKey] = [];
        const foundItem: number = newMenus.subItems[request.parentKey].findIndex((x: ApiMenu) => x.rowKey == request.rowKey);
        if (foundItem > -1) {
          (newMenus.subItems[request.parentKey] as ApiMenu[]).splice(foundItem, 1)
        }
      } else {
        const foundItem: number = newMenus.menus.findIndex(x => x.rowKey == request.rowKey);
        if (foundItem > -1) {
          newMenus.menus.splice(foundItem, 1)
        }
      }
      setMenus(newMenus);
      setDeleteMenuItem(undefined);
      NotificationService.Confirm('Menu deleted')
    }).finally(() => {
      setSaving(false)
    })
  }

  const modalName = (menu: ApiMenu) => {
    if (menu.parentKey) {
      if (menu.rowKey) {
        return 'Edit menu item'
      } else {
        return 'New menu item'
      }
    } else {
      if (menu.rowKey) {
        return 'Edit menu'
      } else {
        return 'New menu'
      }
    }
  }

  return (
    <>
      <PageEditorGlobalStyle />
      <DashboardHeader title='Menu builder' icon='sitemap' />
      {editMenuItem &&
        <CoreModal onClose={() => setEditMenuItem(undefined)} title={modalName(editMenuItem)} small actionBar={<CoreButton requesting={saving} disabled={saving} onClick={() => saveMenu(editMenuItem)}>Confirm</CoreButton>}>
          {editMenuItem.parentKey &&
            <>
              <StyledTextInput disabled={saving} label='Menu item name' value={editMenuItem.name} onChange={(e) => setEditMenuItem({ ...editMenuItem, name: e.target.value })} />
              <br />
              <CoreRadioGroup
                onChange={e => setEditMenuItem({ ...editMenuItem, internal: e == 'internal' })}
                  value={(editMenuItem.internal === undefined ? editMenuItem.url.substring(0, 1) == '/' : editMenuItem.internal) ? 'internal' : 'external'}
                >
                <Row>
                  <Column size={3} tablet={6} mobile={12}>
                    <StyledRadioInput
                      id='internal'
                      disabled={saving}
                      value='internal'
                      label='Internal link'
                      unlink
                    />
                  </Column>
                  <Column size={3} tablet={6} mobile={12}>
                    <StyledRadioInput
                      id='external'
                      disabled={saving}
                      value='external'
                      label='Extenal link'
                      unlink
                    />
                  </Column>
                </Row>
              </CoreRadioGroup>
              {(editMenuItem.internal === undefined ? editMenuItem.url.substring(0, 1) != '/' : !editMenuItem.internal) &&
                <StyledTextInput disabled={saving} label='URL' value={editMenuItem.url} onChange={(e) => setEditMenuItem({ ...editMenuItem, url: e.target.value })} />
              }
              {(editMenuItem.internal === undefined ? editMenuItem.url.substring(0, 1) == '/' : editMenuItem.internal) &&
                <StyledDropdown
                  disabled={saving}
                  label='URL'
                  value={editMenuItem.url}
                  items={pages.map(p => ({ value: p.url, text: p.name } as DropdownItem))}
                  onChange={(e) => setEditMenuItem({ ...editMenuItem, url: e.target.value })}
                />
              }
            </>
          }
          {!editMenuItem.parentKey &&
            <>
              <StyledTextInput disabled={saving} label='Menu name' value={editMenuItem.name} onChange={(e) => setEditMenuItem({ ...editMenuItem, name: e.target.value })} />
            </>
          }
        </CoreModal>
      }
      {deleteMenuItem &&
        <CoreModal onClose={() => setDeleteMenuItem(undefined)} title={deleteMenuItem.parentKey ? 'Delete menu item' : 'Delete menu'} small actionBar={<CoreButton disabled={saving} onClick={() => confirmDeleteMenu(deleteMenuItem)}>Confirm</CoreButton>}>
          Are you sure you want to delete "{deleteMenuItem.name}"?
        </CoreModal>
      }
      {menus && menus.menus.map((menu, index) => (
        <MenuItem
          key={menu.rowKey}
          menu={menu}
          menuList={menus}
          isFirst={index == 0}
          isLast={index == menus.menus.length - 1}
          saveMenu={saveMenu}
          editMenu={setEditMenuItem}
          deleteMenu={setDeleteMenuItem}
        />
      ))}
      {menus && <AddButton onClick={() => setEditMenuItem({})}>Add menu</AddButton>}
      {!menus && <Loader />}
    </>
  );
};

const AddButton = styled(DashboardAddButton)`
    margin-top: 0.5rem;
`

export default MenuBuilder;