import { cloneDeep } from 'lodash';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseBusiness, BaseBookingTimeBlock, BaseBookingDurationRule, EnumWidgetFieldType, WidgetImageLayout } from '../../../../../../api/api-definitions';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../../components/Dashboard/Panel';
import CoreButton from '../../../../../../components/Forms/Button';
import { ErrorMessage, WarningMessage as BaseWarning, InfoMessage } from '../../../../../../components/Forms/Messaging';
import Time from '../../../../../../components/Forms/Time';
import { FloatingActionBar } from '../../../../../../components/Layout/FloatingActionBar';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import Loader from '../../../../../../components/Layout/Loader';
import Icon from '../../../../../../components/Media/Icon';
import { BookingsEmailAddressName, BookingSpecialOccasions, BookingTypeDescriptionSize, BookingTypeNameSize, Constants, EmailAddressSize } from '../../../../../../constants';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../services/NotificationService';
import { BoxShadowStyle, defaultColors } from '../../../../../../theme';
import { businessNowTime, createMomentFromValue, formatDate, getHoursAndMinutesIntervalsFromMinutes } from '../../../../../../utils/date-helpers';
import InfoButton from '../../../../../../components/Cta/InfoButton';
import { StyledColourInput, StyledDropdown, StyledNumberInput, StyledTextInput, StyledTextarea } from '../../../../../../theme/input.styles';
import { useApi } from '../../../../../../hooks/useApi';
import BookingModule from '../../../../../../bookingModule/pages/Bookings';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import DataTable, { DataTableItem } from '../../../../../../components/Layout/Datatable';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import DurationRules from '../BookingRules/DurationRules';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import PacingRules from '../BookingRules/PacingRules';
import CustomFieldValueSelector from './customFieldValueSelector';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import SpecialOccasionOption from './specialOccasionOption';
import BlockOutTimesSetup from '../BookingRules/BlockOutTimeRules';
import WebisteIntegration from './websiteIntegration';
import { parseHandlebarsTemplate } from '../../../../../../utils/data-helpers';
import ImageSelector from '../../../../../../components/Forms/ImageSelector';

interface ExtendedBaseBookingSetup extends BaseBusiness {
    timeBlocks?: ExtendedBaseBookingTimeBlock[]
}

interface ExtendedBaseBookingTimeBlock extends BaseBookingTimeBlock {
    changed?: boolean;
}

const WarningMessage = styled(BaseWarning)`
    padding: 0.5rem;
    margin-right: 11rem;
    flex: 1;
`;

export const DayBox = styled.div<{ checked: boolean, disabled?: boolean, dynamicWidth?: boolean }>`
    display: inline-block;
    border: 1px solid ${props => props.theme.primary};
    transition: all ease 0.5s;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 0.9rem;
    border-radius: 0.2rem;
    ${props => !props.dynamicWidth && 'width: calc(100% / 7 - 0.5rem)'};

    ${props => props.disabled && `
        background-color: #edebeb !important;
    `}

    ${props => props.checked && `
        background-color: ${props.theme.primary};
        color: ${props.theme.primaryContrast};
    `}

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        width: calc(50% - 0.5rem);
    }
`

const TimeContainer = styled(BoxShadowStyle)`
    padding: 1rem;
    margin-bottom: 1rem;
    position: relative;
`

const TimeIcon = styled(Icon)`
    position: absolute;
    top: -0.5rem;
    left: -1rem;
    color: ${props => props.theme.warning};
`

const DEFAULTTIMEBLOCK: ExtendedBaseBookingTimeBlock = {
    changed: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
}

const getIntervals = (lastNumber: number, intervalDifference: number) => {
    const items: DropdownItem[] = [];

    for (let i = 0; i <= lastNumber; i = i + intervalDifference) {
        items.push({
            value: `${i}`,
            text: `${i} mins`
        })
    }

    return items;
}

const BookingSetup = () => {
    const [businessLoaded, businessData] = useBusiness();
    const [saving, setSaving] = useState(false);
    const [preview, setPreview] = useState(false);
    const [templatePreview, setTemplatePreview] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [tabIndex, setTabIndex] = useState<number>(0);
    const bookingDurationValues = useRef<DropdownItem[]>(getIntervals(480, 15));
    const bookingChangeoverValues = useRef<DropdownItem[]>(getIntervals(30, 15));
    const timeBandsValid = useRef(true);
    const ruleTable = useRef<DataTableItem[]>();
    const [templateLoaded, template] = useApi(ApiService.bookingsetup.GetConfirmationTemplate__GET);

    if (!businessData) {
        let [] = useApi<ExtendedBaseBookingSetup>(null);
    }
    if (!businessData) return <Loader />;
    const [dataLoaded, data, setData, updateDay, loadApi, hasChanges] = useApi<ExtendedBaseBookingSetup>(
        ApiService.bookingsetup.Get__GET,
        { id: businessData.id },
        'Failed to load booking setup'
    );
    if (!dataLoaded) return <Loader />;

    const saveChanges = () => {
        setSaving(true)
        const request = cloneDeep(data);
        request.timeBlocks = request.timeBlocks.filter(x => x.changed && !!x.id || (!!!x.id && !x.deleted));
        ApiService.bookingsetup.Update__POST(request).then((response) => {
            if (response.success) {
                loadApi();
                NotificationService.Confirm('Booking configuration saved')
            } else {
                setErrorMessage(response.info);
                NotificationService.Error('Invalid blocked times set up')
            }
        }).catch(() => {
            NotificationService.Error('Sorry, there was an error saving your changes.')
        }).finally(() => {
            setSaving(false)
        })
    }

    const updateCustomWidgetField = (property: string, value: any, index: number) => {
        const newData = cloneDeep(data);
        newData.customWidgetFields[index][property] = value;
        setData(newData)
    }

    const formValid = (
        isNullOrWhitespace(data.bookingConfigurationSettings.maximumBookableParty) ||
        +data.bookingConfigurationSettings.maximumBookableParty > 0
    ) && (
            isNullOrWhitespace(data.bookingConfigurationSettings.maximumNumberOfFutureDaysShownOnWebCalendar) ||
            +data.bookingConfigurationSettings.maximumNumberOfFutureDaysShownOnWebCalendar > 0
        ) && data.customWidgetFields.filter(x => !x.deleted &&
            isNullOrWhitespace(x.label) ||
            isNullOrWhitespace(x.type) ||
            x.type == EnumWidgetFieldType.Dropdown ? isNullOrWhitespace(x.values) : false
        ).length == 0

    const specialOccasionsSplit = isNullOrWhitespace(data.bookingConfigurationSettings.specialOccasions) ? [] : data.bookingConfigurationSettings.specialOccasions.split('|');

    return (
        <div>
            {preview &&
                <CoreModal isOpen onClose={() => setPreview(false)}>
                    <BookingModule
                        previewLocation={businessData.locationId}
                        previewBusinessData={{
                            name: businessData.name,
                            theme: {
                                primaryColour: data.bookingConfigurationSettings.primaryColour,
                                primaryContrastColour: data.bookingConfigurationSettings.primaryContrastColour,
                                backgroundColour: data.bookingConfigurationSettings.backgroundColour,
                                textColour: data.bookingConfigurationSettings.textColour,
                                buttonColour: data.bookingConfigurationSettings.buttonColour,
                                buttonTextColour: data.bookingConfigurationSettings.buttonTextColour,
                            }
                        }}
                    />
                </CoreModal>
            }
            {templatePreview &&
                <CoreModal isOpen onClose={() => setTemplatePreview(false)}>
                    {template?.info &&
                        <>
                            {/* @ts-ignore */}
                            <iframe srcdoc={parseHandlebarsTemplate({
                                data: {
                                    name: 'Test Name',
                                    guests: 2,
                                    place_name: businessData?.name || 'Business name',
                                    place_fullname: businessData?.locationAddress.formattedName || 'Business location details',
                                    booking_time: '18:00',
                                    end_time: '19:30',
                                    booking_date: 'Tuesday, 14th June 2072',
                                    booking_ref: 'A123456',
                                    place_address: businessData?.locationAddress.formattedAddress,
                                    telephone: businessData?.phoneNumber,
                                    bookingConfirmationText: data?.bookingConfigurationSettings?.bookingConfirmationEmailText || ''
                                }
                            }, JSON.parse(template.info).versions[0].html_content)
                            }
                                width='100%'
                                height='100%'
                            />
                        </>
                    }
                </CoreModal>
            }
            <DashboardHeader icon='cog' title='Booking configuration'>Manage defaults for your bookings, block out time ranges and set up time per table size.</DashboardHeader>
            {hasChanges &&
                <FloatingActionBar>
                    {errorMessage &&
                        <ErrorMessage>
                            {errorMessage}
                        </ErrorMessage>
                    }
                    <Row>
                        <Column size={2} noMarginBottom>
                            <CoreButton disabled={saving} outline onClick={() => loadApi()}>Cancel changes</CoreButton>
                        </Column>
                        <Column size={8} noMarginBottom>
                            {!timeBandsValid.current && <WarningMessage>You have an invalid time band setup. Please amend before saving.</WarningMessage>}
                            {hasChanges && timeBandsValid.current && <WarningMessage>{Constants.messaging.makeSureToSave}</WarningMessage>}
                        </Column>
                        <Column size={2} noMarginBottom>
                            <CoreButton requesting={saving} disabled={saving || !timeBandsValid.current || !formValid} floatRight onClick={saveChanges}>Save changes</CoreButton>
                        </Column>
                    </Row>
                </FloatingActionBar>
            }
            {(!data || saving) && <Loader />}
            {data && !saving &&
                <>
                    <TabBar>
                        <TabButton active={tabIndex === 0} onClick={() => setTabIndex(0)}>Booking timings</TabButton>
                        <TabButton active={tabIndex === 1} onClick={() => setTabIndex(1)}>Booking widget</TabButton>
                        <TabButton active={tabIndex === 2} onClick={() => setTabIndex(2)}>Block out times</TabButton>
                        <TabButton active={tabIndex === 3} onClick={() => setTabIndex(3)}>Pacing rules</TabButton>
                    </TabBar>
                    {tabIndex === 0 &&
                        <>
                            <DashboardPanel title='Duration settings'>
                                <Row>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledDropdown
                                            value={data.bookingConfigurationSettings.defaultTableDurationInMinutes}
                                            unlink
                                            items={getHoursAndMinutesIntervalsFromMinutes(1440)}
                                            label={<>Default booking duration (mins)<InfoButton>
                                                This is the default number of minutes the tables will be reserved for
                                                (unless a specific rule is set up for a given party size, date range, time range or day of week).

                                            </InfoButton>
                                            </>}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        defaultTableDurationInMinutes: +e.target.value
                                                    }
                                                })}
                                        />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledDropdown
                                            addDefault={false}
                                            label={<>Default table changeover duration (mins) <InfoButton>
                                                This is the time needed to prepare the table for the next booking.
                                            </InfoButton>
                                            </>}
                                            items={bookingChangeoverValues.current}
                                            value={data.bookingConfigurationSettings.defaultChangeoverTimeInMinutes}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        defaultChangeoverTimeInMinutes: +e.target.value
                                                    }
                                                })} />
                                    </Column>
                                </Row>
                                <InfoMessage>If changeover time is configured, then the booking total time will be booking duration plus changeover. However, your guests will see the end time without changeover time.</InfoMessage>
                            </DashboardPanel>
                            <DurationRules />
                        </>
                    }
                    {tabIndex === 1 &&
                        <>
                            <DashboardPanel title='General settings'>
                                <Row>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledNumberInput
                                            label={<>Maximum party bookable online <InfoButton>
                                                The largest party size that online bookings can be made for.
                                                For parties above this number the clients will need to call/contact the restaurant directly.
                                            </InfoButton>
                                            </>}
                                            value={data.bookingConfigurationSettings.maximumBookableParty}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        maximumBookableParty: e.target.value ? +e.target.value : null
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledNumberInput
                                            label={<>Number of bookable days <InfoButton>
                                                Bookings will only be open on web for this number of days. If left blank then all dates will be bookable, even years in future.
                                            </InfoButton>
                                            </>}
                                            value={data.bookingConfigurationSettings.maximumNumberOfFutureDaysShownOnWebCalendar}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        maximumNumberOfFutureDaysShownOnWebCalendar: e.target.value ? +e.target.value : null
                                                    }
                                                })} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledTextInput
                                            value={data.bookingConfigurationSettings.bookingsEmailAddress}
                                            max={EmailAddressSize}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        bookingsEmailAddress: e.target.value
                                                    }
                                                })}
                                            label={<>{BookingsEmailAddressName}:
                                                <InfoButton>
                                                    We will send booking specific communication to this email address. This can include customer replies, information from {Constants.businessName}, and other bookings related information (like informing you about special requests).
                                                </InfoButton></>} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12} checkboxInput>
                                        <Checkbox
                                            label={<>Receive all confirmation emails <InfoButton>
                                                When this is switched in, you will be receiving confirmation emails when your clients create, amend or cancel bookings online.
                                                If this setting is switched off, you will only receive emails when bookings made online have special requests.
                                                Please make sure you configure the email address in the '{BookingsEmailAddressName}' field in order to use this feature.
                                            </InfoButton>
                                            </>}
                                            asToggle
                                            inputName='receiveClientConfirmationEmails'
                                            checked={data.bookingConfigurationSettings.receiveClientConfirmationEmails}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        receiveClientConfirmationEmails: e.target.checked
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={12} tablet={12} mobile={12}>
                                        <div>
                                            Special occasions shown on widget <InfoButton>
                                                If at least of these options is selected, a special occasions dropdown will appear on your widget,
                                                allowing clients to specify the occasion for the visit.
                                            </InfoButton>
                                        </div>
                                        <CoreButton outline onClick={() => setData(
                                            {
                                                ...data,
                                                bookingConfigurationSettings: {
                                                    ...data.bookingConfigurationSettings,
                                                    specialOccasions: Object.keys(BookingSpecialOccasions()).length == specialOccasionsSplit.length ? '' : Object.keys(BookingSpecialOccasions()).join('|')
                                                }
                                            })}>
                                            {Object.keys(BookingSpecialOccasions()).length == specialOccasionsSplit.length ? 'Deselect' : 'Select'} all
                                        </CoreButton>
                                        <br />
                                        {Object.keys(BookingSpecialOccasions()).map(occasion => (
                                            <SpecialOccasionOption data={specialOccasionsSplit} bookingSetup={data} value={occasion} label={BookingSpecialOccasions()[occasion].label} setData={setData} />
                                        ))}
                                    </Column>
                                </Row>
                                <Row>
                                    <Column size={12}>
                                        <StyledTextarea rows={8}
                                            label={<>Booking confirmation email text <InfoButton>
                                                This text will appear at the bottom of the booking confirmation email.
                                                This can include information about grace period, cancellation policy or any other important details.
                                            </InfoButton>
                                            </>}
                                            value={data.bookingConfigurationSettings.bookingConfirmationEmailText}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        bookingConfirmationEmailText: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                </Row>
                                <CoreButton type='secondary' outline onClick={() => setTemplatePreview(true)}>Preview confirmation email</CoreButton>
                            </DashboardPanel>
                            {!data.isPopUp &&
                                <DashboardPanel title='Standard booking settings'>
                                    <Row>
                                        <Column size={4} tablet={6} mobile={12}>
                                            <StyledTextInput
                                                label={<>Standard booking name <InfoButton>
                                                    This text will replace "Standard booking" on the booking widget.
                                                </InfoButton>
                                                </>}
                                                max={BookingTypeNameSize}
                                                value={data.bookingConfigurationSettings.standardBookingName}
                                                placeholder='Standard booking'
                                                onChange={(e) => setData(
                                                    {
                                                        ...data,
                                                        bookingConfigurationSettings: {
                                                            ...data.bookingConfigurationSettings,
                                                            standardBookingName: e.target.value
                                                        }
                                                    })} />
                                        </Column>
                                        <Column size={4} tablet={6} mobile={12}>
                                            <StyledDropdown
                                                addDefault={false}
                                                unlink
                                                label='Image position on widget'
                                                value={data.bookingConfigurationSettings.standardBookingImageLayout == WidgetImageLayout.NotSet ? WidgetImageLayout.Top : data.bookingConfigurationSettings.standardBookingImageLayout}
                                                items={[{ value: WidgetImageLayout.Top, text: 'Top' }, { value: WidgetImageLayout.Left, text: 'Left' }]}
                                                onChange={(e) => setData(
                                                    {
                                                        ...data,
                                                        bookingConfigurationSettings: {
                                                            ...data.bookingConfigurationSettings,
                                                            standardBookingImageLayout: WidgetImageLayout[e.target.value]
                                                        }
                                                    })}
                                            />
                                        </Column>
                                        <Column size={12}>
                                            <StyledTextarea rows={8}
                                                label={<>Standard booking description <InfoButton>
                                                    This text will appear on the booking widget.
                                                </InfoButton>
                                                </>}
                                                maxLength={BookingTypeDescriptionSize}
                                                value={data.bookingConfigurationSettings.standardBookingDescription}
                                                onChange={(e) => setData(
                                                    {
                                                        ...data,
                                                        bookingConfigurationSettings: {
                                                            ...data.bookingConfigurationSettings,
                                                            standardBookingDescription: e.target.value
                                                        }
                                                    })} />
                                        </Column>
                                        <Column size={4} tablet={6} mobile={12}>
                                            <ImageSelector value={data.bookingConfigurationSettings.standardBookingImageUrl} onChange={(value) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        standardBookingImageUrl: value
                                                    }
                                                })} />
                                        </Column>
                                    </Row>
                                </DashboardPanel>
                            }
                            <DashboardPanel title='Widget theme'>
                                <Row>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Primary colour'
                                            value={data.bookingConfigurationSettings.primaryColour || defaultColors.primary}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        primaryColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Primary contrast colour'
                                            value={data.bookingConfigurationSettings.primaryContrastColour || defaultColors.primaryContrast}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        primaryContrastColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Background colour'
                                            value={data.bookingConfigurationSettings.backgroundColour || defaultColors.background}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        backgroundColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Text colour'
                                            value={data.bookingConfigurationSettings.textColour || '#000000'}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        textColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Button colour'
                                            value={data.bookingConfigurationSettings.buttonColour || '#000000'}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        buttonColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Button text colour'
                                            value={data.bookingConfigurationSettings.buttonTextColour || '#000000'}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        buttonTextColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledColourInput label='Header background colour'
                                            value={data.bookingConfigurationSettings.headerBackgroundColour || '#000000'}
                                            min={0}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        headerBackgroundColour: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <ImageSelector
                                            label='Header logo image'
                                            value={data.bookingConfigurationSettings.headerLogoUrl}
                                            onChange={(value) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        headerLogoUrl: value
                                                    }
                                                })}
                                        />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <ImageSelector
                                            label='Header banner image'
                                            value={data.bookingConfigurationSettings.bannerImageUrl}
                                            onChange={(value) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        bannerImageUrl: value
                                                    }
                                                })}
                                        />
                                    </Column>
                                    {/* <Column size={4} tablet={6} mobile={12}>
                                        <Checkbox
                                            asToggle
                                            label='Show condensed view'
                                            id='showCondensedView'
                                            inputName='showCondensedView'
                                            checked={data.bookingConfigurationSettings.showTimesAsBlocks}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        showTimesAsBlocks: e.target.checked
                                                    }
                                                })}
                                        />
                                    </Column> */}
                                </Row>
                                <CoreButton type='secondary' outline onClick={() => setPreview(true)}>Preview</CoreButton>
                            </DashboardPanel>
                            <DashboardPanel title='Wording'>
                                <Row>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledTextInput label={<>Reserve button text <InfoButton>Name of "Reserve" button text on widget calendar page</InfoButton></>}
                                            value={data.bookingConfigurationSettings.reserveButtonTextStepOne}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        reserveButtonTextStepOne: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                    <Column size={4} tablet={6} mobile={12}>
                                        <StyledTextInput label={<>Complete booking button text <InfoButton>Name of "Reserve" button text on widget client details page</InfoButton></>}
                                            value={data.bookingConfigurationSettings.reserveButtonTextStepTwo}
                                            onChange={(e) => setData(
                                                {
                                                    ...data,
                                                    bookingConfigurationSettings: {
                                                        ...data.bookingConfigurationSettings,
                                                        reserveButtonTextStepTwo: e.target.value
                                                    }
                                                })} />
                                    </Column>
                                </Row>
                            </DashboardPanel>
                            <DashboardPanel title='Custom widget fields'>
                                These fields will appear on the booking widget and if entered, show on the customer booking.
                                <br />
                                <br />
                                {data.customWidgetFields.map((field, index) => (
                                    <Row key={`CustomWidgetField-${index}`}>
                                        <Column size={2}>
                                            <br />
                                            <Checkbox disabled={field.deleted} inputName={`CustomWidgetField-${index}-required`} asToggle label='Required' checked={field.required} onChange={(value) => updateCustomWidgetField('required', !!!field.required, index)} />
                                        </Column>
                                        <Column size={3}>
                                            <StyledTextInput disabled={field.deleted} required={!field.deleted} label='Label' value={field.label} onChange={(e) => updateCustomWidgetField('label', e.target.value, index)} />
                                        </Column>
                                        <Column size={2}>
                                            <StyledDropdown disabled={field.deleted} required={!field.deleted} label='Type' value={field.type} onChange={(e) => updateCustomWidgetField('type', e.target.value, index)} items={[
                                                { value: EnumWidgetFieldType.Text, text: 'Text' },
                                                { value: EnumWidgetFieldType.Number, text: 'Number' },
                                                { value: EnumWidgetFieldType.Dropdown, text: 'Dropdown' },
                                            ]} />
                                        </Column>
                                        <Column size={4}>
                                            {field.type == EnumWidgetFieldType.Dropdown && !field.deleted &&
                                                <CustomFieldValueSelector value={field.values} onChange={(value) => updateCustomWidgetField('values', value, index)} />
                                            }
                                            {field.deleted &&
                                                <><br /><WarningMessage>Will be removed</WarningMessage></>
                                            }
                                        </Column>
                                        <Column size={1}>
                                            <br />
                                            {!field.deleted && <CoreButton onClick={() => updateCustomWidgetField('deleted', true, index)} type='danger'><Icon name='trash' /> Delete</CoreButton>}
                                            {field.deleted && <CoreButton onClick={() => updateCustomWidgetField('deleted', false, index)} type='warning'><Icon name='undo' /> Undo</CoreButton>}
                                        </Column>
                                    </Row>
                                ))}
                                <DashboardAddButton onClick={() => setData({ ...data, customWidgetFields: [...data.customWidgetFields, {}] })}>Add custom field</DashboardAddButton>
                            </DashboardPanel>
                            <WebisteIntegration />
                        </>
                    }
                    {tabIndex === 2 &&
                        <BlockOutTimesSetup />
                    }
                    {tabIndex === 3 &&
                        <PacingRules />
                    }
                </>
            }
        </div>
    );
};

export default BookingSetup;