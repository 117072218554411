import styled, { createGlobalStyle } from "styled-components"
import BREAKPOINTS from "../config/breakpoints"
import { Row } from "../components/Layout/Grid"
import { Constants, HEADING_FONT } from "../constants"
import { BaseIconStyle } from "../theme/input.core.styles"
import { StyledDropdown, StyledCalendar } from "../theme/input.styles"
import { ThemeOptions } from "../api/api-definitions"
import { InfoMessage as CoreInfoMessage, ErrorMessage } from "../components/Forms/Messaging"
import Icon from "../components/Media/Icon"
import { BaseBoxShadowStyle } from "../theme"

export const GlobalWidgetStyle = createGlobalStyle<{ widgetTheme?: ThemeOptions }>`
    body, #pageContent {
        ${props => props.widgetTheme?.backgroundColour ? `background-color: ${props.widgetTheme?.backgroundColour} !important;` : ''}
        ${props => props.widgetTheme?.backgroundColour ? `--ion-background-color: ${props.widgetTheme?.backgroundColour} !important;` : ''}
    }
    .chakra-input__left-addon {
        border-color: #aeaeae !important;
    }

    .chakra-select__positioner {
        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            width: calc(100% - 15px) !important;
            --x: 5px !important;
            --y: 5px !important;
        }
    }

    .chakra-select__content  {
        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            max-height: calc(100vh - 15px) !important;
            min-height: calc(100vh - 15px) !important;
        }
    }

    .chakra-select__item  {
        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            font-size: 1.2rem !important;
            gap: 1rem !important;
            flex: none !important;
            padding-block: 0.7rem !important;
        }
    }
`

export const DayContainer = styled.div<{ alternate?: boolean }>`

`

export const BookingModuleContainer = styled.div<{
    widgetTheme?: ThemeOptions,
    standalone?: boolean,
    inlineDatePicker?: boolean,
    noTabs?: boolean,
    firstPage?: boolean,
}>`
    ${props => props.widgetTheme?.textColour ? `color: ${props.widgetTheme?.textColour} !important;` : ''}
    max-width: 36.15rem;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    ${props => !props.standalone && `z-index: 2;`}
    ${props => props.standalone && props.noTabs && `
        padding-top: 1rem;
        background-color: white;
        border-radius: 0.2rem;
    `}
    ${props => props.standalone && props.firstPage && `
        margin-top: -7rem;
    `}
    position: relative;
    
    ${props => props.standalone && `border: 1px solid #ededed;`}

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        padding: 0.7rem;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #000 #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 5px;
        z-index: 10;
    }

    *::-webkit-scrollbar-track {
        background: rgba(0,0,0,0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: #000;
        border-radius: 5px;
    }

    .react-datepicker__aria-live {
            display: none;
        }

    ${props => props.inlineDatePicker ? `
        .react-datepicker {
            z-index: 10;
        }

        .react-datepicker__header, .react-datepicker__day--selected {
            background-color: ${props.widgetTheme?.primaryColour || props.theme.primary};
            color: ${props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
        }
    ` : `
        .datepicker__event-day {
            position: relative;
            border-radius: 0.3rem;

            ::after {
                content: "*";
                color: ${props.widgetTheme?.primaryColour || props.theme.primary};
                position: absolute;
                left: 60%;
                top: -4px;
                width: 1rem;
                height: 1rem;
                font-weight: bold;
                font-size: 1rem;
                // border-top: 0.9rem solid ${props.widgetTheme?.primaryColour || props.theme.primary};
                // border-left: 0.9rem solid transparent;
            }
        }

        .react-datepicker__day-names, .react-datepicker__week {
            display: flex;
        }

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            flex: 1;
            line-height: 2rem;
            color: ${props.widgetTheme?.textColour || props.theme.primary};
            border: 1px solid transparent;
        }

        .react-datepicker__month-container {
            width: 100%;
        }

        .react-datepicker__current-month {
            color: ${props.widgetTheme?.textColour || props.theme.primary};
        }

        .react-datepicker__day:hover {
            color: ${props.widgetTheme?.textColour || props.theme.primary};
            background-color: transparent;
            border: 1px solid ${props.widgetTheme?.primaryColour || props.theme.primary};
        }

        .react-datepicker__day--disabled {
            opacity: 0.4;
        }

        .react-datepicker__navigation-icon {
            font-size: 40px;
            &::before {
                border-color: ${props.widgetTheme?.textColour || props.theme.primary};
            }
        }

        .react-datepicker__navigation {
            width: 80px;
            height: 60px;
        }

        .react-datepicker__day--outside-month {
            opacity: 0;
            pointer-events: none;
            touch-action: none;
        }

        .react-datepicker {
            background-color: transparent;
            margin-top: 0.5rem;
            width: 100%;
        }

        .react-datepicker__day--keyboard-selected {
            background-color: inherit;
            color: ${props.widgetTheme?.textColour || props.theme.primary};
        }

        .react-datepicker__header {
            background-color: transparent;
            color: ${props.widgetTheme?.textColour || props.theme.primary};
        }

        .react-datepicker__day--selected {
            background-color: ${props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
            color: ${props.widgetTheme?.buttonTextColour || props.theme.primaryContrast};

            &:hover {
                background-color: ${props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
                color: ${props.widgetTheme?.buttonTextColour || props.theme.primaryContrast};
                opacity: 0.7;
            }

            ::after {
                color: ${props.theme.primaryContrast} !important;
            }
        }
    `}

    ${BaseIconStyle} {
        background-color: transparent;
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        margin: 0rem;
    }
`

export const PaymentWarningTitle = styled.strong`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: block;
`

export const PaymentWarningText = styled.div`
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
`

export const MainContentContainer = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        padding: 0rem;
    }
`
export const Title = styled.h1<{ widgetTheme?: ThemeOptions, hasImage?: boolean; }>`
    font-size: 1.2rem !important;
    font-weight: inherit;
    position: sticky;
    top: 0;
    z-index: 3;
    ${props => props.hasImage ? `padding: 0.2rem;` : `padding: 0.7rem;`}
    text-align: center;
    background-color: ${props => props.widgetTheme?.headerBackgroundColour || props.theme.primary};
    color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    ${HEADING_FONT}
`
export const TitleText = styled.h1<{ widgetTheme?: ThemeOptions, hasImage?: boolean; }>`
    font-size: 1rem !important;
    font-weight: inherit;
    position: sticky;
    top: 0;
    z-index: 2;
    ${props => props.hasImage ? `padding: 0.2rem;` : `padding: 0.4rem;`}
    text-align: center;
    background-color: ${props => props.widgetTheme?.headerBackgroundColour || props.theme.primary};
    color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    opacity: 0.9;
    ${HEADING_FONT}
`


export const AltTitle = styled.h1`
    padding: 0px 0px 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.2rem !important;
    text-align: center;
    ${HEADING_FONT}
`

export const LogoImage = styled.img`
    width: auto;
    max-height: 4rem;
    margin: 0 auto;
`

export const BackgroundImage = styled.div<{ imageUrl: string; }>`
    background-image: url(${props => Constants.baseImageUrl + props.imageUrl});
    width: 100%;
    height: 10rem;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    user-select: none;
    position: sticky;
    top: 0rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        height: 5rem;
    }
`

export const HeaderLocationName = styled.h1<{ widgetTheme?: ThemeOptions }>`
    font-size: 1rem !important;
    font-weight: inherit;
    top: 0;
    z-index: 3;
    padding: 0.7rem;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    background-color: ${props => props.widgetTheme?.headerBackgroundColour || props.theme.primary};
    color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    ${HEADING_FONT}
`

export const BackgroundImageMobile = styled.div<{ imageUrl: string; }>`
    background-image: url(${props => Constants.baseImageUrl + props.imageUrl});
    width: 100vw;
    height: 8rem;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    user-select: none;
    display: none;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: block;
    }
`

export const SubTitle = styled.h2<{ widgetTheme?: ThemeOptions }>`
    font-size: 1.1rem !important;
    padding: 0.5rem 0;
    padding-top: 0;
    margin-bottom: 0.5rem;
    // font-weight: bold;
    ${HEADING_FONT}
`

export const InfoForm = styled.div`

`

export const Button = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 1rem;
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;
    border: none;

    &:disabled {
        opacity: 0.7;
    }
`

export const ExperienceOptionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const ExperienceOption = styled.div<{ widgetTheme?: ThemeOptions; selected?: boolean; last?: boolean; minimal?: boolean; minimalStandard?: boolean; }>`
    margin-top: 0.5rem;
    flex: ${props => props.minimal ? `0` : `1 1 auto`};
    padding: 0.5rem;
    border: 1px solid ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    min-width: calc(${props => props.minimalStandard ? '35' : '15'}% - 0.5rem);
    width: calc(50% - 0.5rem);

    ${({ selected, widgetTheme, theme }) => selected ? `
        background-color: ${widgetTheme?.buttonColour || widgetTheme?.primaryColour || theme.primary};
        color: ${widgetTheme?.buttonTextColour || widgetTheme?.primaryContrastColour || theme.primaryContrast};
    ` : `
        &:hover {
            opacity: 0.8;
        }
    `}

    ${props => props.last && `
        margin-right: 0;
        width: 50%;
    `}
`

export const SectionTitle = styled.div<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 0.5rem;
    border-bottom: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: inherit;
    margin-bottom: 1rem;
    font-weight: bold;
`

export const ButtonOutline = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 1rem;
    border: 2px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }
`

export const ButtonTime = styled.button<{ widgetTheme?: ThemeOptions, active?: boolean, twoLines?: boolean; }>`
    width: 100%;
    padding: 0.4rem 0.5rem;
    border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.2rem;
    transition: background-color 0.4s ease, color 0.4s ease;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    }

    ${props => props.active && `
        background-color: ${props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    `}

    line-height: ${props => props.twoLines ? '1rem' : '2rem'};

    span {
        margin: 0.5rem 0.1rem;
        line-height: 0rem;
    }

    .icon {
        font-size: 0.8rem;
    }
`

export const BookingQuickInfo = styled(Row)`
    text-align: center;
    .icon {
        margin-left: 0 !important;
    }
`

export const BookingQuickInfoTwo = styled.div`
    text-align: center;
    display: flow;
    margin-bottom: 1rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: flex;
        flex-wrap: wrap;
    }

    .icon {
        margin: 0 !important;
    }
`

export const BookingQuickInfoItem = styled.span<{ expand?: boolean }>`
    padding: 0 0.5rem;
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) { 
        width: ${props => props.expand ? '100%' : '50%'};
        flex: none;
        display: inline-block;
    }
`

export const ButtonBack = styled.button<{ widgetTheme?: ThemeOptions }>`
    padding: 0.3rem 0.8rem 0.3rem 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.2rem;
    transition: all 0.4s ease;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    &:hover {
        background-color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    }
`

export const ManageMyBookingLink = styled.a<{ widgetTheme?: ThemeOptions }>`
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 2px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.1rem;
    transition: all 0.4s ease;
    margin-top: 1rem;

    &:hover {
        background-color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    }
`

export const YourDetails = styled.div`
    width: 100%;
    flex: 1 0 auto;

    input, textarea {
        color: #000;
        background: #FFF;
    }
`

export const FormRow = styled.div<{ widgetTheme?: ThemeOptions }>`
    display: flex;

    ${StyledDropdown}, ${StyledCalendar}, ${Button}, :focus {
        flex: 1;
        width: auto !important;
        line-height: 1rem !important;
    }

    input, select, select:focus {
        width: 100%;
        background-color: transparent;
        border-color: #aeaeae !important;
        color: ${props => props.widgetTheme?.textColour || '#000'} !important;
    }

    ${BaseIconStyle} {
        border-color: #aeaeae !important;
        color: ${props => props.widgetTheme?.textColour || '#000'} !important;
    }

    option {
        color: ${props => props.widgetTheme?.textColour || '#000'};
        background-color: ${props => props.widgetTheme?.backgroundColour || props.theme.background};
        zoom: 1.2;
    }
`

export const TimeRow = styled(Row)`

`

export const PoweredBy = styled.div<{ widgetTheme?: ThemeOptions }>`
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    text-align: center;
    cursor: pointer;

    img {
        height: 0.7rem;
        display: inline;
        margin-left: 1rem;
    }
`

export const TransitionWrapper = styled.div<{ standalone?: boolean; }>`
    ${props => !props.standalone && `        
        max-width: 100%;
        position: relative;
        min-height: 21rem;
        overflow: hidden;
    `}
`

export const Transition = styled.div<{ active: boolean, paymentActive?: boolean }>`
    width: 100%;
    transition: all 0.4s ease;
    display: flex;
    ${props => props.active ? 'margin-left: -100%;' : ''}
    ${props => props.paymentActive ? 'margin-left: -100%;' : ''}
`

export const ThankYou = styled.div`
    font-size: 1.6rem;
    margin: 1rem 0;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        font-size: 1.2rem;
        font-weight: bold;
    }
`

export const Checkmark = styled(Icon) <{ widgetTheme?: ThemeOptions }>`
    color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    padding: 1rem;
    font-size: 6rem;
    display: inline-block;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        scale: 0.8;
    }
`

export const Logo = styled.span<{ widgetTheme?: ThemeOptions }>`
    display: inline-block;

    .st0, .st1 {
        fill: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast} !important;
    }
    .st2 {
        stroke: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast} !important;
    }

    svg {
        height: 1rem;
    }
`

export const BookingTabs = styled.div<{ standalone?: boolean }>`
    display: flex;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
    background-color: white;
    ${props => props.standalone && `        
        margin-top: -2rem;
        border-radius: 0.2rem;
    `}
`

export const BookingTab = styled.div<{ widgetTheme?: ThemeOptions; selected?: boolean }>`
    flex: 1 0 auto;
    cursor: pointer;
    padding: 0.8rem 0;
    width: 50%;
    ${props => props.selected && `border-bottom: 4px solid ${props.widgetTheme?.primaryColour || props.theme.primary};`}
    ${props => !props.selected && `border-bottom: 1px solid ${props.widgetTheme?.primaryColour || props.theme.primary};`}

    &:hover {
        text-decoration: underline;
    }
`

export const InfoMessage = styled(CoreInfoMessage) <{ widgetTheme?: ThemeOptions; marginTop?: boolean; }>`
    ${props => props.marginTop && `margin-top: 0.5rem;`}
`

export const BookingErrorMessage = styled(ErrorMessage) <{ widgetTheme?: ThemeOptions; marginTop?: boolean; }>`
    ${props => props.marginTop && `margin-top: 0.5rem;`}
`

export const Message = styled.div<{ widgetTheme?: ThemeOptions; marginTop?: boolean; }>`
    ${props => props.marginTop && `margin-top: 0.5rem;`}
    border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-left: 11px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.textColour || props.theme.primary};
    padding: 0.8rem;

    .icon {
        margin: 0 0.2rem 0 0rem;
    }
`

export const BookingStandaloneContainer = styled(MainContentContainer)`
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    padding-top: 0;
    background-color: white;
`