import React from 'react';
import { ApiBusinessBookingDetails, HeldBookingResponse } from '../../../../api/api-definitions';
import { InfoMessage, PaymentWarningText } from '../../../booking.styles';
import { Constants } from '../../../../constants';
import Checkout from '../../../../components/Payments/Checkout';
import DepositInfo from './DepositInfo';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    holdDetails: HeldBookingResponse;
    paymentIntent: string;
    nameToDisplay?: string;
}

const PaymentForm = ({
    business,
    holdDetails,
    paymentIntent,
    nameToDisplay
}: ComponentProps) => {
    return (
        <>
            <PaymentWarningText>
                *By completing the the payment, you authorise {Constants.businessName} and {business.name} to send instructions to the financial institution that issued your card to take payments from your card account in accordance with the terms of {business.name}.
            </PaymentWarningText>
            <DepositInfo business={business} holdDetails={holdDetails} hideNextScreenWording nameToDisplay={nameToDisplay} />
            <Checkout
                intentSecret={paymentIntent}
                payNow={holdDetails.takeDepositNow}
                accountId={holdDetails.accountId}
                widgetTheme={business?.theme}
                returnUrl={window.location.href + `?holdRef=${holdDetails.holdReference}&takeNow=${holdDetails?.takeDepositNow ? 'true' : 'false'}`}
                widget
            />
            <br />
        </>
    );
};

export default PaymentForm;