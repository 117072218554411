import React, { useState } from 'react';
import { BaseLabelStyle } from '../../../theme/input.core.styles';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { Constants } from '../../../constants';
import ImageModal from './imageModal';
import NoImage from '../../Media/NoImage';
import { BoxShadowStyleWithHover } from '../../../theme';
import styled from 'styled-components';
import CoreButton from '../Button';

interface ComponentProps {
    label?: string;
    value: string;
    onChange: (value: string) => void;
}

const ImageSelector = ({ label, value, onChange }: ComponentProps) => {
    const [selectImageOpen, setSelectImageOpen] = useState(false);

    const onConfirm = (value: string) => {
        onChange(value)
        setSelectImageOpen(false)
    }

    return (
        <>
            {label && <BaseLabelStyle>{label}</BaseLabelStyle>}
            <div>
                <ImagePreview image={isNullOrWhitespace(value) ? '' : Constants.baseImageUrl + value} onClick={() => setSelectImageOpen(true)}>
                    {isNullOrWhitespace(value) && <NoImage />}
                </ImagePreview>
                <RemoveButton small onClick={() => setSelectImageOpen(true)}>{isNullOrWhitespace(value) ? 'Add image' : 'Change image'}</RemoveButton>
                {!isNullOrWhitespace(value) && <RemoveButton type='danger' small onClick={() => onChange(undefined)}>Remove image</RemoveButton>}
                {selectImageOpen && <ImageModal value={value} onClose={() => setSelectImageOpen(false)} onConfirm={(image) => onConfirm(image)} />}
            </div>
        </>
    );
};

const RemoveButton = styled(CoreButton)`
    width: 100%;
    margin-top: 0.2rem;
`

const ImagePreview = styled(BoxShadowStyleWithHover)<{ image: string }>`
  background-image: url('${props => props.image}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 200px;
`

export default ImageSelector;