import { ApiBusinessBookingDetails, DepositPaymentType, ExperienceInfo, HeldBookingResponse } from "../../../api/api-definitions";
import { isNullOrWhitespace } from "../../../utils/text-helpers";

export const getMaximumPartyForEvent = (eventGuid: string, business: ApiBusinessBookingDetails) => {
    let event: ExperienceInfo = null;
    if (business.specialEvents && business.specialEvents[eventGuid]) {
        event = business.specialEvents[eventGuid];
    } else if (business.experiences && business.experiences[eventGuid]) {
        event = business.experiences[eventGuid];
    }

    if (business.maximumBookableParty) {
        if (event && event.maximumPeople != undefined) {
            return event.maximumPeople > 99 ? 99 : event.maximumPeople;
        } else {
            return business.maximumBookableParty;
        }
    } else if (event && event.maximumPeople > 99) {
        return 99;
    }
    if (business.maximumBookableParty) return business.maximumBookableParty;
    return 20;
}

export const noShowPaymentRequired = (holdDetails: HeldBookingResponse) : boolean => {
    return (holdDetails?.depositRequired && holdDetails?.paymentType == DepositPaymentType.TakeOnNoShow)
}

export const noShowOrCancellationPaymentRequired = (holdDetails: HeldBookingResponse) : boolean => {
    return (holdDetails?.depositRequired && holdDetails?.paymentType == DepositPaymentType.TakeOnCancelAndNoShow)
}

export const depositUponMakeBookingRequired = (holdDetails: HeldBookingResponse) : boolean => {
    return (holdDetails?.depositRequired && holdDetails?.paymentType == DepositPaymentType.TakeNow)
}