import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { BaseErrorMessage, CheckboxLabelStyle } from '../../../theme/input.core.styles';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { useFormUpdate } from '../../../hooks/formState';
import { createUUID } from '../../../utils/data-helpers';
import i18n from '../../../utils/i18n';
import { FormAttibuteContext, FormContext } from '../FormWrapper';

interface CheckboxProps {
    model?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputName?: string;
    checked?: any;
    required?: boolean;
    label?: React.ReactNode;
    id?: string;
    labelClass?: string;
    disabled?: boolean;
    unlink?: boolean;
    className?: string;
    asToggle?: boolean;
    invert?: boolean;
}

const CheckBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    margin-top: 4px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  cursor: pointer;
  &:checked + ${CheckBoxLabel} {
    background: ${props => props.theme.positive};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-top: 4px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const CheckBoxLabelText = styled(CheckboxLabelStyle)`
  line-height: 1.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
  float: right;
`;

const CheckBoxLabelRequiredLabel = styled(CheckboxLabelStyle)`
  line-height: 1.5rem;
  padding-left: 0rem;
  cursor: pointer;
`;

const Checkbox = ({
    model,
    onChange,
    inputName,
    checked,
    required,
    label,
    labelClass,
    id,
    disabled,
    unlink,
    className,
    asToggle,
    invert
}: CheckboxProps) => {
    const context = useContext(FormContext);
    const uuid = useRef(createUUID());
    const formId = unlink || !context ? null : context;
    const nameToUse = inputName ? inputName : model;
    const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
    const setComponentData = useFormUpdate(formId, model);
    const { error, isChecked } = componentState;

    useEffect(() => {
        if (formId && model) {
            const valid = required ? required && checked : true;
            setComponentData(checked, valid);
        }
        setComponentState({ isChecked: !!checked, error });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setComponentState({ isChecked: checked, error });
        const valid = validate(checked);

        if (formId && model) {
            setComponentData(checked, valid);
        }
        // eslint-disable-next-line
    }, [checked])

    const validate = (value): boolean => {
        if (required && value === false) {
            let error = i18n('Required');
            setComponentState({ isChecked, error });
            return false;
        } else {
            setComponentState({ isChecked, error: null });
            return true;
        }
    }

    const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = invert ? !e.target.checked : e.target.checked;
        const valid = validate(value);

        if (model && formId) {
            setComponentData(value, valid);
        }
        if (onChange) onChange(e);
        setComponentState({ isChecked: value, error })
    }

    if (asToggle) return (
        <span className={className}>
            <FormAttibuteContext.Consumer>
                {attr => (
                    <>
                        <CheckBoxWrapper>
                            <CheckBox
                                id={id ? id : nameToUse}
                                name={nameToUse} type="checkbox"
                                checked={invert ? !checked : checked}
                                required={required}
                                disabled={disabled || attr.disabled}
                                onChange={(e) => setValue(e)}
                            />
                            <CheckBoxLabel htmlFor={id ? id : nameToUse} />
                            <CheckBoxLabelRequiredLabel htmlFor={id ? id : nameToUse} className={required ? 'required ' : ''} />
                            <CheckBoxLabelText htmlFor={id ? id : nameToUse} className={labelClass}>{label}</CheckBoxLabelText>
                        </CheckBoxWrapper>
                        {error &&
                            <BaseErrorMessage className=''>
                                {error}
                            </BaseErrorMessage>
                        }
                    </>
                )}
            </FormAttibuteContext.Consumer>
        </span>
    )
    return (
        <span className={className}>
            <FormAttibuteContext.Consumer>
                {attr => (
                    <>
                        <input type='checkbox' className='chkbx'
                            checked={!!(checked == undefined ? isChecked : checked)}
                            name={nameToUse}
                            id={id ? id : nameToUse}
                            required={required}
                            disabled={disabled || attr.disabled}
                            onChange={(e) => setValue(e)}>
                        </input>
                        <CheckBoxLabelRequiredLabel htmlFor={id ? id : nameToUse} className={required ? 'required ' : ''} />
                        {label &&
                            <CheckboxLabelStyle className={labelClass} htmlFor={id ? id : nameToUse}>
                                {label}
                            </CheckboxLabelStyle>
                        }
                        {error &&
                            <BaseErrorMessage className=''>
                                {error}
                            </BaseErrorMessage>
                        }
                    </>
                )}
            </FormAttibuteContext.Consumer>
        </span>
    );
};

export default Checkbox;