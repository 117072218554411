import React from 'react';
import styled from 'styled-components';
import { GetPageResponse, ApiPageComponent, ApiComponentProperty } from '../../../../../../api/api-definitions';
import { BaseBoxShadowStyle, BaseBoxShadowStyleWithHover } from '../../../../../../theme';
import Icon from '../../../../../../components/Media/Icon';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import { getPlaceHolderArray } from '.';
import DragPlaceholder from './dragPlaceholder';

interface ComponentProps {
    pageInfo: GetPageResponse;
    component: ApiPageComponent;
    editComponent: (component: ApiPageComponent) => void;
    deleteComponent: (component: ApiPageComponent) => void;
    onComponentMove: (item: ApiPageComponent, increment: boolean, placeholderId?: string) => void;
    onComponentDrop: (
        draggedComponent: ApiPageComponent,
        targetComponent: ApiPageComponent,
        position: 'top' | 'bottom' | 'empty',
        oldPlaceholderId?: string,
        newPlaceholderId?: string
    ) => void;
    onAddClick: (index: number, placeholderId: string, parentId: string) => void;
    isFirst: boolean;
    isLast: boolean;
    placeholderId?: string;
    expandedItems: { [rowKey: string]: boolean };
    setExpandedItems: (items: { [rowKey: string]: boolean }) => void;
}

const ComponentItem = ({
    pageInfo,
    component,
    editComponent,
    deleteComponent,
    onComponentMove,
    onComponentDrop,
    onAddClick,
    isFirst,
    isLast,
    placeholderId,
    expandedItems,
    setExpandedItems
}: ComponentProps) => {
    const placeholders = component.properties.filter(x => x.type == 'placeholder');
    const properties = component.properties.filter(x => x.type !== 'placeholder');
    const [dropLine, setDropLine] = React.useState<undefined | 'top' | 'bottom'>();
    const [isDragging, setIsDragging] = React.useState(false)

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const containerTop = rect.y;
        const middle = containerTop + (rect.height / 2);

        setDropLine(event.pageY > middle ? 'bottom' : 'top')
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>, alternativePlaceholder?: string) => {
        setDropLine(undefined)
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const containerTop = rect.y;
        const middle = containerTop + (rect.height / 2);
        const draggedComponent: ApiPageComponent = JSON.parse(event.dataTransfer.getData('component'));
        const position = event.pageY > middle ? 'bottom' : 'top';
        onComponentDrop(draggedComponent, component, position, event.dataTransfer.getData('placeholderId'), alternativePlaceholder || placeholderId);
    }

    const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('component', JSON.stringify(component))
        event.dataTransfer.setData('placeholderId', placeholderId ? placeholderId : '')
        setIsDragging(true)
    }

    return (
        <div>
            <ComponentDropLine active={dropLine === 'top'} />
            <Component
                onDragOver={onDragOver}
                onDragLeave={() => setDropLine(undefined)}
                onDrop={onDrop}
            >
                <ContentContainer draggable onDragStart={onDragStart} onDragEnd={() => setIsDragging(false)} onClick={() => placeholders.length > 0 ? {} : editComponent(component)}>
                    <DragButton onClick={() => onComponentMove(component, false, placeholderId)}><DragIcon name='grip-dots-vertical' /></DragButton>
                    <ComponentInner onClick={() => setExpandedItems({ ...expandedItems, [component.rowKey]: !!!expandedItems[component.rowKey] })}>
                        {pageInfo.availableComponents.find(x => x.rowKey === component.componentId).name}
                        {placeholders.length > 0 && <RightIcon name={expandedItems[component.rowKey] ? 'chevron-down' : 'chevron-right'} />}
                    </ComponentInner>
                </ContentContainer>
                <DeleteButton onClick={() => deleteComponent(component)}><Icon name='trash' /></DeleteButton>
                <ComponentButton disabled={properties.length == 0} onClick={() => editComponent(component)}><Icon name='sliders' /></ComponentButton>
                <ComponentButton disabled={isFirst} onClick={() => onComponentMove(component, true, placeholderId)}><Icon name='chevron-up' /></ComponentButton>
                <ComponentButton disabled={isLast} onClick={() => onComponentMove(component, false, placeholderId)}><Icon name='chevron-down' /></ComponentButton>
            </Component>
            <ComponentDropLine active={dropLine === 'bottom'} />
            {!isDragging && expandedItems[component.rowKey] && placeholders.length > 0 &&
                <ComponentChildren>
                    {placeholders.map((placeholderItem) => {
                        const placeholderArray = getPlaceHolderArray(component, placeholderItem, pageInfo);
                        if (placeholderArray.length == 0) return (
                            <DragPlaceholder
                                onComponentDrop={onComponentDrop}
                                component={component}
                                placeholderItem={placeholderItem}
                            >
                                <AddButton onClick={() => onAddClick(placeholderArray.length, placeholderItem.rowKey, component.rowKey)}>Add component</AddButton>
                                <br />
                            </DragPlaceholder>
                        )
                        return (
                            <div>
                                <PlaceHolderTitle>{placeholderItem.name}</PlaceHolderTitle>
                                {placeholderArray.length > 0 && placeholderArray.map((item, itemIndex) => (
                                    <ComponentItem
                                        key={item.rowKey}
                                        pageInfo={pageInfo}
                                        component={item}
                                        editComponent={editComponent}
                                        deleteComponent={deleteComponent}
                                        onComponentMove={onComponentMove}
                                        onComponentDrop={onComponentDrop}
                                        onAddClick={onAddClick}
                                        setExpandedItems={setExpandedItems}
                                        expandedItems={expandedItems}
                                        isFirst={itemIndex === 0}
                                        isLast={itemIndex === placeholderArray.length - 1}
                                        placeholderId={placeholderItem.rowKey}
                                    />
                                ))}
                                <AddButton onClick={() => onAddClick(placeholderArray.length, placeholderItem.rowKey, component.rowKey)}>Add component</AddButton>
                                <br />
                            </div>
                        )
                    })}
                </ComponentChildren>
            }
        </div>
    );
};

export default ComponentItem;

const AddButton = styled(DashboardAddButton)`
    margin-top: 0.5rem;
`

export const ComponentDropLine = styled.div<{ active?: boolean }>`
    border-bottom: 2px solid ${props => props.active ? props.theme.secondary : 'rgba(255, 255, 255, 0)'};
    height: 1px;
`

const ComponentChildren = styled.div`
    padding-left: 2rem;
    margin-bottom: 1rem;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 17px;
        border-left: 1px solid ${props => props.theme.borderColor};
        top: -20px;
        width: 1px;
        height: 100%;
        z-index: 0;
    }

    &::after {
        content: "";
        position: absolute;
        left: 17px;
        border-bottom: 1px solid ${props => props.theme.borderColor};
        bottom: 19px;
        width: 20px;
        height: 1px;
        z-index: 0;
    }
`

export const PlaceHolderTitle = styled.div`
    font-weight: bold;
    margin-bottom: 1rem;
`

const Component = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
`

const ComponentInner = styled.div`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    flex: 1 0 auto;
`

const ContentContainer = styled.div`
    flex: 1 0 auto;
    display: flex;
    z-index: 1;
`

const DragIcon = styled(Icon)`
    cursor: grab !important;
`

const ComponentButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    margin-left: 1rem;

    &:enabled {
        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        &:hover {
            background: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    }

    &:disabled {
        ${BaseBoxShadowStyle}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        opacity: 0.4;
    }
`

const DragButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem 0rem;
    margin-right: 0.5rem;
    cursor: grab !important;

    &:enabled {
        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        &:hover {
            background: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    }

    &:disabled {
        ${BaseBoxShadowStyle}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        opacity: 0.4;
    }
`

const RightIcon = styled(Icon)`
    float: right;
    margin-top: 0.3rem;
`

const DeleteButton = styled(ComponentButton)`
    &:hover {
        background: ${props => props.theme.negative} !important;
        color: ${props => props.theme.negativeContrast} !important;
    }
`

const UrlLabel = styled.span`
    font-size: 0.9rem;
    color: grey;
`