import { IonApp, IonContent, IonPage, IonSkeletonText, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { light } from './theme';

/* Core CSS required for Ionic components to work properly */
//import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { Suspense, lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));
const SearchScreen = lazy(() => import('./pages/SearchScreen'));
const Showcase = lazy(() => import('./pages/Showcase'));
const Contact = lazy(() => import('./pages/Contact'));
const Login = lazy(() => import('./pages/Login'));
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const NewBusiness = lazy(() => import('./pages/NewBusiness'));
const MyRecipes = lazy(() => import('./pages/MyRecipes'));
const ManageBusiness = lazy(() => import('./pages/ManageBusiness'));
const ViewBusiness = lazy(() => import('./pages/ViewBusiness'));
const Profile = lazy(() => import('./pages/Profile'));
const Admin = lazy(() => import('./pages/Admin'));
const Business = lazy(() => import('./pages/Business'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const BusinessDashboard = lazy(() => import('./pages/BusinessDashboard'));
import NotFound from './pages/NotFound';
import Searchbar from './components/Navigation/searchbar';
import TopNavigation from './components/Navigation/TopNavigation';
import ThemeWrapper from './components/Theme/theme-wrapper';
import AuthenticationWrapper from './components/Authentication/AuthenticationWrapper';
import NotificationContainer from './components/Forms/Notifications/NotificationContainer';
import PageContainer from './components/Layout/PageContainer';
import { Provider } from './components/ui/provider';
// import LogRocket from 'logrocket';

// LogRocket.init('5lcucy/dishforager');

setupIonicReact();

const App: React.FC = () => {
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
  return (
    <RecoilRoot>
      <Provider>
        <ThemeProvider theme={light}>
          <ThemeWrapper>
            <IonApp>
              <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonContent id='pageContent' scrollEvents placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonReactRouter>
                    <AuthenticationWrapper>
                      <TopNavigation />
                      <NotificationContainer />
                      <Searchbar />
                      <Suspense fallback={
                        <PageContainer>
                          <br />
                          <IonSkeletonText animated={true} style={{ width: '40%', height: '2rem', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '80%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '70%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '90%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '80%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '80%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '60%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                          <IonSkeletonText animated={true} style={{ width: '85%', marginBottom: '1rem' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSkeletonText>
                        </PageContainer>
                      }>
                        <Switch>
                          <Route path="/" component={Home} exact />
                          <Route path="/search" component={SearchScreen} />
                          <Route path="/showcase" component={Showcase} />
                          <Route path="/contact" component={Contact} />
                          <Route path="/login" component={Login} />
                          <Route path="/business" component={Business} />
                          <Route path="/terms" component={TermsOfService} />
                          <Route path="/confirm-email" component={ConfirmEmail} />
                          <Route path="/reset-password" component={ResetPassword} />
                          <Route path="/forgot-password" component={ForgotPassword} />
                          <Route path="/register" component={Register} />
                          <Route path="/register-business"><Register isBusiness /></Route>
                          <Route path="/new-business" component={NewBusiness} />
                          <Route path="/my-recipies" component={MyRecipes} />
                          <Route path="/manage-business" component={ManageBusiness} />
                          <Route path="/view-business/:id/" component={ViewBusiness} />
                          <Route path="/standalone/:id/" component={ViewBusiness} />
                          <Route path="/profile" component={Profile} />
                          <Route path="/admin" component={Admin} />
                          <Route path="/dashboard" component={BusinessDashboard} />
                          <Route path='*' component={NotFound} />
                        </Switch>
                      </Suspense>
                    </AuthenticationWrapper>
                  </IonReactRouter>
                </IonContent>
              </IonPage>
            </IonApp>
          </ThemeWrapper>
        </ThemeProvider>
      </Provider>
    </RecoilRoot >
  );
};

export default App;
