import React, { useEffect } from 'react';
import { NotificationService } from '../../../services/NotificationService';
import { NotificationType } from './Notification';
import { createToaster, Toast } from '@chakra-ui/react/toast';
import { IoMdClose } from 'react-icons/io';
import { Toaster } from '../../ui/toaster';


interface NotificationProps {
    text: string;
    type: NotificationType;
    done?: boolean;
}

function getType(type: NotificationType): "info" | "warning" | "success" | "error" | "loading" {
    if (type == NotificationType.Success) return 'success';
    if (type == NotificationType.Error) return 'error';
    if (type == NotificationType.Info) return 'info';
    if (type == NotificationType.Warning) return 'warning';
    return 'info'
}

const toaster = createToaster({
    placement: 'top',
    duration: 2500
  })

const NotificationContainer = () => {
    useEffect(() => {
        NotificationService.RegisterHandler(handler);
    }, [])

    const handler = (text: string, type: NotificationType) => {
        const params = {
            title: text,
            type: getType(type),
            meta: {
                closable: true,
            }
        }
        if (NotificationService._toastCmd) {
            NotificationService._toastCmd(params)
        } else {
            toaster.create(params)
        }
    }
    return <Toaster useToaster={toaster} />
}

export default NotificationContainer;