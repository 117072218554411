import { useRef, useState } from 'react';
import { BaseActiveBooking, BaseBookingPayment, BaseBookingTableSetup, BookingStatus, ExperienceData, ExperienceType, GenericResponse, ResponseOrError, SpecialOccasion } from '../../../../../../api/api-definitions';
import DataTable, { DataTableItem } from '../../../../../../components/Layout/Datatable';
import { Row, Column } from '../../../../../../components/Layout/Grid';
import { StyledDropdown, StyledTextInput } from '../../../../../../theme/input.styles';
import { TIMEFORMAT, businessNowTime, createMomentFromValue, dateTimeToDecimalTime, formatDate } from '../../../../../../utils/date-helpers';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import CoreButton from '../../../../../../components/Forms/Button';
import { GetStatusColour, GetStatusTextColour } from '../bookingUtils';
import SectionGroup from '../../../../../../components/Forms/SectionGroup';
import { ApiService } from '../../../../../../api/api-connectors';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import Icon from '../../../../../../components/Media/Icon';
import { NotificationService } from '../../../../../../services/NotificationService';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import { BookingSpecialOccasions, EmailAddressSize } from '../../../../../../constants';
import { Badge } from '@chakra-ui/react';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { WarningMessage } from '../../../../../../components/Forms/Messaging';
import BookingInfoSummary from './BookingInfoSummary';
import { ExtendedBookingManagementResponse } from '..';
import { ConvertCurrency } from '../../../../../../utils/currency-helper';
import { useBusiness } from '../../../../../../hooks/useBusiness';

interface ComponentProps {
    bookings: BaseActiveBooking[];
    selectedExperience?: number;
    tables: BaseBookingTableSetup[];
    bookingManagementData: ExtendedBookingManagementResponse;
    setViewBooking: (booking: BaseActiveBooking) => void;
    onUpdateBooking: (showNotification: boolean) => void;
    experiences?: {
        [key: string]: ExperienceData;
        [key: number]: ExperienceData;
    }
}

interface BookingFilter {
    reference?: string;
    name?: string;
    telephone?: string;
    email?: string;
    confirmedStatus?: string;
}

function isValidFilterItem(item: string, filterValue: string): boolean {
    return isNullOrWhitespace(filterValue) || item?.toLowerCase().indexOf(filterValue?.toLowerCase()) > -1
}

function getName(booking: BaseActiveBooking): string {
    return booking.isWalkIn ? 'Walk in' : `${booking.client.firstName} ${booking.client.surname ? booking.client.surname : ''}`
}

export const getBookingActions = (booking: BaseActiveBooking): DropdownItem[] => {
    const bookingIsToday = formatDate(booking.startDateTime, 'DD/MM/YYYY') === formatDate(businessNowTime(), 'DD/MM/YYYY');
    const bookingIsFuture = createMomentFromValue(booking.startDateTime).isAfter(businessNowTime());

    if (booking.status === BookingStatus.Pending) {
        return [
            ...(bookingIsToday ? [{ value: BookingStatus.Seated.toString(), text: 'Seat' }] : []),
            ...(bookingIsToday ? [{ value: BookingStatus.PartiallySeated.toString(), text: 'Partially seat' }] : []),
            ...(bookingIsToday ? [{ value: BookingStatus.Arrived.toString(), text: 'Arrived' }] : []),
            ...(!bookingIsToday ? [{ value: BookingStatus.Completed.toString(), text: 'Complete' }] : []),
            { value: BookingStatus.Pending.toString(), text: 'Pending' },
            { value: BookingStatus.NoShow.toString(), text: 'Mark as no show' },
            { value: BookingStatus.Cancelled.toString(), text: 'Cancel' },
        ]
    }
    if (booking.status === BookingStatus.Seated) {
        return [
            { value: BookingStatus.Seated.toString(), text: 'Seat' },
            { value: BookingStatus.Arrived.toString(), text: 'Arrived' },
            { value: BookingStatus.Completed.toString(), text: 'Complete' },
            { value: BookingStatus.Pending.toString(), text: 'Mark as pending' },
        ]
    }
    if (booking.status === BookingStatus.PartiallySeated || booking.status === BookingStatus.Arrived) {
        return [
            { value: BookingStatus.Seated.toString(), text: 'Seat' },
            { value: BookingStatus.PartiallySeated.toString(), text: 'Partially seat' },
            { value: BookingStatus.Arrived.toString(), text: 'Arrived' },
            { value: BookingStatus.Completed.toString(), text: 'Complete' },
            { value: BookingStatus.Pending.toString(), text: 'Mark as pending' },
        ]
    }
    if (booking.status === BookingStatus.NoShow) {
        return [
            { value: BookingStatus.Seated.toString(), text: 'Seat' },
            { value: BookingStatus.PartiallySeated.toString(), text: 'Partially seat' },
            { value: BookingStatus.Arrived.toString(), text: 'Arrived' },
            { value: BookingStatus.NoShow.toString(), text: 'No show' },
            { value: BookingStatus.Pending.toString(), text: 'Mark as pending' },
        ]
    }
    if (booking.status === BookingStatus.Completed) {
        return [
            { value: BookingStatus.Completed.toString(), text: 'Complete' },
            ...((bookingIsToday || bookingIsFuture) ? [{ value: BookingStatus.Pending.toString(), text: 'Mark as pending' }] : []),
            { value: BookingStatus.Cancelled.toString(), text: 'Cancel' },
            ...(bookingIsToday ? [{ value: BookingStatus.NoShow.toString(), text: 'Mark as no show' }] : []),
        ]
    }
    if (booking.status === BookingStatus.Cancelled) {
        return [
            ...(bookingIsToday ? [{ value: BookingStatus.Seated.toString(), text: 'Seat' }] : []),
            ...(bookingIsToday ? [{ value: BookingStatus.PartiallySeated.toString(), text: 'Partially seat' }] : []),
            { value: BookingStatus.Pending.toString(), text: 'Mark as pending' },
            { value: BookingStatus.Cancelled.toString(), text: 'Cancel' },
            { value: BookingStatus.NoShow.toString(), text: 'Mark as no show' },
        ]
    }
}

const BookingList = ({ bookings, tables, setViewBooking, onUpdateBooking, bookingManagementData, selectedExperience, experiences = {} }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [statusModalTitle, setStatusModalTitle] = useState<string>();
    const [bookingFilter, setBookingFilter] = useState<BookingFilter>({});
    const [payments, setPayments] = useState<BaseBookingPayment[]>();
    const selectedBookingStatus = useRef<BookingStatus>();
    const selectedBooking = useRef<BaseActiveBooking>();

    const tableFilter = (booking: BaseActiveBooking): boolean => {
        return !booking.isHeld &&
            (!!selectedExperience ? booking.experienceId == selectedExperience : (booking.experienceId == undefined || experiences[booking.experienceId].type == ExperienceType.Experience)) &&
            isValidFilterItem(booking.bookingReference, bookingFilter.reference) &&
            isValidFilterItem(booking.client?.telephone, bookingFilter.telephone) &&
            isValidFilterItem(getName(booking), bookingFilter.name) &&
            isValidFilterItem(booking.client?.email, bookingFilter.email)
    }

    const updateStatus = (booking: BaseActiveBooking, status: BookingStatus) => {
        ApiService.bookingPayment.List__GET(booking.id, booking.businessId).then((response) => {
            setPayments(response);
        })
        if (status === BookingStatus.Cancelled) {
            selectedBooking.current = booking;
            selectedBookingStatus.current = status;
            setStatusModalTitle('Are you sure you want to cancel this booking?')
        } else if (status === BookingStatus.NoShow) {
            selectedBooking.current = booking;
            selectedBookingStatus.current = status;
            setStatusModalTitle('Are you sure you want to mark this booking as a no show?')
        } else {
            sendBookingStatus(booking, status)
        }
    }

    const statusModalConfirm = (charge: boolean) => {
        setStatusModalTitle(undefined);
        sendBookingStatus(selectedBooking.current, selectedBookingStatus.current, charge)
    }

    const sendBookingStatus = (booking: BaseActiveBooking, status: BookingStatus, chargeClient = false) => {
        const newBooking = cloneDeep(booking);
        if (newBooking.status !== status) {
            newBooking.status = status;
            const errorMessage = 'Could not update booking. It could be that there is already another booking on that timeslot on the selected table.';
            ApiService.bookings.UpdateStatus__POST({
                bookingId: newBooking.id,
                status: newBooking.status,
                preventCardCharge: !chargeClient
            }).then((response: ResponseOrError) => {
                setStatusModalTitle(undefined)
                if (response.success) {
                    booking.status = newBooking.status;
                    onUpdateBooking(true)
                } else {
                    if (response.error) {
                        NotificationService.Error(response.error)
                    } else {
                        NotificationService.Error(errorMessage)
                    }
                }
            }).catch(() =>
                NotificationService.Error(errorMessage))
        }
    }

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        bookings.filter(tableFilter).forEach((booking) => {
            const tableName = booking.tables.map(x => tables.find(y => y.id === x)?.tableName).join(', ');
            const guests = booking.guests;
            const dataItem: DataTableItem = {
                data: {
                    'id': {
                        value: booking.id,
                        hideColumn: true
                    },
                    'Status': {
                        value: booking.status.toString(),
                        style: {
                            backgroundColor: GetStatusColour(booking),
                            color: GetStatusTextColour(booking)
                        }
                    },
                    'Time': {
                        value: <>
                            {formatDate(booking.startDateTime, TIMEFORMAT)}{(booking.status === BookingStatus.Seated || booking.status == BookingStatus.PartiallySeated) && createMomentFromValue(booking.endDateTime).isBefore(businessNowTime()) ? <> - <i style={{ color: 'red' }}>Overrunning</i></> : null}
                            {!!booking.experienceId && <><br /><Badge colorPalette='messenger'><Icon name={experiences[booking.experienceId]?.type == ExperienceType.Event ? 'calendar' : 'sparkles'} /> {experiences[booking.experienceId]?.name}</Badge></>}
                        </>,
                        sortValue: dateTimeToDecimalTime(booking.startDateTime)
                    },
                    'Name': {
                        value: <>{booking.specialOccasion != SpecialOccasion.NotSet && <Icon name={BookingSpecialOccasions()[booking.specialOccasion].icon} />}{getName(booking)}</>,
                    },
                    'Table': {
                        value: tableName ? tableName : <Icon name='ban'></Icon>
                    },
                    'Covers': {
                        value: guests
                    },
                    'Reference': {
                        value: booking.bookingReference
                    },
                    'View': {
                        value: <CoreButton type='secondary' onClick={() => setViewBooking(booking)}>View</CoreButton>,
                        hideName: true
                    },
                    'Actions': {
                        value: <StyledDropdown addDefault={false} value={booking.status.toString()} items={getBookingActions(booking)} onChange={(e) => updateStatus(booking, e.target.value as BookingStatus)} />,
                        hideName: true,
                        textLeft: true,
                        style: { minWidth: '8rem' }
                    }
                },
            }
            items.push(dataItem);
        });
        return items;
    }

    const outstandingPaymentAmount = payments ? payments.reduce((total, payment) => total + (!payment.fufilled ? payment.amount : 0), 0) : 0;
    const baseBookingInfo = selectedBooking.current ? <BookingInfoSummary booking={selectedBooking.current} bookingManagementData={bookingManagementData} /> : <></>;

    return (
        <ListWrap>
            {statusModalTitle &&
                <CoreModal
                    onClose={() => setStatusModalTitle(undefined)}
                    small
                    noClose
                    title={statusModalTitle}
                    actionBar={
                        outstandingPaymentAmount == 0 ? <CoreButton disabled={!payments} onClick={() => sendBookingStatus(selectedBooking.current, selectedBookingStatus.current)}>Confirm</CoreButton> : undefined
                    }>
                    <Row>
                        {baseBookingInfo}
                    </Row>
                    {payments && outstandingPaymentAmount > 0 &&
                        <>
                            <WarningMessage>Booking has outstanding payments of&nbsp;<strong>{ConvertCurrency({ code: business?.currencyCode, amount: outstandingPaymentAmount })}</strong></WarningMessage>
                            <br />
                            <Row>
                                <Column size={6}>
                                    <CoreButton type='warning' full disabled={!payments} onClick={() => statusModalConfirm(false)}>Confirm without charging client</CoreButton>
                                </Column>
                                <Column size={6}>
                                    <CoreButton full disabled={!payments} onClick={() => statusModalConfirm(true)}>Confirm and charge client</CoreButton>
                                </Column>
                            </Row>
                        </>
                    }
                </CoreModal>
            }
            <SectionGroup name='Filter'>
                <FormWrapper onUpdate={setBookingFilter}>
                    {({ id, valid }) => (
                        <Row>
                            <Column size={3} tablet={6} mobile={12}>
                                <StyledTextInput model='reference' label='Booking reference' value={bookingFilter.reference} />
                            </Column>
                            <Column size={3} tablet={6} mobile={12}>
                                <StyledTextInput model='name' label='Name' value={bookingFilter.name} />
                            </Column>
                            <Column size={3} tablet={6} mobile={12}>
                                <StyledTextInput model='telephone' label='Telephone' value={bookingFilter.telephone} />
                            </Column>
                            <Column size={3} tablet={6} mobile={12}>
                                <StyledTextInput model='email' label='Email' max={EmailAddressSize} value={bookingFilter.email} />
                            </Column>
                            {/* <Column size={3} tablet={6} mobile={12} noMarginBottom>
                                <StyledDropdown defaultText='All' value={bookingFilter.confirmedStatus} items={[{value: 'unconfimed', text: 'Unconfirmed only' }, {value: 'confimed', text: 'Confirmed only' }]} model='confirmedStatus' inputName='confirmedStatus' label='Confirmed status'  />
                            </Column> */}
                        </Row>
                    )}
                </FormWrapper>
            </SectionGroup>
            <DataTable defaultSort='Time' data={gentable()} />
        </ListWrap>
    );
};

export const ListWrap = styled.div`
    min-height: 10rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        // height: calc(100vh - 310px);
        position: relative;
        overflow: scroll;
        width: 100%;
        flex: 1;
        -webkit-overflow-scrolling: touch;
    }
`;

export default BookingList;