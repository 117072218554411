import { IonSkeletonText, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router-dom';
import { RecoilEnv, RecoilRoot } from 'recoil';
import TopNavigation from './components/Navigation/TopNavigation';
import ThemeWrapper from './components/Theme/theme-wrapper';
import AuthenticationWrapper from './components/Authentication/AuthenticationWrapper';
import NotFound from './pages/NotFound';
import NotificationContainer from './components/Forms/Notifications/NotificationContainer';
import PageContainer from './components/Layout/PageContainer';
import { Suspense, lazy } from 'react';
import { PWAErrorBoundry } from './components/Boundries/PWAErrorBoundry';
import BusinessDashboard from './pages/BusinessDashboard';
import './theme/variables.css';
import PushNotificationWrapper from './components/Navigation/PushNotificationWrapper';
import { Provider } from './components/ui/provider';
import ScrollToTop from './components/Navigation/ScrollToTop';

const Home = lazy(() => import('./pages/Home'));
const SearchScreen = lazy(() => import('./pages/SearchScreen'));
const Showcase = lazy(() => import('./pages/Showcase'));
const Contact = lazy(() => import('./pages/Contact'));
const Login = lazy(() => import('./pages/Login'));
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Register = lazy(() => import('./pages/Register'));
const NewBusiness = lazy(() => import('./pages/NewBusiness'));
const MyRecipes = lazy(() => import('./pages/MyRecipes'));
const ManageBusiness = lazy(() => import('./pages/ManageBusiness'));
const ViewBusiness = lazy(() => import('./pages/ViewBusiness'));
const Profile = lazy(() => import('./pages/Profile'));
const Admin = lazy(() => import('./pages/Admin'));
//const BusinessDashboard = lazy(() => import('./pages/BusinessDashboard'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Business = lazy(() => import('./pages/Business'));
const BookingModuleModify = lazy(() => import('./bookingModule/pages/Modify'));
const BookingModuleConfirm = lazy(() => import('./bookingModule/pages/Confirm'));

setupIonicReact();

const StandardWrapper = ({ children }) => children;

const DashboardApp: React.FC = () => {
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

  const ComponentToUse = navigator.serviceWorker && window.location.protocol == 'https:' ? PushNotificationWrapper : StandardWrapper;
  // const ComponentToUse = StandardWrapper;
  
  return (
    <PWAErrorBoundry>
      <RecoilRoot>
        <Provider>
          <ThemeWrapper>
            <IonReactRouter>
              <AuthenticationWrapper>
                <ComponentToUse>
                  <TopNavigation hideOptions />
                  <NotificationContainer />
                  <Suspense fallback={
                    <PageContainer>
                      <br />
                      {/* <IonSkeletonText animated={true} style={{ width: '40%', height: '2rem', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '80%', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '70%', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '90%', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '80%', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '80%', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '60%', marginBottom: '1rem' }}></IonSkeletonText>
                      <IonSkeletonText animated={true} style={{ width: '85%', marginBottom: '1rem' }}></IonSkeletonText> */}
                    </PageContainer>
                  }>
                    <ScrollToTop />
                    <Switch>
                      <Route path="/" component={Business} exact />
                      <Route path="/manage-my-booking/:location/:ref/:hash" component={BookingModuleModify} exact />
                      <Route path="/confirm-my-booking/:location/:ref/:hash" component={BookingModuleConfirm} exact />
                      <Route path="/login" component={Login} />
                      <Route path="/business" component={Business} />
                      <Route path="/contact" component={Contact} />
                      <Route path="/confirm-email" component={ConfirmEmail} />
                      <Route path="/terms" component={TermsOfService} />
                      <Route path="/privacy" component={PrivacyPolicy} />
                      <Route path="/reset-password" component={ResetPassword} />
                      <Route path="/forgot-password" component={ForgotPassword} />
                      <Route path="/register" component={Register} />
                      <Route path="/register-business"><Register isBusiness /></Route>
                      <Route path="/new-business" component={NewBusiness} />
                      <Route path="/profile" component={Profile} />
                      <Route path="/dashboard" component={BusinessDashboard} />
                      <Route path='*' component={NotFound} />
                    </Switch>
                  </Suspense>
                </ComponentToUse>
              </AuthenticationWrapper>
            </IonReactRouter>
          </ThemeWrapper>
        </Provider>
      </RecoilRoot>
    </PWAErrorBoundry>
  );
};

export default DashboardApp;
