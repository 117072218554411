import React, { useState } from 'react';
import Icon from '../../../../components/Media/Icon';
import { ApiBusinessBookingDetails, ApiMakeBookingRequest, BookingError, EnumWidgetFieldType, HeldBookingResponse } from '../../../../api/api-definitions';
import FormWrapper from '../../../../components/Forms/FormWrapper';
import { Row, Column } from '../../../../components/Layout/Grid';
import { TELEPHONE_REGEX, EmailAddressSize, EMAIL_REGEX, Constants } from '../../../../constants';
import { StyledTextInput, StyledTextarea, StyledDropdown, StyledNumberInput } from '../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { Button, InfoMessage, PaymentWarningText, PaymentWarningTitle } from '../../../booking.styles';
import Checkbox from '../../../../components/Forms/Checkbox';
import { DropdownItem } from '../../../../components/Forms/Dropdown';
import DepositInfo from './DepositInfo';
import AccordianOption from './AccordianOption';
import TelephoneInput from '../../../../components/Forms/TelephoneInput';
import { AccordionRoot } from '../../../../components/ui/accordion';
import { ErrorMessage } from '../../../../components/Forms/Messaging';
import { depositUponMakeBookingRequired, noShowOrCancellationPaymentRequired, noShowPaymentRequired } from '../utils';
import { ConvertCurrency } from '../../../../utils/currency-helper';

interface ExtentedDetails extends ApiMakeBookingRequest {
    depositPolicy?: boolean;
}

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    bookingErrors: BookingError[];
    bookingHash: string;
    selectedTime: string;
    specialOccasionOptions: DropdownItem[];
    details: ExtentedDetails;
    holdDetails: HeldBookingResponse;
    bookingError: string;
    setDetails: (details: ApiMakeBookingRequest) => void;
    takeClientDetails: () => void;
    nameToDisplay: string;
}

const CustomerDetails = ({
    business,
    bookingErrors,
    bookingHash,
    selectedTime,
    specialOccasionOptions,
    details,
    holdDetails,
    bookingError,
    setDetails,
    takeClientDetails,
    nameToDisplay
}: ComponentProps) => {
    const [depositError, setDepositError] = useState(false)

    const requiresCardDetails = noShowPaymentRequired(holdDetails) ||
        noShowOrCancellationPaymentRequired(holdDetails) ||
        depositUponMakeBookingRequired(holdDetails);

    const onContinueClick = () => {
        if (requiresCardDetails && !details.depositPolicy) {
            setDepositError(true)
        } else {
            takeClientDetails()
        }
    }

    return (
        <FormWrapper onUpdate={setDetails}>
            {({ id, valid }) => (
                <>
                    {isNullOrWhitespace(bookingError) && (!bookingErrors || bookingErrors.length === 0) &&
                        <>

                            {!bookingHash &&
                                <>
                                    <Row hidden={!!!selectedTime}>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='First name' model='firstName' max={100} value={details.firstName} />
                                        </Column>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='Last name' model='lastName' max={100} value={details.lastName} />
                                        </Column>
                                    </Row>
                                    <Row hidden={!!!selectedTime}>
                                        <Column size={6} mobile={12}>
                                            <TelephoneInput
                                                required
                                                countryCodeModel='telephoneCountryCode'
                                                telephoneModel='telephone'
                                                countryCode={details.telephoneCountryCode}
                                                value={details.telephone} />
                                            {/* <StyledTextInput required label='Telephone' model='telephone' max={20} value={details.telephone} regexMatch={TELEPHONE_REGEX} regexError='Value must be a telephone number' /> */}
                                        </Column>
                                        <Column size={6} mobile={12}>
                                            <StyledTextInput required label='Email' max={EmailAddressSize} model='email' value={details.email} regexMatch={EMAIL_REGEX} regexError='Value must be a valid email address' />
                                        </Column>
                                    </Row>
                                </>
                            }
                            <Row hidden={!!!selectedTime}>
                                {specialOccasionOptions.length > 0 &&
                                    <Column size={12} mobile={12}>
                                        <StyledDropdown label='Select an occasion' model='specialOccasion' value={details.specialOccasion} items={specialOccasionOptions} />
                                    </Column>
                                }
                                <Column size={12} mobile={12}>
                                    <AccordionRoot collapsible>
                                        <AccordianOption label='Are there any dietary requirements?'>
                                            <StyledTextarea maxLength={600} model='dietaryRequirements' value={details.dietaryRequirements} />
                                        </AccordianOption>
                                        <AccordianOption label='Any special requests?'>
                                            <StyledTextarea maxLength={600} model='specialRequests' value={details.specialRequests} />
                                        </AccordianOption>
                                    </AccordionRoot>
                                </Column>
                                {!bookingHash && business?.customFields?.map((field, index) => (
                                    <Column size={12} mobile={12} key={`Custom-${index}`}>
                                        {field.type == EnumWidgetFieldType.Text && <StyledTextInput required={field.required} label={field.label} model={`Custom-${index}`} value={details[`Custom-${index}`]} />}
                                        {field.type == EnumWidgetFieldType.Number && <StyledNumberInput required={field.required} label={field.label} model={`Custom-${index}`} value={details[`Custom-${index}`]} />}
                                        {field.type == EnumWidgetFieldType.Dropdown && <StyledDropdown required={field.required} label={field.label} value={details[`Custom-${index}`]} items={field.values.split('|').map(x => ({ value: x }))} model={`Custom-${index}`} />}
                                    </Column>
                                ))}
                                {!bookingHash &&
                                    <>
                                        <Column size={12} mobile={12}>
                                            <Checkbox model='updates' checked={details.updates} label={<>Yes, I would like to receive updates and promotions from <strong>{business.name}</strong>.</>} />
                                        </Column>
                                        <Column size={12} mobile={12} noMarginBottom>
                                            <Checkbox model='platformUpdates' checked={details.platformUpdates} label={<>Yes, I would like to receive news, competitions and promotions from <strong>{Constants.businessName}</strong>.</>} />
                                        </Column>
                                    </>
                                }
                            </Row>
                            {holdDetails?.depositAmount < 0 &&
                                <InfoMessage widgetTheme={business?.theme}>
                                    Upon confirming this amendment, you will be refunded&nbsp;<strong>{ConvertCurrency({ code: holdDetails.businessCurrencyCode, amount: Math.abs(holdDetails.depositAmount) })}</strong>&nbsp;from the previously paid deposit.
                                </InfoMessage>
                            }
                            {requiresCardDetails && <DepositInfo business={business} setDepositError={setDepositError} holdDetails={holdDetails} nameToDisplay={nameToDisplay} />}
                            {depositError &&
                                <ErrorMessage>
                                    Please acknowledge the deposit policy above
                                </ErrorMessage>
                            }
                        </>
                    }
                    {bookingErrors && bookingErrors.length > 0 &&
                        <>
                            <br />
                            {bookingErrors.map((e, index) => (
                                <ErrorMessage key={`Error-${index}`}>
                                    {e.message}
                                </ErrorMessage>
                            ))}
                        </>
                    }
                    {bookingError && !bookingHash && (!bookingErrors || bookingErrors.length == 0) &&
                        <>
                            <br />
                            <ErrorMessage key={`Error`}>{bookingError}</ErrorMessage>
                        </>
                    }
                    <Button disabled={!valid || !isNullOrWhitespace(bookingError) || (bookingErrors && bookingErrors.length > 0)} style={{ marginTop: '1.5rem' }} widgetTheme={business?.theme} type='button' onClick={onContinueClick}>
                        {bookingHash ?
                            (requiresCardDetails ? 'Continue to payment details' : 'Confirm amendment') :
                            (requiresCardDetails ? 'Continue to payment details' : (isNullOrWhitespace(business.reserveButtonTextStepTwo) ? 'Complete booking' : business.reserveButtonTextStepTwo))}
                    </Button>
                    {bookingError && bookingHash &&
                        <>
                            <ErrorMessage>{bookingError}</ErrorMessage>
                        </>
                    }
                </>
            )}
        </FormWrapper>
    );
};

export default CustomerDetails;