import { Badge } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

const DayLabels = ({ monday, tuesday, wednesday, thursday, friday, saturday, sunday }: ComponentProps) => {
    return (
        <>
            {monday && <DayLabel colorPalette='teal'>Mon</DayLabel>}
            {tuesday && <DayLabel colorPalette='teal'>Tue</DayLabel>}
            {wednesday && <DayLabel colorPalette='teal'>Wed</DayLabel>}
            {thursday && <DayLabel colorPalette='teal'>Thu</DayLabel>}
            {friday && <DayLabel colorPalette='teal'>Fri</DayLabel>}
            {saturday && <DayLabel colorPalette='blue'>Sat</DayLabel>}
            {sunday && <DayLabel colorPalette='blue'>Sun</DayLabel>}
        </>
    );
};

const DayLabel = styled(Badge)`
    margin-right: 0.4rem;
`

export default DayLabels;