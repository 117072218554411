import React from 'react';
import styled from "styled-components";
import { HEADING_FONT } from '../../constants';
import Icon from '../Media/Icon';
import CoreButton from '../Forms/Button';
import DocsButton from './DocsButton';
import BREAKPOINTS from '../../config/breakpoints';

const DashboardHeaderStyle = styled.div`
    padding-top: 2rem;
    padding-bottom: 1rem;

    h1 {
        font-weight: bold;
        color: ${props => props.theme.primaryText};
        ${HEADING_FONT}

        span {
          margin-right: 1rem;
          color: ${props => props.theme.secondaryText};
        }

        @media (max-width: ${BREAKPOINTS.tablet}px) {
          font-size: 1.5rem !important;
        }
    }
`;


interface ComponentProps {
    title: React.ReactNode;
    children?: React.ReactNode;
    docsLink?: string;
    icon?: string;
}

const DashboardHeader = ({ title, children, docsLink, icon }: ComponentProps) => {
  return (
    <DashboardHeaderStyle>
        {docsLink && <DocsButton url={docsLink} />}
        <h1>{icon && <Icon duo name={icon} />}{title}</h1>
        {children && <div>{children}</div>}
    </DashboardHeaderStyle>
  );
};

export default DashboardHeader;