import React, { useState } from 'react';
import CoreButton from '../../../../../../../components/Forms/Button';
import { BaseBookingDurationRule } from '../../../../../../../api/api-definitions';
import DataTable, { DataTableItem } from '../../../../../../../components/Layout/Datatable';
import { DISPLAY_DATE_FORMAT, TIMEFORMAT, formatDate } from '../../../../../../../utils/date-helpers';
import { ApiService } from '../../../../../../../api/api-connectors';
import { useBusiness } from '../../../../../../../hooks/useBusiness';
import { NotificationService } from '../../../../../../../services/NotificationService';
import BookingRuleForm from './durationRuleForm';
import DashboardAddButton from '../../../../../../../components/Dashboard/AddButton';
import { useApi } from '../../../../../../../hooks/useApi';
import Loader from '../../../../../../../components/Layout/Loader';
import DashboardPanel from '../../../../../../../components/Dashboard/Panel';
import { InfoMessage } from '../../../../../../../components/Forms/Messaging';
import { DateBandValidityItem } from '../../../../../../../components/Forms/Calendar';
import { ExperienceService } from '../../Experiences/experienceService';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import ExperienceInfoBadge from '../../Experiences/experienceInfoBadge';
import DayLabels from '../../modules/DayLabels';

const defaultDayValue = true;
const defaultDataForRule: BaseBookingDurationRule = {
    id: undefined,
    monday: defaultDayValue,
    tuesday: defaultDayValue,
    wednesday: defaultDayValue,
    thursday: defaultDayValue,
    friday: defaultDayValue,
    saturday: defaultDayValue,
    sunday: defaultDayValue,
    businessId: undefined,
    durationInMinutes: undefined
}

export const generateBookingRulesTable = (
    rules: BaseBookingDurationRule[],
    editAction: (item: BaseBookingDurationRule) => void = null,
    deleteAction: (item: BaseBookingDurationRule) => void = null,
    experienceId?: number
): DataTableItem[] => {
    const items: DataTableItem[] = [];
    rules?.forEach((item) => {
        if (!experienceId || item.experienceId == experienceId) {
            const dataItem: DataTableItem = {
                data: {
                    'Duration (mins)': {
                        value: item.durationInMinutes
                    },
                    'Dates': {
                        value: item.dateFrom ? `${formatDate(item.dateFrom, DISPLAY_DATE_FORMAT)} ${item.dateTo ? `to ${formatDate(item.dateTo, DISPLAY_DATE_FORMAT)}` : 'onwards'}` : '-',
                    },
                    'Times': {
                        value: item.timeFrom ? `${formatDate(item.timeFrom, TIMEFORMAT)} to ${formatDate(item.timeTo, TIMEFORMAT)}` : '-',
                    },
                    'Party size': {
                        value: item.partySizeFrom ? `${item.partySizeFrom}${item.partySizeTo ? ` to ${item.partySizeTo}` : '+'}` : '-',
                    },
                    'Days': {
                        value: <DayLabels {...item} />
                    },
                    'Experience': {
                        value: <>
                            {!experienceId && !!item.experienceId && <ExperienceInfoBadge experienceId={item.experienceId} />}
                        </>,
                        hideName: true
                    },
                    ...(editAction && deleteAction ? {
                        'Edit': {
                            value: <CoreButton disabled={!experienceId && !!item.experienceId} type='secondary' onClick={() => editAction(item)}>Edit</CoreButton>,
                            hideName: true
                        },
                        'Delete': {
                            value: <CoreButton disabled={!experienceId && !!item.experienceId} type='danger' onClick={() => deleteAction(item)}>Delete</CoreButton>,
                            hideName: true
                        },
                    } : {})
                }
            }
            items.push(dataItem);
        }
    });
    return items;
}

interface Props {
    experienceId?: number;
    validDateBands?: DateBandValidityItem[];
}

const DurationRules = ({ experienceId, validDateBands }: Props) => {
    const [businessLoaded, businessData] = useBusiness();
    const activeOnly = !experienceId; // non-expired records only unless we are loading experience setup
    const [rulesLoaded, durationRules, setDurationRules, updateDurationRule, loadDurationRules] = useApi<BaseBookingDurationRule[]>(
        ApiService.bookingDurationRules.Get__GET,
        { id: businessData.id, experienceId: experienceId || 0, activeOnly: activeOnly },
        'Failed to load duration rules',
        (response) => ExperienceService._durationRules = response
    );
    const [editRule, setEditRule] = useState<BaseBookingDurationRule>();
    const [saving, setSaving] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<BaseBookingDurationRule>();

    const deleteDurationRule = () => {
        setSaving(true)
        if (!!itemToDelete) {
            ApiService.bookingDurationRules.Delete__DELETE(itemToDelete).then((response) => {
                if (response.success) {
                    NotificationService.Confirm('Booking duration rule deleted successfully')
                    setItemToDelete(undefined)
                } else {
                    NotificationService.Error('Something went wrong, could not remove booking duration rule.')
                }
            }).catch(() => {
                NotificationService.Error('Sorry, there was an error saving your changes.')
            }).finally(() => {
                setSaving(false)
                loadDurationRules()
            })
        }
    }

    const loadRuleTable = () => {
        return generateBookingRulesTable(durationRules, setEditRule, setItemToDelete, experienceId);
    }

    const warningMessage = "Create rules to configure booking durations (in minutes) based on party sizes, date ranges, and time ranges. ";
    return (
        <div>
            {itemToDelete &&
                <CoreModal
                    small
                    onClose={() => setItemToDelete(undefined)}
                    title='Are you sure you want to delete this rule?'
                    slimPanel
                    actionBar={<CoreButton type='danger' requesting={saving} disabled={saving} onClick={() => deleteDurationRule()}>Confirm</CoreButton>}
                >

                </CoreModal>
            }
            {editRule &&
                <BookingRuleForm
                    rule={editRule}
                    allRules={durationRules}
                    close={() => { setEditRule(undefined); loadDurationRules() }}
                    experienceId={experienceId}
                    validDateBands={validDateBands}
                />
            }
            {!experienceId && <DashboardPanel title='Booking duration rules'></DashboardPanel>}
            {!experienceId && <InfoMessage>
                {warningMessage}
                If for a given date / party size / time the rule is not configured, the system will use the default value that was configured above ("Default booking duration").
            </InfoMessage>}
            {!!experienceId && <InfoMessage>
                {warningMessage}
                If for a given date / party size / time the rule is not configured, the system will use the "Default booking duration".
            </InfoMessage>}
            <br />
            {saving && <Loader />}
            {!saving &&
                <>
                    {durationRules?.length === 0 &&
                        <InfoMessage>There are no booking duration rules currently set up.</InfoMessage>
                    }
                    {durationRules?.length > 0 &&
                        <DataTable data={loadRuleTable()} />
                    }
                    <br />
                    <DashboardAddButton onClick={() => setEditRule({ ...defaultDataForRule, businessId: businessData.id, experienceId, durationInMinutes: 0 })}>Add a booking duration rule</DashboardAddButton>
                </>
            }
        </div>
    );
};

export default DurationRules;