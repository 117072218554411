import React, { useState } from 'react';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import ActionBox from '../../../../../../components/Forms/Messaging';
import { useHistory } from 'react-router';
import { NotificationService } from '../../../../../../services/NotificationService';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseExperience, ExperienceType } from '../../../../../../api/api-definitions';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { BookingTypeNameSize } from '../../../../../../constants';
import InfoButton from '../../../../../../components/Cta/InfoButton';

interface ComponentProps {
    onCancel: () => void;
    businessId: number;
}

const AddExperienceModal = ({ onCancel, businessId }: ComponentProps) => {
    const [addExperienceData, setAddExperienceData] = useState<BaseExperience>({
        id: 0,
        businessId: businessId,
        name: '',
        displayName: '',
        description: '',
        pricePerGuest: 0,
        published: false,
        type: ExperienceType.NotSet
    });
    const [step, setStep] = useState<0 | 1>(0);
    const [saving, setSaving] = useState<boolean>(false);
    const history = useHistory();

    const selectType = (type: ExperienceType) => {
        setAddExperienceData({ ...addExperienceData, type })
        setStep(1);
    }

    const onAdd = () => {
        setSaving(true);
        ApiService.experience.Add__PUT(addExperienceData).then((response) => {
            if (response.success) {
                NotificationService.Confirm(addExperienceData.type == ExperienceType.Event ? 'Event added' : 'Experience added')
                history.push(window.location.pathname + '/' + response.info)
            } else {
                NotificationService.Error('Sorry, there was a problem adding the event or experience.')
            }
        })
            .catch(() => NotificationService.Error('Sorry, there was a problem adding the event or experience.'))
            .finally(() => setSaving(false));
    }

    return (
        <CoreModal tall={step == 0}
            small
            onClose={onCancel}
            title={step == 0 ? 'Add new experience or event' : `Add new ${addExperienceData.type == ExperienceType.Event ? 'event' : 'experience'}`}
            actionBar={step == 1 ? <CoreButton requesting={saving} disabled={isNullOrWhitespace(addExperienceData.name) || isNullOrWhitespace(addExperienceData.name) || addExperienceData.name.length > 20 || saving} onClick={onAdd}>Add {addExperienceData.type == ExperienceType.Event ? 'event' : 'experience'}</CoreButton> : undefined}>
            {step == 0 &&
                <Row>
                    <Column size={12}>
                        Please select the applicable option:
                    </Column>
                    <Column size={6}>
                        <ActionBox
                            icon='star'
                            inline
                            onClick={() => selectType(ExperienceType.Experience)}
                            title='Experience'>
                            These are generally recurring and are part of your normal service hours.
                            Examples are <strong>"Tasting menu"</strong>, <strong>"Set menu"</strong>, <strong>"Afternoon tea"</strong>, <strong>"Aperitivo"</strong> and others.
                        </ActionBox>
                    </Column>
                    <Column size={6}>
                        <ActionBox
                            icon='calendar'
                            inline
                            onClick={() => selectType(ExperienceType.Event)}
                            title='Event'>
                            Events are one off occasions that have a dedicated space set up.
                            Examples are <strong>"Cooking demo"</strong>, <strong>"Guest chef evening"</strong>, <strong>"Celebratory party"</strong> and others.
                        </ActionBox>
                    </Column>
                </Row>
            }
            {step == 1 &&
                <>
                    <Row>
                    <Column size={12}>
                        Please enter the name of the {addExperienceData.type == ExperienceType.Event ? 'event' : 'experience'}:
                    </Column>
                    <Column size={6}>
                        <StyledTextInput
                            required
                            label='Name'
                            max={BookingTypeNameSize}
                            value={addExperienceData.displayName}
                            onBlur={() => setAddExperienceData({...addExperienceData, name: isNullOrWhitespace(addExperienceData.name) && addExperienceData.displayName.length <= 20 ? addExperienceData.displayName : addExperienceData.name } )}
                            onChange={(e) => setAddExperienceData({ ...addExperienceData, displayName: e.target.value })}
                        />
                    </Column>
                    <Column size={6}>
                        <StyledTextInput
                            max={20}
                            model='name'
                            label={<>Short name <InfoButton>This short name is visible on the widget and dashboard in scenarios where there is not enough space to display the full name. For example, an experience called "Bottomless afternoon tea for two" could be set to "Afternoon tea" in the short name field. This name will be visible to your staff when making bookings internally and on the widget where the long name can't be displayed.</InfoButton></>}
                            required
                            value={addExperienceData?.name}
                            onChange={(e) => setAddExperienceData({ ...addExperienceData, name: e.target.value })}
                        />
                    </Column>
                </Row>
                <br />
                <br />
                <br />
                </>
            }
        </CoreModal>
    );
};

export default AddExperienceModal;