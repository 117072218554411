import React from 'react';
import { BookingQuickInfoItem, BookingQuickInfoTwo, Button, InfoMessage, SubTitle } from '../../../booking.styles';
import Icon from '../../../../components/Media/Icon';
import moment, { Moment } from 'moment';
import { WidgetImageLayout, ExperienceType, ApiBusinessBookingDetails, WebEventsAvailabilityResponse } from '../../../../api/api-definitions';
import { DATE_WITH_DAY_OF_WEEK_FORMAT, TIMEFORMAT } from '../../../../utils/date-helpers';
import BookingExperienceOption from './BookingExperienceOption';
import { BookingFormData } from '..';
import Loader from '../../../../components/Layout/Loader';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    formData: BookingFormData;
    selectedTimeForExperienceSelection: string;
    eventsAvailabilityResponse: WebEventsAvailabilityResponse;
    selectedExperience: string;
    bookingError: string;
    loading: boolean;
    updateSelectedExperience: (experienceGuid?: string) => void;
    confirmHold: (date?: Moment, time?: string) => void;
}

const ExperienceSelection = ({
    business,
    formData,
    selectedTimeForExperienceSelection,
    eventsAvailabilityResponse,
    selectedExperience,
    bookingError,
    loading,
    updateSelectedExperience,
    confirmHold,
}: ComponentProps) => {
    if (loading) return <Loader />
    return (
        <>
            <BookingQuickInfoTwo>
                <BookingQuickInfoItem expand>
                    <Icon name='calendar' /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}
                </BookingQuickInfoItem>
                <BookingQuickInfoItem>
                    <Icon name='clock' /> {moment(selectedTimeForExperienceSelection).format(TIMEFORMAT)}
                </BookingQuickInfoItem>
                <BookingQuickInfoItem>
                    <Icon name='user-friends' /> {formData.guests}
                </BookingQuickInfoItem>
            </BookingQuickInfoTwo>
            <SubTitle widgetTheme={business?.theme}>Please select an option:</SubTitle>
            <BookingExperienceOption
                onSelect={eventsAvailabilityResponse.standardAvailable ? updateSelectedExperience : undefined}
                guid={''}
                isSelected={selectedExperience == ''}
                business={business}
                layout={business?.standardBookingImageLayout}
                unavailable={!eventsAvailabilityResponse.standardAvailable}
                title={isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking' : business?.standardBookingName}
                description={business?.standardBookingDescription}
                image={business?.standardBookingImageUrl}
                price={0}
                type={ExperienceType.NotSet}
            />
            {Object.keys(business.specialEvents).map(experienceGuid => {
                if (!eventsAvailabilityResponse.availableIds[experienceGuid]) return null;
                return (
                    <BookingExperienceOption
                    guid={experienceGuid}
                        onSelect={eventsAvailabilityResponse.availableIds[experienceGuid] ? updateSelectedExperience : undefined}
                        isSelected={selectedExperience == experienceGuid}
                        key={'select-event-' + experienceGuid}
                        business={business}
                        location={business?.specialEvents[experienceGuid].location}
                        layout={business?.specialEvents[experienceGuid].layout}
                        unavailable={!eventsAvailabilityResponse.availableIds[experienceGuid]}
                        title={business?.specialEvents[experienceGuid].name}
                        image={business?.specialEvents[experienceGuid].imageUrl}
                        menuUrl={business?.specialEvents[experienceGuid].menuUrl}
                        description={business?.specialEvents[experienceGuid].description}
                        price={business?.specialEvents[experienceGuid].price}
                        type={business?.specialEvents[experienceGuid].type}
                    />
                )
            })}
            {Object.keys(eventsAvailabilityResponse.availableIds).map(experienceGuid => {
                const matchingExpeirence = business.experiences[experienceGuid];
                if (!matchingExpeirence || matchingExpeirence.type != ExperienceType.Experience) return null;
                return (
                    <BookingExperienceOption
                        guid={experienceGuid}
                        onSelect={updateSelectedExperience}
                        isSelected={selectedExperience == experienceGuid}
                        key={'select-event-' + experienceGuid}
                        business={business}
                        location={business?.experiences[experienceGuid].location}
                        layout={business?.experiences[experienceGuid].layout}
                        title={business?.experiences[experienceGuid].name}
                        image={business?.experiences[experienceGuid].imageUrl}
                        menuUrl={business?.experiences[experienceGuid].menuUrl}
                        description={business?.experiences[experienceGuid].description}
                        price={business?.experiences[experienceGuid].price}
                        type={business?.experiences[experienceGuid].type}
                    />
                )
            })}
            {bookingError &&
                <>
                    <InfoMessage marginTop widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                </>
            }
            <Button disabled={selectedExperience == null || selectedExperience == undefined} widgetTheme={business?.theme} type='button' onClick={() => confirmHold()}>
                Confirm selection
            </Button>
        </>
    );
};

export default ExperienceSelection;