import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseActiveBooking, BaseBookingPayment, BookingSource, BookingStatus, ExperienceType, SpecialOccasion, ResponseOrError } from '../../../../../../api/api-definitions';
import CoreButton from '../../../../../../components/Forms/Button';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { NotificationService } from '../../../../../../services/NotificationService';
import { DISPLAY_DATE_TIME_FORMAT, TIMEFORMAT, createMomentFromValue, formatDate, getDurationText, minutesToTime } from '../../../../../../utils/date-helpers';
import { ExtendedTable } from '../TableLayout';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { getBookingActions } from '../modules/BookingList';
import { ExtendedBookingManagementResponse } from '..';
import Icon from '../../../../../../components/Media/Icon';
import Loader from '../../../../../../components/Layout/Loader';
import { StyledTextarea } from '../../../../../../theme/input.styles';
import { GetStatusColour, GetStatusTextColour } from '../bookingUtils';
import AmendBookingModal from './AmendBookingModal';
import { TabBar, TabButton } from '../../../../../../components/Layout/Tabs';
import GuestInfo from '../../../ParentBusinessModules/Guestbook/GuestInfo';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { Alert, Badge } from '@chakra-ui/react';
import BREAKPOINTS from '../../../../../../config/breakpoints';
import InfoButton from '../../../../../../components/Cta/InfoButton';
import { BookingSpecialOccasions } from '../../../../../../constants';
import useOffline from '../../../../../../hooks/useOffline';
import GuestInfoOffline from '../../../ParentBusinessModules/Guestbook/GuestInfoOffline';
import BookingHistory from './components/bookingHistory';
import BookingPayments from './components/bookingPayments';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { ConvertCurrency } from '../../../../../../utils/currency-helper';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { createUUID } from '../../../../../../utils/data-helpers';
import BookingInfoSummary from '../modules/BookingInfoSummary';

interface ComponentProps {
    booking: BaseActiveBooking;
    tables: ExtendedTable[];
    bookingManagementData?: ExtendedBookingManagementResponse;
    locationId: string;
    onClose: () => void;
    onUpdate: (showNotification: boolean) => void;
    updateBooking: (booking: BaseActiveBooking) => void;
}

const ActionBar = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        margin-bottom: 1rem;
    }
`

const Action = styled.div`
    cursor: pointer;
    text-align: center;
    padding: 1rem;
    flex: 1;
    margin-bottom: 1rem;
    max-width: 362px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        min-width: 100%;
        margin-bottom: 0.5rem;
        padding: 0.5rem;
    }

    &:hover {
        opactity: 0.8;
    }
`

const IconButton = styled(CoreButton)`
    width: 2rem;
    span {
        padding: 0;
    }
`

const AmendBookingButton = styled(CoreButton)`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        margin-right: 0 !important;

        .icon {
            margin: 0 !important;
        }
    }`

const AmendButton = styled(AmendBookingButton)`
    margin-right: 1rem;
`

const InlineError = styled(ErrorMessage)`
    display: inline-block;
    width: auto;
`

enum ModalAction {
    BookingStatus,
    RemoveTableAllocation
}

const BookingInfoModal = ({
    booking,
    tables,
    locationId,
    bookingManagementData,
    onClose,
    onUpdate,
    updateBooking
}: ComponentProps) => {
    const offline = useOffline();
    const [businessLoaded, business] = useBusiness();
    const [modalTitle, setModalTitle] = useState<string>();
    const [statusModalTitle, setStatusModalTitle] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [amending, setAmending] = useState<boolean>(false);
    const [amendingDuration, setAmendingDuration] = useState<boolean>(false);
    const [paymentSessionId, setPaymentSessionId] = useState<string>(createUUID());
    const [noteToAmend, setNoteToAmend] = useState<string>();
    const chargeClient = useRef<boolean>(true);
    const [payments, setPayments] = useState<BaseBookingPayment[]>();
    const selectedBookingStatus = useRef<BookingStatus>();
    const [tab, setTab] = useState<number>(0);
    const rendered = useRef(false);
    const modalActionOnConfirm = useRef<ModalAction>();
    const containerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setTimeout(() => {
            rendered.current = true;
        }, 500);

        return () => {
            rendered.current = false;
        }
    }, [])

    const updateBookingStatus = (status: BookingStatus) => {
        ApiService.bookingPayment.List__GET(booking.id, booking.businessId).then((response) => {
            setPayments(response);
        })
        if (status === BookingStatus.Cancelled) {
            modalActionOnConfirm.current = ModalAction.BookingStatus;
            selectedBookingStatus.current = status;
            setStatusModalTitle('Are you sure you want to cancel this booking?')
        } else if (status === BookingStatus.NoShow) {
            modalActionOnConfirm.current = ModalAction.BookingStatus;
            selectedBookingStatus.current = status;
            setStatusModalTitle('Are you sure you want to mark this booking as a no show?')
        } else {
            sendBookingStatus(status)
        }
    }

    const modalConfirm = () => {
        setModalTitle(undefined);
        if (modalActionOnConfirm.current === ModalAction.BookingStatus) {
            sendBookingStatus(selectedBookingStatus.current)
        } else if (modalActionOnConfirm.current === ModalAction.RemoveTableAllocation) {
            confirmUnallocateTables();
        }
    }

    const statusModalConfirm = (charge: boolean) => {
        setModalTitle(undefined);
        chargeClient.current = charge;
        sendBookingStatus(selectedBookingStatus.current)
    }

    const amendNoteConfirm = () => {
        setLoading(true)
        ApiService.bookings.UpdateNotes__POST({ ...booking, notes: noteToAmend }).then((response) => {
            if (response.success) {
                setNoteToAmend(undefined);
                NotificationService.Confirm('Booking note amended')
                setLoading(false)
                updateBooking({ ...booking, notes: noteToAmend });
            } else {
                setLoading(false)
                NotificationService.Error(response.info)
            }
        }).catch(() => {
            setLoading(false)
            NotificationService.Error('Sorry, an error was encountered.')
        })
    }

    const unAllocateTables = () => {
        modalActionOnConfirm.current = ModalAction.RemoveTableAllocation;
        setModalTitle('Are you sure you want to remove table allocation from this booking?')
    }

    const confirmUnallocateTables = () => {
        ApiService.bookings.RemoveTableAllocation__POST(booking).then((response) => {
            if (response.success) {
                setNoteToAmend(undefined);
                NotificationService.Confirm('Booking table allocation removed')
                setLoading(false)
                updateBooking({ ...booking, tables: [], bookedTables: [] });
                onClose()
            } else {
                setLoading(false)
                NotificationService.Error(response.errorMessage)
            }
        }).catch(() => {
            setLoading(false)
            NotificationService.Error('Sorry, an error was encountered.')
        })
    }

    const sendBookingStatus = (status: BookingStatus) => {
        booking.status = status;
        setLoading(true)
        ApiService.bookings.UpdateStatus__POST({
            bookingId: booking.id,
            status,
            preventCardCharge: !chargeClient.current
        }).then((response: ResponseOrError) => {
            if (response.success) {
                onUpdate(true)
            } else {
                setLoading(false)
                NotificationService.Error(response.error)
            }
        }).catch(() => {
            setLoading(false)
            NotificationService.Error('Sorry, an error was encountered.')
        })
    }

    const amendFinish = (newBooking: BaseActiveBooking, close: boolean, reloadPayments?: boolean) => {
        if (close) closeAmendment(reloadPayments);
        updateBooking(newBooking);
    }

    const closeAmendment = (refreshPayment?: boolean) => {
        setAmending(false);
        setAmendingDuration(false);
        if (refreshPayment) setPaymentSessionId(createUUID());
    }

    const isWalkIn = !booking.client || booking.isWalkIn;
    const isUnknown = isWalkIn || booking?.isHeld;
    const showWalkinBadge = isUnknown && !booking?.isHeld;
    const showWebBadge = !isUnknown && booking.bookingSource === BookingSource.Web;
    const showInHouseBadge = !isUnknown && booking.bookingSource === BookingSource.InHouse;

    const outstandingPaymentAmount = payments ? payments.reduce((total, payment) => total + (!payment.fufilled ? payment.amount : 0), 0) : 0;
    const paidAmount = payments ? payments.reduce((total, payment) => total + (payment.fufilled ? payment.amount : 0), 0) : 0;

    const baseBookingInfo = <BookingInfoSummary booking={booking} bookingManagementData={bookingManagementData} />

    const bookingInfo = <>
        <Row>
            {baseBookingInfo}
        </Row>
        <Row>
            {!isNullOrWhitespace(booking.customFields) && booking.customFields.split('|||').map(response => (
                <Column size={6} mobile={12}>
                    <Badge colorPalette='gray'>{bookingManagementData.customFields.find(x => x.id == +response.split('~~~')[0])?.label || 'Unknown field'}</Badge><br />
                    {response.split('~~~')[1] == 'undefined' ? 'N/A' : response.split('~~~')[1]}
                </Column>
            ))}
        </Row>
        <Row>
            {!isNullOrWhitespace(booking.specialRequests) && <>
                <Column size={6} mobile={12}><InfoMessage hideIcon>
                    <div><strong>Special requests:</strong><br />{booking.specialRequests}</div>
                </InfoMessage></Column>
            </>}
            {!isNullOrWhitespace(booking.dietaryRequirements) && <>
                <Column size={6} mobile={12}><InfoMessage hideIcon>
                    <div><strong>Dietary requirements:</strong><br />{booking.dietaryRequirements}</div>
                </InfoMessage></Column>
            </>}
            <Column size={6} mobile={12} style={{ display: 'flex' }}>
                {!offline && <IconContainer><IconButton onClick={() => setNoteToAmend(booking.notes)}><Icon name='pencil' /></IconButton></IconContainer>}<div style={{ whiteSpace: 'pre-wrap', display: 'inline-block' }}>
                    {isNullOrWhitespace(booking.notes) ? 'No booking notes added' : booking.notes}
                </div>
            </Column>
        </Row>
    </>

    return (
        <>
            <CoreModal
                cancelText='Close'
                onClose={() => rendered.current ? onClose() : {}}
                title={<>
                    {!showWalkinBadge && !booking?.isHeld &&
                        <><Icon name='address-card' duo /> {booking.client?.firstName} {booking.client?.surname ? booking.client?.surname : ''} </>
                    }
                    {!showWalkinBadge && booking?.isHeld &&
                        <>Held booking</>
                    }
                    {showWalkinBadge && 'Walk in'}
                    &nbsp;({booking.bookingReference})
                    {(showWalkinBadge || showWebBadge || showInHouseBadge) && <BadgeContainer>
                        {showWalkinBadge && <Badge ml='1' colorPalette='green'>Walk in</Badge>}
                        {showWebBadge && <Badge ml='1' colorPalette='blue'>Online booking</Badge>}
                        {showInHouseBadge && <Badge ml='1' colorPalette='cyan'>In-house booking</Badge>}
                    </BadgeContainer>}
                </>}
                hasCancel
                noTitleMarginBottom
                actionBar={
                    offline ? <><InlineError>No booking actions are available while offline.</InlineError></> :
                        <>
                            {process.env.NODE_ENV === 'development' && <AmendButton icon='calendar' iconOnlymobile onClick={() => window.open(`/manage-my-booking/${business?.locationId}/${booking.bookingReference}/${booking.bookingUniqueId}`, '_blank')}>Open client amend</AmendButton>}
                            {booking.tables.length > 0 && <AmendButton icon='dining-table' iconOnlymobile type='warning' onClick={unAllocateTables}>Unallocate tables</AmendButton>}
                            {booking.tables.length == 0 && <AmendButton icon='dining-table' iconOnlymobile type='success' onClick={() => setAmendingDuration(true)}>Allocate tables</AmendButton>}
                            <AmendButton icon='clock' iconOnlymobile onClick={() => setAmendingDuration(true)}>Amend duration</AmendButton>
                            <AmendBookingButton icon='pencil' iconOnlymobile onClick={() => setAmending(true)}>Amend booking</AmendBookingButton>
                        </>
                }>
                <div>
                    {modalTitle &&
                        <CoreModal
                            onClose={() => setModalTitle(undefined)}
                            small
                            title={modalTitle}
                            actionBar={<CoreButton onClick={modalConfirm}>Confirm</CoreButton>
                            }>
                            <Row>
                                {baseBookingInfo}
                            </Row>
                        </CoreModal>
                    }
                    {statusModalTitle &&
                        <CoreModal
                            onClose={() => setStatusModalTitle(undefined)}
                            small
                            noClose
                            title={statusModalTitle}
                            actionBar={
                                outstandingPaymentAmount == 0 ? <CoreButton disabled={!payments} onClick={modalConfirm}>Confirm</CoreButton> : undefined
                            }>
                            <Row>
                                {baseBookingInfo}
                            </Row>
                            {payments && outstandingPaymentAmount > 0 &&
                                <>
                                    <WarningMessage>Booking has outstanding payments of&nbsp;<strong>{ConvertCurrency({ code: business?.currencyCode, amount: outstandingPaymentAmount })}</strong></WarningMessage>
                                    <br />
                                    <Row>
                                        <Column size={6}>
                                            <CoreButton type='warning' full disabled={!payments} onClick={() => statusModalConfirm(false)}>Confirm without charging client</CoreButton>
                                        </Column>
                                        <Column size={6}>
                                            <CoreButton full disabled={!payments} onClick={() => statusModalConfirm(true)}>Confirm and charge client</CoreButton>
                                        </Column>
                                    </Row>
                                </>
                            }
                            {payments && paidAmount > 0 &&
                                <InfoMessage>This booking has payments made. Consider reviewing and refunding where applicable.</InfoMessage>
                            }
                        </CoreModal>
                    }
                    {noteToAmend !== undefined &&
                        <CoreModal contentRef={containerRef} onClose={() => setNoteToAmend(undefined)} small title={<>Amend internal booking note <InfoButton portalProps={{ containerRef: containerRef }}>These notes cannot be seen by the client, they are private to the business.</InfoButton></>} actionBar={<CoreButton disabled={loading} onClick={amendNoteConfirm}>Confirm</CoreButton>}>
                            <StyledTextarea rows={8} disabled={loading} unlink value={noteToAmend} onChange={(e) => setNoteToAmend(e.target.value)} />
                        </CoreModal>
                    }
                    {loading && <Loader />}
                    {!loading &&
                        <TabBar>
                            <TabButton active={tab == 0} onClick={() => setTab(0)}>Booking information</TabButton>
                            {!isUnknown && <TabButton active={tab == 1} onClick={() => setTab(1)}>Guest information</TabButton>}
                            <TabButton active={tab == 2} onClick={() => setTab(2)}>Booking history</TabButton>
                        </TabBar>
                    }
                    {!loading && tab == 0 &&
                        <>
                            {offline && <><ErrorMessage>No booking actions are available while offline.</ErrorMessage><br /></>}
                            {!offline && <ActionBar>
                                {!booking.isHeld && getBookingActions(booking).filter(x => x.value !== booking.status).map(action => (
                                    <Action
                                        key={action.value}
                                        onClick={() => updateBookingStatus(action.value as BookingStatus)}
                                        style={{
                                            backgroundColor: GetStatusColour({ ...booking, status: action.value as BookingStatus }),
                                            color: GetStatusTextColour({ ...booking, status: action.value as BookingStatus }),
                                        }}
                                    >
                                        {action.text}
                                    </Action>
                                ))}
                            </ActionBar>}
                            <Row reverseMobile>
                                <Column size={12} mobile={12}>
                                    {bookingInfo}
                                </Column>
                            </Row>
                            {business.stripeEnabled && <BookingPayments booking={booking} sessionId={paymentSessionId} />}
                        </>
                    }
                    {tab == 1 &&
                        (offline ? <GuestInfoOffline client={booking.client} /> :
                            <GuestInfo id={booking.clientId} parentId={booking.parentBusinessId} />)
                    }
                    {tab == 2 &&
                        <BookingHistory bookingId={booking.id} />
                    }
                </div>
                {(amending || amendingDuration) &&
                    <AmendBookingModal
                        bookingAmendment={booking}
                        tables={tables}
                        locationId={locationId}
                        bookingManagementData={bookingManagementData}
                        onClose={closeAmendment}
                        onAmendSuccess={amendFinish}
                        amendDuration={amendingDuration}
                    />
                }
            </CoreModal>
        </>
    );
};

const BadgeContainer = styled.div`
    float: right;
    margin-right: 2rem;

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        float: none;
        margin-top: 0.2rem;
    }
`

const IconContainer = styled.div`
    display: inline-block;
    width: 3rem;
`

export default BookingInfoModal;