import React from 'react';
import { ButtonTime, TimeRow } from '../../../booking.styles';
import { Column } from '../../../../components/Layout/Grid';
import Icon from '../../../../components/Media/Icon';
import moment, { Moment } from 'moment';
import { TIMEFORMAT } from '../../../../utils/date-helpers';
import { ButtonTimeLabel, Pipe } from './SearchForm';
import { ApiBusinessBookingDetails, DateAndTimeSlotsWidget, TimeSlotWidget } from '../../../../api/api-definitions';
import { BookingFormData } from '..';

interface ComponentProps {
    index: number;
    keyName: string;
    slot: DateAndTimeSlotsWidget;
    desktopSize: number;
    tabletSize: number;
    mobileSize: number;
    eventGuests?: number;
    setFormData: (value: React.SetStateAction<BookingFormData>) => void;
    setSelectedTimeDropdown: (value: React.SetStateAction<string>) => void;
    hold: (date?: Moment, time?: string, slot?: TimeSlotWidget) => void;
    business: ApiBusinessBookingDetails;
    formData: BookingFormData;
    eventAvailiabilitySearch?: boolean;
}

const TimeSlotsWithDescriptions = ({ index, keyName, eventGuests, slot, desktopSize, tabletSize, mobileSize, setFormData, setSelectedTimeDropdown, hold, business, formData, eventAvailiabilitySearch }: ComponentProps) => {
  return (
    <TimeRow key={`${keyName}-${index}`}>
        {slot && Object.keys(slot.shiftLabelAndTimeSlots).map((shiftLabel, slotIndex) => (
            <>
                {Object.keys(slot.shiftLabelAndTimeSlots).length > 1 &&
                    <Column size={12}>
                        <strong>{shiftLabel}</strong>
                    </Column>
                }
                {slot.shiftLabelAndTimeSlots[shiftLabel].slots.map(time => (
                    <>
                        {!eventAvailiabilitySearch && time.eventGuids.length > 0 && time.eventGuids.map(guid => (
                            <Column size={desktopSize} tablet={tabletSize} mobile={mobileSize} key={`${keyName}-${index}-${slotIndex}-${time.slot}-${guid}`}>
                                <ButtonTime
                                    onClick={() => {
                                        setFormData({ ...formData, date: moment(slot.date), guests: eventGuests });
                                        setSelectedTimeDropdown(time.slot)
                                        hold(moment(slot.date), time.slot, {...time, eventGuids: [guid]})
                                    }}
                                    type='button'
                                    twoLines
                                    widgetTheme={business?.theme}>
                                    <Icon name='calendar' duo /> {moment(time.slot).format(TIMEFORMAT)}
                                    {time.eventGuids.length > 0 && <ButtonTimeLabel>{business?.specialEvents[guid]?.name || 'Event'}</ButtonTimeLabel>}
                                </ButtonTime>
                            </Column>
                        ))}
                        {(!time.eventOnly || eventAvailiabilitySearch) &&
                            <Column size={desktopSize} tablet={tabletSize} mobile={mobileSize} key={`${keyName}-${index}-${slotIndex}-${time.slot}`}>
                                <ButtonTime
                                    onClick={() => {
                                        setFormData({ ...formData, date: moment(slot.date), guests: eventGuests });
                                        setSelectedTimeDropdown(time.slot)
                                        hold(moment(slot.date), time.slot, time)
                                    }}
                                    type='button'
                                    twoLines={time.experienceGuids.length > 0 && !eventAvailiabilitySearch}
                                    widgetTheme={business?.theme}>
                                    {!eventAvailiabilitySearch && time.standardUnavailable && time.experienceGuids.length === 1 && <Icon name='sparkles' duo />} {moment(time.slot).format(TIMEFORMAT)}
                                    {!eventAvailiabilitySearch && time.standardUnavailable && time.experienceGuids.length > 0 && <ButtonTimeLabel>{time.experienceGuids.length > 1 ? 'Experiences' : (business?.experiences[time.experienceGuids[0]]?.name || 'Experience')}</ButtonTimeLabel>}
                                    {!eventAvailiabilitySearch && !time.standardUnavailable && time.experienceGuids.length > 0 && <ButtonTimeLabel>Standard <Pipe>|</Pipe> Experiences</ButtonTimeLabel>}
                                </ButtonTime>
                            </Column>
                        }
                    </>
                ))}
            </>
        ))}
    </TimeRow>
  );
};

export default TimeSlotsWithDescriptions;