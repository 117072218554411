import React, { useEffect } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { BaseExperience, ExperienceOrEventSlot, ExperienceType, ExtendedBaseExperience } from '../../../../../../api/api-definitions';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import Loader from '../../../../../../components/Layout/Loader';
import { Badge } from '@chakra-ui/react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import Icon from '../../../../../../components/Media/Icon';
import { ConvertCurrency } from '../../../../../../utils/currency-helper';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import { formatDate, TIMEFORMAT } from '../../../../../../utils/date-helpers';

interface ComponentProps {
    experienceId: number;
    children?: React.ReactNode;
    colorPalette?: string;
    experience?: ExtendedBaseExperience;
    className?: string;
    validSlotsForDay?: ExperienceOrEventSlot[];
}

const ExperienceInfoBadge = ({ experienceId, children, className, experience, colorPalette = 'green', validSlotsForDay }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [data, setData] = React.useState<ExtendedBaseExperience>(experience);
    const [modalOpen, setModalOpen] = React.useState(false);

    const loadData = () => ApiService.experience.Get__GET(experienceId).then(setData);

    const openModal = () => {
        if (!data) {
            loadData();
        }
        setModalOpen(true);
    }
    const showPrice = businessLoaded && data && !!data?.pricePerGuest;
    const showMinGuest = data && !!data?.minGuests;
    const showMaxGuest = data && !!data?.maxGuests;
    const showLocation = data && !isNullOrWhitespace(data?.displayLocation);

    const someInfoShows = showPrice || showMinGuest || showMaxGuest || showLocation;

    return (
        <>
            <Label colorPalette={colorPalette} className={className} onClick={openModal}>{children || 'Experience/Event'}</Label>
            {modalOpen &&
                <CoreModal
                    cancelText='Close'
                    small
                    slimPanel
                    onClose={() => setModalOpen(false)}
                    title={data?.displayName}
                    actionBar={<>{data && <StyledLink to={`/dashboard/${business.parentBusinessId}/locations/${business.locationId}/experiences/${experienceId}`}>Go to setup <Icon name='arrow-right' duo /></StyledLink>}</>}
                >
                    {!data && <Loader />}
                    {showPrice &&
                        <>
                            <strong>Price per guest:</strong> {ConvertCurrency({ code: business?.currencyCode, amount: data?.pricePerGuest })}
                            <br />
                        </>
                    }
                    {showMinGuest &&
                        <>
                            <strong>Minimum guests:</strong> {data?.minGuests}
                            <br />
                        </>
                    }
                    {showMaxGuest &&
                        <>
                            <strong>Maximum guests:</strong> {data?.maxGuests}
                            <br />
                        </>
                    }
                    {showLocation &&
                        <>
                            <strong>Location:</strong> {data?.displayLocation}
                            <br />
                        </>
                    }
                    {data && !!validSlotsForDay && validSlotsForDay.length > 0 &&
                        <>
                            {someInfoShows && <br />}
                            <strong>On this day running between:</strong><br />
                            {validSlotsForDay.map((x, index) => (
                                <React.Fragment key={`valid-slot-${data?.experienceGuid}-${index}`}>
                                    {`${validSlotsForDay.length === 1 ? '' : `${index + 1}. `}${formatDate(x.timeFrom, TIMEFORMAT)} to ${formatDate(x.timeTo, TIMEFORMAT)}`}
                                    <br />
                                </React.Fragment>
                            ))}
                        </>
                    }
                    {data && ((data?.description && <><br /><div dangerouslySetInnerHTML={{ __html: data.description }}></div></>) || <><br />{`${data?.type == ExperienceType.Event ? 'Event' : 'Experience'} does not have a description.`}</>)}
                </CoreModal>
            }
        </>
    );
};

const Label = styled(Badge)`
    cursor: pointer;
    transition: all ease 0.4s;

    &:hover {
        opacity: 0.8;
    }
`

const StyledLink = styled(Link)`
    font-weight: bold;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }
`

export default ExperienceInfoBadge;