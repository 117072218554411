"use client"

import {
  Toaster as ChakraToaster,
  Portal,
  Spinner,
  Stack,
  Toast,
  createToaster,
} from "@chakra-ui/react"
import { IoMdClose } from "react-icons/io"
import Icon from "../Media/Icon"

export const toaster = createToaster({
  placement: "bottom-end",
  pauseOnPageIdle: true,
})

const getBackgroundColor = (type) => {
  if (type == 'info') return '#2B6CB0';
  if (type == 'error') return '#C53030';
  if (type == 'success') return '#2F855A';
}

export const Toaster = ({ useToaster, portalRef = undefined }) => {
  return (
    <Portal container={portalRef}>
      <ChakraToaster
        toaster={useToaster || toaster}
      // insetInline={{ mdDown: "1rem" }}
      // width={{ md: "356px" }}
      >
        {(toast) => (
          <Toast.Root key={toast.id} width={'fit-content'} color='#FFF' backgroundColor={getBackgroundColor(toast.type)}>
            {toast.type === "loading" ? (
              <Spinner size="sm" color="blue.solid" />
            ) : (
              <></>
            )}
            <Stack gap="1" flex="1" maxWidth="100%">
              {toast.title && <Toast.Title><Icon name={toast.type == 'success' ? 'circle-check' : 'info-circle'} /> {toast.title}</Toast.Title>}
              {toast.description && (
                <Toast.Description>{toast.description}</Toast.Description>
              )}
            </Stack>
            {toast.action && (
              <Toast.ActionTrigger>{toast.action.label}</Toast.ActionTrigger>
            )}
            {toast.meta?.closable &&
              <Toast.CloseTrigger>
                <IoMdClose />
              </Toast.CloseTrigger>
            }
          </Toast.Root>
        )}
      </ChakraToaster>
    </Portal>
  )
}
