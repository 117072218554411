import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Loader from '../../components/Layout/Loader';
import BusinessListService from '../../services/BusinessListService';
import BusinessService from '../../services/BusinessService';
import Breadcrumbs from './Breadcrumbs';
import DashboardLeftNav from './modules/LeftNav';
import CoreModal from '../../components/Layout/CoreModal';
import { BaseSupportTicket } from '../../api/api-definitions';
import SupportTicketForm from './modules/Shared/SupportTickets/supportTicketForm';
import { SupportTicketService } from '../../services/SupportTicketService';
import { ModalService, ModalServiceParams } from '../../services/ModalService';
import DashboardErrorBoundary from '../../components/Boundries/DashboardErrorBoundry';
import { H1, H1style, H2, H2style, H3style, H4style, HeadingDivider } from '../../components/Typography/Headings';
import BREAKPOINTS from '../../config/breakpoints';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import { Constants } from '../../constants';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { LoginState } from '../../components/Authentication/AuthenticationWrapper';
import { pushEvent } from '../../utils/data-helpers';
import { ErrorMessage } from '../../components/Forms/Messaging';
import PermissionsChecker from './modules/Shared/PermissionsChecker';
import { useBusinessPermissions } from '../../hooks/useBusinessPermissions';
import { LoadedLocation } from '../../hooks/useBusiness';
import useOffline from '../../hooks/useOffline';
import BusinessListCacheService from '../../services/BusinessListCacheService';
import BusinessCacheService from '../../services/BusinessCacheService';
import DashboardHeader from '../../components/Navigation/DashboardHeader';

interface ComponentProps {
    children: React.ReactNode;
    route: any;
    title: string;
    requiresAdmin?: boolean;
    permission?: string;
}

export const DashboardPage = styled.div`
    background-color: ${props => props.theme.dashboardBackground};
    color: ${props => props.theme.text};
    min-height: 100vh;
    width: 100%;
    position: absolute;

    ${H1style}, ${H2style}, ${H3style}, ${H4style} {
        text-align: left;
    }

    .chakra-alert {
        color: #000;
    }

    ${HeadingDivider} {
        margin: 0;
        width: 100%;
    }
`

export const CenterContainer = styled.div`
    padding-right: 2rem;
    //padding-left: 2rem;
    padding-bottom: 6rem;
    margin-left: 6.3rem;
    background-color: ${props => props.theme.dashboardBackground};
    color: ${props => props.theme.text};
    position: relative;

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        margin-left: 0.5rem;
        padding-right: 0.5rem;
        //padding-left: 0.5rem;
    }
`;

export const ParentIdState = atom<number>({
    key: 'parentIdState',
    default: null,
});

const DashboardLayout = ({ children, route, title, requiresAdmin, permission }: ComponentProps) => {
    const offline = useOffline();
    const [listLoaded, setListLoaded] = useState<boolean>();
    const [loaded, setLoaded] = useState<boolean>();
    const [supportTicketOpen, setSupportTicketOpen] = useState<BaseSupportTicket>();
    const [modalData, setModalData] = useState<ModalServiceParams>();
    const params: any = useParams();
    const loginValue = useRecoilValue(LoginState);
    const [previousParentId, setPreviousParentId] = useRecoilState(ParentIdState);
    const [business, setBusiness] = useRecoilState(LoadedLocation);
    useBusinessPermissions();

    useEffect(() => {
        if (params.parentId != previousParentId || params.id != business?.locationId) {
            BusinessService.Clear()
            setBusiness(null)
        }
        setLoaded(false);
        if (!isNullOrWhitespace(params.id)) {
            (offline ? BusinessCacheService.GetBusiness(params.id) : BusinessService.GetBusiness(params.id)).then((business) => {
                setLoaded(true)
                document.title = `${Constants.businessName} - ${title} - ${business.name}`
                pushEvent('dashboardPageView', { 'page': `${Constants.businessName} - ${title} - ${business.name}`, 'user': loginValue.data?.userName })
            })
        } else {
            setLoaded(true)
        }
        (offline ? BusinessListCacheService.GetBusinessList(params.parentId || 'null') : BusinessListService.GetBusinessList(previousParentId != params.parentId, params.parentId)).then(response => {
            if (previousParentId != params.parentId) {
                setPreviousParentId(params.parentId)
            }
            if (isNullOrWhitespace(params.parentId)) {
                document.title = `${Constants.businessName} - ${title}`
                pushEvent('dashboardPageView', { 'page': `${Constants.businessName} - ${title}`, 'user': loginValue.data?.userName })
            } else if (isNullOrWhitespace(params.id)) {
                document.title = `${Constants.businessName} - ${title} - ${response[0].parentBusiness?.name}`
                pushEvent('dashboardPageView', { 'page': `${Constants.businessName} - ${title} - ${response[0].parentBusiness?.name}`, 'user': loginValue.data?.userName })
            }
            setListLoaded(true);
        });
    }, [params.id, params.parentId])

    useEffect(() => {
        SupportTicketService.RegisterHandler(newSupportTicket);
        ModalService.RegisterHandler(openModal);
    }, [])

    const openModal = (params: ModalServiceParams) => {
        setModalData(params);
    }

    const newSupportTicket = (ticket?: BaseSupportTicket, full?: boolean) => {
        if (ticket) {
            setSupportTicketOpen(ticket);
        } else {
            setSupportTicketOpen({
                id: undefined,
                parentBusinessId: undefined,
                dateRaisedUtc: undefined,
                priority: undefined,
                status: undefined
            });
        }
    }

    return (
        <DashboardPage>
            {supportTicketOpen &&
                <SupportTicketForm ticket={supportTicketOpen} onClose={() => setSupportTicketOpen(undefined)} />
            }
            {modalData &&
                <CoreModal
                    hasCancel={!modalData.hideCancelButton}
                    small={modalData.small || modalData.medium}
                    mediumPanel={modalData.medium}
                    onClose={() => setModalData(undefined)}
                    actionBar={modalData.actionBar}
                    title={modalData.title}
                >
                    {modalData.children}
                </CoreModal>
            }
            {(!loaded || !listLoaded) && <Loader />}
            {loaded && listLoaded &&
                <>
                    {/* <DashboardHeader /> */}
                    <DashboardLeftNav />
                    <CenterContainer>
                        <Breadcrumbs route={route} />
                        {(!requiresAdmin || loginValue?.data?.isSuperAdmin) &&
                            <>
                                {isNullOrWhitespace(permission) &&
                                    <DashboardErrorBoundary parentBusinessId={+params.parentId}>{children}</DashboardErrorBoundary>
                                }
                                {!isNullOrWhitespace(permission) &&
                                    <PermissionsChecker permmission={permission}><DashboardErrorBoundary parentBusinessId={+params.parentId}>{children}</DashboardErrorBoundary></PermissionsChecker>
                                }
                            </>
                        }
                        {requiresAdmin && !loginValue?.data?.isSuperAdmin &&
                            <>
                                <br />
                                <ErrorMessage>Sorry, you are not authorised to view this page.</ErrorMessage>
                            </>
                        }
                    </CenterContainer>
                </>
            }
        </DashboardPage>
    );
};

export default DashboardLayout;