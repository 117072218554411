import { cloneDeep } from 'lodash';
import React from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Link } from 'react-router-dom';
import { RegisterBusinessModel } from '../../../api/api-definitions';
import CoreButton from '../../../components/Forms/Button';
import Checkbox from '../../../components/Forms/Checkbox';
import Dropdown from '../../../components/Forms/Dropdown';
import FormWrapper from '../../../components/Forms/FormWrapper';
import { InfoMessage, ErrorMessage } from '../../../components/Forms/Messaging';
import TextInput from '../../../components/Forms/TextInput';
import { Column, Row } from '../../../components/Layout/Grid';
import { Constants } from '../../../constants';
import { NotificationService } from '../../../services/NotificationService';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { StyledDropdown, StyledTextInput } from '../../../theme/input.styles';

interface ComponentProps {
  data: RegisterBusinessModel;
  errors?: string[];
  hideBranchName?: boolean;
  setData: (data: RegisterBusinessModel, valid?: boolean) => void;
  onSubmit?: () => void;
}

const NewBusinessForm = ({ data, setData, onSubmit, hideBranchName, errors = [] }: ComponentProps) => {
  const setValue = (googleData) => {
    const id = googleData.value.place_id;
    geocodeByPlaceId(id)
      .then(results => {
        const newData = cloneDeep(data)
        const location = results[0].geometry.location;
        const addressArray = results[0].address_components;
        newData.name = googleData.value.structured_formatting.main_text;
        newData.road = addressArray.find(x => x.types.find(y => y == 'route'))?.long_name;
        if (!newData.road) {
          newData.road = addressArray.find(x => x.types.find(y => y == 'sublocality'))?.long_name;
        }
        newData.city = addressArray.find(x => x.types.find(y => y == 'postal_town'))?.long_name;
        newData.county = addressArray.find(x => x.types.find(y => y == 'administrative_area_level_1'))?.long_name;
        newData.country = addressArray.find(x => x.types.find(y => y == 'country'))?.long_name;
        newData.postCode = addressArray.find(x => x.types.find(y => y == 'postal_code'))?.long_name;
        newData.branchName = `${newData.name} - ${newData.road}`;
        newData.placeId = id;
        newData.longitude = location.lng();
        newData.latitude = location.lat();
        setData(newData, true);
      })
      .catch(error => {
        console.error(error)
        NotificationService.Error('Failed to process API request')
      });
  }

  return (
    <FormWrapper onUpdate={(newData, valid) => setData({ ...data, ...newData }, valid && !isNullOrWhitespace(data.name))} renderWrapperAsDiv>
      {({ id, valid }) => (
        <>
          <Row>
            <Column size={12}>
              <GooglePlacesAutocomplete
                apiKey={Constants.googleMapsApiKey}
                autocompletionRequest={{
                  types: ['restaurant', 'cafe', 'bar'],
                  componentRestrictions: {
                    country: ['uk'],
                  }
                }}
                selectProps={{
                  onChange: setValue,
                  placeholder: 'Start typing for suggestions...'
                }}
                withSessionToken
              />
            </Column>

            {data?.name &&
              <Column size={12}>
                <strong>Address:</strong><br />
                {data?.name && <>{data?.name}<br /></>}
                {data?.road && <>{data?.road}<br /></>}
                {data?.city && <>{data?.city}<br /></>}
                {data?.country && <>{data?.country}<br /></>}
                {data?.postCode && <>{data?.postCode}<br /></>}
                <InfoMessage>Not the correct details? Please contact us after registration.</InfoMessage>
                <br />
              </Column>
            }
            {!hideBranchName &&
              <Column size={12}>
                <Checkbox asToggle label='My business is a chain' checked={data?.isChain} model='isChain' />
                {data?.isChain &&
                  <>
                    <br /><br />
                    <StyledTextInput label='Group name' required={data?.isChain} value={data?.name} model='name' />
                    <br /><br />
                    <StyledTextInput label='Branch name' required={data?.isChain} value={data?.branchName} model='branchName' />
                  </>
                }
              </Column>
            }

            <Column size={12}>
              <StyledDropdown label='Currency' addDefault required value={data?.currency} items={[{ value: 'GBP' }]} model='currency' />
            </Column>

            {errors && errors.length > 0 &&
              <Column size={12}>
                {errors.map(error => (
                  <>
                    <ErrorMessage>
                      {error}
                    </ErrorMessage>
                    <br />
                  </>
                ))}
              </Column>
            }
            {onSubmit &&
              <Column size={12}>
                <CoreButton onClick={onSubmit} disabled={!valid || isNullOrWhitespace(data.name)} full>
                  Register
                </CoreButton>
              </Column>
            }
          </Row>
        </>
      )}
    </FormWrapper>
  );
};

export default NewBusinessForm;