import { useEffect, useRef, useState } from "react";
import FoodBusinessInfoPanel from "../components/Cta/FoodBusinessInfoPanel";
import CoreButton from "../components/Forms/Button";
import FormWrapper from "../components/Forms/FormWrapper";
import { ErrorMessage, SuccessMessage, WarningMessage } from "../components/Forms/Messaging";
import BoxOutline from "../components/Layout/BoxOutline";
import { Column, Row } from "../components/Layout/Grid";
import PageContainer from "../components/Layout/PageContainer";
import TabContainer from "../components/Layout/Tabs/TabContainer";
import TabItem from "../components/Layout/Tabs/TabItem";
import { H1, H2, H3, H4, H5 } from "../components/Typography/Headings";
import { usePageName } from "../hooks/usePageName";
import { StyledCalendar, StyledCurrencyInput, StyledDropdown, StyledNumberInput, StyledTextarea, StyledTextInput, StyledTypeahead } from "../theme/input.styles";
import { InfoMessage } from "../bookingModule/booking.styles";
import TelephoneInput from "../components/Forms/TelephoneInput";
import { NotificationService } from "../services/NotificationService";
import CoreModal, { ModalContext } from "../components/Layout/CoreModal";
import CoreBadge from "../components/CoreBadge";
import DashboardHeader from "../components/Dashboard/Header";
import styled from "styled-components";
import { BoxShadowStyle } from "../theme";
import DashboardPanel from "../components/Dashboard/Panel";
import { Toaster } from "../components/ui/toaster";
import { createToaster } from "@chakra-ui/react";
import Icon from "../components/Media/Icon";

const toaster = createToaster({
    placement: 'top',
    duration: 2000,
})

const Sections = [
    {
        name: 'Typography',
        key: 'typography',
    },
    {
        name: 'Badge',
        key: 'badge',
    },
    {
        name: 'Buttons',
        key: 'buttons',
    },
    {
        name: 'Form elements',
        key: 'forms',
    },
    {
        name: 'Rows and columns',
        key: 'grid',
    },
    {
        name: 'Tabs',
        key: 'tabs',
    },
    {
        name: 'Messaging',
        key: 'messaging',
    },
    {
        name: 'Notifications',
        key: 'notifications',
    },
    {
        name: 'Modals',
        key: 'modals',
    },
    {
        name: 'Panels',
        key: 'panels',
    }
].sort((a, b) => a.name.localeCompare(b.name))

const Showcase = () => {
    const [formState, updateFormState] = useState({} as any);
    const [selectedSection, setSelectedSection] = useState(Sections[0].key)
    const [fullscreen, setFullscreen] = useState(false)
    const fullscreenWrapper = useRef<HTMLDivElement>();
    const isFullScreen = useRef(false);
    usePageName('Showcase')

    useEffect(() => {
        onExitFullScreen()
        NotificationService._toastCmd = toaster.create;

        return () => {
            NotificationService._toastCmd = null;
            unmountFullScreen();
        }
    }, [])

    const exitFullScreen = () => {
        if (isFullScreen.current) {
            toggleFullScreen(false, fullscreenWrapper.current)
        }
    }

    const onExitFullScreen = () => {
        document.addEventListener('fullscreenchange', exitFullScreen, false);
        document.addEventListener('mozfullscreenchange', exitFullScreen, false);
        document.addEventListener('MSFullscreenChange', exitFullScreen, false);
        document.addEventListener('webkitfullscreenchange', exitFullScreen, false);
    }

    const unmountFullScreen = () => {
        document.removeEventListener('fullscreenchange', exitFullScreen, false);
        document.removeEventListener('mozfullscreenchange', exitFullScreen, false);
        document.removeEventListener('MSFullscreenChange', exitFullScreen, false);
        document.removeEventListener('webkitfullscreenchange', exitFullScreen, false);
    }

    const toggleFullScreen = (value: boolean, element: HTMLDivElement) => {
        setFullscreen(value)
        setTimeout(() => {
            isFullScreen.current = value;
        }, 1000);

        if (value) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                //@ts-ignore
            } else if (element.mozRequestFullScreen) {
                /* Firefox */
                //@ts-ignore
                element.mozRequestFullScreen();
                //@ts-ignore
            } else if (element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                //@ts-ignore
                element.webkitRequestFullscreen();
                //@ts-ignore
            } else if (element.msRequestFullscreen) {
                /* IE/Edge */
                //@ts-ignore
                element.msRequestFullscreen();
            }
        } else {
            if (window.document.exitFullscreen) {
                window.document.exitFullscreen();
                //@ts-ignore
            } else if (window.document.mozCancelFullScreen) {
                /* Firefox */
                //@ts-ignore
                window.document.mozCancelFullScreen();
                //@ts-ignore
            } else if (window.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                //@ts-ignore
                window.document.webkitExitFullscreen();
                //@ts-ignore
            } else if (window.document.msExitFullscreen) {
                /* IE/Edge */
                //@ts-ignore
                window.document.msExitFullscreen();
            }
        }
    }

    return (
        <>
            <DashboardHeader icon='dragon' title='Component showcase' />
            <ModalContext.Provider value={fullscreenWrapper.current}>
                <ShowcaseContainer fullscreen={fullscreen} ref={fullscreenWrapper}>
                    <Toaster portalRef={fullscreenWrapper} useToaster={toaster} />
                    <Row>
                        <Column size={3}>
                            {Sections.map(section => (
                                <SidebarLink
                                    onClick={() => setSelectedSection(section.key)}
                                    selected={selectedSection === section.key}
                                >{section.name}</SidebarLink>
                            ))}
                            <SidebarLink
                                onClick={() => toggleFullScreen(!fullscreen, fullscreenWrapper.current)}
                            ><Icon duo name={fullscreen ? 'compress-arrows-alt' : 'expand-arrows-alt'} /> {fullscreen ? 'Exit' : 'Enter'} fullscreen</SidebarLink>
                        </Column>
                        <Column size={9}>
                            {selectedSection == 'badge' &&
                                <>
                                    <Row>
                                        <Column size={2}><CoreBadge colorPalette='primary'>primary</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='secondary'>secondary</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='messenger'>messenger</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='cyan'>cyan</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='red'>red</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='blue'>blue</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='green'>green</CoreBadge></Column>
                                        <Column size={2}><CoreBadge colorPalette='teal'>teal</CoreBadge></Column>
                                    </Row>
                                </>
                            }
                            {selectedSection == 'buttons' &&
                                <>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton type='primary'>
                                                Primary
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='primary' small>
                                                Primary small
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='primary' outline>
                                                Primary outline
                                            </CoreButton>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton type='secondary'>
                                                Secondary
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='secondary' small>
                                                Secondary small
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='secondary' outline>
                                                Secondary outline
                                            </CoreButton>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton type='tertiary'>
                                                Tertiary
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='tertiary' small>
                                                Tertiary small
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='tertiary' outline>
                                                Tertiary outline
                                            </CoreButton>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton type='success'>
                                                Success
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='success' small>
                                                Success small
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='success' outline>
                                                Success outline
                                            </CoreButton>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton type='warning'>
                                                Warning
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='warning' small>
                                                Warning small
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='warning' outline>
                                                Warning outline
                                            </CoreButton>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton type='danger'>
                                                Negative
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='danger' small>
                                                Negative small
                                            </CoreButton>
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton type='danger' outline>
                                                Negative outline
                                            </CoreButton>
                                        </Column>
                                    </Row>
                                </>
                            }
                            {selectedSection == 'typography' &&
                                <>
                                    <H1>
                                        Typography - Heading 1
                                    </H1>
                                    <H2>
                                        Typography - Heading 2
                                    </H2>
                                    <H3>
                                        Typography - Heading 3
                                    </H3>
                                    <H4>
                                        Typography - Heading 4
                                    </H4>
                                    <H5>
                                        Typography - Heading 5
                                    </H5>
                                </>
                            }
                            {selectedSection == 'forms' &&
                                <FormWrapper onUpdate={(data) => updateFormState(data)}>
                                    {({ valid }) => (
                                        <>
                                            <Row>
                                                <Column size={2}>
                                                    Textbox
                                                </Column>
                                                <Column size={10}>
                                                    <StyledTextInput label='My text input' required model='text' value={formState.text} placeholder='Example placeholder' />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Number
                                                </Column>
                                                <Column size={10}>
                                                    <StyledNumberInput label='My number input' wholeNumbersOnly required model='number' value={formState.number} />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Number with addons
                                                </Column>
                                                <Column size={10}>
                                                    <StyledNumberInput leftText='BEFORE' rightText='AFTER' label='My number input' wholeNumbersOnly required model='number2' value={formState.number2} />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Currency
                                                </Column>
                                                <Column size={10}>
                                                    <StyledCurrencyInput label='My currency input' required model='currency' value={formState.currency} />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Currency with symbol
                                                </Column>
                                                <Column size={10}>
                                                    <StyledCurrencyInput currencySymbol="£" label='My currency input' required model='currency2' value={formState.currency2} />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Textarea
                                                </Column>
                                                <Column size={10}>
                                                    <StyledTextarea label='My textarea' model='textarea' value={formState.textarea} required />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Typeahead
                                                </Column>
                                                <Column size={10}>
                                                    <StyledTypeahead label='My typeahead' model='typeahead' value={formState.dropdown} required items={[{ value: 'Test 1' }, { value: 'Test 2' }, { value: 'Test 3' }]} />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Dropdown
                                                </Column>
                                                <Column size={10}>
                                                    <StyledDropdown label='My dropdown' model='dropdown' value={formState.dropdown} required items={[{ value: 'Test 1' }, { value: 'Test 2' }, { value: 'Test 3' }]} addDefault />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Telephone input
                                                </Column>
                                                <Column size={10}>
                                                    <TelephoneInput value={formState.telephone} countryCode={formState.countryCode} telephoneModel="telephone" countryCodeModel="countryCode" />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={2}>
                                                    Calendar
                                                </Column>
                                                <Column size={10}>
                                                    <StyledCalendar label='My calendar' model='calendar' value={formState.calendar} required />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column size={10}>
                                                    <WarningMessage>
                                                        Example warning message
                                                    </WarningMessage>
                                                </Column>
                                                <Column size={2}>
                                                    <CoreButton disabled={!valid} floatRight onClick={(e) => { e.preventDefault(); alert('Saved!') }}>
                                                        Save
                                                    </CoreButton>
                                                </Column>
                                            </Row>
                                        </>
                                    )}
                                </FormWrapper>
                            }
                            {selectedSection == 'grid' &&
                                <>
                                    <p>
                                        Grids are always split into 12. If you want 12 columns then your column size will be 1.
                                    </p>
                                    <H4>
                                        Size 1
                                    </H4>
                                    <Row>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                        <Column size={1} debug>

                                        </Column>
                                    </Row>
                                    <H4>
                                        Size 2
                                    </H4>
                                    <Row>
                                        <Column size={2} debug>

                                        </Column>
                                        <Column size={2} debug>

                                        </Column>
                                        <Column size={2} debug>

                                        </Column>
                                        <Column size={2} debug>

                                        </Column>
                                        <Column size={2} debug>

                                        </Column>
                                        <Column size={2} debug>

                                        </Column>
                                    </Row>
                                    <H4>
                                        Size 3
                                    </H4>
                                    <Row>
                                        <Column size={3} debug>

                                        </Column>
                                        <Column size={3} debug>

                                        </Column>
                                        <Column size={3} debug>

                                        </Column>
                                        <Column size={3} debug>

                                        </Column>
                                    </Row>
                                    <H4>
                                        Size 4
                                    </H4>
                                    <Row>
                                        <Column size={4} debug>

                                        </Column>
                                        <Column size={4} debug>

                                        </Column>
                                        <Column size={4} debug>

                                        </Column>
                                    </Row>
                                    <H4>
                                        Size 6
                                    </H4>
                                    <Row>
                                        <Column size={6} debug>

                                        </Column>
                                        <Column size={6} debug>

                                        </Column>
                                    </Row>
                                    <H4>
                                        Size 12
                                    </H4>
                                    <Row>
                                        <Column size={12} debug>

                                        </Column>
                                    </Row>
                                </>
                            }
                            {selectedSection == 'tabs' &&
                                <TabContainer>
                                    <TabItem title='Tab 1'>
                                        Tab 1 content
                                    </TabItem>
                                    <TabItem title='Tab 2'>
                                        Tab 2 content
                                    </TabItem>
                                    <TabItem title='Tab 3'>
                                        Tab 2 content
                                    </TabItem>
                                </TabContainer>
                            }
                            {selectedSection == 'messaging' &&
                                <>
                                    <InfoMessage>Info message</InfoMessage><br />
                                    <WarningMessage>Warning message</WarningMessage><br />
                                    <ErrorMessage>Error message</ErrorMessage><br />
                                    <SuccessMessage>Success message</SuccessMessage>
                                </>
                            }
                            {selectedSection == 'notifications' &&
                                <>
                                    <CoreButton onClick={() => NotificationService.Confirm('Confirm!')}>Confirm</CoreButton><br /><br />
                                    <CoreButton onClick={() => NotificationService.Error('Error!')}>Error</CoreButton><br /><br />
                                    <CoreButton onClick={() => NotificationService.Info('Info!')}>Info</CoreButton><br /><br />
                                </>
                            }
                            {selectedSection == 'modals' &&
                                <>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, fullPanelOpen: true })}>Full panel</CoreButton>
                                            {formState.fullPanelOpen &&
                                                <CoreModal title='Full panel' full onClose={() => updateFormState({ ...formState, fullPanelOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, panelOpen: true })}>Standard panel</CoreButton>
                                            {formState.panelOpen &&
                                                <CoreModal title='Standard panel' onClose={() => updateFormState({ ...formState, panelOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, mediumPanelOpen: true })}>Medium panel</CoreButton>
                                            {formState.mediumPanelOpen &&
                                                <CoreModal title='Medium panel' mediumPanel onClose={() => updateFormState({ ...formState, mediumPanelOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, slimPanelOpen: true })}>Slim panel</CoreButton>
                                            {formState.slimPanelOpen &&
                                                <CoreModal title='Slim panel' slimPanel onClose={() => updateFormState({ ...formState, slimPanelOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, fullModalOpen: true })}>Full modal</CoreButton>
                                            {formState.fullModalOpen &&
                                                <CoreModal title='Full modal' full small onClose={() => updateFormState({ ...formState, fullModalOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, modalOpen: true })}>Standard modal</CoreButton>
                                            {formState.modalOpen &&
                                                <CoreModal title='Standard modal' small onClose={() => updateFormState({ ...formState, modalOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, mediumModalOpen: true })}>Medium modal</CoreButton>
                                            {formState.mediumModalOpen &&
                                                <CoreModal title='Standard modal' mediumPanel small onClose={() => updateFormState({ ...formState, mediumModalOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                        <Column size={3}>
                                            <CoreButton onClick={() => updateFormState({ ...formState, slimModalOpen: true })}>Slim modal</CoreButton>
                                            {formState.slimModalOpen &&
                                                <CoreModal title='Standard modal' slimPanel small onClose={() => updateFormState({ ...formState, slimModalOpen: false })}>
                                                    Modal content
                                                </CoreModal>
                                            }
                                        </Column>
                                    </Row>
                                </>
                            }
                            {selectedSection == 'panels' &&
                                <>
                                    <FoodBusinessInfoPanel
                                        imageUrl={'https://media.istockphoto.com/photos/varied-food-carbohydrates-protein-vegetables-fruits-dairy-legumes-on-picture-id1218254547?b=1&k=20&m=1218254547&s=170667a&w=0&h=mOEC7x7qU5NC78mCULs-jAPeLkxy8opOvIbGSnwmAyw='}
                                        title={'Full Panel'}
                                        category={'Panel category'}>
                                        <p>
                                            Panel description
                                        </p>
                                    </FoodBusinessInfoPanel>
                                    <br />
                                    <br />
                                    <DashboardPanel title='Dashboard panel'>
                                        Panel content
                                    </DashboardPanel>
                                </>
                            }
                        </Column>
                    </Row>
                </ShowcaseContainer>
            </ModalContext.Provider>
        </>
    );
};

const ShowcaseContainer = styled(BoxShadowStyle) <{ fullscreen?: boolean }>`
    background-color: ${props => props.theme.dashboardElevatedBackground};
    padding: 1rem;

    ${props => props.fullscreen && `
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        max-width: 100%;

        .table-wrap {
            max-height: 50vh;
            overflow: auto;
        }
    `}
`

const SidebarLink = styled.div<{ selected?: boolean }>`
    padding: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    cursor: pointer;
    border-radius: 3px;

    ${props => props.selected && `background-color: #ececec;`}

    &:hover {
        background-color: #ececec;
    }

    &:last-child {
        border-bottom: none;
    }
`

export default Showcase;