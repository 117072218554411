import React, { useEffect, useState } from 'react';
import { PageEditorGlobalStyle } from '../EditPage/page/lagacy-page.style';
import { DashboardPage } from '../../../../DashboardLayout';
import DashboardHeader from '../../../../../../components/Dashboard/Header';
import { ApiService } from '../../../../../../api/api-connectors';
import { ApiComponent, ApiComponentProperty } from '../../../../../../api/api-definitions';
import styled from 'styled-components';
import Icon from '../../../../../../components/Media/Icon';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import { BaseBoxShadowStyleWithHover, BaseBoxShadowStyle } from '../../../../../../theme';
import ComponentModal from './componentModal';
import PropertyModal from './propertyModal';
import { NotificationService } from '../../../../../../services/NotificationService';
import { createUUID } from '../../../../../../utils/data-helpers';
import { cloneDeep } from 'lodash';
import Loader from '../../../../../../components/Layout/Loader';
import { ModalService } from '../../../../../../services/ModalService';
import CoreButton from '../../../../../../components/Forms/Button';
import { useParams } from 'react-router-dom';
import { Badge } from '@chakra-ui/react';

const ComponentBuilder = () => {
    const params: any = useParams();
    const [items, setItems] = useState<ApiComponent[]>();
    const [expandedItems, setExpandedItems] = useState<{ [rowKey: string]: boolean }>({})
    const [componentToEdit, setComponentToEdit] = useState<ApiComponent>();
    const [componentPropertyToEdit, setComponentPropertyToEdit] = useState<ApiComponentProperty>();

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        ApiService.componentBuilderApi.Get__GET(params.parentId).then(setItems)
    }

    const save = (itemsToSave: ApiComponent[]) => {
        ApiService.componentBuilderApi.Save__POST(itemsToSave, params.parentId).then(() => {
            NotificationService.Confirm('Saved changes')
        }).catch(() => {
            NotificationService.Error('Unable to save changes')
        })
    }

    const closePropertyModal = (property?: ApiComponentProperty) => {
        if (property) {
            const newItems = cloneDeep(items);
            const item = newItems.find(x => x.rowKey === property.componentId);
            if (item) {
                const foundProperty = item.properties.find(x => x.rowKey === property.rowKey);
                if (foundProperty) {
                    foundProperty.name = property.name;
                    foundProperty.type = property.type;
                } else {
                    item.properties.push(property)
                }
                setItems(newItems)
                save([item])
            }
        }
        setComponentPropertyToEdit(undefined)
    }

    const closeComponentModal = (component?: ApiComponent) => {
        if (component) {
            const newItems = cloneDeep(items);
            const item = newItems.find(x => x.rowKey === component.rowKey);
            if (item) {
                item.name = component.name;
                item.category = component.category;
                item.filePath = component.filePath;
            } else {
                newItems.push(component);
            }
            setItems(newItems)
            save([component])
        }
        setComponentToEdit(undefined)
    }

    const deleteComponent = (component: ApiComponent) => {
        ModalService.Open({
            title: `Are you sure you want to delete "${component.name}"?`,
            actionBar: <CoreButton onClick={() => confirmDeleteComponent(component)}>Confirm</CoreButton>,
            small: true
        })
    }

    const confirmDeleteComponent = (component: ApiComponent) => {
        ModalService.Close();
        ApiService.componentBuilderApi.Delete__POST(component, params.parentId).then(() => {
            NotificationService.Confirm('Component deleted')
            load()
        }).catch(() => {
            NotificationService.Error('Unable to delete component')
        })
    }

    const deleteComponentProperty = (component: ApiComponentProperty) => {
        ModalService.Open({
            title: `Are you sure you want to delete "${component.name}"?`,
            actionBar: <CoreButton onClick={() => confirmDeleteComponentProperty(component)}>Confirm</CoreButton>,
            small: true
        })
    }

    const confirmDeleteComponentProperty = (component: ApiComponentProperty) => {
        ModalService.Close();
        ApiService.componentBuilderApi.DeleteProperty__POST(component, params.parentId).then(() => {
            NotificationService.Confirm('Component property deleted')
            load()
        }).catch(() => {
            NotificationService.Error('Unable to delete component property')
        })
    }

    return (
        <>
            <DashboardHeader title='Component builder' icon='cog' />
            {componentToEdit && <ComponentModal item={componentToEdit} onClose={closeComponentModal} />}
            {componentPropertyToEdit && <PropertyModal item={componentPropertyToEdit} onClose={closePropertyModal} />}
            <br />
            {!items && <Loader />}
            {items && items.map(item => (
                <>
                    <Component>
                        <ContentContainer>
                            <ComponentInner onClick={() => setExpandedItems({ ...expandedItems, [item.rowKey]: !!!expandedItems[item.rowKey] })}>
                                {item.name}
                                <RightIcon name={!!expandedItems[item.rowKey] ? 'chevron-down' : 'chevron-right'} />
                            </ComponentInner>
                            <DeleteButton onClick={() => deleteComponent(item)}><Icon name='trash' /></DeleteButton>
                            <ComponentButton onClick={() => setComponentToEdit(item)}><Icon name='sliders' /></ComponentButton>
                        </ContentContainer>
                    </Component>
                    {!!expandedItems[item.rowKey] &&
                        <ComponentChildren>
                            {item.properties.map((property) => (
                                <Component>
                                    <ContentContainer>
                                        <ComponentInner onClick={() => setComponentPropertyToEdit(property)}>
                                            {property.name} <UrlLabel>{property.rowKey}</UrlLabel> <PropertyTypeLabel colorPalette='blue'>{property.type}</PropertyTypeLabel>
                                        </ComponentInner>
                                        <DeleteButton onClick={() => deleteComponentProperty(property)}><Icon name='trash' /></DeleteButton>
                                        <ComponentButton onClick={() => setComponentPropertyToEdit(property)}><Icon name='sliders' /></ComponentButton>
                                    </ContentContainer>
                                </Component>
                            ))}
                            <AddButton onClick={() => setComponentPropertyToEdit({ rowKey: createUUID(), componentId: item.rowKey })}>Add component property</AddButton>
                        </ComponentChildren>
                    }
                </>
            ))}
            {items && <AddButton onClick={() => setComponentToEdit({ rowKey: createUUID(), properties: [] })}>Add component</AddButton>}
        </>
    );
};

const AddButton = styled(DashboardAddButton)`
    margin-top: 0.5rem;
`

const ComponentDropLine = styled.div<{ active?: boolean }>`
    border-bottom: 2px solid ${props => props.active ? props.theme.secondary : 'rgba(255, 255, 255, 0)'};
    height: 1px;
`

const ComponentChildren = styled.div`
    padding-left: 2rem;
    margin-bottom: 1rem;
    position: relative;
`

const PlaceHolderTitle = styled.div`
    font-weight: bold;
    margin-bottom: 1rem;
`

const Component = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    z-index: 1;
`

const ComponentInner = styled.div`
    border: 1px solid ${props => props.theme.boxShadowBorder};
    ${BaseBoxShadowStyleWithHover}
    background: #FFF;
    padding: 1rem;
    flex: 1 0 auto;
`

const ContentContainer = styled.div`
    flex: 1 0 auto;
    display: flex;
    z-index: 1;
`

const DragIcon = styled(Icon)`
    cursor: grab !important;
`

const ComponentButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem;
    margin-left: 1rem;

    &:enabled {
        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};

        &:hover {
            background: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    }

    &:disabled {
        ${BaseBoxShadowStyle}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        opacity: 0.4;
    }
`

const DragButton = styled.button`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background: #FFF;
    padding: 1rem 0rem;
    margin-right: 0.5rem;
    cursor: grab !important;

    &:enabled {
        ${BaseBoxShadowStyleWithHover}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        &:hover {
            background: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    }

    &:disabled {
        ${BaseBoxShadowStyle}
        border: 1px solid ${props => props.theme.boxShadowBorder};
        opacity: 0.4;
    }
`

const RightIcon = styled(Icon)`
    float: right;
    margin-top: 0.3rem;
`

const DeleteButton = styled(ComponentButton)`
    &:hover {
        background: ${props => props.theme.negative} !important;
        color: ${props => props.theme.negativeContrast} !important;
    }
`

const UrlLabel = styled.span`
    font-size: 0.9rem;
    color: grey;
`

const PropertyTypeLabel = styled(Badge)`
    float: right;
`

export default ComponentBuilder;