import React from 'react';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledCurrencyInput, StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import Icon from '../../../../../../components/Media/Icon';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import { GetCurrencySymbol } from '../../../../../../utils/currency-helper';
import styled from 'styled-components';

interface ComponentProps {
    onRemove: () => void;
}

const DishOption = ({ onRemove }: ComponentProps) => {
    const [loaded, business] = useBusiness();

    return (
        <Row>
            <Column size={1}>
                <ArrowButton borderRaduisTop><Icon name='arrow-up' /></ArrowButton>
                <ArrowButton noBorderTop borderRaduisBottom><Icon name='arrow-down' /></ArrowButton>
            </Column>
            <Column size={7}>
                <StyledTextInput />
            </Column>
            <Column size={3}>
                <StyledCurrencyInput currencySymbol={GetCurrencySymbol(business.currencyCode)} />
            </Column>
            <Column size={1}>
                <CoreButton full type='danger'><Icon name='trash' onClick={onRemove} /></CoreButton>
            </Column>
        </Row>
    );
};

const ButtonWrapper = styled.div`
    
`

const ArrowButton = styled.div<{noBorderTop?: boolean, borderRaduisTop?: boolean, borderRaduisBottom?: boolean}>`
    border: 1px solid ${(props) => props.theme.borderColor};
    ${props => props.noBorderTop && 'border-top: none;'}
    ${props => props.borderRaduisTop && 'border-top-left-radius: 3px; border-top-right-radius: 3px;'}
    ${props => props.borderRaduisBottom && 'border-radius-bottom: 3px;'}
    text-align: center;
`

export default DishOption;