import React, { useState } from 'react';
import { ExtendedArea, TableSetupWrapper } from '.';
import { StyledDropdown, StyledTextInput } from '../../../../../../theme/input.styles';
import CoreButton from '../../../../../../components/Forms/Button';
import { cloneDeep } from 'lodash';
import { ApiService } from '../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../services/NotificationService';
import { Column } from '../../../../../../components/Layout/Grid';
import DashboardAddButton from '../../../../../../components/Dashboard/AddButton';
import Icon from '../../../../../../components/Media/Icon';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import FormWrapper from '../../../../../../components/Forms/FormWrapper';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { ModalService } from '../../../../../../services/ModalService';
import { DropdownItem } from '../../../../../../components/Forms/Dropdown';
import ActionBox from '../../../../../../components/Forms/Messaging';
import DataTable, { DataTableItem } from '../../../../../../components/Layout/Datatable';
import { DeleteRequest, ExperienceType } from '../../../../../../api/api-definitions';
import { priorityOptions } from '../bookingConstants';
import { capitalizeFirst } from '../../../../../../utils/string-helpers';

interface ComponentProps {
    areas: ExtendedArea[];
    selectedArea: ExtendedArea;
    businessId: number;
    selectArea: (id: number) => void;
    updateData: (shapes: ExtendedArea[]) => void;
    experienceId?: number;
    experienceType?: ExperienceType;
}

const defaultArea: ExtendedArea = {
    name: '',
    bookableOnline: true,
    active: true
}

const AreaForm = ({ areas, businessId, selectArea, updateData, experienceId, experienceType }: ComponentProps) => {
    const [editData, setEditData] = useState<ExtendedArea>(areas.length > 0 ? undefined : { ...defaultArea });
    const [editDataOpen, setEditDataOpen] = useState<boolean>(!experienceId && areas.length === 0);
    const [saving, setSaving] = useState<boolean>(false);

    const removeArea = (id: number) => {
        const deleteParams: DeleteRequest = {
            id: id
        }
        setSaving(true)
        ApiService.tablesetupareas.Delete__DELETE(deleteParams).then((response) => {
            if (response.success) {
                const newAreas = areas.filter(x => x.id !== id);
                updateData(newAreas)
                selectArea(newAreas.length > 0 ? newAreas[0].id : undefined);
                ModalService.Close();
                NotificationService.Confirm('Area removed successfully.')
            } else {
                NotificationService.Error('Cannot delete. Area has future bookings.')
            }
        }).catch(() => {
            NotificationService.Error('Cannot delete. Area has future bookings.')
        }).finally(() => setSaving(false))
    }

    const saveArea = () => {
        setSaving(true)
        editData.name = capitalizeFirst(editData.name);
        ApiService.tablesetupareas.Update__POST(editData).then(() => {
            const data = cloneDeep(areas);
            const areaIndex = data.findIndex(x => x.id === editData.id);
            data[areaIndex] = { ...editData };
            updateData(data);
            setEditData(undefined);
            setEditDataOpen(false);
            NotificationService.Confirm('Area saved');
        }).finally(() => setSaving(false))
    }

    const addArea = () => {
        setSaving(true)
        editData.name = capitalizeFirst(editData.name);
        const newArea = cloneDeep(editData);
        newArea.businessId = businessId;
        newArea.active = !!experienceId || newArea.active;
        newArea.experienceId = experienceId;
        ApiService.tablesetupareas.Insert__PUT(newArea).then(response => {
            const data = cloneDeep(areas);
            newArea.id = +response.info;
            data.push(newArea);
            updateData(data);
            selectArea(newArea.id);
            setEditData(undefined);
            setEditDataOpen(false);
            NotificationService.Confirm('Area added');
        }).finally(() => setSaving(false))
    }

    const onSaveClick = () => {
        if (editData.id) {
            saveArea();
        } else {
            addArea();
        }
    }

    const gentable = (): DataTableItem[] => {
        const items: DataTableItem[] = [];
        areas.forEach((item) => {
            if (!item.experienceId || item.experienceId == experienceId) {
                const dataItem: DataTableItem = {
                    data: {
                        'Area name': {
                            value: item.name,
                            sortable: true,
                        },
                        'Edit': {
                            value: !!experienceId && item.experienceId != experienceId ? '' : <CoreButton onClick={() => { setEditDataOpen(true); setEditData({ ...item }) }}><Icon name='pencil' /> Edit area</CoreButton>
                        },
                        'Delete': {
                            value: !!experienceId && item.experienceId != experienceId ? '' : <CoreButton onClick={() => ModalService.Open({
                                actionBar: <CoreButton type='danger' onClick={() => removeArea(item.id)}>Delete area</CoreButton>,
                                title: `Are you sure you want to delete "${item.name}"?`,
                                small: true
                            })} type='danger'><Icon name='trash' /> Delete area</CoreButton>
                        }
                    },
                }
                items.push(dataItem);
            }
        });
        return items;
    }

    return (
        <>
            {editDataOpen &&
                <FormWrapper<ExtendedArea> onUpdate={(formDetails) => setEditData({ ...editData, ...formDetails })}>
                    {({ id, valid }) => (
                        <CoreModal onClose={() => setEditDataOpen(false)} title={editData.id ? 'Edit area' : 'Add new area'}
                            actionBar={<CoreButton requesting={saving} disabled={!valid || saving} onClick={onSaveClick}>{editData.id ? 'Save area' : 'Add area'}</CoreButton>}>
                            <StyledTextInput required label='Area name' model='name' value={editData.name || ''} />
                            <br />
                            <Checkbox asToggle label='Bookable online' model='bookableOnline' checked={editData.bookableOnline} />
                            <br />
                            <br />
                            {!experienceId && <>
                                <Checkbox asToggle label='Active' model='active' checked={editData.active} />
                                <br />
                                <br />
                            </>
                            }
                            <StyledDropdown defaultText='No priority' value={editData.priority} items={priorityOptions} label='Priority' model='priority' />
                        </CoreModal>
                    )}
                </FormWrapper>
            }
            {areas.length === 0 &&
                <ActionBox title='Please set up an area to begin'>
                    <CoreButton onClick={() => { setEditDataOpen(true); setEditData({ ...defaultArea }) }}>Add area <Icon name='layer-group' light /></CoreButton>
                </ActionBox>
            }
            {areas.length > 0 &&
                <>
                    <DashboardAddButton onClick={() => { setEditDataOpen(true); setEditData({ ...defaultArea }) }}>Add{experienceId && ` ${experienceType.toLowerCase()}`} area <Icon name='layer-group' light /></DashboardAddButton>
                    <br />
                    <DataTable noResultsMessage={`${experienceId && experienceType ? `The are no custom areas set up for this ${experienceType.toLowerCase()}` : 'The are no areas set up'}`} data={gentable()} />
                </>
            }
        </>
    );
};

export default AreaForm;