import React, { useState, useRef } from 'react';
import { ApiBusinessBookingDetails, WidgetImageLayout, ThemeOptions, ExperienceType } from '../../../../api/api-definitions';
import styled from 'styled-components';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import Icon from '../../../../components/Media/Icon';
import ReadMore from '../../../../components/Typography/ReadMore';
import { Constants } from '../../../../constants';
import CoreModal from '../../../../components/Layout/CoreModal';

interface ComponentProps {
    title: string;
    description: string;
    price?: number;
    location?: string;
    image?: string;
    menuUrl?: string;
    business?: ApiBusinessBookingDetails;
    layout: WidgetImageLayout;
    isSelected?: boolean;
    onSelect?: (guid: string) => void;
    onSearch?: () => void;
    disabled?: boolean;
    guid: string;
    unavailable?: boolean;
    type: ExperienceType;
}

const BookingExperienceOption = ({
    guid,
    title,
    description,
    image,
    menuUrl,
    price,
    layout,
    location,
    unavailable,
    business,
    isSelected,
    disabled,
    onSelect,
    onSearch,
    type
}: ComponentProps) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuOpenRef = useRef(false);

    const menuToggle = (open: boolean) => {
        setMenuOpen(open);
        menuOpenRef.current = open;
    }
    const icon = type == ExperienceType.Experience ? 
                <Icon name='sparkles' duo doNotStyleDuo /> : 
                type == ExperienceType.Event ? 
                <Icon name='calendar' duo doNotStyleDuo /> :
                <></>
    return (
        <>
            {menuOpen &&
                <CoreModal title={title} full onClose={() => menuToggle(false)} cancelText='Close preview'>
                    <iframe src={Constants.baseImageUrl + menuUrl} style={{ width: '100%', height: '100%', border: 'none' }} />
                </CoreModal>
            }
            <Wrapper hidePointer={!onSelect} widgetTheme={business?.theme} onClick={() => onSelect && !menuOpenRef.current ? onSelect(isSelected ? undefined : guid) : {}} selected={isSelected}>
                {image && layout != WidgetImageLayout.Left && <TopImage style={{ backgroundImage: `url(${Constants.baseImageUrl}${image})` }} />}
                <ContentContainer leftImage={layout == WidgetImageLayout.Left}>
                    {image && layout == WidgetImageLayout.Left && <LeftImage src={`${Constants.baseImageUrl}${image}`} />}
                    <ContentFlexContainer>
                        <div>
                            <TitleContainer>
                                <Title textLeft={layout == WidgetImageLayout.Left || isNullOrWhitespace(image)}>{icon}{title}</Title>
                                {unavailable && <UnavailableBox>Unavailable</UnavailableBox>}
                            </TitleContainer>
                            {!isNullOrWhitespace(description) && <Description><ReadMore text={description} /></Description>}
                        </div>
                        {layout == WidgetImageLayout.Left && ((!isNullOrWhitespace(price) && price > 0) || !isNullOrWhitespace(location)) &&
                            <BottomContainer>
                                {!isNullOrWhitespace(location) && <Location><IconContainer><LocationIcon name='location-dot' /></IconContainer>{location}</Location>}
                                {!isNullOrWhitespace(price) && price > 0 && <Price><span>{ConvertCurrency({ code: 'GBP', amount: price })} per person</span></Price>}
                            </BottomContainer>
                        }
                    </ContentFlexContainer>
                </ContentContainer>
                {layout != WidgetImageLayout.Left && ((!isNullOrWhitespace(price) && price > 0) || !isNullOrWhitespace(location)) &&
                    <BottomContainer>
                        {!isNullOrWhitespace(location) && <Location><IconContainer><LocationIcon name='location-dot' /></IconContainer>{location}</Location>}
                        {!isNullOrWhitespace(price) && price > 0 && <Price><span>{ConvertCurrency({ code: 'GBP', amount: price })} per person</span></Price>}
                    </BottomContainer>
                }
                {!isNullOrWhitespace(menuUrl) && <ButtonOutline widgetTheme={business?.theme} onClick={() => menuToggle(true)}><Icon name='eye' /> View menu</ButtonOutline>}
                {onSelect && <SelectOptionBox selected={isSelected} widgetTheme={business?.theme}>{isSelected ? <><Icon name='check' /> Selected</> : 'Select option'}</SelectOptionBox>}
                {onSearch && <Button disabled={disabled} widgetTheme={business?.theme} onClick={() => onSearch()}><Icon name='magnifying-glass' /> Search availability</Button>}
            </Wrapper>
        </>
    );
};

const ContentFlexContainer = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const LeftImage = styled.img`
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    max-height: 10rem;
`;

const TopImage = styled.div`
    width: calc(100%);
    height: 150px;
    flex-shrink: 0;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FAFAFA;
    margin-bottom: 0.5rem;
`

const Button = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 0.5rem;
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;
    border: none;

    &:disabled {
        opacity: 0.7;
    }
`

const ButtonOutline = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 0.5rem;
    color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    border: 1px solid ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    background-color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }
`

const Wrapper = styled.div<{ widgetTheme?: ThemeOptions, selected?: boolean; hidePointer?: boolean; }>`
    border: ${props => props.selected ? '2px' : '1px'} solid ${props => props.widgetTheme?.primaryColour ? props.widgetTheme?.primaryColour : props.theme.primary};
    border-radius: 0.2rem;
    padding: 1rem;
    margin-bottom: 1rem;
    ${props => !props.hidePointer && `cursor: pointer`};
`

const Title = styled.div<{ colour?: string; textLeft?: boolean }>`
    font-weight: bold;
    flex: 1 1 auto;
    padding-right: 1rem;
    text-align: ${props => props.textLeft ? 'left' : 'center'};
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
`

const Location = styled.div`
    flex: 1 1 auto;
`

const LocationIcon = styled(Icon)`
    font-size: 0.8rem;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
`

const IconContainer = styled.div`
    width: 1.5rem;
    display: inline-block;
`

const TitleContainer = styled.div`
    display: flex;
`

const SelectOptionBox = styled.div<{ widgetTheme?: ThemeOptions, selected?: boolean }>`
    flex: none;
    border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    height: fit-content;
    padding: 0.5rem;
    text-align: center;
    ${props => props.selected && `
        background-color: ${props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    `}
`

const UnavailableBox = styled.div<{ widgetTheme?: ThemeOptions, selected?: boolean }>`
    flex: none;
    width: 9rem;
    height: fit-content;
    padding: 0.5rem;
    text-align: center;
    font-style: italic;
`

const Price = styled.div`
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1rem;
    margin-top: 1rem;
    text-align: right;
    flex: none;
`

const Description = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`

const BottomContainer = styled.div`
    // display: flex;
    margin: 1rem 0;
`

const ContentContainer = styled.div<{ leftImage?: boolean }>`
    display: flex;
    ${props => props.leftImage ? `
        ${LeftImage} {
            padding-right: 1rem;
            max-width: 50%;
        }
    ` : `
        flex-direction: column;
    `}
`

export default BookingExperienceOption;