import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import TerminalPayment from './TerminalPayment';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { BaseActiveBooking, BaseBookingPayment, BaseDepositRule, DepositPaymentType, DepositRuleType } from '../../../../../../api/api-definitions';
import Icon from '../../../../../../components/Media/Icon';
import { ApiService } from '../../../../../../api/api-connectors';
import CoreButton from '../../../../../../components/Forms/Button';
import { InfoMessage, WarningMessage } from '../../../../../../components/Forms/Messaging';
import { ConvertCurrency, GetCurrencySymbol } from '../../../../../../utils/currency-helper';
import { StyledCurrencyInput } from '../../../../../../theme/input.styles';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { NotificationService } from '../../../../../../services/NotificationService';
import { isNullOrWhitespace } from '../../../../../../utils/text-helpers';
import Loader from '../../../../../../components/Layout/Loader';

interface ComponentProps {
    booking: BaseActiveBooking;
    onClose: (payment?: BaseBookingPayment) => void;
    lockForm?: boolean;
    amount?: number;
    takeNow?: boolean;
    depositRuleToApply?: BaseDepositRule;
    totalTakenToDate?: number;
}

const CreateCustomerPayment = ({ booking, onClose, depositRuleToApply, lockForm = false, amount = undefined, takeNow = false, totalTakenToDate = 0 }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const [paymentMethod, setPaymentMethod] = useState<1 | 2>();
    const [charge, setCharge] = useState<number>(amount);
    const [chargeNow, setChargeNow] = useState(takeNow);
    const [loading, setLoading] = useState(true);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [depositRule, setDepositRule] = useState<BaseDepositRule>(depositRuleToApply);
    const paymentObject = useRef<BaseBookingPayment>();

    useEffect(() => {
        // @ts-ignore
        window.processPaymentConfirmation = processPaymentConfirmation;

        if (!lockForm && !depositRuleToApply) {
            ApiService.bookings.GetDepositRuleForBooking__POST(booking).then((rule) => {
                if (rule) {
                    const remainingAmount = (rule.amount * (rule.type == DepositRuleType.PerPerson ? booking.guests : 1) + rule.bookingFee) - totalTakenToDate;
                    setDepositRule(rule)
                    setCharge(remainingAmount > 0 ? remainingAmount : null)
                    setChargeNow(rule.paymentType == DepositPaymentType.TakeNow)
                }
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const createCharge = () => {
        setLoading(true);
        let ruleAmount: number = 0;
        if (depositRule) {
            ruleAmount = depositRule.amount * (depositRule.type == DepositRuleType.PerPerson ? booking.guests : 1) + depositRule.bookingFee;
        }
        (chargeNow ? ApiService.checkout.CreateInHousePaymentIntent__POST : ApiService.checkout.CreateInHouseSetupIntent__POST)({
            clientId: booking.clientId,
            businessId: business.id,
            amount: charge
        }).then((response) => {
            setLoading(false)
            paymentObject.current = {
                businessId: booking.businessId,
                clientId: booking.clientId,
                bookingId: booking.id,
                id: 0,
                intentId: '',
                setupIntent: !chargeNow,
                amount: charge,
                currency: business.currencyCode,
                fufilled: chargeNow,
                depositRuleId: depositRuleToApply || ruleAmount == charge ? depositRule?.id : undefined
            }
            window.open(`/dashboard/make-payment?intent=${response.info}&payNow=${chargeNow}&account=${business.stripeAccountKey}`, '_blank')
        })
    }

    const processPaymentConfirmation = (code: string) => {
        if (code) {
            paymentObject.current.intentId = code;
            ApiService.bookingPayment.AddBookingPayment__PUT(paymentObject.current).then(() => {
                setConfirmed(true);
            })
        } else {
            NotificationService.Error('Payment processing failed');
        }
    }

    const getChargeableText = () => {
        if (depositRule.paymentType == DepositPaymentType.TakeNow) return 'now';
        if (depositRule.paymentType == DepositPaymentType.TakeOnCancelAndNoShow) return 'when the customer cancels or no-shows';
        return 'when the customer no-shows';
    }

    return (
        <CoreModal
            onClose={() => onClose()}
            title={'Process customer payment'}
            small={!!depositRuleToApply}
            noClose={!!depositRuleToApply}
            hasCancel={!loading}
            cancelButton={!!depositRuleToApply && !loading && !confirmed ? <>
                <CoreButton type='warning' floatLeft disabled={isNullOrWhitespace(charge)} onClick={onClose}>Skip taking card details</CoreButton>
            </> : undefined}
            cancelText={confirmed ? 'Close' : 'Cancel'}
        >
            {loading && <Loader />}
            {!loading && !confirmed && !paymentMethod &&
                <>
                    {depositRule &&
                        <>
                            <InfoMessage>
                                <div>
                                    Based on payment rule setup, the recommended payment amount is&nbsp;<strong>{ConvertCurrency({ code: business?.currencyCode, amount: depositRule.amount * (depositRule.type == DepositRuleType.PerPerson ? booking.guests : 1) + depositRule.bookingFee })}</strong>&nbsp;based on&nbsp;<strong>{ConvertCurrency({ code: business.currencyCode, amount: depositRule.amount })} {depositRule.type == DepositRuleType.PerPerson ? 'per person' : 'per booking'}</strong>&nbsp;chargeable&nbsp;<strong>{getChargeableText()}</strong>{depositRule.bookingFee > 0 && <>&nbsp;which includes a booking fee of&nbsp;<strong>{ConvertCurrency({ code: business?.currencyCode, amount: depositRule.bookingFee })}</strong></>}
                                    {totalTakenToDate > 0 &&
                                        <>
                                            <br />
                                            <br />
                                            The customer has currently paid &nbsp;<strong>{ConvertCurrency({ code: business?.currencyCode, amount: totalTakenToDate })}</strong>&nbsp;
                                        </>
                                    }
                                </div>
                            </InfoMessage>
                            <br />
                        </>
                    }
                    <Row>
                        <Column size={4}>
                            <StyledCurrencyInput value={charge} min={1} onChange={(e) => setCharge(+e.target.value)} currencySymbol={GetCurrencySymbol(business?.currencyCode)} label='Payment amount' />
                        </Column>
                        <Column size={2} checkboxInput>
                            <Checkbox label='Charge now' inputName='chargeNow' asToggle checked={chargeNow} unlink onChange={() => setChargeNow(!chargeNow)} />
                        </Column>
                        <Column size={6} hidden={chargeNow}>
                            <WarningMessage>When choosing not to charge the client immediately, a temporary hold will be placed on the client's card which you can choose to apply later.</WarningMessage>
                        </Column>
                    </Row>
                </>
            }
            {!loading && !confirmed && !paymentMethod &&
                <>
                    <strong style={{ display: 'inline-block', marginBottom: '0.5rem' }}>Please select a payment method</strong>
                    <Row>
                        <Column size={6}>
                            <CoreButton disabled={!charge || charge < 1} full onClick={() => charge < 1 ? {} : createCharge()}><Icon name='globe' /> Manual card details entry</CoreButton>
                        </Column>
                        {/* <Column size={6}>
                            <CoreButton disabled={charge < 1} full onClick={() => charge < 1 ? {} : setPaymentMethod(2)}><Icon name='credit-card' /> Card machine</CoreButton>
                        </Column> */}
                    </Row>
                </>
            }
            {!loading && confirmed &&
                <SuccessBox>
                    <SuccessIcon name='badge-check' />
                    <br />
                    <br />
                    <br />
                    <SuccessTitle>{chargeNow ? 'Payment confirmed' : 'Authorisation charge confirmed'}</SuccessTitle>
                    <br />
                    <br />
                    <CoreButton onClick={() => onClose(paymentObject.current)}>Confirm</CoreButton>
                </SuccessBox>
            }
            {!loading && paymentMethod == 2 &&
                <TerminalPayment amount={charge} booking={booking} onClose={onClose} />
            }
        </CoreModal>
    );
};

export const SuccessIcon = styled(Icon)`
    color: ${props => props.theme.positive};
    width: 7rem;
    line-height: 2rem;
    font-size: 7rem;
    border-radius: 50%;
`

export const SuccessBox = styled.div`
    max-width: 30rem;
    margin: 2rem auto;
    font-size: 2rem;
    text-align: center;
    padding: 1rem;
    `

const SuccessTitle = styled.span`
    line-height: 2rem;
`

export default CreateCustomerPayment;