import React from 'react';
import { BookingQuickInfoItem, BookingQuickInfoTwo, InfoMessage } from '../../../booking.styles';
import Icon from '../../../../components/Media/Icon';
import moment from 'moment';
import { DATE_WITH_DAY_OF_WEEK_FORMAT, TIMEFORMAT } from '../../../../utils/date-helpers';
import { ApiBusinessBookingDetails } from '../../../../api/api-definitions';
import { BookingFormData } from '..';
import { WarningMessage } from '../../../../components/Forms/Messaging';

interface ComponentProps {
    business: ApiBusinessBookingDetails;
    formData: BookingFormData;
    selectedTime: string;
    selectedExperience: string;
    experienceTypeShouldShow: () => boolean;
    getSelectedExperienceName: () => string;
    getSelectedExperienceLocation: () => string;
}

const BookingSummary = ({
    business,
    formData,
    selectedTime,
    selectedExperience,
    experienceTypeShouldShow,
    getSelectedExperienceName,
    getSelectedExperienceLocation
}: ComponentProps) => {

    const showExperienceType = experienceTypeShouldShow();
    const experienceLocation = showExperienceType ? getSelectedExperienceLocation() : null;

    return (
        <>
            <BookingQuickInfoTwo>
                <BookingQuickInfoItem expand>
                    <Icon name='calendar' duo doNotStyleDuo /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}
                </BookingQuickInfoItem>
                <BookingQuickInfoItem>
                    <Icon name='clock' duo doNotStyleDuo /> {moment(selectedTime).format(TIMEFORMAT)}
                </BookingQuickInfoItem>
                <BookingQuickInfoItem>
                    <Icon name='user-friends' duo doNotStyleDuo /> {formData.guests}
                </BookingQuickInfoItem>
                {showExperienceType && <>
                    <br />
                    <BookingQuickInfoItem expand>
                        <Icon name='utensils' duo doNotStyleDuo /> {
                            getSelectedExperienceName()
                        }
                    </BookingQuickInfoItem>
                </>}
                {showExperienceType && experienceLocation &&
                    <><br/><Icon name='location-dot'></Icon>&nbsp;{experienceLocation}</>}
            </BookingQuickInfoTwo>
            {showExperienceType && experienceLocation &&
                <WarningMessage>Please note, the event will take place at the following address:<br />
                    {experienceLocation}
                </WarningMessage>}
            <br />
        </>
    );
};

export default BookingSummary;