import React from 'react';
import styled, { useTheme } from 'styled-components';

interface ComponentProps {
    name: string;
    regular?: boolean;
    light?: boolean;
    duo?: boolean;
    thin?: boolean;
    sharp?: boolean;
    kit?: boolean;
    far?: boolean;
    floatRight?: boolean;
    noMargin?: boolean;
    customMargin?: number;
    className?: string;
    primaryColor?: string;
    secondaryColor?: string;
    secondaryOpacity?: string;
    doNotStyleDuo?: boolean;
    onClick?: () => void;
}

interface IconProps {
  floatRight?: boolean;
  noMargin?: boolean;
  customMargin?: number;
}

const IconSpan = styled.span<IconProps>`
    ${(props) => props.noMargin ? 'margin-right: 0.2rem;' : 
                (props.customMargin ? `margin: 0 ${props.customMargin}rem;` : 'margin: 0 0.5rem;')}
    ${(props) => props.floatRight ? 'float: right;' : ''}
`;

const customIcons = [
  'dining-table'
]

const brandIcons = [
  'stripe',
  'paypal',
  'cc-paypal'
]

const CoreIcon = ({
  name,
  className,
  onClick,
  floatRight,
  noMargin,
  light,
  regular,
  duo,
  thin,
  sharp,
  kit,
  far,
  customMargin,
  primaryColor,
  secondaryColor,
  doNotStyleDuo,
  secondaryOpacity
}: ComponentProps) => {
  const theme = useTheme();
  let type = 'solid';
  if (regular) type = 'regular';
  if (light) type = 'light';
  if (duo) type = 'duotone';
  if (thin) type = 'thin';
  if (sharp) type = 'sharp';
  if (kit || customIcons.includes(name)) type = 'kit';
  if (brandIcons.includes(name)) type = 'brands';
  return (
    <IconSpan
      onClick={onClick}
      floatRight={floatRight}
      noMargin={noMargin}
      customMargin={customMargin}
      // @ts-ignore
      //style={duo && !doNotStyleDuo ? {"--fa-primary-color": primaryColor || theme.primary, "--fa-secondary-color": secondaryColor || theme.secondary, "--fa-secondary-opacity": secondaryOpacity || '0.5'} : undefined}
      className={`${className ? className : ''}${far ? ' far' : ` fa-${type}`} icon fa-${name}`}
    />
  );
};

export default CoreIcon;


// import React from 'react';
// import styled, { useTheme } from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-4a8173b851/icons'

// interface ComponentProps {
//     name: string;
//     regular?: boolean;
//     light?: boolean;
//     duo?: boolean;
//     thin?: boolean;
//     sharp?: boolean;
//     kit?: boolean;
//     far?: boolean;
//     floatRight?: boolean;
//     noMargin?: boolean;
//     customMargin?: number;
//     className?: string;
//     primaryColor?: string;
//     secondaryColor?: string;
//     secondaryOpacity?: string;
//     doNotStyleDuo?: boolean;
//     onClick?: () => void;
// }

// interface IconProps {
//   floatRight?: boolean;
//   noMargin?: boolean;
//   customMargin?: number;
// }

// const IconSpan = styled(FontAwesomeIcon)<IconProps>`
//     ${(props) => props.noMargin ? 'margin-right: 0.2rem;' : 
//                 (props.customMargin ? `margin: 0 ${props.customMargin}rem;` : 'margin: 0 0.5rem;')}
//     ${(props) => props.floatRight ? 'float: right;' : ''}
// `;

// const customIcons = [
//   'dining-table'
// ]

// const brandIcons = [
//   'stripe',
//   'paypal',
//   'cc-paypal'
// ]

// const Icon = ({
//   name,
//   className,
//   onClick,
//   floatRight,
//   noMargin,
//   light,
//   regular,
//   duo,
//   thin,
//   sharp,
//   kit,
//   far,
//   customMargin,
//   primaryColor,
//   secondaryColor,
//   doNotStyleDuo,
//   secondaryOpacity
// }: ComponentProps) => {
//   const theme = useTheme();
//   let type = 'fas';
//   if (regular) type = 'far';
//   if (light) type = 'fal';
//   if (duo) type = 'fad';
//   if (thin) type = 'fat';
//   if (sharp) type = 'fass';
//   if (kit || customIcons.includes(name)) type = 'fak';
//   if (brandIcons.includes(name)) type = 'fab';

//   return (
//     <IconSpan
//       onClick={onClick}
//       floatRight={floatRight}
//       noMargin={noMargin}
//       customMargin={customMargin}
//       icon={byPrefixAndName[type][name]}
//       // @ts-ignore
//       //style={duo && !doNotStyleDuo ? {"--fa-primary-color": primaryColor || theme.primary, "--fa-secondary-color": secondaryColor || theme.secondary, "--fa-secondary-opacity": secondaryOpacity || '0.5'} : undefined}
//       className={`${className ? className : ''} icon`}
//     />
//   );
// };

// export default Icon;