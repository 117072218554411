import React, { useRef, useState } from 'react';
import { BaseBookingDurationRule } from '../../../../../../../api/api-definitions';
import FormWrapper from '../../../../../../../components/Forms/FormWrapper';
import { Column, Row } from '../../../../../../../components/Layout/Grid';
import { ApiService } from '../../../../../../../api/api-connectors';
import { NotificationService } from '../../../../../../../services/NotificationService';
import { StyledCalendar, StyledDropdown, StyledNumberInput, StyledTimeInput } from '../../../../../../../theme/input.styles';
import Checkbox from '../../../../../../../components/Forms/Checkbox';
import { isNullOrWhitespace } from '../../../../../../../utils/text-helpers';
import { DayBox } from '../../BookingSetup';
import CoreButton from '../../../../../../../components/Forms/Button';
import CoreModal from '../../../../../../../components/Layout/CoreModal';
import Icon from '../../../../../../../components/Media/Icon';
import { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../../../components/Forms/Messaging';
import DataTable from '../../../../../../../components/Layout/Datatable';
import { generateBookingRulesTable } from '.';
import { formatDate, DATABASE_TIME_FORMAT, getHoursAndMinutesIntervalsFromMinutes, getMinDateForRuleSetup } from '../../../../../../../utils/date-helpers';
import Time from '../../../../../../../components/Forms/Time';
import { DateBandValidityItem } from '../../../../../../../components/Forms/Calendar';
import InfoButton from '../../../../../../../components/Cta/InfoButton';
import { BaseInputStyle, BaseLabelStyle } from '../../../../../../../theme/input.core.styles';
import styled from 'styled-components';
import moment from 'moment';

interface ComponentProps {
    rule: BaseBookingDurationRule;
    allRules: BaseBookingDurationRule[];
    close: () => void;
    experienceId?: number;
    validDateBands?: DateBandValidityItem[];
}

interface ExtendedBaseBookingDurationRule extends BaseBookingDurationRule {
    partySizeEnabled?: boolean;
    dateRangeEnabled?: boolean;
    timeRangeEnabled?: boolean;
}

const BookingRuleForm = ({ rule, allRules, close, experienceId, validDateBands }: ComponentProps) => {
    const [data, setData] = useState<ExtendedBaseBookingDurationRule>({
        ...rule,
        dateRangeEnabled: !isNullOrWhitespace(rule.dateFrom) || !isNullOrWhitespace(rule.dateTo),
        timeRangeEnabled: !isNullOrWhitespace(rule.timeFrom) || !isNullOrWhitespace(rule.timeTo),
        partySizeEnabled: !isNullOrWhitespace(rule.partySizeFrom) || !isNullOrWhitespace(rule.partySizeTo),
    });
    const [overlappingIds, setOverlappingIds] = useState<number[]>();
    const modalContentRef = useRef<HTMLDivElement>();
    const [saving, setSaving] = useState<boolean>(false);

    const save = () => {
        setSaving(true)
        if (experienceId) data.experienceId = experienceId;
        if (isNullOrWhitespace(data.partySizeTo)) data.partySizeTo = undefined;
        if (data.timeRangeEnabled) {
            if (!isNullOrWhitespace(data.timeFrom)) data.timeFrom = formatDate(data.timeFrom, DATABASE_TIME_FORMAT);
            if (!isNullOrWhitespace(data.timeTo)) data.timeTo = formatDate(data.timeTo, DATABASE_TIME_FORMAT);
        }
        if (!isNullOrWhitespace(data.dateFrom)) data.dateFrom = formatDate(data.dateFrom, DATABASE_TIME_FORMAT);
        if (!isNullOrWhitespace(data.dateTo)) data.dateTo = formatDate(data.dateTo, DATABASE_TIME_FORMAT);
        const apiEndpoint = data.id ? ApiService.bookingDurationRules.Save__POST : ApiService.bookingDurationRules.Add__PUT;
        apiEndpoint(data).then((response) => {
            if (response.errorMessage) {
                if (response.idsOfOverlappingRecords?.length > 0) {
                    setOverlappingIds(response.idsOfOverlappingRecords)
                    setTimeout(() => {
                        if (modalContentRef) modalContentRef.current.scrollTo({
                            top: 1000,
                            left: 0,
                            behavior: "smooth",
                        })
                    }, 200);
                } else {
                    NotificationService.Error(response.errorMessage)
                }
            } else {
                NotificationService.Confirm(data.id ? 'Rule saved' : 'Rule added');
                close()
            }
        }).catch(() => {
            NotificationService.Error('Sorry, there has been an error processing the request.')
        }).finally(() => setSaving(false))
    }

    const allSelected = data.monday && data.tuesday && data.wednesday && data.thursday && data.friday && data.saturday && data.sunday;

    const toggleSelectAll = () => {
        setData({
            ...data,
            monday: !allSelected,
            tuesday: !allSelected,
            wednesday: !allSelected,
            thursday: !allSelected,
            friday: !allSelected,
            saturday: !allSelected,
            sunday: !allSelected,
        })
    }

    const hoursChange = (hours: number) => {
        const totalHours = hours * 60;
        const minutes = +data.durationInMinutes % 60;
        setData({...data, durationInMinutes: totalHours + minutes})
    }

    const minutesChange = (minutes: number) => {
        const hours = Math.floor(+data.durationInMinutes / 60) * 60;
        setData({...data, durationInMinutes: hours + minutes})
    }

    const sameAsDefault = !experienceId && allSelected && !data.dateRangeEnabled && !data.partySizeEnabled && !data.timeRangeEnabled;

    return (
        <FormWrapper onUpdate={(formDetails) => setData({ ...data, ...formDetails })}>
            {({ id, valid }) => (
                <CoreModal
                    isOpen
                    title={data.id ? 'Edit duration rule' : 'Add new duration rule'}
                    onClose={close}
                    contentRef={modalContentRef}
                    warning={sameAsDefault ? 'Please set conditions for this rule to apply based on dates, times and/or party sizes.' : null}
                    actionBar={<>
                        <CoreButton requesting={saving} disabled={!valid || data.durationInMinutes <= 0 || sameAsDefault || saving} onClick={save}><Icon name='save' /> Save</CoreButton>
                    </>}>
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <Checkbox label='Party size' asToggle model='partySizeEnabled' checked={data.partySizeEnabled} />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledNumberInput required={data.partySizeEnabled} disabled={!data.partySizeEnabled} hideValue={!data.partySizeEnabled} model='partySizeFrom' value={data.partySizeFrom} max={data.partySizeTo} label='From' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledNumberInput placeholder='Leave blank for no limit' disabled={!data.partySizeEnabled} hideValue={!data.partySizeEnabled} model='partySizeTo' value={data.partySizeTo} min={data.partySizeFrom} 
                            label={data.partySizeEnabled ? 'To (inclusive)' : 'To'} />
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <Checkbox label='Date range' asToggle model='dateRangeEnabled' checked={data.dateRangeEnabled} />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledCalendar validDates={validDateBands} minDate={getMinDateForRuleSetup(data.dateFrom)} required={data.dateRangeEnabled} disabled={!data.dateRangeEnabled} hideValue={!data.dateRangeEnabled} model='dateFrom' value={data.dateFrom} label='From' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledCalendar placeholder='Leave blank for no limit' validDates={validDateBands} disabled={!data.dateRangeEnabled} hideValue={!data.dateRangeEnabled} model='dateTo' value={data.dateTo} minDate={getMinDateForRuleSetup(data.dateFrom, true)} label={data.dateRangeEnabled ? 'To (inclusive)' : 'To'} />
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <Checkbox label='Time range' asToggle model='timeRangeEnabled' checked={data.timeRangeEnabled} />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledTimeInput required={data.timeRangeEnabled} disabled={!data.timeRangeEnabled} hideValue={!data.timeRangeEnabled} model='timeFrom' value={data.timeFrom} label='From' />
                        </Column>
                        <Column size={4} mobile={6}>
                            <StyledTimeInput required={data.timeRangeEnabled} disabled={!data.timeRangeEnabled} hideValue={!data.timeRangeEnabled} model='timeTo' value={data.timeTo} min={data.timeFrom} label={data.timeRangeEnabled ? 'To (inclusive)' : 'To'} />
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={12}>
                            <br />
                            <CoreButton type='secondary' outline onClick={toggleSelectAll}>{allSelected ? 'Deselect' : 'Select'} all</CoreButton>
                        </Column>
                        <Column size={8} mobile={12}>
                            <label> Days of week</label>
                            <br />
                            <DayBox onClick={() => setData({ ...data, monday: !data.monday })} checked={data.monday}><Icon name={data.monday ? 'check' : 'times'} /> Mon</DayBox>
                            <DayBox onClick={() => setData({ ...data, tuesday: !data.tuesday })} checked={data.tuesday}><Icon name={data.tuesday ? 'check' : 'times'} /> Tue</DayBox>
                            <DayBox onClick={() => setData({ ...data, wednesday: !data.wednesday })} checked={data.wednesday}><Icon name={data.wednesday ? 'check' : 'times'} /> Wed</DayBox>
                            <DayBox onClick={() => setData({ ...data, thursday: !data.thursday })} checked={data.thursday}><Icon name={data.thursday ? 'check' : 'times'} /> Thu</DayBox>
                            <DayBox onClick={() => setData({ ...data, friday: !data.friday })} checked={data.friday}><Icon name={data.friday ? 'check' : 'times'} /> Fri</DayBox>
                            <DayBox onClick={() => setData({ ...data, saturday: !data.saturday })} checked={data.saturday}><Icon name={data.saturday ? 'check' : 'times'} /> Sat</DayBox>
                            <DayBox onClick={() => setData({ ...data, sunday: !data.sunday })} checked={data.sunday}><Icon name={data.sunday ? 'check' : 'times'} /> Sun</DayBox>
                        </Column>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                        <Column size={4} mobile={0} noMarginBottom></Column>
                        <Column size={4} mobile={12}>
                            <StyledDropdown
                                value={data.durationInMinutes}
                                model='durationInMinutes'
                                required
                                items={getHoursAndMinutesIntervalsFromMinutes(1440)}
                                label={<>
                                    Duration in minutes<InfoButton>This is the booking duration without the changeover time.
                                    </InfoButton>
                                </>}
                            />
                        </Column>
                        <Column size={4} mobile={0} noMarginBottom></Column>
                    </Row>
                    {overlappingIds &&
                        <>
                            <ErrorMessage>
                                Cannot save the rule as it conflicts with the below rule{overlappingIds.length > 1 ? 's' : ''}.
                                Please review and amend the rule setup before saving.
                            </ErrorMessage>
                            <br />
                            <DataTable data={generateBookingRulesTable(allRules.filter(x => overlappingIds.includes(x.id)), null, null, experienceId)} />
                        </>
                    }
                </CoreModal>
            )}
        </FormWrapper>
    );
};

const InputFlex = styled.div`
    display: flex;
`

export default BookingRuleForm;